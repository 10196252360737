import { Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Theme, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import { actions } from "../../store/Actions";
import { AutocompleteEmpresas, ButtonLoading, InputDate, InputDateTime, InputEntregador, SelectMultiple, TextFieldChip } from "..";
import { CamposTypes, FiltrosCampo, getFiltros } from "src/dtos/getFiltros";
import AutocompleteMultiple from "./AutocompleteMultiple";
import { FilterAltOutlined } from "@mui/icons-material";

type FormFieldType =
    'input' |
    'date' |
    'dateTime' |
    'select' |
    'checkbox' |
    'autocompleteEmpresa' |
    'customSelect' |
    'selectMultiple' |
    'autocompleteMultiple' |
    'tags' |
    'entregador';

export interface Field {
    label: string;
    name: string;
    type?: FormFieldType;
    rules?: RegisterOptions;
    options?: { [key: string]: any; }[];
    labelKey?: string;
    valueKey?: string;
    fullWidth?: boolean;

    ehFiltro?: boolean;
    filtroInline?: boolean;
    nameFiltro?: string;
    tipoFiltro?: CamposTypes;
    filtroCustomizado?: (nome: string, valor: any) => string;
    ou?: boolean;
    maiorQue?: boolean;
    menorQue?: boolean;
    igual?: boolean;
}

export interface DispatchMethods {
    actionName: keyof typeof actions;
    payload?: any;
}

export interface CardFormFiltrosProps {
    fields: Field[];
    ehFiltro?: boolean;
    formMethods: UseFormReturn<any>;
    dispatchMethods?: DispatchMethods;
    considerados?: any;
    consideradosFiltro?: FiltrosCampo;
    solicitadoParceiro?: any;
    // solicitadoParceiroFiltro?: FiltrosCampo;
    alocados?: any;
    // alocadosFiltro?: FiltrosCampo;
    reentrega?: any;
    reentregaFiltro?: FiltrosCampo;
    valueKey?: string;
    labelKey?: string;
    loading?: boolean;
    containerProps?: {
        elevation?: number;
        showHeader?: boolean;
        containerHasPadding?: boolean;
    }
}

export interface CardFormFiltrosRef {
    onSubmit: () => void;
    atualizarDispatch: boolean;
    fezRequisicao: boolean;
}

export const CardFormFiltros = forwardRef<CardFormFiltrosRef, CardFormFiltrosProps>(({
    fields,
    formMethods,
    dispatchMethods,
    loading,
    considerados,
    consideradosFiltro,
    solicitadoParceiro,
    // solicitadoParceiroFiltro,
    alocados,
    // alocadosFiltro,
    reentrega,
    reentregaFiltro,
    containerProps,
}, ref) => {
    const dispatch = useDispatch();
    const [atualizarDispatch, setAtualizarDispatch] = useState(false);
    const [fezRequisicao, setFezRequisicao] = useState(false);

    const {
        register,
        getValues,
        watch,
        setValue,
        handleSubmit,
    } = formMethods;

    const onSubmit = async (data: any) => {
        // Verifica se campos requeridos foram preenchidos
        // Fiz isso pois quando o componente é montado, o hookForm não 
        // consegue lidar com a validação de campos/rules
        const isRequiredValid = fields
            .filter((field) => field?.rules)
            .every((field) => {
                const isRequired = field.rules?.required;
                const hasValue = !!formMethods.getValues(field.name);
                return !isRequired || hasValue;
            });

        if (!isRequiredValid) {
            setTimeout(() => {
                formMethods.trigger();
            }, 1000)
            return
        };

        setAtualizarDispatch(true)
        const filteredData: any = {};

        const fieldNames = fields.map((field) => field.name);

        const processField = (parentKey: string, value: any) => {
            if (value !== null && typeof value === "object" && !Array.isArray(value)) {
                console.log(parentKey, value, typeof value === "object" && value !== null && !Array.isArray(value))
                Object.keys(value).forEach((key) => {
                    const fullKey = `${parentKey}.${key}`;
                    processField(fullKey, value[key]);
                });
            } else if (fieldNames.indexOf(parentKey) === -1 && value) {
                filteredData[parentKey] = value;
            }
        };

        Object.keys(data).forEach((key) => {
            const value = getValues(key);
            processField(key, value);
        });

        console.log(filteredData)

        if (data.pagina < 1) filteredData.pagina = 1;

        const filtrosData: FiltrosCampo[] = []
        fields.forEach((field) => {
            const fieldValue = getValues(field.name);
            if (fieldValue && typeof fieldValue === 'object') {
                if (Array.isArray(fieldValue)) {
                    console.log(field.name, fieldValue)
                    if (field?.ehFiltro) {
                        const campo = {
                            nome: field.name,
                            valor: fieldValue,
                            tipo: field.tipoFiltro,
                            filtroCustomizado: field.filtroCustomizado,
                            ou: field.ou,
                            maiorQue: field.maiorQue,
                            menorQue: field.menorQue,
                            igual: field.igual,
                        };
                        filtrosData.push(campo);
                    } else if (field?.filtroInline) {
                        filteredData[field.name] = fieldValue?.map((item: any) => item?.toString())?.join('|');
                    } else {
                        const valueKey = field.valueKey || 'value';
                        // Todo: talvez tenha que tratar este else de uma forma diferente, por enquanto ele manda para o filteredData: filteredData[field.value] = fieldValue
                        // Caso seja o valor do campo seja um obj, tenta acessar o valor com o valueKey, caso n exista, tenta pegar pelo padrão, que é 'value'. 
                        fieldValue.forEach((item: string | object, index) => {
                            if (typeof item === 'object') {
                                filteredData[`${field.name}[${index}]`] = item?.[valueKey as keyof typeof item]
                            } else {
                                filteredData[`${field.name}[${index}]`] = item
                                console.log('filteredData', filteredData);
                            }
                        });
                    }
                } else {
                    Object.keys(fieldValue)?.forEach((key) => {
                        const nomeCompleto = `${field.name}.${key}`;
                        const valorKey = fieldValue[key];
                        console.log('valorKey', nomeCompleto, valorKey);
                        if (field.ehFiltro) {
                            const campo = {
                                nome: nomeCompleto,
                                valor: valorKey,
                                tipo: field.tipoFiltro,
                                filtroCustomizado: field.filtroCustomizado,
                                ou: field.ou,
                                maiorQue: field.maiorQue,
                                menorQue: field.menorQue,
                                igual: field.igual,
                            };
                            filtrosData.push(campo);
                        } else {
                            filteredData[nomeCompleto] = valorKey;
                        }
                    });
                }

            } else {
                if (field?.ehFiltro) {
                    const campo = {
                        nome: field.nameFiltro ? field.nameFiltro : field.name,
                        valor: getValues(field?.name),
                        tipo: field.tipoFiltro,
                        filtroCustomizado: field.filtroCustomizado,
                        ou: field.ou,
                        maiorQue: field.maiorQue,
                        menorQue: field.menorQue,
                        igual: field.igual,
                    };
                    filtrosData.push(campo);
                } else {
                    const fieldValue = getValues(field.name);

                    if (typeof fieldValue === 'object' && fieldValue !== null) { // Verificação adicional   
                        Object.keys(fieldValue).forEach((key) => {
                        });
                    }
                    filteredData[field.name] = getValues(field.name);
                }

            }

        });

        let filtrosString = getFiltros(filtrosData);

        if (considerados && getValues('considerados')) {
            if (consideradosFiltro) {
                filtrosString = getFiltros([consideradosFiltro], filtrosString)
            } else {
                filteredData.entraFatura = 'entraFatura';
            }
        }

        if (solicitadoParceiro && getValues('solicitadoParceiro')) {
            filteredData.solicitadoEntregadorParceiro = 'solicitado';
        }

        if (alocados && getValues('alocados')) {
            filteredData.somenteAlocados = 'alocados';
        }

        if (reentrega && getValues('reentrega')) {
            if (reentregaFiltro) {
                filtrosString = getFiltros([reentregaFiltro], filtrosString)
            } else {
                filteredData.mostrarReentregas = 'mostrarReentregas';
            }
        }

        const finalData = {
            ...filteredData,
            filtros: filtrosString || filteredData?.filtros || undefined,
        };

        Object.keys(finalData).forEach((key) => {
            const value = finalData[key];
            if (typeof value === 'boolean' || value === '' || value === null || value === undefined) {
                delete finalData[key];
            }
        });

        if (dispatchMethods?.actionName) {
            const actionCreator = actions[dispatchMethods.actionName];
            if (actionCreator) {
                dispatch(actionCreator(finalData as any));
                if (!fezRequisicao) setFezRequisicao(true)
            }
        }
    };

    useImperativeHandle(ref, () => ({
        onSubmit: handleSubmit(onSubmit),
        atualizarDispatch: atualizarDispatch,
        fezRequisicao: fezRequisicao,
    }));

    const renderField = (field: Field, formMethods: UseFormReturn<any>) => {
        const { label, type = 'input', options, name, rules, valueKey, labelKey, fullWidth } = field;
        const styles = fullWidth ? { flex: '1 0 100%' } : { flex: '2 0 250px' };

        switch (type as FormFieldType) {
            case 'input':
                return (
                    <TextField
                        {...register(name, rules)}
                        label={label}
                        sx={styles}
                        size="small"
                    />
                );
            case 'date':
                return <InputDate sx={styles} hookForm={{ name, methods: formMethods, rules }} label={label} />;
            case 'dateTime':
                return <InputDateTime sx={styles} hookForm={{ name, methods: formMethods, rules }} label={label} />;
            case 'select':
                return (
                    <FormControl size="small" sx={styles}>
                        <InputLabel id={`${name}-label`}>{label}</InputLabel>
                        <Select
                            label={label}
                            labelId={`${label}-label`}
                            id={label}
                            {...register(name, rules)}
                            value={watch(name)}
                            onChange={(event) => setValue(name, event.target.value)}
                            sx={{ flex: '2 0 0px' }}
                            defaultValue={''}
                        >
                            <MenuItem value=''>
                                Selecione...
                            </MenuItem>
                            {Array.isArray(options) && options.map((option) => (
                                <MenuItem key={option.value} value={option.value?.toString()}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'selectMultiple':
                if (!valueKey || !labelKey) {
                    throw new Error('valueKey and labelKey are required for selectMultiple fields');
                }
                return (
                    <SelectMultiple
                        formControl={{
                            sx: styles,
                        }}
                        size="small"
                        label={label}
                        hookForm={{
                            methods: formMethods,
                            name,
                        }}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        options={options}
                    />
                )
            case 'autocompleteMultiple':
                return (
                    <AutocompleteMultiple
                        sx={styles}
                        size="small"
                        label={label}
                        hookForm={{
                            methods: formMethods,
                            name,
                        }}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        options={options || []}
                    />
                )
            case 'customSelect':
                const resolvedValueKey = field.valueKey || 'value';
                const resolvedLabelKey = field.labelKey || 'label';
                return (
                    <FormControl size="small" sx={styles}>
                        <InputLabel id={`${name}-label`}>{label}</InputLabel>
                        <Select
                            label={label}
                            labelId={`${label}-label`}
                            id={label}
                            {...register(name, rules)}
                            value={watch(name) || ''}
                            onChange={(event) => setValue(name, event.target.value)}
                            sx={{ gap: 2 }}
                            defaultValue={''}
                        >
                            <MenuItem value=''>
                                Selecione...
                            </MenuItem>
                            {Array.isArray(options) && options.map((option) => {
                                return (
                                    <MenuItem key={option[resolvedValueKey]} value={option[resolvedValueKey]}>
                                        {option[resolvedLabelKey]}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                );
            case 'checkbox':
                return (
                    <FormControlLabel
                        control={<Checkbox {...register(name, rules)} />}
                        label={label}
                        sx={styles}
                    />
                );
            case 'autocompleteEmpresa':
                return (
                    <AutocompleteEmpresas
                        label={label}
                        hookForm={{
                            methods: formMethods,
                            name,
                            rules,
                        }}
                        sx={styles}
                    />
                );
            case 'tags':
                return (
                    <TextFieldChip
                        sx={styles}
                        label={label}
                        hookForm={{
                            methods: formMethods,
                            name,
                            rules,
                        }}
                    />
                )
            case 'entregador':
                return (
                    <InputEntregador
                        sx={styles}
                        hookForm={{
                            methods: formMethods,
                            name,
                            rules,
                        }}
                    />
                )
            default:
                return null;
        }
    };

    useEffect(() => {
        if (atualizarDispatch) {
            setAtualizarDispatch(false)
        }
    }, [atualizarDispatch])

    return (
        <Card
            elevation={containerProps?.elevation ?? 1}
            sx={{ borderRadius: 2, overflow: 'visible' }}
        >
            {(containerProps?.showHeader ?? true) && (
                <CardContent>
                    <Box display="flex" alignItems="center" gap={1}>
                        <FilterAltOutlined color="primary" />
                        <Typography
                            variant="h6"
                            onDoubleClick={async () => {
                                const isValid = await formMethods.trigger();
                                console.group('Informações tabela: ')
                                console.log('getValues', isValid, formMethods.getValues());
                                console.log('isValid', isValid);
                                console.log('FezRequisicao', fezRequisicao);
                                console.groupEnd()
                            }}
                        >
                            Filtros
                        </Typography>
                    </Box>
                </CardContent>
            )}

            <CardContent
                sx={!(containerProps?.containerHasPadding ?? true) ? {
                    p: 0,
                    '&:last-child': { pb: 0 }
                } : {}}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        gap={2}
                    >
                        {fields?.map((field) => (
                            <React.Fragment key={field.name}>
                                {renderField(field, formMethods)}
                            </React.Fragment>
                        ))}
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="left"
                        flexWrap={'wrap'}
                        sx={{ paddingTop: 2, gap: 2 }}
                    >
                        {considerados && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('considerados')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Somente Considerados na Fatura
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {solicitadoParceiro && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('solicitadoParceiro')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Solicitado entregador parceiro
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {alocados && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('alocados')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Somente alocados
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                        {reentrega && (
                            <Box
                                sx={(theme: Theme) => ({
                                    borderRadius: theme.sizes.borderRadius.xs,
                                    pr: theme.sizes.padding.sm,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                })}
                            >
                                <FormControlLabel
                                    control={<Checkbox {...register('reentrega')} />}
                                    label={
                                        <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                            Pedidos reentrega
                                        </Typography>
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="flex-end" sx={{ width: '100%', mt: 2 }}>
                        <ButtonLoading
                            type="submit"
                            variant="containedFilled"
                            loading={loading}
                            sx={{
                                width: 'min-content',
                            }}
                        >
                            Filtrar
                        </ButtonLoading>
                    </Box>
                </form>
            </CardContent>
        </Card >
    );
});
