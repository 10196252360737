import { useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DynamicDataContainer } from "src/ds";
import { Field } from "src/ds/components/CardFormFiltros";
import { RootState } from "src/store/reducers";
import timer from "../../assets/icons/time.svg";
import motorcycle from "../../assets/icons/motorcycle.svg";
import bag from "../../assets/icons/bag.svg";
import TabelaPerformance from "src/components/PerformanceEntregador/TabelaPerformance";
import formatTimeFromMinutes from "src/ds/utils/formatMinutes";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const PerformanceEntregador = () => {
    const cardRef = useRef<DynamicDataContainerRef>(null);
    const theme = useTheme();
    const data = useSelector((state: RootState) => state.entregadores.listarPerformanceEntregador.data?.dados);
    const exportarPerformanceEntregadores = useSelector((state: RootState) => state.entregadores.exportarPerformanceEntregador.data);
    const loading = useSelector((state: RootState) => state.entregadores.listarPerformanceEntregador.loading)
    const loadingExportar = useSelector((state: RootState) => state.entregadores.exportarPerformanceEntregador.loading)
    const dispatch = useDispatch()

    const formFields: Field[] = [
        { label: 'Entregador', name: 'usuarioID', type: 'entregador' },
        { label: 'Data inicial', name: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
        { label: 'Data final', name: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
    ]

    const hookForm = useForm({
        defaultValues: {
            dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
            dtFinal: dayjs().format('DD/MM/YYYY 00:00'),
            usuarioID: '',
            paginacao: 20,
            pagina: 1
        },
    })

    const menuOptions = [{
        label: 'Exportar',
        action: () => {
            dispatch(exportarPerformanceEntregadores(hookForm.getValues()))
        }
    }]

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                fields: formFields,
                dispatchMethods: { actionName: 'listarPerformanceEntregadorStart' },
                loading: loading,
            }}
            cardTabelaTotalProps={{
                cardData: [
                    {
                        srcIcon: bag,
                        altIcon: "Ícone quantidade total pedidos",
                        bgcolor: theme.palette.blue[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL DE PEDIDOS",
                            subtitle1: data?.totalPedidos ? data?.totalPedidos : "0",
                        },
                    },
                    {
                        srcIcon: timer,
                        altIcon: "Ícone quantidade total tempo médio",
                        bgcolor: theme.palette.grey[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL TEMPO MÉDIO",
                            subtitle1: data?.mediaTempoOnline ? formatTimeFromMinutes(data?.mediaTempoOnline) : "0",
                        },
                    },
                    {
                        srcIcon: motorcycle,
                        altIcon: "Quantidade total entregadores",
                        bgcolor: theme.palette.red[600],
                        texts: {
                            title1: "QUANTIDADE TOTAL ENTREGADORES",
                            subtitle1: data?.totalEntregadores ?? "0",
                        },
                    },
                ]
            }}
            sections={[{
                title: 'Performance por entregador',
                label: 'Performance por entregador',
                tableNavigation: {
                    indiceInicial: data?.indiceInicial,
                    indiceFinal: data?.indiceFinal,
                    indiceTotal: data?.total,
                    ultimaPagina: data?.ultimaPagina,
                    podeTodos: false
                },
                menu: {
                    options: menuOptions,
                    loading: loadingExportar,
                },
                component: <TabelaPerformance
                    data={data?.lista}
                    loading={loading}
                    hookForm={hookForm}
                />
            }]}
        />
    );
};

export default PerformanceEntregador;
