import { Box, Button, Typography, Grid } from "@mui/material";
import { dinheiro } from "../../../ds/common";
import { useDispatch, useSelector } from "react-redux";
import { despacharRequest } from "../../../store/reducers/criarPedido";
import { BoxLoading, ModalBase } from "src/ds";
import { RootState } from "src/store/reducers";

interface ModalCalculosProps {
    open: boolean;
    handleClose: () => void;
    payloadzin: any;
}

const ModalCalculos: React.FC<ModalCalculosProps> = ({ open, handleClose, payloadzin }) => {
    const pedido: any = useSelector((state: RootState) => state.criarPedido.atribuir?.data);
    const loading: boolean = useSelector((state: RootState) => state.criarPedido.atribuir?.loading);
    const dispatch = useDispatch();

    const handleConfirmar = () => {
        let corrigido = { ...payloadzin };
        if (payloadzin?.orcamento === true) {
            corrigido.orcamento = false;
        }
        if (payloadzin?.cobrarPorKmTotal === false) {
            corrigido.cobrarPorKmTotal = false;
        }
        dispatch(despacharRequest(corrigido));
        handleClose();
    };

    return (
        <ModalBase
            open={open}
            onClose={handleClose}
            size="sm"
            headerModal={{ title: "Confirme a rota selecionada", onClose: handleClose }}
        >
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <BoxLoading />
                </Box>
            ) : (
                <Box p={2}>
                    <Box mb={2}>
                        {pedido?.memoriaCalculo?.map((item: any, index: number) => (
                            <Typography key={index} variant="body1">
                                {item}
                            </Typography>
                        ))}
                    </Box>
                    <Box mb={2} bgcolor={"#f5f5f5"} textAlign={"center"}>
                        <Typography variant="body1">
                            <strong>KM Total:</strong> {pedido?.totalKm?.toFixed(2) || "N/A"}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Total:</strong> {dinheiro(pedido?.totalCobrado)}
                        </Typography>
                    </Box>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="containedFilled" color="primary" onClick={handleConfirmar}>
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </ModalBase>
    );
};

export default ModalCalculos;
