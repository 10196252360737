import { BoltOutlined, MapOutlined } from "@mui/icons-material";
import { Box, Button, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { BotaoSituacao, sanitizeSituacao, findOrigemImg } from "src/ds";
import { Pedido } from "src/dtos/PedidosDTO";

export const CustomTableHead = ({ headers }: { headers: string[] }) => (
    <TableHead sx={{ backgroundColor: 'grey.50' }}>
        <TableRow>
            {headers.map((header, index) => (
                <TableCell
                    key={index}
                    sx={{
                        textAlign: 'center',
                        padding: '8px',
                        borderRadius: index === 0 ? '8px 0 0 8px' : index === headers.length - 1 ? '0 8px 8px 0' : '0',
                        color: 'grey.600',
                    }}
                >
                    {header}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);

export const CustomTableRow = ({ pedido, isLast, template, toggleModalStatus }: { pedido: Pedido; isLast: boolean, template: any, toggleModalStatus: any }) => (

    <TableRow
        sx={{
            backgroundColor: 'grey.50',
            '&:not(:last-child)': {
                borderBottom: '1px solid #ddd',
            },

        }}
    >
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={findOrigemImg(pedido?.aplicacaoOrigemNome, template?.faviconUrl)}
                    alt="Logo"
                    style={{ width: 40, height: 40, marginRight: 8, borderRadius: '8px' }}
                />
                <Typography>{pedido?.aplicacaoOrigemNome}</Typography>
            </Box>
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Typography sx={{ fontWeight: 'bold' }}>{pedido?.codigoQuatro}</Typography>
            <Typography variant="body2" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Editar</Typography>
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Typography><strong>{pedido?.empresa?.nome}</strong></Typography>
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Tooltip title={pedido?.clienteNome || ''} placement="top" arrow>
                <Typography>
                    {(() => {
                        const fullName = pedido?.clienteNome?.trim() || '';
                        const words = fullName.split(' ');
                        if (words.length === 1) {
                            return words[0];
                        }
                        return `${words[0]} ${words[words.length - 1]}`;
                    })()}
                </Typography>
            </Tooltip>
        </TableCell>

        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Typography>
                <strong>Endereço:</strong> {pedido?.entregaEnderecoStr}
            </Typography>
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
            align="center"
        >
            <Typography>{pedido?.entregadorUsuario?.nome || '-'}</Typography>
        </TableCell>
        <TableCell sx={{ borderRight: '1px solid #ddd' }} align="center">
            {pedido?.formaPagamento?.nome}
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
                flex: 1,
                width: 'min-content'
            }}
        >
            <Box sx={{ flex: '1' }}>

                <BotaoSituacao situacao={sanitizeSituacao(pedido)} sx={{ maxWidth: '100%' }} onClick={toggleModalStatus}>
                    {sanitizeSituacao(pedido)}
                </BotaoSituacao>
            </Box>
        </TableCell>
        <TableCell
            sx={{
                borderRight: '1px solid #ddd',
            }}
        >
            <Typography>{pedido?.tempoDecorridoCadastro || 'N/A'}</Typography>
        </TableCell>
        <TableCell>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                <Button variant="contained" color="grey" size="small">
                    <MapOutlined />
                </Button>
                <Button variant="contained" color="grey" size="small">
                    <BoltOutlined />
                </Button>
                <Button variant="containedFilled" color="primary" size="small" sx={{ minWidth: '100px', fontSize: '12px' }}>
                    Selecionar
                </Button>
            </Box>
        </TableCell>
    </TableRow>
);
