import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { excluirTabelaPorKmStart, listarPorKmCalculadoStart } from "src/store/reducers/tabelaPagamento";
import { dinheiro } from "src/ds/common";
import ModalEditarKmCalculado from "./ModalEditarKmCalculado";
import { ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound } from "src/ds";
import { RootState } from "src/store/reducers";

const EditarKmCalculado = ({ dados }: any) => {
    const dispatch = useDispatch();

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
            filtros: "tabelaValorID ==" + `"` + dados?.tabelaValorID + `"`
        },
    });

    const itens = useSelector((state: any) => state.tabelaPagamento.porKmCalculado.data?.dados);
    const loading = useSelector((state: any) => state.tabelaPagamento.porKmCalculado.loading);
    const itensDados = itens?.lista;
    const [openEditar, setOpenEditar] = useState(false);
    const [openDeletar, setOpenDeletar] = useState(false);
    const [item, setItem] = useState<any>();
    const response = useSelector((state: RootState) => state.tabelaPagamento.salvarTabelaPorKm);

    const listarKmCalculado = () => {
        const valores = hookForm.getValues();
        dispatch(listarPorKmCalculadoStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    }

    useEffect(() => {
        listarKmCalculado();
    }, [response?.data, dados?.tabelaValorID]);

    const handleEditar = (item: any) => {
        setOpenEditar(true);
        setItem(item);
    };

    const handleDeletar = (item: any) => {
        setItem(item);
        setOpenDeletar(true);
    };

    const confirmarDeletar = () => {
        dispatch(excluirTabelaPorKmStart(item?.tabelaValorKmCalculadoID));
        setOpenDeletar(false);
    };

    useEffect(() => {
        const valores = hookForm.getValues();
        dispatch(listarPorKmCalculadoStart({
            pagina: valores.pagina,
            paginacao: valores.paginacao,
            filtros: valores.filtros,
        }));
    }, [hookForm.watch('pagina'), hookForm.watch('paginacao')]);
    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => {
                    setOpenEditar(true);
                    setItem(dados);
                }}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo valor
            </Button>

            <Stack sx={{ flex: 1, gap: 2 }}>
                {itens?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={itens.indiceInicial}
                        indiceFinal={itens.indiceFinal}
                        indiceTotal={itens.total}
                        ultimaPagina={itens?.ultimaPagina}
                        hookForm={hookForm}
                    />

                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Km Inicial</TableCell>
                            <TableCell align="left">Km Final</TableCell>
                            <TableCell align="left">Valor</TableCell>
                            <TableCell align="left">Taxa de Retorno</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : itensDados?.length > 0 ? (
                            itensDados.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">{item.kmInicial}</TableCell>
                                    <TableCell align="left">{item.kmFinal}</TableCell>
                                    <TableCell align="left">{dinheiro(item.valor)}</TableCell>
                                    <TableCell align="left">{dinheiro(item.taxaRetorno)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="edit" size="small" onClick={() => handleEditar(item)}>
                                            <Edit color="warning" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small" onClick={() => handleDeletar(item)}>
                                            <Delete color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>

            <ModalEditarKmCalculado open={openEditar} handleClose={() => setOpenEditar(false)} dados={item} />

            <ModalOpcoes
                descricao={(
                    <Typography>
                        Tem certeza que deseja excluir o valor de <span style={{ fontWeight: 'bold' }}>{item?.kmInicial}</span>km a <span style={{ fontWeight: 'bold' }}>{item?.kmFinal}</span>km?
                    </Typography>
                )}
                acao='Excluir'
                open={openDeletar}
                onClose={() => setOpenDeletar(false)}
                opcoes={[
                    { label: 'Cancelar', action: () => setOpenDeletar(false) },
                    { label: 'Excluir', action: confirmarDeletar },
                ]}
            />
        </Stack>
    );
};

export default EditarKmCalculado;
