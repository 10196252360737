import { CardContent, styled, Theme } from "@mui/material";
import { hexToRgb } from "..";

export const CardPrincipal = styled(CardContent)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.sizes.borderRadius.sm,
    padding: `${theme.sizes.padding.xs} ${theme.sizes.padding.sm}`,
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: `0px 0px 4px 0px rgba(${hexToRgb(theme.palette.getContrastText(theme.palette.background.default))}, 0.2)`,
    border: '1px solid #ccc',
    marginBottom: theme.spacing(2),
    minHeight: 'fit-content',
    height: 'auto',
    paddingTop: theme.spacing(1),
    overflow: 'hidden',
    '&:hover': {
        boxShadow: `0px 0px 7px 0px rgba(${hexToRgb(theme.palette.getContrastText(theme.palette.background.default))}, 0.3)`,
    },
    '&:last-child': {
        paddingBottom: theme.sizes.padding.sm,
    }
}));

export const CardPedidoContent = styled(CardContent)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default,
}))
