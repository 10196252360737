import { useState } from 'react';
import { Box, Typography, useTheme, Grid2, SxProps, Theme, TableBody, Table } from '@mui/material';
import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, Sector } from 'recharts';
import { TableRowLoading } from 'src/ds';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { UseFormReturn } from 'react-hook-form';

interface DashboardPerformanceProps {
    formMethods: UseFormReturn<any>;
    loading: boolean;
    fezRequisicao?: boolean;
    atualizar?: boolean;
}

const useChartHover = () => {
    const [activeIndexes, setActiveIndexes] = useState<{ [key: string]: number | null }>({});

    const onLegendHover = (chartId: string, index: number) => {
        setActiveIndexes((prev) => ({ ...prev, [chartId]: index }));
    };

    const onLegendLeave = (chartId: string) => {
        setActiveIndexes((prev) => ({ ...prev, [chartId]: null }));
    };

    return { activeIndexes, onLegendHover, onLegendLeave };
};

const DashboardPerformance = ({ formMethods, loading, atualizar, fezRequisicao }: DashboardPerformanceProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { getValues } = formMethods;
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const { activeIndexes, onLegendHover, onLegendLeave } = useChartHover();

    const porcentagemNegativo = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.porcentagemNegativos);
    const porcentagemPositivo = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.porcentagemPositivos);
    const porcentagemZero = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.registrosTempoZero);

    const faixa0a5 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_0_a_5);
    const faixa5a10 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_5_a_10);
    const faixa10a15 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_10_a_15);
    const faixa15a20 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_15_a_20);
    const faixa20a25 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_20_a_25);
    const faixa30a35 = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_30_a_35);

    const mediaTempoPedidoPronto = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.mediaTempoPedidoPronto);
    const mediaTempoRotaEntregador = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.mediaTempoRotaEntregador);
    const tempoMedioDespacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.tempoMedioDespacho);

    const media0a5Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_0_a_5_despacho);
    const media5a10Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_5_a_10_despacho);
    const media10a15Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_10_a_15_despacho);
    const media15a20Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_15_a_20_despacho);
    const media20a30Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_20_a_30_despacho);
    const media30a40Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_30_a_40_despacho);
    const media40a50Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_40_a_50_despacho);
    const media50a60Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_50_a_60_despacho);

    const media0a5Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_0_a_5_producao);
    const media5a10Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_5_a_10_producao);
    const media10a15Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_10_a_15_producao);
    const media15a20Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_15_a_20_producao);
    const media20a30Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_20_a_30_producao);
    const media30a40Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_30_a_40_producao);
    const media40a50Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_40_a_50_producao);
    const media50a60Producao = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.media_50_a_60_producao);

    const faixa0a5Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_0_a_5);
    const faixa5a10Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_5_a_10);
    const faixa10a15Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_10_a_15);
    const faixa15a20Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_15_a_20);
    const faixa20a25Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_20_a_25);
    const faixa30a35Despacho = useSelector((state: RootState) => state.pedidos.performancePedidos.data?.dados?.faixa_30_a_35);

    if (loading) {
        return (
            <Table>
                <TableBody>
                    <TableRowLoading />
                </TableBody>
            </Table>
        );
    }

    const colors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.blue[600], theme.palette.primary.dark, theme.palette.secondary.light, theme.palette.warning.main, theme.palette.warning.dark];

    const porcentagemData = [
        { name: 'Fora do prazo', value: porcentagemPositivo },
        { name: 'Dentro do prazo', value: (porcentagemNegativo || 0) + (porcentagemZero || 0) }
    ];


    const faixaData = [
        { name: '0 a 5 min', value: media0a5Producao },
        { name: '5 a 10 min', value: media5a10Producao },
        { name: '10 a 15 min', value: media10a15Producao },
        { name: '15 a 20 min', value: media15a20Producao },
        { name: '20 a 30 min', value: media20a30Producao },
        { name: '30 a 40 min', value: media30a40Producao },
        { name: '40 a 50 min', value: media40a50Producao },
        { name: '50 a 60 min', value: media50a60Producao }
    ];

    const despachoData = [
        { name: '0 a 5 min', value: media0a5Despacho },
        { name: '5 a 10 min', value: media5a10Despacho },
        { name: '10 a 15 min', value: media10a15Despacho },
        { name: '15 a 20 min', value: media15a20Despacho },
        { name: '20 a 30 min', value: media20a30Despacho },
        { name: '30 a 40 min', value: media30a40Despacho }
    ];

    const rotaData = [
        { name: '0 a 5 min', value: faixa0a5 },
        { name: '5 a 10 min', value: faixa5a10 },
        { name: '10 a 15 min', value: faixa10a15 },
        { name: '15 a 20 min', value: faixa15a20 },
        { name: '20 a 25 min', value: faixa20a25 },
        { name: '30 a 35 min', value: faixa30a35 }
    ];

    const stylesBox: SxProps<Theme> = {
        width: '100%',
        border: '1px solid #E0E0E0',
        borderRadius: '10px',
        p: 2,
        overflow: 'auto',
        scrollbarWidth: 'thin'
    }

    const totalValue = despachoData?.reduce((acc, curr) => acc + (curr.value || 0), 0);

    const totalFaixa = faixaData.reduce((acc, item) => acc + (item.value ?? 0), 0);

    const faixaDataWithPercentage = faixaData.map(item => {
        const calculatedPercentage = totalFaixa ? ((item.value ?? 0) / totalFaixa * 100) : 0;
        const adjustedPercentage = calculatedPercentage < 0.1 && calculatedPercentage > 0 ? 0.1 : calculatedPercentage;
        return {
            ...item,
            percentage: adjustedPercentage.toFixed(1),
        };
    });

    const despachoDataWithPercentage = despachoData.map((item) => ({
        ...item,
        percentage: ((item.value ? item.value : 0) / totalValue * 100).toFixed(1)
    }));

    const totalRota = rotaData.reduce((acc, item) => acc + (item.value ?? 0), 0);
    const rotaDataWithPercentage = rotaData.map((item) => ({
        ...item,
        percentage: totalRota ? ((item.value ?? 0) / totalRota) * 100 : 0, // Porcentagem inicial
    }));
    const totalPercentage = rotaDataWithPercentage.reduce((acc, item) => acc + item.percentage, 0);
    const adjustment = 100 - totalPercentage; // Diferença necessária para ajustar para 100%
    if (adjustment !== 0) {
        const maxIndex = rotaDataWithPercentage.reduce(
            (maxIdx, item, idx, array) => (item.percentage > array[maxIdx].percentage ? idx : maxIdx),
            0
        );
        rotaDataWithPercentage[maxIndex].percentage += adjustment; // Ajusta o maior valor
    }

    // Adiciona porcentagens formatadas
    const rotaDataFinal = rotaDataWithPercentage.map((item) => ({
        ...item,
        percentage: item.percentage.toFixed(1), // Formata a porcentagem com uma casa decimal
    }));



    return (
        <Box sx={{ p: 2, gap: 2 }}>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, lg: 6 }} sx={stylesBox}>
                    <Typography variant="h6">Tempo de produção por faixa de minutos</Typography>
                    {faixaDataWithPercentage.length > 0 ? (
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={faixaDataWithPercentage}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill={theme.palette.secondary.main}
                                    //@ts-ignore
                                    activeIndex={activeIndexes['faixaData']}
                                    activeShape={(props: any) => (
                                        <Sector
                                            cx={props.cx}
                                            cy={props.cy}
                                            innerRadius={props.innerRadius}
                                            outerRadius={props.outerRadius + 10}
                                            startAngle={props.startAngle}
                                            endAngle={props.endAngle}
                                            fill={props.fill}
                                        />
                                    )}
                                    label={({ index }) => {
                                        const entry = faixaDataWithPercentage[index];
                                        if (parseFloat(entry.percentage) >= 1 || index === activeIndexes['faixaData']) {
                                            return `${entry.name}: ${entry.percentage}%`;
                                        }
                                        return '';
                                    }}
                                    isAnimationActive={false}
                                >
                                    {faixaDataWithPercentage.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value, name, entry) => `${entry.payload.percentage}%`} />
                                <Legend
                                    onMouseEnter={(e) => {
                                        const index = faixaDataWithPercentage.findIndex(item => item.name === e.value);
                                        if (index !== -1) {
                                            onLegendHover('faixaData', index);
                                        }
                                    }}
                                    onMouseLeave={() => onLegendLeave('faixaData')}
                                    formatter={(value) => `${value}`}
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    )
                        :
                        (
                            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
                                <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
                            </Box>
                        )}
                </Grid2>


                <Grid2 size={{ xs: 12, lg: 6 }} sx={stylesBox}>
                    <Typography variant="h6">Tempo de despacho por faixa de minutos</Typography>
                    {despachoDataWithPercentage.length > 0 ?
                        (<ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={despachoDataWithPercentage}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill={theme.palette.primary.main}
                                    //@ts-ignore
                                    activeIndex={activeIndexes['despachoData']}
                                    activeShape={(props: any) => (
                                        <Sector
                                            cx={props.cx}
                                            cy={props.cy}
                                            innerRadius={props.innerRadius}
                                            outerRadius={props.outerRadius + 10}
                                            startAngle={props.startAngle}
                                            endAngle={props.endAngle}
                                            fill={props.fill}
                                        />
                                    )}
                                    label={({ name, percentage }) =>
                                        percentage > 0 ? `${name}: ${percentage}%` : null
                                    }
                                >
                                    {despachoDataWithPercentage.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                    ))}
                                </Pie>
                                <Tooltip formatter={(value, name, entry) => `${((Number(value) / totalValue) * 100).toFixed(1)}%`} />
                                <Legend
                                    onMouseEnter={(e) => {
                                        const index = despachoDataWithPercentage.findIndex(item => item.name === e.value);
                                        if (index !== -1) {
                                            onLegendHover('despachoData', index);
                                        }
                                    }}
                                    onMouseLeave={() => onLegendLeave('despachoData')}
                                    formatter={(value) => `${value}`}
                                />
                            </PieChart>
                        </ResponsiveContainer>) : (

                            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
                                <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
                            </Box>
                        )}
                </Grid2>

                <Grid2 size={{ xs: 12, lg: 6 }} sx={stylesBox}>
                    <Typography variant="h6">Tempo rota entregador por faixa de minutos</Typography>
                    {rotaDataWithPercentage.length > 0 ? (<ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={rotaDataFinal}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill={theme.palette.secondary.main}
                                activeIndex={activeIndexes['rotaData'] ?? undefined}
                                activeShape={(props: any) => (
                                    <Sector
                                        cx={props.cx}
                                        cy={props.cy}
                                        innerRadius={props.innerRadius}
                                        outerRadius={props.outerRadius + 10}
                                        startAngle={props.startAngle}
                                        endAngle={props.endAngle}
                                        fill={props.fill}
                                    />
                                )}
                                label={({ name, percentage }) =>
                                    percentage > 0 ? `${name}: ${percentage}%` : null
                                }
                            >
                                {rotaDataFinal.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value, name, entry) => `${entry.payload.percentage}%`} />
                            <Legend
                                onMouseEnter={(e) => {
                                    const index = rotaDataFinal.findIndex(item => item.name === e.value);
                                    if (index !== -1) {
                                        onLegendHover('rotaData', index);
                                    }
                                }}
                                onMouseLeave={() => onLegendLeave('rotaData')}
                                formatter={(value) => `${value}`}
                            />
                        </PieChart>


                    </ResponsiveContainer>) : (

                        <Box display="flex" justifyContent="center" alignItems="center" height="90%">
                            <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
                        </Box>
                    )}
                </Grid2>

                <Grid2 size={{ xs: 12, lg: 6 }} sx={stylesBox}>
                    <Typography variant="h6">Pedidos dentro e fora do prazo estimado</Typography>
                    {porcentagemData.length > 0 ? (<ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={porcentagemData}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill={theme.palette.primary.main}
                                activeIndex={activeIndexes['porcentagemData'] ?? undefined}
                                activeShape={(props: any) => (
                                    <Sector
                                        cx={props.cx}
                                        cy={props.cy}
                                        innerRadius={props.innerRadius}
                                        outerRadius={props.outerRadius + 10}
                                        startAngle={props.startAngle}
                                        endAngle={props.endAngle}
                                        fill={props.fill}
                                    />
                                )}
                                label={({ name, value }) => `${name}: ${value.toFixed(1)}%`}
                            >
                                {porcentagemData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip
                                formatter={(value) => `${Number(value).toFixed(1)}%`}
                            />

                            <Legend
                                onMouseEnter={(e) => {
                                    const index = porcentagemData.findIndex(item => item.name === e.value);
                                    if (index !== -1) {
                                        onLegendHover('porcentagemData', index);
                                    }
                                }}
                                onMouseLeave={() => onLegendLeave('porcentagemData')}
                                formatter={(value) => `${value}`}
                            />
                        </PieChart>

                    </ResponsiveContainer>) :
                        (
                            <Box display="flex" justifyContent="center" alignItems="center" height="90%">
                                <Typography variant="notFound" align="center">Nenhum item encontrado</Typography>
                            </Box>
                        )}
                </Grid2>

            </Grid2>

        </Box >
    );
};

export default DashboardPerformance;
