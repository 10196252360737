import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import TabelaPagamento from "../../components/TabelaPagamento/TabelaPagamento";
import CadastrarTabela from "src/components/TabelaPagamento/ModalCadastrarTabela";
import { ModalOpcoes, useResponseSnackbar, converterDataCadastro, DynamicDataContainer } from "src/ds";
import { limparEmpresaTabela, limparTipoVeiculo } from "src/store/reducers/tabelaPagamento";
import { definirTipoDePagamentoStart, limparTipoDePagamento } from "src/store/reducers/config";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/reducers";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const TabelaPagamentoPage = () => {
    const tabelasPagamentoDados = useSelector((state: any) => state.tabelaPagamento?.listaTabelasPagamento?.data?.dados);
    const tabelasPagamento = tabelasPagamentoDados?.lista;
    const loadingTabelasPagamento = useSelector((state: any) => state.tabelaPagamento.listaTabelasPagamento?.loading);
    const dispatch = useDispatch();
    const [openCadastro, setOpenCadastro] = useState(false);
    const response = useSelector((state: any) => state.tabelaPagamento.adicionarEmpresaTabela);
    const response3 = useSelector((state: any) => state.tabelaPagamento.salvarTipoVeiculo);
    const response4 = useSelector((state: any) => state.config.tipoDePagamento);
    const [ultimaTabelaCadastrada, setUltimaTabelaCadastrada] = useState(null);
    const [triggerEditar, setTriggerEditar] = useState(false);
    const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
    const [tipoPagamento, setTipoPagamento] = useState<string | number>('');
    const metodoPagamento = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);
    const navigate = useNavigate();
    const tipoUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);

    useResponseSnackbar([
        { response, clearAction: () => { dispatch(limparEmpresaTabela()); } },
        { response: response3, clearAction: () => { dispatch(limparTipoVeiculo()); } },
        { response: response4, clearAction: () => { dispatch(limparTipoDePagamento()); } }
    ]);

    useEffect(() => {
        if (tabelasPagamento?.length > 0) {
            const storedId = localStorage.getItem('lastEditedTabelaId');

            const cincoMinutosAtras = new Date(new Date().getTime() - 0.1 * 60 * 1000);

            const tabelasRecentes = tabelasPagamento?.filter((tabela: any) => {
                const dataCadastro = converterDataCadastro(tabela.dataCadastro);
                return dataCadastro > cincoMinutosAtras;
            });

            const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
                const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
                const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
                return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
            }, null);
            if (tabelaMaisRecente && tabelaMaisRecente.tabelaValorID !== storedId) {
                setUltimaTabelaCadastrada(tabelaMaisRecente);
                setTriggerEditar(true);
                localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.tabelaValorID);
                navigate(`/TabelaValor/Index/${tabelaMaisRecente.tabelaValorID}`);
            }
        }
    }, [tabelasPagamento]);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            dataInicial: '',
            dataFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const cardRef = useRef<DynamicDataContainerRef>(null)

    const handleTipoPagamentoChange = (event: any) => {
        setTipoPagamento(event.target.value);
    };

    useEffect(() => {
        if (metodoPagamento) setTipoPagamento(metodoPagamento);
    }, [])

    const handleConfirmar = () => {
        dispatch(definirTipoDePagamentoStart({ tipo: tipoPagamento }));
        setOpenModalOpcoes(false);
    };

    return (
        <Box sx={(theme) => ({ backgroundColor: theme.palette.background.paperDark })}>
            <DynamicDataContainer
                hookForm={hookForm}
                ref={cardRef}
                cardFormFiltrosProps={{
                    dispatchMethods: { actionName: 'listaTabelaPagamentoStart' },
                    fields: [
                        { name: "nome", ehFiltro: true, label: "Buscar por nome", tipoFiltro: 'TEXTO' },
                    ],
                    loading: loadingTabelasPagamento
                }}
                sections={[{
                    title: 'Tabela de pagamento',
                    label: 'Tabela de pagamento',
                    tableNavigation: {
                        indiceInicial: tabelasPagamentoDados?.indiceInicial,
                        indiceFinal: tabelasPagamentoDados?.indiceFinal,
                        indiceTotal: tabelasPagamentoDados?.total,
                        ultimaPagina: tabelasPagamentoDados?.ultimaPagina,
                    },
                    extraButtons: [
                        ...(tipoUsuario === 'SAAS' ? [{
                            label: 'Método de cálculo',
                            action() { setOpenModalOpcoes(true) },
                        }] : []),
                        {
                            label: 'Inserir',
                            action() { setOpenCadastro(true) },
                        }
                    ],
                    component: <TabelaPagamento
                        fezRequisicao={cardRef.current?.fezRequisicao}
                        tabelasPagamento={tabelasPagamento}
                        loading={loadingTabelasPagamento}
                        atualizar={cardRef.current?.onSubmit}
                        ultimaTabelaCadastrada={ultimaTabelaCadastrada}
                        triggerEditar={triggerEditar}
                        setTriggerEditar={setTriggerEditar}
                    />
                }]}
            />
            <CadastrarTabela open={openCadastro} onClose={() => {
                setOpenCadastro(false);
                setTimeout(() => {
                    cardRef.current?.onSubmit();
                }, 1000);
            }} />
            <ModalOpcoes
                open={openModalOpcoes}
                onClose={() => setOpenModalOpcoes(false)}
                acao="Definir método de calculo"
                opcoes={[
                    { label: 'Cancelar', action() { setOpenModalOpcoes(false); } },
                    { label: 'Confirmar', action: handleConfirmar, disabled: tipoPagamento === '' }
                ]}
                descricao={
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel shrink htmlFor="estado-label">Tipos</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            id="estado-label"
                            label="Estado"
                            sx={{ textAlign: 'left' }}
                            value={tipoPagamento}
                            onChange={handleTipoPagamentoChange}
                        >
                            <MenuItem disabled value=''>
                                Selecione um tipo
                            </MenuItem>
                            <MenuItem key={0} value={0}>
                                Por raio
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Por rota
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                Por bairro
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            />
        </Box>
    );
};

export default TabelaPagamentoPage;
