import { useState } from "react";

interface UseSnackbarProps {
    duration?: number;
    initialOpen?: boolean;
    message?: string;
    severity?: SeverityType;
    title?: string;
}

type SeverityType = 'success' | 'info' | 'warning' | 'error'

interface ShowSnackbarProps {
    message: string;
    severity: SeverityType;
    duration?: number;
    title?: string;
}

export interface UseSnackbarReturn {
    duration: number;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    severity: SeverityType;
    setSeverity: React.Dispatch<React.SetStateAction<SeverityType>>;
    showSnackbar: (options: ShowSnackbarProps) => void;
    showSuccess: (message: string, duration?: number) => void;
    showError: (message: string, duration?: number) => void;
    handleClose: (event: React.SyntheticEvent | Event, reason: string) => void;
    title: string;
}

const useSnackbar = (initialOptions: UseSnackbarProps = { message: '', severity: 'success', initialOpen: false, title: '' }): UseSnackbarReturn => {
    const [open, setOpen] = useState(initialOptions.initialOpen || false);
    const [message, setMessage] = useState(initialOptions.message || '');
    const [title, setTitle] = useState(initialOptions.title || '');
    const [severity, setSeverity] = useState<SeverityType>(initialOptions.severity as SeverityType || 'success');
    const duration = initialOptions?.duration || 0;

    const showSnackbar = (
        { message, severity, duration, title }: ShowSnackbarProps
    ) => {
        if (typeof message === 'object') {
            setMessage(JSON.stringify(message));
        } else {
            message && setMessage(message);
        }

        setSeverity(severity);
        setOpen(true);
        title && setTitle(title);

        const timeoutDuration = duration ?? 0;
        if (timeoutDuration > 0) {
            setTimeout(() => {
                setOpen(false);
            }, duration);
        }
    };

    const showSuccess = (message: string, duration?: number) => {
        showSnackbar({ message, severity: 'success', duration });
    };

    const showError = (message: string, duration?: number) => {
        showSnackbar({ message, severity: 'error', duration });
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason: string) => {
        if (reason === 'clickaway') {
            // Evita fechar o Snackbar em determinados casos
            return;
        }
        setOpen(false);
    };

    return {
        duration,
        open,
        setOpen,
        message,
        setMessage,
        severity,
        setSeverity,
        showSnackbar,
        showSuccess,
        showError,
        handleClose,
        title
    };
};

export default useSnackbar