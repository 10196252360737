import { Box, Button } from '@mui/material'
import { useSelector } from 'react-redux';
import { ContainerBodyModal, ModalBase, PLANO_BRENDI, useResponsive } from 'src/ds'
import { RootState } from 'src/store/reducers';

const ModalVideos = ({ open, onClose }: any) => {
    const usuarioPlano = useSelector((state: RootState) => state.config.usuarioPlano);
    const { isMobile } = useResponsive()
    const usuarioBrendi = usuarioPlano === PLANO_BRENDI
    return (
        <ModalBase open={open} onClose={onClose}>
            <ContainerBodyModal sx={{ height: 'min-content', maxHeight: '95vh' }}>
                <iframe
                    style={{
                        aspectRatio: '16/8',
                        width: '100%',
                    }}
                    src={
                        usuarioBrendi
                            ?
                            isMobile
                                ? "https://www.loom.com/embed/fde465cfe4d147c5b9c5efce82b81483?sid=9c24b1a9-d4d0-4055-83ea-67951d413a6f"
                                : "https://www.loom.com/embed/fde465cfe4d147c5b9c5efce82b81483?sid=9c24b1a9-d4d0-4055-83ea-67951d413a6f"
                            : isMobile
                                ? "https://www.youtube.com/embed/k_r5Y60pZfA"
                                : "https://www.youtube.com/embed/6Xgfqp-HaX4"
                    }

                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        onClick={onClose}
                        variant='containedFilled'
                        sx={{ width: 'fit-content', marginTop: '1rem' }}
                    >
                        Comece a utilizar
                    </Button>
                </Box>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default ModalVideos