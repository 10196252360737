import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Interface para os parâmetros da lista de empresas
interface ListaEmpresasParams {
    nome?: string;
    tagsFiltro?: string[];
    paginacao: number;
    pagina: number;
    ordenacaoCampo?: string;
    ordenacaoOrdem?: string;
    filtros?: string;
    titulos?: string;
    variaveis?: string;
    formato?: string;
}


interface EmpresaState {
    loading: boolean;
    data: any | null;
    error: string | null;
}

interface EmpresasState {
    listaEmpresas: EmpresaState;
    tiposEmpresas: EmpresaState;
    salvarCliente: EmpresaState;
    editarEmpresa: EmpresaState;
    paginaEmpresa: EmpresaState;
}

const initialState: EmpresasState = {
    listaEmpresas: {
        loading: false,
        data: null,
        error: null,
    },
    tiposEmpresas: {
        loading: false,
        data: null,
        error: null,
    },
    salvarCliente: {
        loading: false,
        data: null,
        error: null
    },
    editarEmpresa: {
        loading: false,
        data: null,
        error: null
    },
    paginaEmpresa: {
        loading: false,
        data: null,
        error: null
    }
};

const empresasSlice = createSlice({
    name: "Empresas",
    initialState: initialState,
    reducers: {
        listaEmpresasStart(state, action: PayloadAction<ListaEmpresasParams>) {
            state.listaEmpresas.loading = true;
            state.listaEmpresas.error = null;
        },
        listaEmpresasFailure(state, action: PayloadAction<string>) {
            state.listaEmpresas.loading = false;
            state.listaEmpresas.error = action.payload;
        },
        listaEmpresasSuccess(state, action: PayloadAction<any>) {
            state.listaEmpresas.loading = false;
            state.listaEmpresas.data = action.payload;
        },
        exportarTabelaEmpresas(state, action: PayloadAction<ListaEmpresasParams>) {
            state.listaEmpresas.loading = true;
            state.listaEmpresas.error = null;
        },
        exportarTabelaEmpresasSuccess(state, action: PayloadAction<any>) {
            state.listaEmpresas.loading = false;
        },
        exportarTabelaEmpresasFailure(state, action: PayloadAction<string>) {
            state.listaEmpresas.loading = false;
            state.listaEmpresas.error = action.payload;
        },
        listarTiposEmpresas(state, action: PayloadAction<any>) {
            state.tiposEmpresas.loading = true;
            state.tiposEmpresas.error = null;
        },
        tiposEmpresasSuccess(state, action: PayloadAction<any>) {
            state.tiposEmpresas.loading = false;
            state.tiposEmpresas.data = action.payload;
        },
        tiposEmpresasFailure(state, action: PayloadAction<string>) {
            state.tiposEmpresas.loading = false;
            state.tiposEmpresas.error = action.payload;
        },
        salvarClienteEmpresaStart(state, action: PayloadAction<any>) {
            state.salvarCliente.loading = true;
            state.salvarCliente.error = null;
        },
        salvarClienteEmpresaSuccess(state, action: PayloadAction<any>) {
            state.salvarCliente.loading = false;
            state.salvarCliente.data = action.payload;
        },
        salvarClienteEmpresaFailure(state, action: PayloadAction<string>) {
            state.salvarCliente.loading = false;
            state.salvarCliente.error = action.payload;
        },
        limparSalvarCliente(state) {
            state.salvarCliente.loading = false;
            state.salvarCliente.error = null;
            state.salvarCliente.data = null;
        },
        editarEmpresaStart(state, action) {
            state.editarEmpresa.loading = true;
            state.editarEmpresa.error = null;
        },
        editarEmpresaSuccess(state, action: PayloadAction<any>) {
            state.editarEmpresa.loading = false;
            state.editarEmpresa.data = action.payload;
        },
        editarEmpresaFailure(state, action: PayloadAction<string>) {
            state.editarEmpresa.loading = false;
            state.editarEmpresa.error = action.payload;
        },
        editarEmpresaClear(state) {
            state.editarEmpresa.loading = false;
            state.editarEmpresa.error = null;
            state.editarEmpresa.data = null;
        },
        paginaEmpresaStart(state, action) {
            state.paginaEmpresa.loading = true;
            state.paginaEmpresa.error = null;
        },
        paginaEmpresaSuccess(state, action: PayloadAction<any>) {
            state.paginaEmpresa.loading = false;
            state.paginaEmpresa.data = action.payload;
        },
        paginaEmpresaFailure(state, action: PayloadAction<string>) {
            state.paginaEmpresa.loading = false;
            state.paginaEmpresa.error = action.payload;
        },
    },
});

export const {
    listaEmpresasStart,
    listaEmpresasFailure,
    listaEmpresasSuccess,
    exportarTabelaEmpresas,
    exportarTabelaEmpresasSuccess,
    exportarTabelaEmpresasFailure,
    listarTiposEmpresas,
    tiposEmpresasSuccess,
    tiposEmpresasFailure,
    salvarClienteEmpresaStart,
    salvarClienteEmpresaFailure,
    salvarClienteEmpresaSuccess,
    limparSalvarCliente,
    editarEmpresaFailure,
    editarEmpresaStart,
    editarEmpresaSuccess,
    paginaEmpresaStart,
    paginaEmpresaFailure,
    paginaEmpresaSuccess,
    editarEmpresaClear
} = empresasSlice.actions;

export default empresasSlice;
