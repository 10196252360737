import TabelaEscalas from './TabelaEscalas';
import AbrirVagas from './ModalAbrirVagas';
import { useDispatch, useSelector } from 'react-redux';
import { ContainerBodyModal, ModalBase } from 'src/ds'
import { RootState } from 'src/store/reducers';
import SelecionarEmpresa from './SelecionarEmpresa';
import { useEffect, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Stack, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { listarEmpresasPick } from 'src/store/reducers/config';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CalendarioEscalas from './CalendarioEscalas';
import { escalasStart } from 'src/store/reducers/escalas';

type Visualizacao = 'mes' | 'escalas'
interface ModalEscalasProps {
    open: boolean;
    handleClose: () => void;
}

const ModalEscalas = ({ open, handleClose }: ModalEscalasProps) => {
    const tipoDeUsuario = useSelector((state: RootState) => state.config.tipoDeUsuario)
    const storeEmpresaID = useSelector((state: RootState) => state.config.empresaID)
    const [selectEmpresaID, setSelectEmpresaID] = useState<string | null>(null)
    const [empresaID, setEmpresaID] = useState<string | null>(null)
    const empresas = useSelector((state: RootState) => state.config.empresasPick?.dados?.lista);
    const dispatch = useDispatch();
    const [dateInterval, setDateInterval] = useState({ dataInicio: '', dataFim: '' })
    const [abrirVagasModal, setAbrirVagasModal] = useState(false)

    const podeVisualizar = (tipoDeUsuario !== 'Representante' && empresaID) ? true : false
    const [visualizacao, setVisualizacao] = useState<Visualizacao>('escalas')
    useEffect(() => {
        setVisualizacao(podeVisualizar ? 'mes' : 'escalas')
    }, [podeVisualizar])
    const handleCloseVagas = () => {
        setAbrirVagasModal(false)
    }

    useEffect(() => {
        if (empresas && empresas.length > 0) return
        dispatch(listarEmpresasPick({
            paginacao: -1,
            pagina: -1,
            ordenacaoCampo: '',
            ordenacaoOrdem: '',
            filtros: '',
            filtrarPorTags: true
        }))
    }, [])

    useEffect(() => {
        if (!empresas || empresas.length === 0) return
        setSelectEmpresaID(storeEmpresaID ?? selectEmpresaID)
    }, [empresas])

    useEffect(() => {
        if (!storeEmpresaID) return
        setEmpresaID(storeEmpresaID)
    }, [storeEmpresaID])

    useEffect(() => {
        setEmpresaID(selectEmpresaID)
    }, [selectEmpresaID])

    const handleChangeVisualizacao = (value: Visualizacao | null) => {
        if (!value) return
        setVisualizacao(value)
    }

    const callbackAtualizar = () => {
        dispatch(escalasStart({ data: { inicio: dateInterval.dataInicio, fim: dateInterval.dataFim }, empresaID }));
    }

    return (
        <>
            <ModalBase
                open={open}
                onClose={handleClose}
                size='lg'
                headerModal={{
                    title: 'Gerenciamento de escalas',
                    controls: (podeVisualizar && empresaID) ? [{
                        label: 'Abrir Vagas',
                        action: () => { setAbrirVagasModal(true) },
                        icon: <AddCircleOutlineRoundedIcon color='primary' />
                    }] : null,
                }}
            >
                <ContainerBodyModal>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        {(empresas && empresas.length > 0) &&
                            <SelecionarEmpresa setEmpresaID={setSelectEmpresaID} storeEmpresaID={storeEmpresaID} />
                        }
                        {podeVisualizar && (
                            <ToggleButtonGroup
                                size='small'
                                value={visualizacao}
                                onChange={(e, value) => handleChangeVisualizacao(value as Visualizacao)}
                                exclusive
                                sx={{ ml: 'auto', mb: 2 }}
                            >
                                {/* @ts-expect-error */}
                                <ToggleButton
                                    value="mes"
                                    variant='plain'
                                >
                                    Mês
                                </ToggleButton>
                                {/* @ts-expect-error */}
                                <ToggleButton
                                    value="escalas"
                                    variant='plain'
                                >
                                    Escalas
                                </ToggleButton>
                            </ToggleButtonGroup>
                        )}
                        {!empresaID && (
                            <Stack direction={'column'} sx={{ justifyContent: 'center', alignItems: 'center', flex: '1' }}>
                                <Typography variant="notFound">Selecione uma empresa</Typography>
                                <InfoRoundedIcon sx={(theme: Theme) => ({ color: theme.palette.grey[400], fontSize: '3rem', mt: '1rem' })} />
                            </Stack>
                        )}
                        {empresaID && (
                            visualizacao === 'mes' ? (
                                <CalendarioEscalas empresaID={empresaID} setDateInterval={setDateInterval} callback={callbackAtualizar} />
                            ) : (
                                <TabelaEscalas empresaID={empresaID} setDateInterval={setDateInterval} callback={callbackAtualizar} podeVisualizar={podeVisualizar} />
                            )
                        )}

                    </Box>
                </ContainerBodyModal >
            </ModalBase>
            {(empresaID && abrirVagasModal) &&
                <AbrirVagas
                    open={abrirVagasModal}
                    onClose={handleCloseVagas}
                    empresaID={empresaID}
                    callback={callbackAtualizar}
                />
            }
        </>
    )
}

export default ModalEscalas