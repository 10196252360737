import { FlagOutlined, Timer } from '@mui/icons-material'
import { Grid2, Stack, Typography } from '@mui/material'
import React from 'react'
import { ContainerLoading } from 'src/ds';

const TabKm = ({ tempoMedio, loading }: { tempoMedio: any, loading: boolean }) => {
    if (loading) {
        return <ContainerLoading />;
    }

    return (
        <Grid2 container spacing={2}
            direction="column"

        >
            <Grid2
                container
                sx={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "0.5rem",
                }}
            >
                <Grid2
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            display: "flex",
                            fontWeight: "500",
                            textAlign: "center",
                            alignItems: "flex-end",
                        }}
                    >
                        <FlagOutlined /> Quilometragem
                    </Typography>
                </Grid2>
                <Grid2


                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        alignSelf: "center",
                    }}
                >
                    <Typography
                        variant="body1"
                        sx={{
                            display: "flex",
                            fontWeight: "500",
                            textAlign: "center",
                            alignItems: "flex-end",
                        }}
                    >
                        <Timer /> Tempo Médio
                    </Typography>
                </Grid2>
            </Grid2>

            {Array.isArray(tempoMedio) && tempoMedio.length > 0 ? (
                tempoMedio.map((item, index) => (
                    <Stack key={index} direction={"row"} justifyContent={"space-around"}>
                        <Grid2>
                            <Typography
                                sx={{
                                    padding: "0.5rem",
                                    textAlign: "center",
                                }}
                            >
                                {item.kmFinal} km
                            </Typography>
                        </Grid2>
                        <Grid2>
                            <Typography
                                sx={{
                                    padding: "0.5rem",
                                    textAlign: "center",
                                }}
                            >
                                {item.tempoMedio} min
                            </Typography>
                        </Grid2>
                    </Stack>
                ))
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        mt: "1rem",
                        width: "100%",
                    }}
                >
                    Não há dados disponíveis
                </Typography>
            )}
        </Grid2>
    )
}

export default TabKm