import { Pedido } from "src/dtos/PedidosDTO";
import sanitizeSituacao from "./sanitizeSituacao";
import { Theme } from "@mui/material";
import { PedidoEmAndamento } from "src/dtos/RastreioDTO";

const createMarkerPedidoElement = (pedido: Pedido | PedidoEmAndamento, theme: Theme): HTMLElement => {
  const pedidoElement = document.createElement("div");
  pedidoElement.className = "pedidoElement";
  pedidoElement.style.backgroundColor = theme.palette.status[sanitizeSituacao(pedido)];
  pedidoElement.style.border = `0px solid ${theme.palette.status[sanitizeSituacao(pedido)]}`;
  pedidoElement.style.color = theme.palette?.getContrastText(theme.palette?.status[sanitizeSituacao(pedido)]) || "#000";

  const codigoDiv = document.createElement("div");
  codigoDiv.innerText = `#${pedido.codigoQuatro}`;
  codigoDiv.className = "codigoDiv";

  const setaDiv = document.createElement("div");
  setaDiv.className = "setaDiv";
  setaDiv.style.borderTop = `10px solid ${theme.palette.status[sanitizeSituacao(pedido)]}`;

  // Adiciona os elementos internos no pedidoElement
  pedidoElement.appendChild(codigoDiv);
  pedidoElement.appendChild(setaDiv);

  // Define um ID para o pedidoElement
  pedidoElement.id = pedido.pedidoID;

  return pedidoElement;
};

export default createMarkerPedidoElement;
