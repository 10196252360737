import React, { useState, useEffect } from 'react';
import { Modal, Button, Box, CircularProgress, useTheme } from '@mui/material';
import robozinho from '../../../assets/robo.png';
import robo2 from '../../../assets/robo2.png';
import { useDispatch, useSelector } from 'react-redux';
import { concluirTreinamento } from '../../../store/reducers/utils';
import si from '../../../assets/undefined.mp3';
import { PLANO_BRENDI, PLANO_FREEMIUM, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';

const ModalBoasVindas = ({ open, setAbrirEntregadores }: { open: boolean; setAbrirEntregadores: any }) => {
    const { isMobile } = useResponsive();
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [empresaCarregada, setEmpresaCarregada] = useState(false);
    const usuarioPlano = useSelector((state: RootState) => state.config.usuarioPlano);

    const usuarioBrendi = usuarioPlano === PLANO_BRENDI

    const empresaNome = useSelector((state: RootState) => {
        const pickSelecionadaNome = state?.config?.masterPage?.pickSelecionada?.nome;
        const usuarioNome = state?.config?.masterPage?.usuario?.nome;
        return pickSelecionadaNome || usuarioNome || '';
    });
    //@ts-expect-error
    const planoUsuario = useSelector(state => state?.config?.usuarioPlano) || '';
    const theme = useTheme();
    const dispatch = useDispatch();
    const audio = new Audio(si);
    //@ts-expect-error
    const devMode = useSelector(state => state.utils?.devMode);
    useEffect(() => {
        if (empresaNome) {
            setEmpresaCarregada(true);
        }
    }, [empresaNome]);

    useEffect(() => {
        if (open && devMode) {
            audio?.play();
        }
    }, [open, audio]);

    const texts = [
        `Olá <span style="color: ${theme.palette.primary.main};">${empresaNome}</span>, seja bem-vindo a PicknGo! A partir de agora você terá total controle da sua operação de delivery. Veja nossos benefícios na aba de "Tenho interesse” e faça parte desse time de campeões. `,
        "Agradecemos sua presença aqui, desfrute do melhor sistema de logística para delivery. Nosso time está ansioso para poder disponibilizar uma experiência completa!",
    ];

    const robots = [robo2, robozinho];

    const handleNextText = () => {
        if (currentTextIndex === texts.length - 1) {
            setVideoModalOpen(true);
        } else {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }
    };

    const finalizarTreinamento = () => {
        setVideoModalOpen(false);
        dispatch(concluirTreinamento());
        setAbrirEntregadores(true);
    };

    return (
        <>
            <Modal
                open={open}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                slotProps={{
                    backdrop: {
                        style: {
                            backdropFilter: 'blur(1px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        outline: 'none',
                        border: 'none',
                        userSelect: 'none'
                    }}
                >
                    {!empresaCarregada && PLANO_FREEMIUM === planoUsuario ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '16px',
                                    flexDirection: isMobile ? 'column' : 'row'
                                }}>
                                <img
                                    src={robots[currentTextIndex]}
                                    alt="Robozinho"
                                    style={{
                                        order: isMobile ? 2 : 'inherit',
                                        width: '100%',
                                        maxWidth: '300px',
                                        outline: 'none',
                                        border: 'none',
                                        userSelect: 'none',
                                        transform: 'translateY(-10px)',
                                        animation: 'spin 1s linear infinite'
                                    }}
                                />
                                <div style={{
                                    position: 'relative',
                                    background: 'white',
                                    borderRadius: '8px',
                                    padding: '8px 16px',
                                    maxWidth: '300px',
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                    marginLeft: isMobile ? '0' : '-20px',
                                    color: '#000',
                                }}>
                                    <span dangerouslySetInnerHTML={{ __html: texts[currentTextIndex] }} />
                                    <div style={{
                                        position: 'absolute',
                                        top: isMobile ? '100%' : '50%',
                                        left: isMobile ? '50%' : '-10px',
                                        transform: isMobile ? 'rotate(-90deg)' : 'rotate(0deg)',
                                        width: '0',
                                        height: '0',
                                        border: '10px solid transparent',
                                        borderRightColor: 'white',
                                        borderLeft: 'none',
                                        marginTop: '-10px'
                                    }} />
                                </div>
                            </div>
                            <Button onClick={handleNextText} variant="containedFilled" style={{ marginTop: '-36px', width: '10%', alignSelf: 'flex-end' }}>
                                Próximo
                            </Button>
                        </>
                    )}
                </Box>
            </Modal >

            <Modal
                open={videoModalOpen}
                onClose={() => setVideoModalOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                BackdropProps={{
                    style: {
                        backdropFilter: 'blur(1px)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: isMobile ? '8px' : '16px',
                        width: '140vh',
                        height: 'auto',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <iframe
                        style={{
                            aspectRatio: '16/8',
                            width: '100%',
                        }}
                        src={
                            !usuarioBrendi
                                ?
                                isMobile
                                    ? "https://www.loom.com/embed/fde465cfe4d147c5b9c5efce82b81483?sid=9c24b1a9-d4d0-4055-83ea-67951d413a6f"
                                    : "https://www.loom.com/embed/fde465cfe4d147c5b9c5efce82b81483?sid=9c24b1a9-d4d0-4055-83ea-67951d413a6f"
                                : isMobile
                                    ? "https://www.youtube.com/embed/k_r5Y60pZfA"
                                    : "https://www.youtube.com/embed/6Xgfqp-HaX4"
                        }
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <Button onClick={finalizarTreinamento} variant='containedFilled' sx={{ width: '20%', marginTop: '16px' }}>Comece a utilizar</Button>
                </Box>
            </Modal>
        </>
    );
}

export default ModalBoasVindas;

// const styles = devMode ? `
// @keyframes spin {
//   0% { transform: translateY(-10px) rotateX(0deg) rotateY(0deg); }
//   100% { transform: translateY(-10px) rotateX(0deg) rotateY(360deg); }
// }
// ` : '';

// document.head.insertAdjacentHTML(
//     "beforeend",
//     `<style>${styles}</style>`
// );
