// InputEntregador.tsx
import React, { useEffect, useState } from 'react';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import TextFieldWithButton from './TextFieldWithButton';
import { Entregador } from 'src/dtos/EntregadoresDTO';
import { SxProps } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { Clear } from '@mui/icons-material';

interface HookForm {
  name: string;
  methods: UseFormReturn<any>;
  shouldReturn?: keyof Entregador;
  rules?: RegisterOptions;
  defaultEntregadorData?: {
    id?: string | null;
    nome?: string | null
  };
}

interface InputEntregadorProps {
  hookForm: HookForm;
  label?: string;
  placeholder?: string;
  sx?: SxProps;
  disabled?: boolean;
}

const InputEntregador: React.FC<InputEntregadorProps> = ({
  hookForm: {
    methods,
    rules,
    name,
    shouldReturn = 'id',
    defaultEntregadorData,
  },
  label = "Entregador",
  sx,
  placeholder = 'Selecione um entregador',
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [entregadorSelecionado, setEntregadorSelecionado] = useState<Partial<Entregador> | null>(null);
  const { control, setValue, formState: { errors } } = methods;
  const loading = useSelector((state: RootState) => state.escalas.listarEntregadores.loading);

  useEffect(() => {
    if (entregadorSelecionado) {
      if (shouldReturn && shouldReturn in entregadorSelecionado) {
        setValue(name, entregadorSelecionado[shouldReturn]);
      } else {
        console.error(`O campo "${shouldReturn}" não existe no entregador selecionado.`);
        setValue(name, null)
      }
    }
  }, [entregadorSelecionado, setValue, shouldReturn]);

  useEffect(() => {
    if (defaultEntregadorData?.id && defaultEntregadorData?.nome) {
      if (shouldReturn !== 'id') {
        throw new Error(`Quando usado defaultEntregadorData, o campo "${shouldReturn}" não pode ser diferente de "id".`);
      } else {
        setEntregadorSelecionado({ id: defaultEntregadorData.id, nome: defaultEntregadorData.nome })
        setValue(name, defaultEntregadorData.id)
      }
    }
  }, [defaultEntregadorData]);

  const selecionarEntregador = (entregador: Entregador) => {
    setEntregadorSelecionado(entregador);
  };

  const hasEntregador = !!(entregadorSelecionado?.nome)

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        disabled={disabled || loading}
        render={({ field }) => (
          <TextFieldWithButton
            size="small"
            label={label}
            sx={sx}
            placeholder={placeholder}
            onChange={undefined}
            value={entregadorSelecionado?.nome || ''}
            disabled={!!field.disabled}
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message ? String(errors?.[name]?.message) : ''}
            slotProps={{
              htmlInput: {
                readOnly: true,
                onClick: hasEntregador ? () => setOpen(true) : undefined,
              }
            }}
            buttonProps={{
              variant: 'containedFilled',
              icon: loading
                ? <CircularProgress size={20} />
                : hasEntregador ? <Clear /> : <SearchRoundedIcon />,
              onClick: () => {
                if (hasEntregador) {
                  setEntregadorSelecionado(null)
                  setValue(name, null)
                } else {
                  setOpen(true)
                }
              },
            }}
          />
        )}
      />
      {open && (
        <ModalPesquisarEntregador
          modalProps={{
            open,
            onClose: () => setOpen(false),
          }}
          callback={selecionarEntregador}
        />
      )}
    </>
  );
};

export default InputEntregador;
