import FullCalendar from '@fullcalendar/react'
import dayGridMonth from '@fullcalendar/daygrid'
import allLocales from '@fullcalendar/core/locales-all';
import { DatesSetArg, EventClickArg, EventInput, EventMountArg } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg, EventDragStopArg } from "@fullcalendar/interaction"
import listPlugin from '@fullcalendar/list';
import dayjs from 'dayjs';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alterarDataClear, alterarDataStart, criarVagaClear, criarVagaStart, escalasStart, finalizarJornadaClear, finalizarJornadaStart, iniciarJornadaClear, iniciarJornadaStart } from 'src/store/reducers/escalas';
import { RootState } from 'src/store/reducers';
import { BoxLoading, ModalOpcoes, useResponsive } from 'src/ds';
import ModalAbrirVagas from './ModalAbrirVagas';
import { useTheme, Typography } from '@mui/material';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { CriarVagaPayload, Escala } from 'src/dtos/EscalasDTO';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { Entregador } from 'src/dtos/EntregadoresDTO';

interface CalendarioEscalasProps {
    empresaID: string;
    callback?: () => void;
    setDateInterval: Dispatch<React.SetStateAction<{ dataInicio: string; dataFim: string }>>
}

const CalendarioEscalas = (
    { empresaID, setDateInterval, callback }: CalendarioEscalasProps
) => {
    const { isMobile } = useResponsive()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { showSuccess, showError } = useSnackbarContext();
    const calendarRef = useRef<FullCalendar | null>(null)
    const escalasStore = useSelector((state: RootState) => state.escalas.listaEscalas)
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [listaEventos, setListaEventos] = useState<EventInput[]>([])
    const [modalOpen, setModalOpen] = useState(false)
    const SEM_ENTREGADOR = '00000000-0000-0000-0000-000000000000'
    const [editarVaga, setEditarVaga] = useState(false)
    const [pedidoID, setPedidoID] = useState<null | string>(null)
    const [initialDate, setInitialDate] = useState<null | {
        dataAgendamento: string;
        dataFimDiaria: string;
    }>(null)
    const [pedidoSelecionado, setPedidoSelecionado] = useState<Escala | null>(null)
    const [confirmacaoIniciar, setConfirmacaoIniciar] = useState(false)
    const [confirmacaoFinalizar, setConfirmacaoFinalizar] = useState(false)
    const [atribuirEntregador, setAtribuirEntregador] = useState(false)
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null)
    const alterarDataStore = useSelector((state: RootState) => state.escalas.alterarData)

    const listarVagas = () => { dispatch(escalasStart({ data: { inicio: dataInicio, fim: dataFim }, empresaID })) };

    useEffect(() => {
        if (!dataInicio || !dataFim) return;
        listarVagas()
    }, [dataInicio, dataFim, dispatch, empresaID])

    useEffect(() => {
        if (!escalasStore.data) return;
        const lista = escalasStore.data.dados?.lista.map((item): EventInput => {
            const startDate = item?.dataInicio ? dayjs(item?.dataInicio, 'DD/MM/YYYY HH:mm') : null;
            const endDate = item?.dataFim ? dayjs(item?.dataFim, 'DD/MM/YYYY HH:mm') : null;
            const hasEntregador = item.entregadorUsuarioID !== SEM_ENTREGADOR
            return {
                title: item?.entregadorUsuario?.nome || '',
                start: startDate ? startDate.format('YYYY-MM-DD HH:mm') : '',
                end: endDate ? endDate.format('YYYY-MM-DD HH:mm') : '',
                id: item.pedidoID,
                interactive: true,
                extendedProps: { hasEntregador, vaga: item }
            }
        })
        setListaEventos(lista || [])
    }, [escalasStore.data])

    const handleEventClick = (info: EventClickArg) => {
        info.jsEvent.stopPropagation()
        info.jsEvent.preventDefault()
        info.jsEvent.stopImmediatePropagation()
        const { event } = info;
        const eventId = event.id;

        const vaga = escalasStore.data?.dados?.lista.find((item) => item.pedidoID === eventId);

        if (vaga) {
            setEditarVaga(true);
            setPedidoID(vaga.pedidoID)
            setModalOpen(true);
        } else {
            console.error('Vaga não encontrada: ', vaga)
        }
    };

    const handleDatesSet = (arg: DatesSetArg) => {
        const { startStr, endStr } = arg
        const dtInicio = dayjs(startStr).format('DD/MM/YYYY')
        const dtFim = dayjs(endStr).format('DD/MM/YYYY')
        setDataInicio(dtInicio)
        setDataFim(dtFim)
        setDateInterval({ dataInicio: dtInicio, dataFim: dtFim })
    }

    const handleDateClick = (arg: DateClickArg) => {
        setInitialDate({
            dataAgendamento: dayjs(arg.dateStr).hour(9).minute(0).format('DD/MM/YYYY HH:mm'),
            dataFimDiaria: dayjs(arg.dateStr).hour(18).minute(0).format('DD/MM/YYYY HH:mm')
        })
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setPedidoID(null)
        setModalOpen(false)
        setEditarVaga(false)
        setInitialDate(null)
    }

    const handleEventDrop = (info: EventDragStopArg) => {
        const { event } = info;
        const start = event.start;
        let end = event.end;

        if (!end) {
            end = start;
        }

        const dataAgendamento = dayjs(start).format('DD/MM/YYYY HH:mm');
        const dataConcluido = dayjs(end).format('DD/MM/YYYY HH:mm');


        dispatch(alterarDataStart({ dataAgendamento, dataConcluido, pedidoID: event.id }));
    }

    useEffect(() => {
        if (alterarDataStore.data?.sucesso) {
            showSuccess({
                message: alterarDataStore.data.mensagem,
            });
        } else if (alterarDataStore.error) {
            showError({
                message: alterarDataStore.error,
            });
        }
        callback && callback();

        return () => {
            dispatch(alterarDataClear())
        };
    }, [alterarDataStore.data, alterarDataStore.error])

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const newView = isMobile ? 'listMonth' : 'dayGridMonth';
            calendarApi.changeView(newView);
        }
    }, [isMobile]);

    const criarVaga = useSelector((state: RootState) => state.escalas.criarVaga);
    const finalizarJornada = useSelector((state: RootState) => state.escalas.finalizarJornada)
    const iniciarJornada = useSelector((state: RootState) => state.escalas.iniciarJornada)

    useEffect(() => {
        if (criarVaga.data) {
            showSuccess({
                message: criarVaga.data.mensagem,
            });
            setEntregadorSelecionado(null);
            callback?.()
        } else if (criarVaga.error) {
            showError({
                message: criarVaga.error,
            });
        }
        return () => {
            dispatch(criarVagaClear())
        }
    }, [criarVaga.data, criarVaga.error]);

    useEffect(() => {
        if (finalizarJornada.data) {
            showSuccess({
                message: finalizarJornada.data.mensagem,
            });
            setConfirmacaoFinalizar(false);
            callback?.()
        } else if (finalizarJornada.error) {
            showError({
                message: finalizarJornada.error,
            });
        }
        return () => {
            dispatch(finalizarJornadaClear())
        }
    }, [finalizarJornada.data, finalizarJornada.error]);

    useEffect(() => {
        if (iniciarJornada.data) {
            showSuccess({
                message: iniciarJornada.data.mensagem,
            });
            setConfirmacaoIniciar(false);
            callback?.()
        } else if (iniciarJornada.error) {
            showError({
                message: iniciarJornada.error,
            });
        }
        return () => {
            dispatch(iniciarJornadaClear())
        }
    }, [iniciarJornada.data, iniciarJornada.error]);

    function handleAtribuirEntregador(entregador: Entregador) {
        setEntregadorSelecionado(entregador)
        setAtribuirEntregador(false)
    }

    function handleConfirmacaoAtribuirEntregador() {
        if (!pedidoSelecionado || !entregadorSelecionado) return;
        const infoPayload: CriarVagaPayload = {
            pedidoID: pedidoSelecionado.pedidoID || '',
            codigoExterno: 'DIARIA',
            empresaID: pedidoSelecionado.empresaID,
            tipoVeiculoID: pedidoSelecionado.tipoVeiculoID,
            entregadorUsuarioID: entregadorSelecionado?.usuarioID || '',
            modalForm_form_entregadorUsuarioID_input: entregadorSelecionado?.nome || '',
            dataAgendamento: pedidoSelecionado.dataAgendamento,
            dataConcluido: pedidoSelecionado.dataFinalizado,
            taxaTotalCobrada: pedidoSelecionado.taxaTotalCobrada,
            minimoGarantidoCobrado: !!pedidoSelecionado.taxaMinimaGarantidaCobrada,
            taxaTotalEntregador: pedidoSelecionado.taxaTotalEntregador,
            minimoGarantidoEntregador: !!pedidoSelecionado.taxaMinimaGarantidaEntregador,
            qtdPedidosMinimoGarantidoCobrado: pedidoSelecionado.qtdPedidosMinimoGarantidoCobrado ? pedidoSelecionado.qtdPedidosMinimoGarantidoCobrado.toString() : '0',
            detalhes: pedidoSelecionado.detalhes || '',
            minimoGarantidoPorPedido: pedidoSelecionado.qtdPedidosMinimoGarantidoCobrado ? pedidoSelecionado.taxaMinimaGarantidaCobrada.toString() : 'false',
            qtdVagas: 1,
            datasReplicacaoAux: '',
        }
        const formPayload = new FormData();
        Object.keys(infoPayload).forEach(key => {
            const index = key as keyof CriarVagaPayload
            const value = infoPayload[index];
            formPayload.append(key, String(value));
        });
        dispatch(criarVagaStart(formPayload))
    }

    const handleEventDidMount = useCallback((info: EventMountArg) => {
        const { hasEntregador, vaga } = info.event.extendedProps as { hasEntregador: boolean, vaga: Escala };
        const { el } = info;

        if (hasEntregador) {
            // Caso a vaga esteja finalizada, a cor deve ser cinza
            if (vaga.dataChegouEstabelecimento && vaga.dataFinalizado) {
                el.style.backgroundColor = theme.palette.grey[300];
                el.style.borderColor = theme.palette.grey[300];
                el.style.color = theme.palette.getContrastText(theme.palette.grey[300]);
            } else {
                el.style.backgroundColor = theme.palette.primary.main;
                el.style.borderColor = theme.palette.primary.main;
                el.style.color = theme.palette.getContrastText(theme.palette.primary.main);
            }
        } else {
            // Vagas sem entregadores 
            el.style.backgroundColor = theme.palette.secondary.main;
            el.style.borderColor = theme.palette.secondary.main;
            el.style.color = theme.palette.getContrastText(theme.palette.secondary.main);
        }

        // Caso a vaga esteja finalizada, ou seja mobile, retorna sem botão
        if ((vaga.dataChegouEstabelecimento && vaga.dataFinalizado) || isMobile) return;

        // Seleciona eventos maiores que um dia
        const ehDiaDiferente = dayjs(vaga.dataInicio, 'DD/MM/YYYY HH:mm').isBefore(dayjs(vaga.dataFim, 'DD/MM/YYYY HH:mm'), 'day');
        const ehSabado = dayjs(vaga.dataInicio, 'DD/MM/YYYY HH:mm').day() === 6;

        const parent = el.parentNode as HTMLElement;
        if (parent) {
            // Adicionando classe de forma forçada
            setTimeout(() => {
                if (ehDiaDiferente) {
                    parent.classList.add('dia-diferente');
                    if (ehSabado) parent.classList.add('eh-sabado');
                } else {
                    parent.classList.add('has-button');
                }
            }, 0);
        }

        const button = document.createElement('button');

        if (hasEntregador) {
            if (!vaga.dataChegouEstabelecimento) {
                const playIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>`;
                button.innerHTML = playIcon;
                button.style.backgroundColor = theme.palette.success.light;
                button.style.color = theme.palette.getContrastText(theme.palette.success.light);

                button.addEventListener('pointerdown', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setConfirmacaoIniciar(true);
                    setPedidoSelecionado(vaga);
                });

                let tooltip: HTMLDivElement | null = null;

                button.addEventListener('mouseover', () => {
                    if (!tooltip) {
                        tooltip = document.createElement('div');
                        tooltip.className = 'tooltip';
                        tooltip.innerHTML = 'Iniciar jornada';
                        button.appendChild(tooltip);
                    }
                });

                button.addEventListener('mouseout', () => {
                    if (tooltip) {
                        tooltip.remove();
                        tooltip = null;
                    }
                });
                if (ehDiaDiferente) {
                    el.appendChild(button);
                } else {
                    el.parentNode?.insertBefore(button, el.nextSibling);
                }
            } else if (!vaga.dataFinalizado) {
                const stopIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>'
                button.innerHTML = stopIcon;
                button.style.backgroundColor = theme.palette.error.light;
                button.style.color = theme.palette.getContrastText(theme.palette.error.light);

                button.addEventListener('pointerdown', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setConfirmacaoFinalizar(true);
                    setPedidoSelecionado(vaga);
                });

                let tooltip: HTMLDivElement | null = null;

                button.addEventListener('mouseover', () => {
                    if (!tooltip) {
                        tooltip = document.createElement('div');
                        tooltip.className = 'tooltip';
                        tooltip.innerHTML = 'Finalizar jornada';
                        button.appendChild(tooltip);
                    }
                });

                button.addEventListener('mouseout', () => {
                    if (tooltip) {
                        tooltip.remove();
                        tooltip = null;
                    }
                });

                if (ehDiaDiferente) {
                    el.appendChild(button);
                } else {
                    el.parentNode?.insertBefore(button, el.nextSibling);
                }
            } else {
                button.remove();
            }
        } else {
            const userPlus = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM504 312l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>'
            button.innerHTML = userPlus;
            button.style.backgroundColor = theme.palette.secondary.main;
            button.style.color = theme.palette.getContrastText(theme.palette.secondary.main);
            if (button.children[0]) (button.children[0] as SVGSVGElement).style.fill = theme.palette.getContrastText(theme.palette.secondary.main);
            button.addEventListener('pointerdown', (e) => {
                e.preventDefault();
                e.stopPropagation();
                setAtribuirEntregador(true);
                setPedidoSelecionado(vaga);
            });

            let tooltip: HTMLDivElement | null = null;

            button.addEventListener('mouseover', () => {
                if (!tooltip) {
                    tooltip = document.createElement('div');
                    tooltip.className = 'tooltip';
                    tooltip.innerHTML = 'Atribuir entregador';
                    button.appendChild(tooltip);
                }
            });

            button.addEventListener('mouseout', () => {
                if (tooltip) {
                    tooltip.remove();
                    tooltip = null;
                }
            });

            if (ehDiaDiferente) {
                el.appendChild(button);
            } else {
                el.parentNode?.insertBefore(button, el.nextSibling);
            }
        }
    }, [theme.palette, isMobile]);

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.removeAllEvents(); // Limpa eventos antigos
            calendarApi.addEventSource(listaEventos); // Adiciona novos eventos
        }
    }, [listaEventos]);

    return (
        <>
            <BoxLoading loading={escalasStore.loading || alterarDataStore.loading} sx={{ height: '100%', flex: '1 1 100%', minHeight: '100%', pb: 2 }}>
                <FullCalendar
                    ref={calendarRef}
                    initialView={isMobile ? 'listMonth' : 'dayGridMonth'}
                    viewHeight={'100%'}
                    plugins={[dayGridMonth, interactionPlugin, listPlugin]}
                    locales={allLocales}
                    locale={'pt-br'}
                    datesSet={handleDatesSet}
                    events={listaEventos}
                    eventClick={handleEventClick}
                    dateClick={(e) => handleDateClick(e)}
                    editable={true}
                    droppable={true}
                    eventDrop={(e) => handleEventDrop(e)}
                    eventDidMount={handleEventDidMount}
                />
            </BoxLoading>
            {modalOpen &&
                <ModalAbrirVagas
                    open={modalOpen}
                    onClose={handleModalClose}
                    empresaID={empresaID}
                    callback={callback}
                    initialDate={initialDate}
                    editar={editarVaga}
                    pedidoID={pedidoID}
                />}
            {confirmacaoIniciar && (
                <ModalOpcoes
                    open={confirmacaoIniciar}
                    onClose={() => setConfirmacaoIniciar(false)}
                    acao='Iniciar jornada'
                    descricao={<Typography>
                        Deseja iniciar a jornada de <strong>{pedidoSelecionado?.entregadorUsuario?.nome} ({dayjs(pedidoSelecionado?.dataInicio, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')} - {dayjs(pedidoSelecionado?.dataFim, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')})</strong> ?
                    </Typography>}
                    opcoes={[
                        { label: 'Cancelar', action() { setConfirmacaoIniciar(false) } },
                        {
                            label: 'Confirmar',
                            action() {
                                pedidoSelecionado && dispatch(iniciarJornadaStart(pedidoSelecionado.pedidoID))
                            },
                            loading: iniciarJornada.loading
                        }
                    ]}
                />
            )}
            {confirmacaoFinalizar && (
                <ModalOpcoes
                    open={confirmacaoFinalizar}
                    onClose={() => setConfirmacaoFinalizar(false)}
                    acao='Finalizar jornada'
                    descricao={<Typography>
                        Deseja finalizar a jornada de <strong>{pedidoSelecionado?.entregadorUsuario?.nome} ({dayjs(pedidoSelecionado?.dataInicio, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')} - {dayjs(pedidoSelecionado?.dataFim, 'DD/MM/YYYY HH:mm:ss').format('HH:mm')})</strong> ?
                    </Typography>}
                    opcoes={[
                        { label: 'Cancelar', action() { setConfirmacaoFinalizar(false) } },
                        {
                            label: 'Confirmar',
                            action() {
                                pedidoSelecionado && dispatch(finalizarJornadaStart(pedidoSelecionado.pedidoID))
                            },
                            loading: finalizarJornada.loading
                        }
                    ]}
                />
            )}

            {atribuirEntregador && (
                <ModalPesquisarEntregador
                    modalProps={{ onClose: () => setAtribuirEntregador(false), open: atribuirEntregador }}
                    callback={handleAtribuirEntregador}
                />
            )}

            {entregadorSelecionado && (
                <ModalOpcoes
                    open={!!entregadorSelecionado}
                    onClose={() => setEntregadorSelecionado(null)}
                    acao='Atribuir entregador'
                    descricao={<Typography>
                        Deseja atribuir o entregador <strong>{entregadorSelecionado?.nome} </strong>  para esta vaga?
                    </Typography>}
                    opcoes={[
                        { label: 'Cancelar', action: handleConfirmacaoAtribuirEntregador },
                        {
                            label: 'Confirmar',
                            action: () => handleConfirmacaoAtribuirEntregador(),
                            loading: criarVaga.loading,
                        }
                    ]}
                />
            )}
        </>
    )
}

export default CalendarioEscalas
