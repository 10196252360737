import { createSlice } from "@reduxjs/toolkit";
import { DefaultState } from "src/dtos/DefaultDTO";
import { EmpresaListarDTO } from "src/dtos/EmpresasDTO";
import {
  EntregadoresOnlineDTO,
  PedidoEmAndamentoDTO,
} from "src/dtos/RastreioDTO";

interface InitialState {
  pedido: DefaultState<PedidoEmAndamentoDTO>;
  entregadores: DefaultState<EntregadoresOnlineDTO>;
  empresa: DefaultState<EmpresaListarDTO>;
  avaliacao: {
    data: {
      comentario: string | null;
      avaliacao: number | null;
      pedidoID: string | null;
    } | null;
  };
}

const initialState: InitialState = {
  pedido: {
    loading: false,
    error: null,
    data: null,
  },
  entregadores: {
    loading: false,
    error: null,
    data: null,
  },
  empresa: {
    loading: false,
    error: null,
    data: null,
  },
  avaliacao: {
    data: {
      comentario: null,
      avaliacao: null,
      pedidoID: null,
    },
  },
};

const slice = createSlice({
  name: "rastreioReducer",
  initialState,
  reducers: {
    pedidoStart(state, action) {
      state.pedido = {
        ...state.pedido,
        loading: true,
      };
    },
    pedidoSuccess(state, action) {
      state.pedido = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    pedidoFailure(state, action) {
      state.pedido = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    entregadoresStart(state, action) {
      console.log("entregadoresStart", action.payload);
      state.entregadores.error = null;
      state.entregadores.loading = true;
    },
    entregadoresSuccess(state, action) {
      state.entregadores.error = null;
      state.entregadores.loading = false;
      state.entregadores.data = action.payload;
    },
    entregadoresFailure(state, action) {
      state.entregadores.data = null;
      state.entregadores.loading = false;
      state.entregadores.error = action.payload;
    },
    empresaStart(state, action) {
      state.empresa = {
        ...state.empresa,
        loading: true,
      };
    },
    empresaSuccess(state, action) {
      state.empresa = {
        loading: false,
        data: action.payload,
        error: null,
      };
    },
    empresaFailure(state, action) {
      state.empresa = {
        loading: false,
        data: null,
        error: action.payload,
      };
    },
    avaliacaoRequest(state, action) {
      const { comentario, avaliacao, pedidoID } = action.payload;
      state.avaliacao.data = { comentario, avaliacao, pedidoID };
    },
  },
});
export const {
  pedidoStart,
  pedidoSuccess,
  pedidoFailure,
  entregadoresStart,
  entregadoresSuccess,
  entregadoresFailure,
  empresaStart,
  empresaSuccess,
  empresaFailure,
  avaliacaoRequest,
} = slice.actions;
export default slice;
