import { Button, Paper, Stack, SxProps, Typography } from "@mui/material";
import ModalBase, { ModalBaseProps } from "./ModalBase";
import { ButtonProps } from "@mui/material/Button/Button";
import ButtonLoading from "./ButtonLoading";
import { JSX, useEffect } from 'react'

interface ModalOpcoesProps {
    modalProps?: ModalBaseProps;
    descricao?: string | JSX.Element;
    opcoes: Opcao[];
    acao: string;
    open: boolean;
    onClose: () => void;
    sx?: SxProps;
}

interface Opcao {
    color?: ButtonProps["color"];
    disabled?: boolean;
    action: () => void;
    label: string;
    loading?: boolean;
}

/**
 * ModalOpcoes Component
 * 
 * Um componente modal personalizável que exibe um título de ação, descrição opcional, e um conjunto de botões de ação.
 * 
 * @component
 * 
 * @param {Object} props - Os props do componente.
 * @param {string} [props.descricao] - Descrição opcional a ser exibida no modal.
 * @param {Opcao[]} props.opcoes - Um array de opções (botões) a serem exibidas no modal.
 * @param {string} props.acao - O título da ação a ser exibido no cabeçalho do modal.
 * @param {boolean} [props.open] - Determina se o modal será aberto.
 * @param {function} [props.onClose] - Função que é executada quando o ação de fechar o modal é executada.
 * @param {ModalBaseProps} props.modalProps - Propriedades a serem passadas para o componente ModalBase.
 * 
 * @typedef {Object} Opcao
 * @property {ButtonProps["color"]} [color] - Cor opcional para o botão. O padrão é `grey` ou `primary`, por padrão, o último botão tem a cor de destaque.
 * @property {boolean} [disabled] - Determina se o botão é habilitado ou não. O padrão é `false`.
 * @property {boolean} [loading] - Determina se o botão está em carregamento ou não. O padrão é `false`.
 * @property {function} action - A função a ser executada quando o botão é clicado.
 * @property {string} label - A label para o botão.
 * 
 * @returns {JSX.Element} O componente ModalOpcoes renderizado.
 */
export default ({ descricao, opcoes, acao, open, onClose, modalProps, sx }: ModalOpcoesProps) => {
    useEffect(() => {
        if (!open) return;
        function handleKeyUp(event: KeyboardEvent) {
            console.log(event.key)
            if (event.key === 'Escape') {
                opcoes[0]?.action()
            } else if (event.key === 'Enter') {
                if(opcoes[1] && !opcoes[1].disabled && !opcoes[1].loading) {
                    opcoes[1]?.action()
                }
            }
        }

        document.addEventListener('keyup', handleKeyUp)

        return () => {
            document.removeEventListener('keyup', handleKeyUp)
        }
    }, [open])

    return (
        <ModalBase
            size="xs"
            {...modalProps}
            sx={sx}
            open={open}
            onClose={onClose}
            aria-describedby={
                typeof descricao === 'string' ? descricao : acao
            }
        >
            <Paper sx={{ p: 2, bgcolor: 'background.paper', width: '100%', boxSizing: 'border-box' }}>
                <Stack >
                    <Typography variant="h6" component={"h4"}
                        sx={{
                            width: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            px: 2,
                            borderRadius: '10px',
                            boxSizing: 'border-box',
                            my: 1
                        }}
                    >
                        {acao}
                    </Typography>
                    {typeof descricao === 'string' && typeof descricao !== 'undefined'
                        ? (
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                {descricao}
                            </Typography>
                        )
                        : descricao
                    }
                </Stack>
                <Stack direction="row" sx={{ justifyContent: 'flex-end', mt: 2, gap: 1 }} useFlexGap>
                    {opcoes.map((opcao, index, array) => (
                        <ButtonLoading
                            {...(opcao.loading ?? false)
                                ? { loading: true }
                                : { disabled: opcao.disabled }
                            }
                            key={index}
                            variant="containedFilled"
                            color={opcao?.color ?? (index == array.length - 1 ? "primary" : 'grey')}
                            onClick={opcao.action}
                            sx={{ width: 'min-content' }}
                        >
                            {opcao.label}
                        </ButtonLoading>
                    ))}
                </Stack>
            </Paper>
        </ModalBase >
    );
}
