
export const GOOGLE_API_KEY = 'AIzaSyDi_LKzscOj1wkn9GeYROet6_dfGfz91Kk'
export const PLANO_FREEMIUM = '8ad3b371-1a50-ef11-acd3-0e6c0ab65689'
export const PLANO_BRENDI = '5f6efa15-85de-ef11-acd3-0e6c0ab65689'
export const MAPBOX_API_KEY = 'pk.eyJ1IjoicGlja25nby1kZW1vIiwiYSI6ImNsOXlxNXpxNzA3Y20zb2x4djBodmh0dHIifQ.F4C8zTSN6X257GKlrB5nWQ'

//* Custom Hooks
export { default as useSnackbar } from './hooks/useSnackbar'
export { default as useResponsive } from './hooks/useResponsive'
export { default as useResponseSnackbar } from './hooks/useResponseSnackbar'
export { default as useAudio } from './hooks/useAudio'

//* Utils
// Máscaras Estáticas para Inputs
export { default as maskTelefone } from "./utils/maskTelefone";
export { default as maskMoney } from "./utils/maskMoney";
export { default as maskCpf } from "./utils/maskCpf";
export { default as maskDecimal } from "./utils/maskDecimal";
// Máscaras e formatarores real-time
export { default as liveMaskMoney } from "./utils/liveMaskMoney";
export { default as liveMaskPercentage } from "./utils/liveMaskPercentage";
export { default as dynamicMask } from "./utils/dynamicMask";
// Markers
export { default as createMarkerPedidoElement } from "./utils/createMarkerPedidoElement";
export { default as createMarkerEntregadorElement } from "./utils/createMarkerEntregadorElement";
// Outros
export { default as hexToRgb } from "./utils/hexToRgb";
export { default as sanitizeSituacao } from "./utils/sanitizeSituacao";
export { default as unSanitizeSituacao } from "./utils/unSanitizeSituacao";
export { default as getSituacaoStyles } from "./utils/getSituacaoStyles";
export { default as findOrigemImg } from "./utils/findOrigemImg";
export { default as validateCNPJ } from "./utils/validateCNPJ";
export { default as validateCPF } from "./utils/validateCPF";
export { default as formatTime } from "./utils/formatTime";
export { default as setCookies } from "./utils/setCookies";
export { default as converterDataCadastro } from "./utils/converterDataCadastro";

//* Componentes Simples Estilizdos com styled
// Padrão de nome: Componente - Funcionalidade
export { BoxScroll, BoxLine, BoxTableMinHeight } from "./components/styledBoxes"
export { IconButtonFechar } from "./components/styledIconButtons"
export { SwitchOnOff } from "./components/styledSwitchs"
export { ContainerBodyModal } from "./components/styledContainers"
export { ChipSituacao } from "./components/styledChips"
export { CardPrincipal, CardPedidoContent } from "./components/styledCardContent"
export { GridPainelLateral, GridColumn } from "./components/styledGrids"

//* Componentes Com Lógica Interna
export { default as AutocompleteEmpresas } from './components/AutocompleteEmpresas';
export { default as AutocompleteMap } from './components/AutocompleteMap';
export { default as AutocompleteMultiple } from './components/AutocompleteMultiple';
export { default as AutocompleteTipoVeiculo } from './components/AutocompleteTipoVeiculo';
export { default as AvatarWithModal } from "./components/AvatarWithModal";
export { default as BotaoSituacao } from './components/BotaoSituacao';
export { default as BoxLoading } from "./components/BoxLoading";
export { default as ButtonGroupPages } from './components/ButtonGroupPages';
export { default as ButtonLoading } from "./components/ButtonLoading";
export { default as ButtonTableOrder } from "./components/ButtonTableOrder";
export { default as CardTabelaTotal } from "./components/CardTabelaTotal";
export { default as ContainerLoading } from './components/ContainerLoading';
export { default as DynamicDataContainer } from './components/DynamicDataContainer';
export { default as HeaderCard } from "./components/HeaderCard";
export { default as HeaderModal } from "./components/HeaderModal";
export { default as ImageWithLoading } from "./components/ImageWithLoading";
export { default as InputDate } from './components/InputDate';
export { default as InputDateTime } from './components/InputDateTime';
export { default as InputTime } from './components/InputTime';
export { default as ListCardTabelaTotal } from './components/ListCardTabelaTotal';
export { default as ListOfSkeleton } from './components/ListOfSkeleton';
export { default as MapWithMarker } from './components/MapWithMarker';
export { default as MenuTabelas } from './components/MenuTabelas';
export { default as ModalBase } from "./components/ModalBase";
export { default as ModalOpcoes } from "./components/ModalOpcoes";
export { default as SelectBanco } from './components/SelectBanco';
export { default as SelectBotFuncao } from './components/SelectBotFuncao';
export { default as SelectBotFuncaoMsg } from './components/SelectBotFuncaoMsg';
export { default as SelectCidade } from './components/SelectCidade';
export { default as SelectEstado } from './components/SelectEstado';
export { default as SelectFormaPagamento } from './components/SelectFormaPagamento';
export { default as SelectMultiple } from './components/SelectMultiple';
export { default as SelectPaginacao } from './components/SelectPaginacao';
export { default as SelectTipoDespacho } from './components/SelectTipoDespacho';
export { default as SelectTipoFatura } from './components/SelectTipoFatura';
export { default as SelectTipoPedido } from './components/SelectTipoPedido';
export { default as SelectToggleAtivo } from './components/SelectToggleAtivo';
export { default as SnackbarAlert } from "./components/SnackbarAlert";
export { default as SnackbarAlertContext } from './components/SnackbarAlertContext';
export { default as StarRating } from './components/StarRating';
export { default as TableNavigation } from './components/TableNavigation';
export { default as TextFieldChip } from './components/TextFieldChip';
export { default as TextFieldDistance } from './components/TextFieldDistance';
export { default as TextFieldMoney } from './components/TextFieldMoney';
export { default as TextFieldTelefone } from './components/TextFieldTelefone';
export { default as TextFieldWithButton } from "./components/TextFieldWithButton";
export { default as TimeCounter } from './components/TimeCounter';
export { default as TipoDeVeiculo } from './components/TipoDeVeiculo';
export { default as TableRowLoading } from "./components/TableRowLoading";
export { default as TableRowNotFound } from "./components/TableRowNotFound";
export { default as InputEntregador } from './components/InputEntregador';
export { default as TableRowOrdenacao } from './components/TableRowOrdenacao';
export { default as TableCellOrdenacaoSimples } from './components/TableCellOrdenacaoSimples';
export { default as SelectVagaPadrao } from './components/SelectVagaPadrao';
export { default as DynamicTable } from './components/DynamicTable';
export { default as DynamicRow } from './components/DynamicRow';
