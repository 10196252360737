import { Box, Button, Typography } from "@mui/material";
import React, { Component, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class GlobalErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Erro capturado no GlobalErrorBoundary:", error, errorInfo);
    }

    handleReload = () => {
        try {
            // Limpa localStorage e sessionStorage
            localStorage.clear();
            sessionStorage.clear();
    
            // Expira todos os cookies
            document.cookie.split(";").forEach(cookie => {
                document.cookie = cookie
                    .replace(/^ +/, "")
                    .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
            });
    
            // Tenta limpar o cache do navegador
            if ('caches' in window) {
                caches.keys().then(names => {
                    names.forEach(name => caches.delete(name));
                });
            }
    
            // Força um hard reload
            window.location.href = window.location.origin;
        } catch (error) {
            console.error("Erro ao limpar dados:", error);
            window.location.reload();
        }
    };

    render() {
        if (this.state.hasError) {
            return (
                <Box
                    component="section"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        backgroundColor: "#f2f2f2",
                        color: "#721c24",
                        padding: 4,
                        borderRadius: 2,
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                        boxSizing: "border-box",
                        gap: 2,
                        textAlign: "center",
                    }}
                >
                    <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                        Oops! Algo deu errado.
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: "#ffffff",
                            border: "1px solid #f5c6cb",
                            borderRadius: 1,
                            padding: 2,
                            marginTop: 2,
                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                            maxWidth: "600px",
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="body1" sx={{ fontSize: 18 }}>
                            {this.state.error?.message || "Um erro desconhecido ocorreu."}
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            color: "white",
                            bgcolor: "#000",
                            "&:hover": { bgcolor: "#333" },
                            paddingX: 3,
                            paddingY: 1,
                            borderRadius: 1,
                        }}
                        onClick={this.handleReload}
                    >
                        Voltar para a página inicial
                    </Button>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default GlobalErrorBoundary;
