// InputTime.tsx
import { SxProps } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';

interface HookForms {
    name: string;
    label?: string;
    methods: UseFormReturn<any>;
    rules?: RegisterOptions;
}

interface InputTimeProps {
    hookForm: HookForms;
    sx?: SxProps;
}

const InputTime = ({ hookForm, sx }: InputTimeProps) => {
    const { name, methods, rules, label } = hookForm;
    const { control, formState: { errors } } = methods;

    return (
        <LocalizationProvider
            // Todo: Retirar LocalizationProvider desnecessários
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
        >
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field: { onChange, value: watchValue, ...field } }) => (
                    <TimePicker
                        {...field}
                        label={label || name}
                        slotProps={{
                            field: { clearable: true },
                            textField: {
                                size: 'small',
                                label: label || name,
                                error: !!errors?.[name],
                                helperText: errors?.[name]?.message ? String(errors?.[name]?.message) : '',
                            },
                        }}
                        sx={sx}
                        ampm={false}
                        value={watchValue ? dayjs(watchValue, 'HH:mm') : null}
                        onChange={(newValue) => {
                            onChange(newValue ? dayjs(newValue).format('HH:mm') : null)
                        }}
                        format="HH:mm"
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default InputTime;
