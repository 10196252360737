import { Box, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { BoxTableMinHeight, ButtonTableOrder, TableNavigation, TableRowLoading, TableRowNotFound } from 'src/ds'
import { CardFormFiltros, CardFormFiltrosRef } from 'src/ds/components/CardFormFiltros'
import liveMaskDistance from 'src/ds/utils/liveMaskDistance'
import { RootState } from 'src/store/reducers'
import { listarPedidosGeral } from 'src/store/reducers/pedidos'

const Historico = () => {

  const loading = useSelector((state: RootState) => state.pedidos?.listaPedidos?.loading);
  const pedidos = useSelector((state: RootState) => state.pedidos?.listaPedidos?.data?.dados);
  const dispatch = useDispatch();
  const cliente = useSelector((state: any) => state.clientes?.pagina?.data?.modelo);
  const cardRef = useRef<CardFormFiltrosRef>(null);

  const hookForm = useForm({
    defaultValues: {
      empresaClienteID: cliente?.empresaClienteID || '',
      pagina: 1,
      paginacao: 20,
      ordenacaoCampo: '',
      ordenacaoOrdem: '',
      filtros: ``,
    }
  })

  const { watch } = hookForm;

  useEffect(() => {
    dispatch(listarPedidosGeral({
      empresaClienteID: watch('empresaClienteID'),
      pagina: watch('pagina'),
      paginacao: watch('paginacao'),
      ordenacaoCampo: watch('ordenacaoCampo'),
      ordenacaoOrdem: watch('ordenacaoOrdem'),
      filtros: watch('filtros'),
    }))
  }, [])

  const [sortDirection, setSortDirection] = React.useState<{ [key: string]: 'asc' | 'desc' }>({
    empresaNome: 'asc',
    nome: 'asc',
    enderecoStr: 'asc',
    telefone: 'asc',
    qtdPedidos: 'asc',
  })

  useEffect(() => {
    dispatch(listarPedidosGeral({
      ...hookForm.getValues(),
    }));
  }, [watch("paginacao"), watch("pagina")]);

  const toggleSortDirection = (column: string) => {
    const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
    setSortDirection((prevState) => ({
      ...prevState,
      [column]: newSortDirection,
    }));
  };

  return (
    <BoxTableMinHeight>
      <CardFormFiltros dispatchMethods={{ actionName: 'listarPedidosGeral' }} ref={cardRef} formMethods={hookForm} fields={
        [
          { name: 'dtInicial', label: 'Data inicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
          { name: 'dtFinal', label: 'Data final', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
        ]
      }
        containerProps={
          { containerHasPadding: true, showHeader: true, elevation: 0 }
        }
      />
      {pedidos?.ultimaPagina ?
        <TableNavigation
          indiceInicial={pedidos?.indiceInicial}
          indiceFinal={pedidos?.indiceFinal}
          indiceTotal={pedidos?.indiceFinal}
          ultimaPagina={pedidos?.ultimaPagina}
          hookForm={hookForm} /> : null
      }
      <Table>
        <TableHead>
          <TableRow sx={{ borderTop: '1px solid #ccc' }}>

            <TableCell align="center">
              <ButtonTableOrder onClick={() => toggleSortDirection('empresaNome')} sortDirection={sortDirection} nome="empresaNome">
                Código
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center">
              <ButtonTableOrder onClick={() => toggleSortDirection('nome')} sortDirection={sortDirection} nome="nome">
                Detalhes
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('enderecoStr')} sortDirection={sortDirection} nome="enderecoStr">
                Entregador
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('telefone')} sortDirection={sortDirection} nome="telefone">
                Distância
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('qtdPedidos')} sortDirection={sortDirection} nome="qtdPedidos">
                Cadastro
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('despachado')} sortDirection={sortDirection} nome="despachado">
                Despachado
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('aceito')} sortDirection={sortDirection} nome="aceito">
                Aceito
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('noEstabelecimento')} sortDirection={sortDirection} nome="noEstabelecimento">
                No estabelecimento
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('emRota')} sortDirection={sortDirection} nome="emRota">
                Em rota
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('finalizado')} sortDirection={sortDirection} nome="finalizado">
                Finalizado
              </ButtonTableOrder>
            </TableCell>
            <TableCell align="center" >
              <ButtonTableOrder onClick={() => toggleSortDirection('retorno')} sortDirection={sortDirection} nome="retorno">
                Retorno
              </ButtonTableOrder>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRowLoading />
          ) : (
            pedidos && pedidos?.lista.length > 0
              ? pedidos?.lista.map((row, index) => (
                <TableRow key={index} hover>
                  <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                    <Chip label={`${row.codigo}` || '-'} color="success" size="small" />
                  </TableCell>
                  <TableCell align="center" sx={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                    {row.detalhes || '-'}
                  </TableCell>
                  <TableCell align="center">{row.entregadorUsuarioNome || '-'}</TableCell>
                  <TableCell align="center">{liveMaskDistance(row.distanciaCalculadaKm, false) || '-'}</TableCell>
                  <TableCell align="center">{row.dataCadastro || '-'}</TableCell>
                  <TableCell align="center">{row.dataDespachado || '-'}</TableCell>
                  <TableCell align="center">{row.dataAceito || '-'}</TableCell>
                  <TableCell align="center">{row.dataChegouEstabelecimento || '-'}</TableCell>
                  <TableCell align="center">{row.dataEmRota || '-'}</TableCell>
                  <TableCell align="center">{row.dataFinalizado || '-'}</TableCell>
                  <TableCell align="center">{row.dataRetorno || '-'}</TableCell>
                </TableRow>
              ))
              : <TableRowNotFound fezRequisicao={false} />
          )}
        </TableBody>
      </Table>
    </BoxTableMinHeight>
  )
}

export default Historico