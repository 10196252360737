import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { listarOcorrenciaRequest } from "../../../store/reducers/config";
import { useDispatch, useSelector } from "react-redux";
import { usePedido } from "../../../context/PedidoContext";
import { useForm, Controller } from "react-hook-form";
import { registrarOcorrencia, registrarOcorrenciaClean } from "../../../store/reducers/criarPedido";
import { RootState } from "src/store/reducers";
import { ButtonLoading, GridPainelLateral, HeaderCard, BoxLine } from "src/ds";
import { useSnackbarContext } from "src/context/SnackbarContext";

interface CriarOcorrenciaProps {
    close: () => void
}

const CriarOcorrencia = ({ close }: CriarOcorrenciaProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const { pedidoAtual } = usePedido();
    const [shouldClose, setShouldClose] = useState(false);

    useEffect(() => {
        dispatch(listarOcorrenciaRequest());
    }, [dispatch]);

    const tiposOcorrencias = useSelector((state: RootState) => state.config.ocorrencias);
    const usuarioID = useSelector((state: RootState) => state.config.masterPage?.usuario?.id);
    const ocorrenciaStore = useSelector((state: RootState) => state.criarPedido.ocorrencia);

    const {
        control,
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        defaultValues: {
            tipoOcorrenciaID: '',
            usuarioID: usuarioID,
            observacoes: '',
            pedidoID: pedidoAtual?.pedidoID,
            empresaID: pedidoAtual?.empresaID
        }
    });

    const onSubmit = (data: any) => {
        dispatch(registrarOcorrencia(data));
    };

    useEffect(() => {
        if (ocorrenciaStore.data) {
            showSuccess({
                message: ocorrenciaStore.data,
            });
            setShouldClose(true);
        } else if (ocorrenciaStore.error) {
            showError({
                message: ocorrenciaStore.error,
            });
        }
    }, [ocorrenciaStore.data, ocorrenciaStore.error]);

    useEffect(() => {
        if (shouldClose) {
            setTimeout(() => {
                dispatch(registrarOcorrenciaClean());
                close();
            }, 300)
        }
    }, [shouldClose]);

    return (
        <GridPainelLateral container sx={{ padding: '.5rem' }}>
            <HeaderCard title={`Ocorrência #${pedidoAtual?.codigoQuatro}`} onClick={close} />
            <BoxLine gap={2} mt={2}>
                <Box sx={{ flex: '1', width: '1px' }}>
                    <form id="ocorrenciaForm" onSubmit={handleSubmit(onSubmit)}>
                        <FormControl fullWidth sx={{ margin: '0 0 1rem 0' }}>
                            <InputLabel shrink htmlFor="tipoOcorrenciaID">Tipo de ocorrência</InputLabel>
                            <Controller
                                name="tipoOcorrenciaID"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        size='small'
                                        displayEmpty
                                        label="Tipo de ocorrência"
                                        id="tipoOcorrenciaID"
                                        fullWidth
                                        error={!!errors.tipoOcorrenciaID}
                                    >
                                        <MenuItem value="" disabled>
                                            Selecione um tipo
                                        </MenuItem>
                                        {tiposOcorrencias?.map((unidade) => (
                                            <MenuItem
                                                key={unidade.tipoOcorrenciaID}
                                                value={unidade.tipoOcorrenciaID}
                                                sx={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {unidade.nome}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                rules={{ required: 'Tipo de ocorrência é obrigatório' }}
                            />
                        </FormControl>
                        <TextField
                            {...register('observacoes', { required: 'Descrição é obrigatória' })}
                            multiline
                            rows={3}
                            label="Descrição"
                            placeholder="Descreva a ocorrência..."
                            error={!!errors.observacoes}
                            helperText={errors.observacoes ? 'Descrição é obrigatória' : ''}
                            fullWidth
                        />
                    </form>
                </Box>
            </BoxLine>
            <ButtonLoading
                loading={ocorrenciaStore.loading || shouldClose}
                type="submit"
                variant="containedFilled"
                form="ocorrenciaForm"
                sx={{ marginTop: 'auto' }}
            >
                Registrar ocorrência
            </ButtonLoading>
        </GridPainelLateral>
    );
};

export default CriarOcorrencia;
