import { IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalOpcoes, TableRowLoading, TableRowNotFound, TableRowOrdenacao } from 'src/ds';
import { RootState } from 'src/store/reducers';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { pontuacaoExcluirClear, pontuacaoExcluirStart } from 'src/store/reducers/entregador';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import ModalHandlePontuacao from './ModalHandlePontuacao';

interface TabelaPontuacaoProps {
  onSubmit?: () => void;
  callback: () => void;
  hookForm: UseFormReturn<any>;
  fezRequisicao?: boolean
}

const TabelaPontuacao: React.FC<TabelaPontuacaoProps> = ({ onSubmit, hookForm, fezRequisicao, callback }) => {
  const dispatch = useDispatch();
  const { showSuccess, showError } = useSnackbarContext()
  const pontuacaoData = useSelector((state: RootState) => state.entregadores.pontuacao.data)
  const loading = useSelector((state: RootState) => state.entregadores.pontuacao.loading)
  const [pontuacaoSelecionada, setPontuacaoSelecionada] = useState<null | { id: string, nome: string }>(null);
  const pontuacaoExcluirStore = useSelector((state: RootState) => state.entregadores.pontuacaoExcluir);
  const [pontuacaoIdEdit, setPontuacaoIdEdit] = useState<string | null>(null);

  function handleConfirmarExcluir(id: string) {
    dispatch(pontuacaoExcluirStart({ id }));
  }

  useEffect(() => {
    if (pontuacaoExcluirStore.data) {
      showSuccess({
        message: pontuacaoExcluirStore.data.mensagem,
      })
      callback();
      setPontuacaoSelecionada(null);
    } else if (pontuacaoExcluirStore.error) {
      showError({
        message: pontuacaoExcluirStore.error,
      });
    }
    return () => {
      dispatch(pontuacaoExcluirClear());
    }
  }, [pontuacaoExcluirStore.data, pontuacaoExcluirStore.error])

  const toggleSortDirection = (column: string, direction: string) => {
    hookForm.setValue('ordenacaoOrdem', direction);
    hookForm.setValue('ordenacaoCampo', column);
    onSubmit && onSubmit();
  };

  const cells = React.useMemo(() => [
    { label: 'Origem', name: 'nome' },
    { label: 'Cadastro', name: 'pontuacaoCadastro' },
    { label: 'Login Diário', name: 'pontuacaoLogin' },
    { label: 'Primeiro Pedido do Dia', name: 'pontuacaoPrimeiroPedidoDoDia' },
    { label: 'Aceitar Pedido', name: 'pontuacaoAceitarPedido' },
    { label: 'Recusar Pedido', name: 'pontuacaoRecusarPedido' },
    { label: 'Ações' },
  ], []);

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRowOrdenacao
              onSortChange={toggleSortDirection}
              cells={cells}
            />
          </TableHead>
          <TableBody>
            {loading
              ? <TableRowLoading />
              : pontuacaoData?.dados?.lista && pontuacaoData?.dados?.lista.length > 0
                ? pontuacaoData?.dados?.lista.map(item => (
                  <TableRow>
                    <TableCell align='center'>
                      {item.nome}
                    </TableCell>
                    <TableCell align='center'>
                      {item.pontuacaoCadastro}
                    </TableCell>
                    <TableCell align='center'>
                      {item.pontuacaoLogin}
                    </TableCell>
                    <TableCell align='center'>
                      {item.pontuacaoPrimeiroPedidoDoDia}
                    </TableCell>
                    <TableCell align='center'>
                      {item.pontuacaoAceitarPedido}
                    </TableCell>
                    <TableCell align='center'>
                      {item.pontuacaoRecusarPedido}
                    </TableCell>
                    <TableCell align='center'>
                      <Stack direction='row' justifyContent={'center'}>
                        <Tooltip
                          title='Editar'
                          arrow
                          placement='top'
                          onClick={() => setPontuacaoIdEdit(item.pontuacaoID)}
                        >
                          <IconButton size='small' color='primary'><EditRoundedIcon /></IconButton>
                        </Tooltip>
                        {/* <Tooltip title='Excluir' arrow placement='top'>
                          <IconButton
                            size='small'
                            color='error'
                            onClick={() => setPontuacaoSelecionada({ id: item.pontuacaoID, nome: item.nome })}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </Tooltip> */}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
                : (
                  <TableRowNotFound fezRequisicao={fezRequisicao} />
                )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {pontuacaoSelecionada && (
        <ModalOpcoes
          acao='Excluir pontuação'
          descricao={<Typography>Tem certeza que deseja excluir a pontuação <strong>{pontuacaoSelecionada.nome}</strong>?</Typography>}
          open={!!pontuacaoSelecionada}
          onClose={() => setPontuacaoSelecionada(null)}
          opcoes={[
            {
              label: 'Cancelar',
              action: () => setPontuacaoSelecionada(null),
            },
            {
              label: 'Confirmar',
              action: () => handleConfirmarExcluir(pontuacaoSelecionada.id),
              loading: pontuacaoExcluirStore.loading,
            },
          ]}
        />
      )} */}
      {pontuacaoIdEdit && (
        <ModalHandlePontuacao
          open={!!pontuacaoIdEdit}
          onClose={() => setPontuacaoIdEdit(null)}
          callback={callback}
          pontuacaoID={pontuacaoIdEdit}
        />
      )}
    </>
  )
}

export default TabelaPontuacao
