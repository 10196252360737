import { useEffect, useRef, useState } from 'react'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid2, InputAdornment, Slide, Stack, styled, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { useDispatch, useSelector } from 'react-redux';
import { criarVagaStart, desassociarEntregadorClear, desassociarEntregadorStart, criarVagaClear, paginaEscalaClear, paginaEscalaStart } from '../../../store/reducers/escalas';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ModalPesquisarEntregador from 'src/components/common/ModalPesquisarEntregador';
import { AutocompleteTipoVeiculo, BoxLoading, ButtonLoading, ContainerBodyModal, InputDateTime, ModalBase, ModalOpcoes, SelectVagaPadrao, TextFieldMoney, TextFieldWithButton, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { Entregador as EntregadorDTO } from 'src/dtos/EntregadoresDTO';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import dayjs from 'dayjs';
import ButtonHandleVaga from './ButtonHandleVaga';
import { CriarVagaPayload, VagaPadrao } from 'src/dtos/EscalasDTO';
import { Link } from 'react-router-dom';
dayjs.locale('pt-br');

interface TipoVeiculo {
    tipoVeiculoID: string;
}

interface Entregador {
    usuarioID: string;
    nome: string;
}

export interface AbrirVagasFormValues {
    tipoVeiculo: TipoVeiculo;
    entregador?: Entregador | null;
    entregadorUsuarioID: string;
    dataAgendamento: string;
    dataFimDiaria?: string | null;
    taxaTotalCobrada: string | number;
    taxaTotalEntregador: string | number;
    minimoGarantidoCobrado: boolean;
    minimoGarantidoEntregador: boolean;
    qtdPedidosMinimos: string;
    minimoGarantidoPorPedido: string | boolean;
    detalhesVaga: string;
    datasReplicacaoAux: string;
}

interface AbrirVagasProps {
    open: boolean;
    onClose: () => void;
    empresaID: string;
    callback?: () => void;
    editar?: boolean;
    pedidoID?: string | null;
    initialDate?: null | {
        dataAgendamento: string;
        dataFimDiaria: string;
    }
}

const ModalAbrirVagas = ({
    open,
    onClose,
    empresaID,
    callback,
    editar,
    pedidoID,
    initialDate
}: AbrirVagasProps) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const { isMobile } = useResponsive();
    const calendarContainer = useRef(null);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState<dayjs.Dayjs | null>(null);
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [modalEntregadoresOpen, setModalEntregadoresOpen] = useState(false);
    const [modalQntVagasOpen, setModalQntVagasOpen] = useState(false);
    const [qntVagas, setQntVagas] = useState(1);
    const [entregadorSelecionado, setEntregadorSelecionado] = useState<Entregador | null>(null);
    const [modalDesassociarEntregador, setModalDesassociarEntregador] = useState(false)
    const storeVeiculosLoading = useSelector((state: RootState) => state.escalas.listarVeiculos.loading);
    const criarVagaStore = useSelector((state: RootState) => state.escalas.criarVaga);
    const desassociarVagasStore = useSelector((state: RootState) => state.escalas.desassociarEntregador);
    const tipoUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
    const paginaPedido = useSelector((state: RootState) => state.escalas?.paginaEscala);
    const modeloPedido = useSelector((state: RootState) => state.escalas?.paginaEscala.data?.modelo);
    const [modelo, setModelo] = useState<AbrirVagasFormValues | null>(null);
    const hookForm = useForm<AbrirVagasFormValues>();
    const { register, handleSubmit, watch, setValue, reset, formState: { errors }, control, getValues } = hookForm;

    useEffect(() => {
        if (entregadorSelecionado) {
            setValue('entregador', {
                nome: entregadorSelecionado?.nome,
                usuarioID: entregadorSelecionado?.usuarioID,
            })
            setValue('entregadorUsuarioID', entregadorSelecionado?.usuarioID);
        }
    }, [entregadorSelecionado]);

    const selecionarEntregador = (entregador: EntregadorDTO) => setEntregadorSelecionado(entregador);

    function reqPaginaVaga() { if (pedidoID) dispatch(paginaEscalaStart({ id: pedidoID })) };

    useEffect(() => { reqPaginaVaga() }, [pedidoID]);

    useEffect(() => {
        if (initialDate) {
            setValue('dataAgendamento', initialDate.dataAgendamento);
            setValue('dataFimDiaria', initialDate.dataFimDiaria);
        }
    }, [initialDate])

    useEffect(() => {
        if (modelo) hookForm.reset(modelo);
    }, [modelo]);

    function definirVagaPadrao(data?: VagaPadrao | null) {
        if (!data) {
            setModelo(null);
            return
        };
        const dataAgendamentoInitial = getValues('dataAgendamento') ? dayjs(getValues('dataAgendamento'), 'DD/MM/YYYY') : dayjs();
        const dataAgendamento = dataAgendamentoInitial.format('DD/MM/YYYY') + ' ' + data.horaInicio.slice(0, 5);
        const dataFimDiariaInitial = getValues('dataFimDiaria') ? dayjs(getValues('dataFimDiaria'), 'DD/MM/YYYY') : dayjs();
        const dataFimDiaria = dataFimDiariaInitial.format('DD/MM/YYYY') + ' ' + data.horaFim.slice(0, 5);

        setModelo({
            entregador: getValues('entregador') || null,
            entregadorUsuarioID: '',
            tipoVeiculo: { tipoVeiculoID: data.tipoVeiculoID },
            dataAgendamento,
            dataFimDiaria,
            taxaTotalCobrada: data.taxaMinimaGarantidaCobrada
                ? data.taxaMinimaGarantidaCobrada
                : data.taxaTotalCobrada?.toString() || '0',
            taxaTotalEntregador: data.taxaMinimaGarantidaEntregador
                ? data.taxaMinimaGarantidaEntregador.toString()
                : data.taxaTotalEntregador?.toString() || '0',
            minimoGarantidoCobrado: !!data.taxaMinimaGarantidaCobrada,
            minimoGarantidoEntregador: !!data.taxaMinimaGarantidaEntregador,
            qtdPedidosMinimos: data.qtdPedidosMinimoGarantidoCobrado?.toString() || '0',
            minimoGarantidoPorPedido: !!data.qtdPedidosMinimoGarantidoCobrado,
            detalhesVaga: data.detalhes || '',
            datasReplicacaoAux: ''
        })
    }

    useEffect(() => {
        if (modeloPedido) {
            setModelo({
                entregadorUsuarioID: modeloPedido.entregadorUsuarioID || '',
                tipoVeiculo: { tipoVeiculoID: modeloPedido.tipoVeiculoID },
                entregador: (modeloPedido.entregadorUsuarioID && modeloPedido.entregadorUsuario) ? {
                    usuarioID: modeloPedido.entregadorUsuarioID,
                    nome: modeloPedido.entregadorUsuario.nome
                } : null,
                dataAgendamento: modeloPedido.dataAgendamento,
                dataFimDiaria: modeloPedido.dataConcluido,
                taxaTotalCobrada: modeloPedido.taxaTotalCobrada?.toString() || '0',
                taxaTotalEntregador: modeloPedido.taxaTotalEntregador?.toString() || '0',
                minimoGarantidoCobrado: !!modeloPedido.taxaMinimaGarantidaCobrada,
                minimoGarantidoEntregador: !!modeloPedido.taxaMinimaGarantidaEntregador,
                qtdPedidosMinimos: modeloPedido.qtdPedidosMinimoGarantidoCobrado?.toString() || '0',
                minimoGarantidoPorPedido: !!modeloPedido.qtdPedidosMinimoGarantidoCobrado,
                detalhesVaga: modeloPedido.detalhes || '',
                datasReplicacaoAux: ''
            });
        }
    }, [modeloPedido])

    const criarVagaReq = (criarVagaPayload?: AbrirVagasFormValues | null) => {
        if (qntVagas >= 1 && criarVagaPayload) {
            const {
                tipoVeiculo,
                entregador,
                dataFimDiaria,
                dataAgendamento,
                taxaTotalCobrada,
                taxaTotalEntregador,
                minimoGarantidoCobrado,
                minimoGarantidoEntregador,
                qtdPedidosMinimos,
                minimoGarantidoPorPedido,
                detalhesVaga
            } = criarVagaPayload

            const infoPayload: CriarVagaPayload = {
                pedidoID: pedidoID || '',
                codigoExterno: 'DIARIA',
                empresaID: empresaID,
                tipoVeiculoID: tipoVeiculo.tipoVeiculoID,
                entregadorUsuarioID: entregador?.usuarioID || '',
                modalForm_form_entregadorUsuarioID_input: entregador?.nome || '',
                dataAgendamento: dataAgendamento,
                dataConcluido: dataFimDiaria,
                taxaTotalCobrada: taxaTotalCobrada,
                minimoGarantidoCobrado: minimoGarantidoCobrado,
                taxaTotalEntregador: taxaTotalEntregador,
                minimoGarantidoEntregador: minimoGarantidoEntregador,
                qtdPedidosMinimoGarantidoCobrado: qtdPedidosMinimos,
                detalhes: detalhesVaga,
                minimoGarantidoPorPedido: minimoGarantidoPorPedido ? minimoGarantidoPorPedido.toString() : 'false',
                qtdVagas: qntVagas,
                datasReplicacaoAux: selectedDates.length > 0 ? selectedDates.join(',') : '',
                ...selectedDates.reduce((obj, date, index) => ({ ...obj, [`datasReplicacao[${index}]`]: date }), {})
            }
            const formPayload = new FormData();
            Object.keys(infoPayload).forEach(key => {
                const value = infoPayload[key as keyof CriarVagaPayload];
                formPayload.append(key, String(value));
            });
            dispatch(criarVagaStart(formPayload))
        } else {
            console.log('valor inválido');
        }
    };

    const onSubmit: SubmitHandler<AbrirVagasFormValues> = (formData) => {
        const usuarioID = watch('entregador')?.usuarioID
        if (!editar) !usuarioID ? setModalQntVagasOpen(true) : criarVagaReq(formData)
        else criarVagaReq(formData)
    };

    const handleDatasReplicacao = (date: dayjs.Dayjs) => {
        setSelectedDay(date);
        const formattedDate = date.format('DD/MM/YYYY')

        if (!formattedDate) return;

        const isSelected = selectedDates.includes(formattedDate);

        if (isSelected) {
            setSelectedDates((prevDates) =>
                prevDates.filter((d) => d !== formattedDate)
            );
            setSelectedDay(null);
        } else {
            setSelectedDates((prevDates) => [...prevDates, formattedDate]);
            setSelectedDay(date);
        }
    };

    useEffect(() => {
        return () => {
            dispatch(desassociarEntregadorClear())
            dispatch(paginaEscalaClear())
        }
    }, [])

    useEffect(() => {
        if (criarVagaStore.data) {
            showSuccess({
                message: criarVagaStore.data?.mensagem,
            });
            setModalQntVagasOpen(false);
            callback?.()
            dispatch(criarVagaClear());
            modeloPedido && dispatch(paginaEscalaClear());
            setEntregadorSelecionado(null)
            setQntVagas(1)
            reset()
            onClose();
        } else if (criarVagaStore.error) {
            showError({
                message: criarVagaStore?.error,
            });
        }
    }, [criarVagaStore.error, criarVagaStore.data]);

    useEffect(() => {
        if (desassociarVagasStore.data) {
            showSuccess({
                message: desassociarVagasStore.data.mensagem,
            });
            setModalDesassociarEntregador(false)
            setEntregadorSelecionado({
                nome: '',
                usuarioID: ''
            })
            dispatch(desassociarEntregadorClear());
            reqPaginaVaga();
            callback && callback();
        } else if (desassociarVagasStore.error) {
            showError({
                message: desassociarVagasStore.error,
            });
        }
    }, [desassociarVagasStore.data, desassociarVagasStore.error]);

    const handleDesassociarEntregador = () => {
        if (pedidoID) setModalDesassociarEntregador(true);
    }

    const validateDateTime = (value: string) => {
        if (!value) return 'Campo obrigatório';

        const dataAgendamento = watch('dataAgendamento');
        const dataFimDiaria = watch('dataFimDiaria');

        const startDate = dayjs(dataAgendamento, 'DD/MM/YYYY HH:mm');
        const endDate = dayjs(dataFimDiaria, 'DD/MM/YYYY HH:mm');

        if (endDate.isBefore(startDate)) {
            return 'A data fim não pode ser menor que a data de início.';
        }

        // Verifica se o intervalo entre as datas é maior que 24 horas
        const diffInMinutes = endDate.diff(startDate, 'minute');
        if (diffInMinutes > 1440) {
            return 'O intervalo entre as datas não pode ser maior que 24 horas.';
        }

        return true;
    }

    function handleCloseModal() {
        dispatch(criarVagaClear());
        modeloPedido && dispatch(paginaEscalaClear());
        onClose()
    }

    function handleExcluirVaga() {
        callback?.()
        handleCloseModal()
    }

    function trocarStatusCallback() {
        reqPaginaVaga()
        callback?.()
    }

    return (
        <>
            <ModalBase
                open={open}
                onClose={handleCloseModal}
                size='sm'
                headerModal={{ title: editar ? 'Edição de diária' : 'Criação de diárias' }}
            >
                <ContainerBodyModal>
                    <Grid2 container height={'100%'}>
                        {/* <button style={{ marginBottom: '.5rem' }} onClick={() => console.log(hookForm.getValues())}>store</button> */}
                        <BoxLoading loading={paginaPedido.loading || storeVeiculosLoading}>
                            <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }} id="form">
                                <Grid2 container spacing={'1rem'} mb={2}>
                                    {empresaID && (
                                        <SelectVagaPadrao
                                            empresaID={empresaID}
                                            callback={definirVagaPadrao}
                                            sx={{ width: '100%' }}
                                        />
                                    )}
                                    <Grid2 size={{ xs: 12 }}>
                                        <AutocompleteTipoVeiculo
                                            hookForm={{
                                                defaultVeiculoID: modelo?.tipoVeiculo.tipoVeiculoID,
                                                methods: hookForm,
                                                name: 'tipoVeiculo.tipoVeiculoID',
                                            }}
                                            label='Tipo veículo'
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }}>
                                        <Controller
                                            control={control}
                                            name="entregador"
                                            render={({ field }) => (
                                                <TextFieldWithButton
                                                    label="Entregador"
                                                    placeholder="Selecione um entregador"
                                                    size='small'
                                                    {...field}
                                                    value={field.value?.nome || ''}
                                                    slotProps={{
                                                        htmlInput: {
                                                            readOnly: true,
                                                        },
                                                    }}
                                                    buttonProps={{
                                                        variant: 'containedFilled',
                                                        icon: <SearchRoundedIcon />,
                                                        onClick: () => setModalEntregadoresOpen(true),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }} container>
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                gap: '1rem',
                                                flexDirection: isMobile ? 'column' : 'row',
                                            }}
                                            useFlexGap
                                            divider={!isMobile && <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '100%', mt: 0 }} />}
                                        >
                                            <InputDateTime
                                                hookForm={{
                                                    name: 'dataAgendamento',
                                                    methods: hookForm,
                                                    rules: {
                                                        required: 'Campo obrigatório',
                                                        validate: validateDateTime,
                                                    },
                                                }}
                                                label='Data e hora de agendamento'
                                                sx={{ flex: '1' }}
                                            />
                                            <InputDateTime
                                                hookForm={{
                                                    name: 'dataFimDiaria',
                                                    methods: hookForm,
                                                    rules: {
                                                        required: 'Campo obrigatório',
                                                        validate: validateDateTime,
                                                    },
                                                }}
                                                label='Data e hora de fim da diária'
                                                sx={{ flex: '1' }}
                                            />
                                        </Stack>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }} container>
                                        <Stack
                                            sx={{
                                                width: '100%',
                                                gap: '1rem',
                                                flexDirection: isMobile ? 'column' : 'row',
                                            }}
                                            useFlexGap
                                            divider={!isMobile && <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '100%', mt: 0 }} />}
                                        >
                                            {tipoUsuario !== 'SAAS' && <TextFieldMoney
                                                label="Taxa total cobrada"
                                                hookForm={{
                                                    methods: hookForm,
                                                    name: "taxaTotalCobrada",
                                                    rules: {
                                                        required: "Campo obrigatório"
                                                    },
                                                    defaultValue: modelo?.taxaTotalCobrada || 0
                                                }}
                                                sx={{ flex: 1 }}
                                            />}
                                            <TextFieldMoney
                                                label="Taxa total a pagar"
                                                hookForm={{
                                                    methods: hookForm,
                                                    name: "taxaTotalEntregador",
                                                    rules: {
                                                        required: "Campo obrigatório"
                                                    },
                                                    defaultValue: modelo?.taxaTotalEntregador || 0
                                                }}
                                                sx={{ flex: 1 }}
                                            />
                                        </Stack>
                                    </Grid2>
                                    {tipoUsuario !== 'SAAS' &&
                                        <Grid2 size={{ xs: 6 }}>
                                            <Controller
                                                name='minimoGarantidoCobrado'
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Tooltip title="Ao marcar essa função será determinado o valor mínimo que o sistema cobrará do estabelecimento pelo uso do entregador, mesmo que o volume de taxas seja abaixo desse valor" arrow placement='top'>

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        {...field}
                                                                        value={watch("minimoGarantidoCobrado")}
                                                                        checked={!!watch("minimoGarantidoCobrado")}
                                                                        onChange={field.onChange}
                                                                    />
                                                                }
                                                                label="Mínimo garantido cobrado"
                                                            />
                                                        </Tooltip>
                                                    )
                                                }}
                                            />
                                        </Grid2>}
                                    <Grid2 size={{ xs: 6 }}>
                                        <Controller
                                            name='minimoGarantidoEntregador'
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Tooltip title="Ao marcar essa função será determinado o valor mínimo que o entregador receberá, garantindo sua remuneração mesmo que o total de taxas realizadas não atinja esse valor." arrow placement='top'>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    value={watch("minimoGarantidoEntregador")}
                                                                    checked={!!watch("minimoGarantidoEntregador")}
                                                                    onChange={field.onChange}
                                                                />
                                                            }
                                                            label="Mínimo garantido pago"
                                                        />
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 6 }} alignContent={'center'}>
                                        <Controller
                                            name='minimoGarantidoPorPedido'
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Tooltip title="Ative o Mínimo Garantido Pago e defina o valor mínimo por número de pedidos. O entregador será remunerado com o mínimo garantido por X pedidos e, ao superar o limite, receberá também as taxas pelos pedidos excedentes." arrow placement='top'>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    value={watch("minimoGarantidoPorPedido")}
                                                                    checked={!!watch("minimoGarantidoPorPedido")}
                                                                    onChange={field.onChange}
                                                                />
                                                            }
                                                            label="Qnt. mínima de pedidos"
                                                        />
                                                    </Tooltip>
                                                )
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: tipoUsuario !== 'SAAS' ? 6 : 12 }}>
                                        <TextField
                                            size='small'
                                            label="Qtd. pedidos mínimo"
                                            fullWidth
                                            {...register("qtdPedidosMinimos", { required: "Campo obrigatório" })}
                                            onChange={(e) => {
                                                const inputValue = e.target.value
                                                let cleanedValue = inputValue.replace(/\D/g, '')
                                                if (cleanedValue.length > 1) cleanedValue = cleanedValue.replace(/^0+/, '')
                                                setValue("qtdPedidosMinimos", cleanedValue)
                                            }}
                                            value={watch("qtdPedidosMinimos") || 0}
                                            error={!!errors.qtdPedidosMinimos}
                                            helperText={!!errors.qtdPedidosMinimos && errors.qtdPedidosMinimos?.message}
                                            slotProps={{
                                                input: {
                                                    type: 'number',
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Os clientes podem definir uma meta de pedidos para os entregadores, garantindo um valor mínimo a ser pago pelo número de pedidos. Após ultrapassar a quantidade mínima de pedidos, o pagamento será o mínimo mais taxa por pedido excedente." arrow placement="top">
                                                                <HelpOutlineRoundedIcon />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={{ xs: 12 }}>
                                        <TextField
                                            size='small'
                                            label="Detalhes da vaga"
                                            fullWidth
                                            value={watch("detalhesVaga")}
                                            {...register("detalhesVaga")}
                                            slotProps={{ inputLabel: { shrink: !!watch("detalhesVaga") } }}
                                            error={!!errors.detalhesVaga}
                                            multiline
                                            placeholder='Digite aqui informações importantes da vaga...'
                                            rows={4}
                                        />
                                    </Grid2>
                                    {!editar && (
                                        <Grid2 size={{ xs: 12 }}>

                                            <Controller
                                                control={control}
                                                name="datasReplicacaoAux"
                                                render={({ field }) => (
                                                    <TextFieldWithButton
                                                        label="Datas de replicação"
                                                        size='small'
                                                        {...field}
                                                        value={selectedDates.join(', ')}
                                                        slotProps={{
                                                            htmlInput: {
                                                                readOnly: true,
                                                            }
                                                        }}
                                                        buttonProps={{
                                                            variant: 'containedFilled',
                                                            icon: <CalendarMonthRoundedIcon />,
                                                            onClick: () => setCalendarOpen((prev) => !prev),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Box ref={calendarContainer}>
                                                <Slide in={calendarOpen} container={calendarContainer.current} direction="down">
                                                    <div>
                                                        {calendarOpen &&
                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                                                <DateCalendar
                                                                    showDaysOutsideCurrentMonth
                                                                    onChange={(date) => handleDatasReplicacao(dayjs(date))}
                                                                    value={selectedDay}
                                                                    shouldDisableDate={(date) => {
                                                                        const dataAgendamento = watch('dataAgendamento')
                                                                        if (dayjs(date).isBefore(dayjs(), 'day') || (dataAgendamento && dayjs(date).isSame(dayjs(dataAgendamento, 'DD/MM/YYYY HH:mm'), 'day'))) {
                                                                            return true
                                                                        };
                                                                        return false;
                                                                    }}
                                                                    slots={{
                                                                        day: (props) => {
                                                                            const highlighted = selectedDates.includes(dayjs(props.day).format('DD/MM/YYYY'))
                                                                            return (<HighlightedPickersDay highlighted={highlighted} {...props} />)
                                                                        }
                                                                    }}
                                                                />
                                                            </LocalizationProvider>}
                                                    </div>
                                                </Slide>
                                            </Box>
                                        </Grid2>
                                    )}
                                </Grid2>
                                <Stack
                                    useFlexGap
                                    sx={{
                                        width: '100%',
                                        gap: 1,
                                        flexWrap: 'wrap',
                                        flexDirection: isMobile ? 'column' : 'row',
                                        mt: 'auto',
                                        pb: 2,
                                        justifyContent: isMobile ? 'center' : 'flex-end',
                                        alignItems: isMobile ? 'stretch' : 'center',
                                    }}
                                >
                                    {(pedidoID) &&
                                        <ButtonHandleVaga
                                            pedidoID={pedidoID}
                                            entregador={{
                                                dataChegouEstabelecimento: modeloPedido?.dataChegouEstabelecimento,
                                                dataFinalizado: modeloPedido?.dataFinalizado,
                                                entregadorUsuarioID: modeloPedido?.entregadorUsuarioID
                                            }}
                                            callback={trocarStatusCallback}
                                            closeCriarVaga={handleExcluirVaga}
                                        />
                                    }
                                    {(editar && pedidoID && modeloPedido?.entregadorUsuarioID && watch('entregador')?.usuarioID !== '') &&
                                        <ButtonLoading
                                            loading={desassociarVagasStore.loading}
                                            variant='containedFilled'
                                            color='secondary'
                                            onClick={handleDesassociarEntregador}
                                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                                        >
                                            Desassociar entregador
                                        </ButtonLoading>}
                                    {paginaPedido.data?.permissoes['pedido.visualizar'] && (
                                        <Button
                                            component={Link}
                                            to={`/Pedido/Index/${pedidoID}`}
                                            target='_blank'
                                            variant='containedFilled'
                                            color='grey'
                                            sx={{ width: isMobile ? '100%' : 'min-content' }}
                                        >
                                            Visualizar
                                        </Button>
                                    )}
                                    <Button
                                        variant='containedFilled'
                                        color='grey'
                                        onClick={handleCloseModal}
                                        sx={{ width: isMobile ? '100%' : 'min-content' }}
                                    >
                                        Cancelar
                                    </Button>
                                    <ButtonLoading
                                        loading={criarVagaStore.loading}
                                        type='submit'
                                        variant='containedFilled'
                                        sx={{ width: isMobile ? '100%' : 'min-content' }}
                                    >
                                        {editar ? 'Salvar' : 'Abrir'} vaga
                                    </ButtonLoading>
                                </Stack>
                            </form>
                        </BoxLoading>
                    </Grid2>
                </ContainerBodyModal>
            </ModalBase>

            {/* MODAL QNT VAGAS */}
            <ModalOpcoes
                open={modalQntVagasOpen}
                onClose={() => setModalQntVagasOpen(false)}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action() { setModalQntVagasOpen(false) },
                    },
                    {
                        label: 'Criar Vaga',
                        action() { criarVagaReq(getValues()) },
                        loading: criarVagaStore.loading,
                        disabled: qntVagas < 1,
                    },
                ]}
                acao='Criar vaga'
                descricao={
                    <Grid2 container spacing={2} sx={{ width: '100%', mt: 2 }} >
                        <Grid2 size={{ xs: 12 }}>
                            <Typography variant='body1' sx={{ width: '100%' }}>Quantas vagas gostaria de abrir com esses dados?</Typography>
                        </Grid2>
                        <Grid2 size={{ xs: 12 }}>
                            <TextField
                                label="Qnt. de Vagas"
                                fullWidth
                                size='small'
                                value={qntVagas}
                                onChange={(e) => setQntVagas(Number(e.target.value))}
                                type='number'
                                slotProps={{ htmlInput: { min: 1 } }}
                            />
                        </Grid2>
                    </Grid2>
                }
            />

            {/* MODAL ENTREGADORES */}
            <ModalPesquisarEntregador
                modalProps={{
                    open: modalEntregadoresOpen,
                    onClose: () => setModalEntregadoresOpen(false),
                }}
                callback={selecionarEntregador}
            />

            {modalDesassociarEntregador && (
                <ModalOpcoes
                    open={modalDesassociarEntregador}
                    onClose={() => setModalDesassociarEntregador(false)}
                    acao='Desassociar entregador'
                    descricao={<Typography>Você deseja desassociar o entregador <strong>{modeloPedido?.entregadorNome}</strong> da vaga?</Typography>}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => { setModalDesassociarEntregador(false) },
                        },
                        {
                            label: 'Confirmar',
                            loading: desassociarVagasStore.loading,
                            action: () => { if (pedidoID) dispatch(desassociarEntregadorStart({ pedidoID })) }
                        }
                    ]}
                />
            )}
        </>
    )
}

export default ModalAbrirVagas

type HighlightedPickersDayProps = PickersDayProps<any> & {
    highlighted: boolean;
    theme?: Theme;
}

const HighlightedPickersDay = styled(PickersDay)(({ theme, highlighted }: HighlightedPickersDayProps) => ({
    ...(highlighted && {
        backgroundColor: theme?.palette.primary.main,
        color: theme?.palette.common.white,
        '&:hover': {
            backgroundColor: theme?.palette.primary.dark,
        },
    }),
}));
