
import { useTheme, } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import timer from "../../assets/icons/time.svg";
import dayjs from "dayjs";
import { RootState } from "../../store/reducers";
import { DynamicDataContainer, formatTime } from "src/ds";
import { useForm } from "react-hook-form";
import TabelaPerformance from "src/components/PerformancePedidos/TabelaPerformance";
import DashboardPerformance from "src/components/PerformancePedidos/DashboardPerformance";
import { exportarPerformancePedidosStart } from "src/store/reducers/pedidos";
import { useSnackbarContext } from "src/context/SnackbarContext";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const PerformancePedido = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { showError } = useSnackbarContext();
    const cardRef = useRef<DynamicDataContainerRef>(null)

    const defaultValues = {
        empresaID: '',
        filtroCodigo: '',
        distanciaInicial: '',
        distanciaFinal: '',
        dtInicial: dayjs().subtract(1, 'day').format('DD/MM/YYYY 00:00'),
        dtFinal: dayjs().format('DD/MM/YYYY 23:59'),
        filtroNome: '',
        faixaKm: '',
        paginacao: '20',
        pagina: '1',
        ordenacaoCampo: '',
        ordenacaoOrdem: '',
        filtros: '',
        titulos: '',
        variaveis: '',
        formato: '',
    }

    const hookForm = useForm({ defaultValues });
    const { watch } = hookForm;

    useEffect(() => { hookForm.reset(defaultValues) }, []);

    const pedidosDados = useSelector((state: RootState) => state.pedidos.performancePedidos?.data?.dados);
    const pedidosError = useSelector((state: RootState) => state.pedidos.performancePedidos.error);
    const loadingPedidos = useSelector((state: RootState) => state.pedidos.performancePedidos.loading);
    const loadingExportar = useSelector((state: RootState) => state.pedidos.exportarPerformancePedidos.loading);

    const menuOptions = [{
        label: 'Exportar',
        action: () => {
            dispatch(exportarPerformancePedidosStart(hookForm.getValues()))
        }
    }];

    useEffect(() => {
        if (pedidosError) {
            showError({
                message: pedidosError,
            })
        }
    }, [pedidosError]);

    const faixaKm = watch('faixaKm')

    useEffect(() => {
        if (faixaKm) {
            if (faixaKm === 'todos') {
                hookForm.setValue('distanciaInicial', '')
                hookForm.setValue('distanciaFinal', '')
            } else if (faixaKm === '10') {
                hookForm.setValue('distanciaInicial', faixaKm)
                hookForm.setValue('distanciaFinal', '99')
            } else {
                const numberFaixa = Number(faixaKm)
                hookForm.setValue('distanciaInicial', `${numberFaixa}`)
                hookForm.setValue('distanciaFinal', `${numberFaixa + 1}`)
            }
        }
    }, [faixaKm])

    return (
        <DynamicDataContainer
            hookForm={hookForm}
            ref={cardRef}
            cardFormFiltrosProps={{
                dispatchMethods: { actionName: 'listarPerformancePedidosStart' },
                loading: loadingPedidos,
                fields: [
                    { label: 'Código', name: 'filtroCodigo' },
                    { label: 'Empresa', name: 'empresaID', type: 'autocompleteEmpresa' },
                    { label: 'Data inicial', name: 'dtInicial', type: 'dateTime', rules: { required: 'Data inicial é obrigatória' } },
                    { label: 'Data final', name: 'dtFinal', type: 'dateTime', rules: { required: 'Data final é obrigatória' } },
                    { label: 'Entregador', name: 'filtroNome' },
                    {
                        label: 'Quilometragem', name: 'faixaKm', type: 'customSelect', options: [
                            { label: 'Todas', value: 'todos' },
                            { label: 'Faixa de 1Km', value: '1' },
                            { label: 'Faixa de 2Km', value: '2' },
                            { label: 'Faixa de 3Km', value: '3' },
                            { label: 'Faixa de 4Km', value: '4' },
                            { label: 'Faixa de 5Km', value: '5' },
                            { label: 'Faixa de 6Km', value: '6' },
                            { label: 'Faixa de 7Km', value: '7' },
                            { label: 'Faixa de 8Km', value: '8' },
                            { label: 'Faixa de 9Km', value: '9' },
                            { label: 'Faixa de 10Km ou mais', value: '10' }
                        ],
                        valueKey: 'value',
                        labelKey: 'label',
                    }
                ]
            }}
            cardTabelaTotalProps={{
                cardData: [
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Tempo Médio do Pedido Pronto',
                        bgcolor: theme.palette.grey[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO PEDIDO PRONTO',
                            subtitle1: pedidosDados?.mediaTempoPedidoPronto ? formatTime(pedidosDados?.mediaTempoPedidoPronto) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Tempo Médio do Despacho',
                        bgcolor: theme.palette.blue[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO DESPACHO',
                            subtitle1: pedidosDados?.tempoMedioDespacho ? formatTime(pedidosDados?.tempoMedioDespacho) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Tempo Médio Rota do Entregador',
                        bgcolor: theme.palette.yellow[600],
                        texts: {
                            title1: 'TEMPO MÉDIO ROTA DO ENTREGADOR',
                            subtitle1: pedidosDados?.mediaTempoRotaEntregador ? formatTime(pedidosDados?.mediaTempoRotaEntregador) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Porcentagem Entregas Dentro da Estimativa (ETA)',
                        bgcolor: theme.palette.green[600],
                        texts: {
                            title1: 'PORCENTAGEM ENTREGAS DENTRO DA ESTIMATIVA (ETA)',
                            subtitle1: pedidosDados?.porcentagemNegativos ? `${pedidosDados?.porcentagemNegativos.toFixed(2)} %` : '0%'
                        }
                    },
                ]
            }}
            sections={[
                {
                    label: 'Pedidos',
                    tableNavigation: {
                        indiceInicial: pedidosDados?.indiceInicial,
                        indiceFinal: pedidosDados?.indiceFinal,
                        indiceTotal: pedidosDados?.total,
                        ultimaPagina: pedidosDados?.ultimaPagina,
                        podeTodos: false,
                        requiredFields: ['dtInicial', 'dtFinal']
                    },
                    menu: { options: menuOptions, loading: loadingExportar },
                    component: <TabelaPerformance
                        pedidos={pedidosDados?.lista}
                        loading={loadingPedidos}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                        hookForm={hookForm}
                    />
                },
                {
                    label: 'Dashboard',
                    updateOnExchange: true,
                    component: <DashboardPerformance
                        formMethods={hookForm}
                        loading={loadingPedidos}
                        atualizar={cardRef.current?.atualizarDispatch}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />,
                }
            ]}
        />
    );
};

export default PerformancePedido;
