import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

interface RouteError {
    message?: string;
}

const ErrorBoundary = () => {
    const error = useRouteError() as RouteError;
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box
            component="section"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: theme.palette.background.default,
                color: '#721c24',
                padding: 4,
                borderRadius: 2,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                boxSizing: 'border-box',
                gap: 2,
            }}
        >
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                Oops! Algo deu errado.
            </Typography>
            <Box
                sx={{
                    backgroundColor: '#ffffff',
                    border: '1px solid #f5c6cb',
                    borderRadius: 1,
                    padding: 2,
                    marginTop: 2,
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                    maxWidth: '600px',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Typography variant="body1" sx={{ fontSize: 18 }}>
                    {error.message || "Um erro desconhecido ocorreu."}
                </Typography>
            </Box>
            <Button
                variant="containedFilled"
                color="primary"
                onClick={() => navigate('/Pedido/PainelPerformance')}
                sx={{ width: 'fit-content' }}
            >
                Volte para a página inicial
            </Button>
        </Box>
    );
};

export default ErrorBoundary;
