import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePedido } from 'src/context/PedidoContext';
import { ButtonLoading, ContainerBodyModal, ModalBase } from 'src/ds';
import { despacharRequest } from 'src/store/reducers/criarPedido';

const ModalTaxaExtra = ({ open, setOpen, data, entregador }: any) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const { pedidoAtual, rotaAtual, novaListaPedidos, limparNovaLista } = usePedido();
    const dispatch = useDispatch();
    const atribuirStore = useSelector((state: any) => state.criarPedido?.atribuir);


    const handleAtribuir = () => {
        let payload
        if (novaListaPedidos?.length > 0) {
            payload = {
                pedidoID: novaListaPedidos.map((p: any) => p.pedidoID),
                usuarioID: entregador.usuarioID,
                rota: true
            }
        } else {
            payload = {
                // @ts-expect-error
                pedidoID: rotaAtual ? rotaAtual.map(p => p.pedidoID) : pedidoAtual?.pedidoID,
                usuarioID: entregador.usuarioID,
                rota: !!rotaAtual,
                taxaExtraIDs: selectedOptions
            };
        }
        dispatch(despacharRequest(payload));
        limparNovaLista();

    }

    const handleSelect = (id: string) => {
        setSelectedOptions(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(option => option !== id) // Deseleciona
                : [...prevSelected, id] // Seleciona
        );
    };

    return (
        <ModalBase open={open} onClose={() => setOpen(false)} headerModal={{ title: 'Taxa extra' }} size='sm'>
            <ContainerBodyModal sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'auto',
            }}>
                <Typography variant="h6">Selecione as taxas extras que deseja atribuir</Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    justifyContent: 'center',
                    py: 2,
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    backgroundColor: 'red',
                }}>
                    {data?.map((item: any) => (
                        <Box key={item?.taxaExtraID} sx={{
                            mb: 1,

                        }}>
                            <Button
                                variant={selectedOptions.includes(item?.taxaExtraID) ? 'containedFilled' : 'contained'}
                                color={selectedOptions.includes(item?.taxaExtraID) ? 'primary' : 'default'}
                                onClick={() => handleSelect(item?.taxaExtraID)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    px: 2,
                                    flexDirection: 'column',
                                    ":hover": {
                                        backgroundColor: 'secondary.main',
                                        color: 'white',
                                        borderColor: 'secondary.main',
                                    }
                                }}
                            >
                                <Typography variant="body1">{item?.nome}</Typography>
                                <Typography variant="body1">R$ {item?.valorCobrar}</Typography>
                            </Button>
                        </Box>
                    ))}

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    p: 2,
                    mt: 'auto',
                    alignSelf: 'flex-end'
                }}>
                    <Button
                        variant="containedFilled"
                        color="secondary"
                        onClick={() => setOpen(false)}
                        sx={{ width: 'min-content' }}
                    >
                        <Typography>Fechar</Typography>
                    </Button>
                    <ButtonLoading
                        variant="containedFilled"
                        color="primary"
                        onClick={() => handleAtribuir()}
                        sx={{ width: 'min-content' }}
                        loading={atribuirStore?.loading}
                    >

                        <Typography>Despachar</Typography>
                    </ButtonLoading>
                </Box>
            </ContainerBodyModal>
        </ModalBase>
    );
};

export default ModalTaxaExtra;
