import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { BoxLoading } from "src/ds";
import { RootState } from "src/store/reducers";
import { forcarLogin } from "src/store/reducers/login";
import { xtokenMasterpageStart } from "src/store/reducers/utils";

const RootRedirect = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const xtoken = localStorage.getItem('@token');
    const masterPage = useSelector((state: RootState) => state.config?.masterPage);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            localStorage.setItem('@token', token);
            dispatch(
                xtokenMasterpageStart({
                    payload: token,
                    navigation: navigate,
                })
            );
        }
    }, [token, dispatch, navigate]);


    if (xtoken) {
        return <Navigate to="/Painel" replace />;
    } else {
        return <Navigate to="/Login" replace />;
    }
};

export default RootRedirect;
