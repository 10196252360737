import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { RootState } from "src/store/reducers";
import { DynamicDataContainer } from "src/ds";
import TabelaDA from "src/components/DespachoAutomatico/TabelaDespachoAutomatico";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const DespachoAutomatico = () => {
    const daDados = useSelector((state: RootState) => state.despachoAutomatico.listaDa.data?.dados);
    const daLista = daDados?.lista;
    const loading = useSelector((state: RootState) => state.despachoAutomatico.listaDa.loading);
    const hookForm = useForm({
        defaultValues: {
            filtros: '',
            empresaID: '',
            dtInicial: '',
            dtFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const cardRef = useRef<DynamicDataContainerRef>(null)

    return (
        <DynamicDataContainer
            ref={cardRef}
            hookForm={hookForm}
            cardFormFiltrosProps={{
                dispatchMethods: {
                    actionName: 'listarDaStart'
                },
                fields: [
                    { label: "Buscar", name: "nome", ehFiltro: true, tipoFiltro: 'TEXTO' },
                    {
                        label: 'Empresas',
                        name: 'empresas',
                        type: 'autocompleteEmpresa',
                        ehFiltro: true,
                        filtroCustomizado: (nome, valor) => `${nome}.Any(empresaID.toString().toLower().Equals("${valor.toLowerCase()}"))`,
                    },
                ],
                loading: loading,
            }}
            sections={[
                {
                    title: 'Despacho automático',
                    label: "Despacho automático",
                    component: <TabelaDA
                        dados={daLista}
                        loading={loading}
                        fezRequisicao={cardRef.current?.fezRequisicao}
                    />,
                    tableNavigation: {
                        indiceInicial: daDados?.indiceInicial,
                        indiceFinal: daDados?.indiceFinal,
                        indiceTotal: daDados?.indiceFinal,
                        ultimaPagina: daDados?.ultimaPagina || 0
                    }
                }
            ]}
        />
    );
};

export default DespachoAutomatico;
