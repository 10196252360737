import { Box, BoxProps, CircularProgress } from '@mui/material'
import { useState } from 'react'

interface ImageWithLoadingProps {
    src: string
    alt?: string
    loadingColor?: string;
    boxProps?: BoxProps;
}

const ImageWithLoading = ({
    src, alt, boxProps, loadingColor
}: ImageWithLoadingProps) => {
    const [loading, setLoading] = useState(true)
    return (
        <Box
            {...boxProps}
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...boxProps?.sx
            }}
        >
            <img
                src={src}
                alt={alt}
                style={{
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100%',
                }}
                onLoad={() => setLoading(false)}
            />
            {loading && <CircularProgress size={24} {...!!loadingColor && { sx: { color: loadingColor } }} />}
        </Box>
    )
}

export default ImageWithLoading