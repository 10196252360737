import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { limparEntregaParceira, listarEntregaParceira, solicitarEntregaParceiraStart } from '../../../store/reducers/config';
import imgUber from '../../../assets/images/uber.png';
import imgIfood from '../../../assets/images/ifood.png';
import { usePedido } from '../../../context/PedidoContext';
import { CardPrincipal, GridPainelLateral, HeaderCard, ListOfSkeleton } from '../../../ds';
import { RootState } from 'src/store/reducers';
import { dinheiro } from 'src/ds/common';

const EntregaParceira = ({ close }: { close: () => void }) => {
    const dispatch = useDispatch();
    const orcamentos = useSelector((state: RootState) => state.config.listaEntregaParceira?.data?.orcamentos);
    const loading = useSelector((state: RootState) => state.config.listaEntregaParceira?.loading);
    const theme = useTheme();
    const pedido = usePedido().pedidoAtual;
    const codigoQuatro = pedido?.codigoQuatro;
    const pedidoID = pedido?.pedidoID;

    const [selectedPartner, setSelectedPartner] = useState<any | null>(null);

    useEffect(() => {
        dispatch(listarEntregaParceira(pedidoID));
    }, [pedidoID, dispatch]);

    const handleCloseClick = () => {
        dispatch(limparEntregaParceira());
        close();
    };

    const handleSelectPartner = (parceiro: any) => {
        setSelectedPartner(parceiro);
    };

    const handleSendRequest = () => {
        if (selectedPartner) {
            dispatch(solicitarEntregaParceiraStart({
                parceiro: selectedPartner.parceiroCodigo,
                pedidoID: pedidoID,
                valorOrcado: selectedPartner.taxaEntrega,
                cotacaoId: selectedPartner?.cotacaoId
            }));
        }
        setTimeout(() => {
            close();
        }, 1000);
    };

    return (
        <GridPainelLateral container sx={{ padding: '.5rem' }}>
            <HeaderCard title={`Pedido #${codigoQuatro}`} onClick={handleCloseClick} />
            {loading
                ? <ListOfSkeleton qnt={3} type="rounded" height={'120px'} />
                : orcamentos && Object.keys(orcamentos)?.length > 0 ?
                    orcamentos?.map((item: any, index: number) => {
                        const { imgUrl, parceiro, tempoMinimoEntrega } = item;
                        let imagem = imgUrl;
                        if (imgUrl.includes('uber')) imagem = imgUber;
                        if (imgUrl.includes('ifood')) imagem = imgIfood;

                        return (
                            <CardPrincipal
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: '1rem',
                                    padding: '1.5rem 0.75rem',
                                    backgroundColor: parceiro === selectedPartner?.parceiro ? theme.palette.action.selected : '',
                                    cursor: 'pointer'
                                }}
                                key={index}
                                onClick={() => handleSelectPartner(item)}
                            >
                                <Avatar src={imagem} sx={{ width: '60px', height: '60px' }} />
                                <div>
                                    <Typography sx={{ fontSize: '1rem', fontWeight: '500' }}>{parceiro}</Typography>
                                    <Typography sx={{ fontSize: '0.8rem', color: '#888' }}>Tempo estimado para chegada: {Math.floor(tempoMinimoEntrega)} minutos</Typography>
                                    <Typography sx={{ fontSize: '0.8rem', color: '#888' }}>Taxa de entrega: {dinheiro(item?.taxaEntrega)}</Typography>
                                    {item?.desconto ? <Typography sx={{ fontSize: '0.8rem', color: '#888' }}>Desconto: {dinheiro(item?.desconto)}</Typography> : null}
                                </div>
                            </CardPrincipal>
                        );
                    }) :
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <Typography variant='notFound' >Nenhuma entrega parceira disponível para este pedido</Typography>
                    </Box>
            }
            <Button
                variant="containedFilled"
                sx={{ marginTop: 'auto' }}
                onClick={handleSendRequest}
                disabled={!selectedPartner}
            >
                Solicitar entrega
            </Button>
        </GridPainelLateral>
    );
};

export default EntregaParceira;
