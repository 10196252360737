import { Avatar, Box } from '@mui/material';
import { useState } from 'react'
import ModalBase from './ModalBase';

interface AvatarWithModalProps {
    src?: string;
    username?: string;
    alt?: string;
    size?: number;
    sx?: any
}

const AvatarWithModal = ({ src, alt = 'Avatar', size = 40, username, sx }: AvatarWithModalProps) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            {src ? (
                <Avatar

                    src={src}
                    alt={alt}
                    sx={{ width: size, height: size, cursor: 'pointer', ...sx }}
                    onClick={handleOpen}
                />
            ) : username && username.length > 0 && (
                <Avatar
                    alt={alt}
                    sx={{ width: size, height: size, ...sx }}
                >
                    {username[0].toUpperCase()}
                </Avatar>
            )}
            <ModalBase
                size='sm'
                open={open}
                onClose={handleClose}
                aria-labelledby="ModalBase-title"
                aria-describedby="modal-description"
                headerModal={{
                    title: alt,
                    onClose: handleClose
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        flex: '1 1 auto',
                        overflow: 'auto',
                        display: 'flex',
                        overflowY: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={src}
                        alt={alt}
                        style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }}
                    />
                </Box>
            </ModalBase>
        </div>
    )
}

export default AvatarWithModal