import React, { useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { limparRecuperarSenha, recuperarSenhaStart } from 'src/store/reducers/login'
import { RootState } from 'src/store/reducers'
import { ButtonLoading } from 'src/ds'
import Typography from '@mui/material/Typography'
import { Alert, Box } from '@mui/material'
import { useSnackbarContext } from 'src/context/SnackbarContext'

const RestaurarSenha = () => {
    const dispatch = useDispatch();
    const { showError } = useSnackbarContext();
    const storeRecuperarSenha = useSelector((state: RootState) => state.login.recuperarSenha)
    const loading = useSelector((state: RootState) => state.login.recuperarSenha?.loading)
    const [etapa, setEtapa] = React.useState(0)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            email: '',
        },
        mode: 'onBlur',
    })

    useEffect(() => {
        dispatch(limparRecuperarSenha())
        return () => { dispatch(limparRecuperarSenha()) }
    }, [])

    const handleLogin = (data: { email: string }) => {
        dispatch(recuperarSenhaStart(data))
    }

    useEffect(() => {
        if (storeRecuperarSenha.error) {
            showError({
                message: storeRecuperarSenha.error,
            });
            // @ts-ignore
        } else if (storeRecuperarSenha.data?.sucesso) {
            // @ts-ignore
            // restaurarSnackbar.showSnackbar({ message: storeRecuperarSenha.data.mensagem, severity: 'success' })
            setEtapa(1)
        }
    }, [storeRecuperarSenha])

    return (
        <>
            <form style={{ height: '100%' }} onSubmit={handleSubmit((data) => handleLogin(data))}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: etapa !== 0 ? 'center' : 'space-between',
                        height: '100%',
                    }}
                >
                    {etapa === 0 ? (
                        <>
                            <Typography variant="body1">
                                Insira o seu endereço de e-mail abaixo e enviaremos um link para que você possa redefinir sua senha.
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder='exemplo@exemplo.com'
                                label="Email"
                                {...register('email', { required: 'Email obrigatório', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Email inválido' } })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                sx={{ my: '1rem' }}
                            />
                            <ButtonLoading loading={loading} variant="containedFilled" type="submit">
                                Enviar
                            </ButtonLoading>
                        </>
                    ) : (
                        <Alert severity='success'>
                            <Typography variant="body1" align='center'> Um e-mail foi enviado para alteração da senha.</Typography>
                        </Alert>
                    )}
                </Box>
            </form>
        </>
    )
}

export default RestaurarSenha