import {
  escalasStart,
  escalasSuccess,
  escalasFailure,
  paginaEscalaStart,
  paginaEscalaSuccess,
  paginaEscalaFailure,
  iniciarJornadaStart,
  iniciarJornadaSuccess,
  iniciarJornadaFailure,
  finalizarJornadaStart,
  finalizarJornadaSuccess,
  finalizarJornadaFailure,
  excluirJornadaStart,
  excluirJornadaSuccess,
  excluirJornadaFailure,
  listarVeiculosStart,
  listarVeiculosSuccess,
  listarVeiculosFailure,
  listarEntregadoresStart,
  listarEntregadoresSuccess,
  listarEntregadoresFailure,
  criarVagaStart,
  criarVagaSuccess,
  criarVagaFailure,
  desassociarEntregadorStart,
  desassociarEntregadorSuccess,
  desassociarEntregadorFailure,
  alterarDataStart,
  alterarDataSuccess,
  alterarDataFailure,
  vagaPadraoStart,
  vagaPadraoSuccess,
  vagaPadraoFailure,
  vagaPadraoSalvarStart,
  vagaPadraoSalvarSuccess,
  vagaPadraoSalvarFailure,
  vagaPadraoExcluirStart,
  vagaPadraoExcluirSuccess,
  vagaPadraoExcluirFailure,
  empresasVinculadasStart,
  empresasVinculadasSuccess,
  empresasVinculadasFailure,
  excluirEmpresaVinculadaStart,
  excluirEmpresaVinculadaSuccess,
  excluirEmpresaVinculadaFailure,
  salvarEmpresaVinculadaStart,
  salvarEmpresaVinculadaSuccess,
  salvarEmpresaVinculadaFailure,
  listarVagasEmpresaSelecionadaStart,
  listarVagasEmpresaSelecionadaSuccess,
  listarVagasEmpresaSelecionadaFailure,
} from "../reducers/escalas";
import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";
import { ApiResponse } from "src/dtos/DefaultDTO";

function* handleApiRequest(
  action: any,
  apiEndpoint: string,
  method: "get" | "post" = "post"
) {
  const { payload, onSuccess, onFailure, isFormData = true } = action;
  let data = isFormData ? new FormData() : payload;
  if (payload instanceof FormData) {
    data = payload;
  } else if (isFormData) {
    Object.keys(payload || {}).forEach((key) => {
      data.append(key, payload[key]);
    });
  }

  try {
    const response: ApiResponse<any> = yield call(
      api[method],
      apiEndpoint,
      data
    );
    if (response?.data?.sucesso === true) {
      yield put(onSuccess(response.data));
    } else {
      yield put(
        onFailure(response.data?.mensagem || "Um erro inesperado ocorreu")
      );
    }
  } catch (error: any) {
    yield put(onFailure(error.message || "Um erro inesperado ocorreu"));
  }
}

function* escalasSaga() {
  yield takeLatest(escalasStart, (action) =>
    handleApiRequest(
      {
        payload: {
          dataAgendamento: action.payload.data.inicio,
          dataConcluido: action.payload.data.fim,
          empresaID: action.payload.empresaID,
          paginacao: "-1",
          pagina: "1",
        },
        onSuccess: escalasSuccess,
        onFailure: escalasFailure,
      },
      "/Pedido/ListarDiarias"
    )
  );
  yield takeLatest(iniciarJornadaStart, (action) =>
    handleApiRequest(
      {
        payload: { pedidoID: action.payload },
        onSuccess: iniciarJornadaSuccess,
        onFailure: iniciarJornadaFailure,
      },
      "/Pedido/IniciarJornada"
    )
  );
  yield takeLatest(finalizarJornadaStart, (action) =>
    handleApiRequest(
      {
        payload: { pedidoID: action.payload },
        onSuccess: finalizarJornadaSuccess,
        onFailure: finalizarJornadaFailure,
      },
      "/Pedido/FinalizarJornada"
    )
  );
  yield takeLatest(excluirJornadaStart, (action) =>
    handleApiRequest(
      {
        payload: { pedidoID: action.payload },
        onSuccess: excluirJornadaSuccess,
        onFailure: excluirJornadaFailure,
      },
      "/Pedido/FecharVaga"
    )
  );
  yield takeLatest(listarVeiculosStart, (action) =>
    handleApiRequest(
      {
        payload: {
          paginacao: "-1",
          pagina: "1",
          ordenacaoCampo: "",
          ordenacaoOrdem: "",
          ordenacaoOrdemfiltros: "",
        },
        onSuccess: listarVeiculosSuccess,
        onFailure: listarVeiculosFailure,
      },
      "TipoVeiculo/Listar"
    )
  );
  yield takeLatest(listarEntregadoresStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: listarEntregadoresSuccess,
        onFailure: listarEntregadoresFailure,
      },
      "Usuario/ListarEntregadoresAtivos"
    )
  );
  yield takeLatest(criarVagaStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: criarVagaSuccess,
        onFailure: criarVagaFailure,
      },
      "Pedido/AdicionarFinanceiro"
    )
  );
  yield takeLatest(desassociarEntregadorStart, (action) =>
    handleApiRequest(
      {
        payload: { pedidoID: action.payload.pedidoID },
        onSuccess: desassociarEntregadorSuccess,
        onFailure: desassociarEntregadorFailure,
      },
      "Pedido/DesassociarEntregador"
    )
  );
  yield takeLatest(alterarDataStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        isFormData: true,
        onSuccess: alterarDataSuccess,
        onFailure: alterarDataFailure,
      },
      "/Pedido/AlterarData"
    )
  );
  yield takeLatest(paginaEscalaStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: paginaEscalaSuccess,
        onFailure: paginaEscalaFailure,
      },
      "/Pedido/Pagina"
    )
  );
  yield takeLatest(vagaPadraoStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: vagaPadraoSuccess,
        onFailure: vagaPadraoFailure,
      },
      "/VagaPadrao/Listar"
    )
  );
  yield takeLatest(vagaPadraoSalvarStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: vagaPadraoSalvarSuccess,
        onFailure: vagaPadraoSalvarFailure,
      },
      "/VagaPadrao/Salvar"
    )
  );
  yield takeLatest(vagaPadraoExcluirStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: vagaPadraoExcluirSuccess,
        onFailure: vagaPadraoExcluirFailure,
      },
      "/VagaPadrao/Excluir"
    )
  );
  yield takeLatest(empresasVinculadasStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: empresasVinculadasSuccess,
        onFailure: empresasVinculadasFailure,
      },
      "/VagaPadrao/empresasVinculadasVagaPadrao"
    )
  );
  yield takeLatest(excluirEmpresaVinculadaStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: excluirEmpresaVinculadaSuccess,
        onFailure: excluirEmpresaVinculadaFailure,
      },
      "/VagaPadrao/removerEmpresaVinculadaVagaPadrao"
    )
  );
  yield takeLatest(salvarEmpresaVinculadaStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: salvarEmpresaVinculadaSuccess,
        onFailure: salvarEmpresaVinculadaFailure,
      },
      "/VagaPadrao/adicionarEmpresaVinculadaVagaPadrao"
    )
  );
  yield takeLatest(listarVagasEmpresaSelecionadaStart, (action) =>
    handleApiRequest(
      {
        payload: action.payload,
        onSuccess: listarVagasEmpresaSelecionadaSuccess,
        onFailure: listarVagasEmpresaSelecionadaFailure,
      },
      "/VagaPadrao/ListarVagaEmpresaSelecionada"
    )
  );
}

export default escalasSaga;
