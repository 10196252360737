// DynamicRow.tsx
import { TableCell, TableCellProps, TableRow } from '@mui/material';
import React from 'react';

interface RowColumns<T> {
  field: string;
  render?: (value: any, row: T) => React.ReactNode
  align?: TableCellProps['align']
}

export interface DynamicRowProps<T> {
  row: T;
  key: string | number | keyof T
  columns?: RowColumns<T>[]
}

/**
 * Componente que renderiza uma linha dinâmica de uma tabela com base nas colunas definidas.
 * Cada coluna pode ser configurada para exibir um valor, ou um valor customizado através da função `render`.
 * 
 * @template T Tipo genérico para a linha de dados (ex.: um objeto representando um item da tabela).
 * @param {Object} props As propriedades do componente.
 * @param {T} props.row A linha de dados a ser renderizada na tabela.
 * @param {string | number | keyof T} props.key A chave única para identificar a linha.
 * @param {RowColumns[]} [props.columns] A lista de colunas a ser renderizada.
 * @returns {React.ReactNode} Um JSX que representa a linha da tabela.
 */
const DynamicRow = <T,>({
  columns,
  key,
  row
}: DynamicRowProps<T>) => {
  if (!columns) return null

  return (
    <TableRow key={row[key as keyof typeof row] ? String(row[key as keyof typeof row]) : String(key)}>
      {columns.map((column, index) => {
        const value = column.field.split('.').reduce<any>((acc, curr) => {
          return acc ? acc[curr as keyof typeof acc] : undefined;
        }, row);
        return (
          <TableCell key={index} align={column.align ?? "center"}>
            {column.render ? column.render(value, row as T) : value || '-'}
          </TableCell>
        )
      })}
    </TableRow>
  );
};

export default DynamicRow;
