import { QrCode } from '@mui/icons-material';
import { Box, Card, Chip, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { dinheiro } from 'src/ds/common';
import { ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, ButtonTableOrder, ButtonLoading, useResponseSnackbar, useResponsive } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { gerarQrCodeStart } from 'src/store/reducers/utils';
import { comprarCreditosRepresentanteStart, comprarCreditosStart, consultarPagamentoStart, disponiveisRepresentanteStart, limparComprarCreditos, limparConsultarPagamento, meusCartoesStart, minhasTransacoesRepresentanteStart, minhasTransacoesStart } from 'src/store/reducers/wallet';
import QrCodeWallet from './QRCode';
import ModalCartao from 'src/ds/components/ModalCartao';
import ModalSelectCartao from './ModalCartoes';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import ModalCadastrarCartao from './CadastrarCartao';

const AbastecerRepresentante = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state: any) => state.wallet.minhasTransacoesRepresentante.loading);
    const loadingComprar = useSelector((state: any) => state.wallet.comprarCreditosRepresentante.loading);
    const data = useSelector((state: any) => state.wallet.minhasTransacoesRepresentante.data);
    const qrCode = useSelector((state: RootState) => state.utils.gerarQrCode?.data?.qrcode);
    const novoCredito = useSelector((state: any) => state.wallet.novoCredito.data);
    const disponiveis = useSelector((state: any) => state.wallet.disponiveisRepresentante.data?.dados?.lista);
    const hookForm = useForm({
        defaultValues: {
            pagina: 1,
            paginacao: 20,
        }
    });

    const [openModalCartao, setOpenModalCartao] = useState(false);
    const [assinaturaID, setAssinaturaID] = useState<any>();
    const [openSelectCartao, setOpenSelectCartao] = useState(false);

    useEffect(() => {
        dispatch(minhasTransacoesRepresentanteStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5 }));
        dispatch(disponiveisRepresentanteStart({ pagina: 0, paginacao: -1 }));
    }, [hookForm.watch('pagina'), hookForm.watch('paginacao')]);
    const snack = useSnackbarContext();
    const response = useSelector((state: RootState) => state.wallet.comprarCreditosRepresentante);

    useEffect(() => {
        if (response.error) {
            snack.showError({ message: response.error });
        } else if (response.data?.sucesso) {
            snack.showSuccess({ message: response.data?.mensagem });
            dispatch(limparConsultarPagamento());
            dispatch(minhasTransacoesRepresentanteStart({ pagina: hookForm.getValues().pagina || 1, paginacao: hookForm.getValues().paginacao || 5 }));
        }
    }, [response]);


    const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
    const [copiaECola, setCopiaECola] = useState<any>();
    const [rowID, setRowID] = useState<any>();
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        dataCadastro: 'asc',
        valor: 'asc',
        quantidadeCreditos: 'asc',
        situacaoNome: 'asc',
    });
    const [selectedCard, setSelectedCard] = useState<any>();

    const toggleSortDirection = (column: string) => {
        const newSortDirection = sortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection({
            dataCadastro: 'asc',
            valor: 'asc',
            quantidadeCreditos: 'asc',
            situacaoNome: 'asc',
            [column]: newSortDirection,
        });

        const payload = {
            paginacao: hookForm.getValues().paginacao || 5,
            pagina: hookForm.getValues().pagina || 1,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(minhasTransacoesRepresentanteStart(payload));
    };

    const cardsData = disponiveis
    const handleBuyCredits = (card: any, cartao: any) => {
        if (cartao === true) {
            setOpenSelectCartao(true);
            dispatch(meusCartoesStart({ pagina: 0, paginacao: -1 }));
            setSelectedCard(card);
            return;
        } else {
            if (cartao) {
                dispatch(
                    comprarCreditosRepresentanteStart({
                        planoCreditoID: selectedCard.planoCreditoID,
                        nome: String(card.nome),
                        valor: String(card.valor),
                        quantidadeCreditos: String(card.quantidadeCreditos),
                        empresaCartaoID: cartao,
                    })
                );
                return;
            }
        }

        dispatch(
            comprarCreditosRepresentanteStart({
                planoCreditoID: card.planoCreditoID,
                nome: String(card.nome),
                valor: String(card.valor),
                quantidadeCreditos: String(card.quantidadeCreditos),
            })
        );
        setRowID(card.planoCreditoID);
    };

    const handleConfirmarPagamento = (row: any) => {
        setRowID(row.pickWalletID);
        const payload = {
            conteudo: row.pixQrcode,
        };
        setCopiaECola(row.pixQrcode);
        dispatch(gerarQrCodeStart(row.pixQrcode));
        setOpenQrCodeModal(true);
    }

    useEffect(() => {
        if (novoCredito) {
            console.log(novoCredito);
            dispatch(gerarQrCodeStart(novoCredito.pixQrcode));
            setCopiaECola(novoCredito?.pixQrcode);
            setOpenQrCodeModal(true);
            dispatch(limparComprarCreditos());
        }
    }, [novoCredito]);

    const { isMobile } = useResponsive();

    return (
        <Box>
            <Stack direction={isMobile ? "column" : "row"} alignItems="center" spacing={2} sx={{ p: 2 }}>
                {cardsData?.map((card: any) => (
                    <Card key={card.creditos} sx={{ p: 2, borderRadius: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2">{`${card.quantidadeCreditos} Créditos`}</Typography>
                        <Typography variant="h4" sx={{ my: 2 }}>{dinheiro(card.valor)}</Typography>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            {`Compre ${card.quantidadeCreditos} créditos para utilizar a plataforma`}
                        </Typography>
                        <Stack direction="row" spacing={2} justifyContent="center">
                            {card.aceitaPix &&
                                <ButtonLoading variant="containedFilled" color="primary" onClick={() => handleBuyCredits(card, false)} loading={loadingComprar}>
                                    Pix
                                </ButtonLoading>}
                            {card.aceitaCartao &&
                                <ButtonLoading variant="containedFilled" color="primary" onClick={() => handleBuyCredits(card, true)} loading={loadingComprar}>
                                    Cartão
                                </ButtonLoading>}
                        </Stack>
                    </Card>
                ))}
            </Stack>
            <QrCodeWallet
                modalProps={{
                    open: openQrCodeModal,
                    onClose: () => setOpenQrCodeModal(false),
                }}
                qrCode={qrCode}
                loading={false}
                atualizar={() => { dispatch(consultarPagamentoStart({ id: rowID })); }}
                copiaECola={copiaECola}
            />
            <Card elevation={1} sx={{ m: 2, borderRadius: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                </Box>
                {data?.dados && (
                    <TableNavigation
                        indiceInicial={data.dados.indiceInicial}
                        indiceFinal={data.dados.indiceFinal}
                        indiceTotal={data.dados.total}
                        ultimaPagina={data.dados.ultimaPagina}
                        hookForm={hookForm}
                    />
                )}
                <TableContainer sx={{ width: '100%', overflowX: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('dataCadastro')} sortDirection={sortDirection} nome="dataCadastro">
                                        Data
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('valor')} sortDirection={sortDirection} nome="valor">
                                        Valor
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('quantidadeCreditos')} sortDirection={sortDirection} nome="quantidadeCreditos">
                                        Créditos
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell>
                                    <ButtonTableOrder onClick={() => toggleSortDirection('situacaoNome')} sortDirection={sortDirection} nome="situacaoNome">
                                        Situação
                                    </ButtonTableOrder>
                                </TableCell>
                                <TableCell align="center">
                                    <ButtonTableOrder>
                                        Ações
                                    </ButtonTableOrder>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRowLoading />
                            ) : data && data.dados?.lista.length > 0 ? (
                                data.dados?.lista?.map((row: any) => (
                                    <TableRow key={row.pickWalletID} hover>
                                        <TableCell align="center">{row.dataCadastro}</TableCell>
                                        <TableCell align="center">{dinheiro(row.valor)}</TableCell>
                                        <TableCell align="center">{row.quantidadeCreditos}</TableCell>
                                        <TableCell align="center"><Chip label={row.situacaoNome} color={row.situacao === 5 ? 'error' : 'success'} /></TableCell>
                                        <TableCell align="center">
                                            {row.situacao !== 5 && (
                                                <Tooltip title="Abrir QR Code" arrow>
                                                    <IconButton onClick={() => handleConfirmarPagamento(row)} size="small">
                                                        <QrCode />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRowNotFound text="Nenhum resultado encontrado" />
                            )}
                        </TableBody>
                    </Table>
                    <ModalOpcoes
                        open={false}
                        onClose={() => console.log(false)}
                        acao="Excluir"
                        descricao={<Typography>
                            Tem certeza que deseja excluir <span style={{ fontWeight: 'bold' }}>Pix</span>?
                        </Typography>}
                        opcoes={[
                            {
                                label: 'Cancelar',
                                action() { console.log(false) },
                            },
                            {
                                label: 'Excluir',
                                action() { console.log() },
                            },
                        ]}
                    />
                </TableContainer>
            </Card>
            <ModalCadastrarCartao open={openModalCartao} onClose={() => setOpenModalCartao(false)} empresaID={localStorage.getItem('@xempresa')?.toString()} dispatchMethods={{ actionName: 'cadastrarCartaoStart' }} />
            <ModalSelectCartao
                open={openSelectCartao}
                handleClose={() => setOpenSelectCartao(false)}
                onComprar={(selectedCartao) => handleBuyCredits(selectedCard, selectedCartao)}
                onRegistrarCartao={() => setOpenModalCartao(true)}
            />
        </Box>
    );
};

export default AbastecerRepresentante;
