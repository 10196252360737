import { Fade, Grid2, Modal } from '@mui/material';
import { HeaderModalProps } from './HeaderModal';
import HeaderModal from './HeaderModal';
import useResponsive from "../hooks/useResponsive";

export interface ModalBaseProps {
    open: boolean;
    onClose: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    children?: React.ReactNode;
    headerModal?: HeaderModalProps;
    [key: string]: any;
}

const ModalBase = ({
    open,
    onClose,
    children,
    size = 'md',
    headerModal,
    ...props
}: ModalBaseProps) => {
    const { isMobile } = useResponsive();
    const sizeMap: any = {
        xs: { xs: 12, sm: 6, md: 4, lg: 4, xl: 4 },
        sm: { xs: 12, sm: 8, md: 6, lg: 6, xl: 6 },
        md: { xs: 12, sm: 10, md: 8, lg: 8, xl: 8 },
        lg: { xs: 12, sm: 12, md: 10, lg: 10, xl: 10 },
        xl: { xs: 12, sm: 12, md: 11, lg: 11, xl: 11 },
    };

    return (
        <Modal
            open={open}
            onClose={(_, reason) => {
                if (reason === 'backdropClick') return
                onClose()
            }}
            slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'blur(1px)',
                    }
                }
            }}
            {...props}
        >
            <Fade in={open} unmountOnExit>
                <Grid2 container sx={{width: '100%', height: '100%'}}>
                    <Grid2
                        container
                        size={sizeMap[size]}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: isMobile ? '95%' : `100%`,
                            maxWidth: '90vw',
                            maxHeight: '95vh',
                            overflow: 'auto',
                            bgcolor: 'background.default',
                            border: 'none',
                            borderRadius: '10px',
                            boxShadow: 24,
                        }}
                    >
                        {headerModal?.title && (
                            <Grid2 size={12}>
                                <HeaderModal
                                    title={headerModal.title}
                                    onClose={headerModal?.onClose ? headerModal?.onClose : onClose}
                                    {...headerModal}
                                />
                            </Grid2>
                        )}
                        <Grid2 size={12}>
                            {children}
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Fade>
        </Modal >
    )
}


export default ModalBase