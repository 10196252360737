import { useState } from 'react';
import { Button, Typography, Card, CardContent, Grid2, Paper } from '@mui/material';
import iconeMoto from '../../../assets/iconecontratarmoto.png';
import iconeOperacao from '../../../assets/iconecontrataroperacao.png';
import ModalPickngo from './ModalPickngo';
import ModalParceiros from './ModalParceiros';
import { BoxLoading, BoxScroll, ContainerBodyModal, ImageWithLoading, ModalBase, PLANO_BRENDI, useResponsive } from 'src/ds';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

const ModalContratarPlanos = ({ open, onClose }: { open: boolean, onClose: () => void }) => {
    const [openMotoModal, setOpenMotoModal] = useState(false);
    const [openOperacaoModal, setOpenOperacaoModal] = useState(false);
    const { isMobile } = useResponsive();
    const parceiros = useSelector((state: RootState) => state.utils.parceiros);
    const loading = useSelector((state: RootState) => state.utils.parceirosLoading);
    const usuarioPlano = useSelector((state: RootState) => state.config.usuarioPlano);

    const handleOpenMotoModal = () => {
        setOpenMotoModal(true);
    };

    const handleOpenOperacaoModal = () => {
        setOpenOperacaoModal(true);
    };

    const handleCloseMotoModal = () => {
        setOpenMotoModal(false);
    };

    const handleCloseOperacaoModal = () => {
        setOpenOperacaoModal(false);
    };

    return (
        <ModalBase
            open={open}
            onClose={onClose}
            headerModal={{
                title: 'Selecione a melhor opção para você',
                onClose: () => {
                    onClose();
                },
            }}
        >
            <ContainerBodyModal sx={{ maxHeight: '75vh', height: '100%' }}>
                <BoxScroll sx={{ pb: 0 }} >
                    <BoxLoading
                        loading={loading}
                    >
                        <Grid2 container direction="column">
                            <Grid2
                                container
                                spacing={2}
                                sx={{
                                    width: '100%',
                                    mt: 1,
                                    direction: isMobile ? 'column' : 'row',
                                    justifyContent: 'center',
                                    p: isMobile ? 1 : 2,
                                }}
                            >
                                <Grid2 size={{ xs: 12, md: 6 }} >
                                    <Paper elevation={0} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '100%' }}>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <ImageWithLoading
                                                src={iconeMoto}
                                                alt="icone moto"
                                                boxProps={{ sx: { width: '100%', maxWidth: '200px', marginBottom: 2, aspectRatio: 1 } }}
                                            />
                                            <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold' }} component="div" textAlign="center">
                                                Faça a gestão dos seus entregadores e pedidos
                                            </Typography>
                                            <Typography variant="body2" color="text.gray" textAlign="center">
                                                Transforme a gestão do seu delivery: monitore pedidos e entregadores com facilidade e eficiência, utilizando a plataforma da Pick no seu computador.
                                            </Typography>
                                            <Button variant="containedFilled" sx={{ marginTop: 2, alignSelf: 'center', width: '60%' }} onClick={handleOpenMotoModal}>
                                                Tenho interesse!
                                            </Button>
                                        </CardContent>
                                    </Paper>
                                </Grid2>
                                {parceiros && parceiros.length > 0 && usuarioPlano !== PLANO_BRENDI &&
                                    <Grid2 size={{ xs: 12, md: 6 }} >
                                        <Card elevation={0} sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '100%' }}>
                                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                <ImageWithLoading
                                                    src={iconeOperacao}
                                                    alt="icone operação"
                                                    boxProps={{ sx: { width: '100%', maxWidth: '200px', marginBottom: 2, aspectRatio: 1 } }}
                                                />
                                                <Typography gutterBottom variant="h5" sx={{ fontWeight: 'bold' }} component="div" textAlign="center">
                                                    Quero terceirizar minha operação logística
                                                </Typography>
                                                <Typography variant="body2" color="text.gray" textAlign="center">
                                                    Aumente a eficiência e os ganhos da sua empresa terceirizando com clientes Pick. Nossa plataforma eleva a gestão do seu negócio a um novo nível de rentabilidade.
                                                </Typography>
                                                <Button variant="containedFilled" sx={{ marginTop: 2, alignSelf: 'center', width: '60%' }} onClick={handleOpenOperacaoModal}>
                                                    Ver operadores disponíveis
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid2>
                                }
                            </Grid2>
                        </Grid2>
                    </BoxLoading>
                </BoxScroll>
            </ContainerBodyModal>
            <ModalPickngo open={openMotoModal} onClose={handleCloseMotoModal} />
            <ModalParceiros open={openOperacaoModal} onClose={handleCloseOperacaoModal} />
        </ModalBase>
    );
}

export default ModalContratarPlanos;
