import { AppBar, Avatar, Box, Button, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, useTheme } from "@mui/material";
import React, { forwardRef, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePagina } from "src/context/PaginaContext";
import { RootState } from "src/store/reducers";
import { logout } from "src/services/api";
import * as Icons from 'react-icons/fa';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { setOpenMenuItem, toggleColorMode } from "src/store/reducers/utils";
import ModalOpcoes from "./ModalOpcoes";
import ModalQrCode from "src/components/common/ModalQrCode";
import ModalContratarPlanos from "src/components/Painel/ModalContratarPlanos";
import { Logout } from "@mui/icons-material";
import useResponsive from "../hooks/useResponsive";
import { PLANO_BRENDI, PLANO_FREEMIUM } from "..";
import { usePedido } from "src/context/PedidoContext";
import { limparModelo } from "src/store/reducers/criarPedido";
import { dinheiro } from "../common";

interface MenuOption {
    menuItemID: string;
    menuItemPaiID?: string;
    item: string;
    icone: string;
    url: string;
    novaAba?: boolean;
    children?: MenuOption[];
}

interface MenuSubItemComponentProps {
    option: MenuOption;
    handleMenuItemClick: (option: MenuOption) => void;
    paginaAtual: string;
    getIconComponent: (iconName: string) => React.ReactNode;
}

interface MenuItemComponentProps {
    option: MenuOption;
    handleMenuItemClick: (option: MenuOption) => void;
    paginaAtual: string;
    getIconComponent: (iconName: string) => React.ReactNode;
    isOpen: boolean;
    toggleOpenMenu: () => void;
}

interface HeaderProps {
    abrirCriarPedido: () => void;
}

const buildMenuHierarchy = (menuOptions: MenuOption[] = []) => {
    const menuMap: Record<string, MenuOption> = {};
    const menuHierarchy: MenuOption[] = [];

    // Criar mapa de menuItemID para facilitar a busca dos pais
    menuOptions?.forEach((option) => {
        menuMap[option.menuItemID] = { ...option, children: [] };
    });

    // Associar itens filhos aos seus pais
    menuOptions?.forEach((option) => {
        if (option.menuItemPaiID) {
            menuMap[option.menuItemPaiID]?.children?.push(menuMap[option.menuItemID]);
        } else {
            menuHierarchy.push(menuMap[option.menuItemID]);
        }
    });

    return menuHierarchy;
};

const MenuSubItemComponent: React.FC<MenuSubItemComponentProps> = ({
    option,
    handleMenuItemClick,
    paginaAtual,
    getIconComponent
}) => {
    const theme = useTheme();
    const menuItemRef = useRef<HTMLAnchorElement | null>(null);
    const styles = {
        backgroundColor: paginaAtual === option.item ? theme.palette.primary.main : "inherit",
        color: paginaAtual === option.item
            ? theme.palette.primary.contrastText
            : theme.palette.grey[500],
        borderRadius: "5px",
        marginY: "0.3rem",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            cursor: "pointer",
        },
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        boxSizing: "border-box",
        overflowX: "hidden",
        width: "100%",
    };

    if (paginaAtual === option.item) {
        setTimeout(() => {
            menuItemRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }, 100)
    }

    const href = /^https?:\/\//.test(option.url)
        ? option.url
        : !!option.url.replace('#', '').replace(/^\/+/, '')
            ? window.location.origin + '/' + option.url.replace('#', '').replace(/^\/+/, '')
            : undefined

    return (
        <ListItem
            ref={menuItemRef}
            href={href}
            component='a'
            onClick={() => handleMenuItemClick(option)}
            sx={styles}
        >
            <ListItemIcon sx={{ color: theme.palette.text.primary, minWidth: '35px' }}>
                {getIconComponent(option.icone)}
            </ListItemIcon>
            <ListItemText primary={option.item} sx={{ padding: 0, margin: 0 }} />
        </ListItem>
    );
};

const MenuItemComponent: React.FC<MenuItemComponentProps> = ({
    option,
    handleMenuItemClick,
    paginaAtual,
    getIconComponent,
    isOpen,
    toggleOpenMenu,
}) => {
    const theme = useTheme();
    const menuItemRef = useRef<HTMLAnchorElement | null>(null);
    const hasChildren = Array.isArray(option.children) && option.children.length > 0;
    if (paginaAtual === option.item) {
        setTimeout(() => {
            menuItemRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }, 100)
    }
    const styles = {
        backgroundColor: paginaAtual === option.item ? theme.palette.primary.main : "inherit",
        color: paginaAtual === option.item
            ? theme.palette.primary.contrastText
            : theme.palette.grey[500],
        borderRadius: "5px",
        marginY: "0.3rem",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            cursor: "pointer",
        },
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        boxSizing: "border-box",
        overflowX: "hidden",
        width: "100%",
    };

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (hasChildren) {
            toggleOpenMenu();
        } else {
            handleMenuItemClick(option);
        }
    };

    const href = /^https?:\/\//.test(option.url)
        ? option.url
        : !!option.url.replace('#', '').replace(/^\/+/, '')
            ? window.location.origin + '/' + option.url.replace('#', '').replace(/^\/+/, '')
            : undefined;

    return (
        <List>
            <ListItem
                ref={menuItemRef}
                component="a"
                href={href}
                onClick={handleClick}
                sx={styles}
                aria-expanded={hasChildren ? isOpen : undefined}
                aria-controls={hasChildren ? `submenu-${option.item}` : undefined}
            >
                <ListItemIcon
                    sx={{
                        color: paginaAtual === option.item ? theme.palette.text.secondary : theme.palette.text.primary,
                        minWidth: '35px'
                    }}>
                    {getIconComponent(option.icone)}
                </ListItemIcon>
                <ListItemText primary={option.item} sx={{ padding: 0, margin: 0 }} />
                {hasChildren && (isOpen ? <Icons.FaChevronUp /> : <Icons.FaChevronDown />)}
            </ListItem>

            {hasChildren && (
                <Collapse
                    in={isOpen}
                    timeout="auto"
                    unmountOnExit sx={{ width: '100%' }}
                >
                    <List
                        component="a"
                        onClick={(e) => { e.preventDefault() }}
                        href={href}
                        disablePadding
                        id={`submenu-${option.item}`}
                        sx={{ pl: '1rem' }}
                    >
                        {option.children?.map((childOption) => (
                            <MenuSubItemComponent
                                key={childOption.menuItemID}
                                option={childOption}
                                handleMenuItemClick={handleMenuItemClick}
                                paginaAtual={paginaAtual}
                                getIconComponent={getIconComponent}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </List>
    );
};

const Header = forwardRef<HTMLDivElement, HeaderProps>(
    ({ abrirCriarPedido, ...props }, ref) => {
        const theme = useTheme();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { isMobile } = useResponsive()
        const [isDrawerOpen, setDrawerOpen] = useState(false);
        const { paginaAtual, selecionarPagina } = usePagina();
        const usuarioPlano = useSelector((state: RootState) => state.config.usuarioPlano);
        const [mostrarModalContratar, setMostrarModalContratar] = useState(false);
        const abrirFreemium = () => setMostrarModalContratar(true)
        const [modalQrCodeOpen, setModalQrCodeOpen] = useState(false);
        const [modalSairOpen, setModalSairOpen] = useState(false);
        const empresaID = useSelector((state: RootState) => state.config?.empresaID);
        const walletRepresentante = useSelector((state: RootState) => state.config?.wallet?.data?.saldo)
        const wallet = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.saldoWallet);
        const usuario = useSelector((state: RootState) => state.login.usuario);
        const masterPage = useSelector((state: RootState) => state.config?.masterPage);
        const template = useSelector((state: RootState) => state.config?.template?.template);
        const empresa = useSelector((state: RootState) => state.config?.masterPage?.empresa);
        const menus = useSelector((state: RootState) => state.config?.masterPage?.menu);
        const configEmpresa = useSelector((state: RootState) => state.config?.masterPage?.empresa);
        const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);
        // @ts-expect-error
        const companyName = usuario?.nome || masterPage?.pickSelecionada?.nome
        const companyLogo = empresa?.logoUrl ? empresa?.logoUrl : template?.faviconUrl
        const companyImg = useSelector((state: RootState) => state.config?.template?.template?.logoUrl);
        const listaPedidos = useSelector((state: RootState) => state.config?.emAndamento?.dados?.lista);
        const { selecionarPedido } = usePedido();


        const menuHierarchy = useMemo(() => buildMenuHierarchy(menus), [menus]);

        const generateMenuActions = (menuOptions: any) => {
            return menuOptions?.map((option: any) => {
                const sanitizedUrl = option.url.replace(/^\/?#/, '');

                if (option.novaAba) {
                    if (option.url === 'https://intercom.help/pickngo/pt-BR/collections/8592351-faq-2-0' || option.url === 'https://linktr.ee/pickngosaas') {
                        return () => window.open(`${sanitizedUrl}`, "_blank");
                    }
                    return () => window.open(`${window.location.origin}${sanitizedUrl}`, "_blank");
                } else {
                    const basePath = window.location.pathname;

                    const prefix = basePath.endsWith('/') ? '' : '/';
                    const fullPath = `${prefix}${sanitizedUrl.startsWith('/') ? sanitizedUrl.substring(1) : sanitizedUrl}`;

                    return () => {
                        navigate(fullPath)
                        setTimeout(() => {
                            window.HubSpotConversations?.widget.refresh();
                        }, 1000);
                    };
                }
            });
        };

        const menuActions = generateMenuActions(menus) || [];

        const additionalActions = [
            ...(configEmpresa?.podeUsarQrcode ? [() => { abrirQrCodeEmpresa() }] : []),
            () => { setModalSairOpen(true) }
        ];

        const completeMenuActions = [...menuActions, ...additionalActions];

        const abrirQrCodeEmpresa = () => {
            if (empresaID) {
                setModalQrCodeOpen(true);
            } else {
                alert("abrirQrCode / empresaID is null: " + empresaID);
            }
        };

        const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
            if (event instanceof KeyboardEvent) {
                if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

            }
            setDrawerOpen(open);
        };

        const handleLogout = () => { logout() };

        const handleMenuItemClick = (option: MenuOption) => {
            const menuIDsPermitidos = [
                '6b15bf71-92fb-11ef-babc-0aa63e100dd7',
                'b4133270-92fa-11ef-babc-0aa63e100dd7',
                '002f6245-efb9-11ef-bb17-0aa63e100dd7',
                '2421f439-efb9-11ef-bb17-0aa63e100dd7'
            ];

            if (usuarioPlano === PLANO_FREEMIUM || usuarioPlano === PLANO_BRENDI) {
                console.log('usuarioPlano', usuarioPlano);
                if (option?.url && option.url.includes('/Painel')) {
                    selecionarPagina(option.item);

                    const actionIndex = menus.findIndex((opt: MenuOption) => opt.url === option.url);
                    if (actionIndex !== -1) {
                        completeMenuActions[actionIndex]();
                    }

                    setDrawerOpen(false);
                };

                if (!menuIDsPermitidos.includes(option?.menuItemID)) {
                    abrirFreemium();
                    return;
                }
            }
            console.log('handleMenuItemClick', option);

            selecionarPagina(option.item);

            const actionIndex = menus.findIndex((opt: MenuOption) => opt.url === option.url);
            if (actionIndex !== -1) {
                completeMenuActions[actionIndex]();
            }

            setDrawerOpen(false);
        };


        const IconsDic = {
            'fa-home': Icons.FaHome,
            'far fa-home': Icons.FaHome,
            'fa-bars': Icons.FaBars,
            'fa-plus-circle': Icons.FaPlusCircle,
            'fa-sun': Icons.FaSun,
            'fa-moon': Icons.FaMoon,
            'fa-chart-bar': Icons.FaChartBar,
            'fas fa-chart-bar': Icons.FaChartBar,
            'fa-motorcycle': Icons.FaMotorcycle,
            'fa-dollar-sign': Icons.FaDollarSign,
            'fa-user': Icons.FaUser,
            'fas fa-chart-pie': Icons.FaChartPie,
            'fa-address-card': Icons.FaAddressCard,
            'fa fa-headset': Icons.FaHeadset,
            'fa-comment-dots': Icons.FaCommentDots,
            'fa-money-check-alt': Icons.FaMoneyCheckAlt,
            'fas fa-gift': Icons.FaGift,
            'fa-cogs': Icons.FaCogs,
            'fas fa-dollar-sign': Icons.FaDollarSign,
            'fa-bullhorn': Icons.FaBullhorn,
            'fa-question-circle': Icons.FaQuestionCircle,
        };

        const getIconComponent = (iconName: string) => {
            const IconComponent = IconsDic[iconName as keyof typeof IconsDic];
            return IconComponent ? <IconComponent /> : null;
        };
        const openMenuItem = useSelector((state: RootState) => state.utils.openMenuItem);

        const handleToggleMenuItem = (menuItemID: string) => {
            dispatch(setOpenMenuItem(menuItemID));
        };

        return (
            <>
                <div
                    style={{ width: '100%', overflow: 'hidden' }}
                    id="painelHeader"
                    {...props}
                    ref={ref}
                >
                    <AppBar
                        position="sticky"
                        sx={{
                            backgroundColor: theme.palette.background.default,
                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                            justifyContent: 'center',
                            maxHeight: '52px',
                            p: theme.sizes.padding.xs,
                            overflowX: 'hidden',
                            boxShadow: 'none',
                            overflow: 'hidden',
                        }}
                        elevation={0}
                    >
                        <Toolbar
                            sx={{
                                justifyContent: isMobile ? 'space-between' : '',
                                p: '0 0 0 .25rem',
                                minHeight: '0px',
                                height: '100%',
                                width: '100%',
                                boxSizing: 'border-box',
                                '@media (min-width: 600px)': {
                                    minHeight: '0px',
                                    px: '0',
                                },
                                zIndex: 10,
                            }}
                        >
                            {/* @ts-expect-error */}
                            <IconButton
                                edge="start"
                                color="primary"
                                size="small"
                                aria-label="menu"
                                onClick={toggleDrawer(!isDrawerOpen)}
                                sx={{ mr: 2, ml: 0.2 }}
                            >
                                <Icons.FaBars fontSize="medium" />
                            </IconButton>
                            {!isMobile && (
                                <>
                                    <Avatar
                                        onClick={() => {
                                            navigate('/Pedido/PainelPerformance')
                                            selecionarPagina('Painel')
                                        }}
                                        alt={companyName}
                                        src={companyLogo}
                                        sx={{ mr: 1, cursor: 'pointer' }}
                                    />
                                    <Typography variant="body1" color={theme.palette.text.primary}>{companyName}</Typography>
                                    <Divider
                                        orientation="vertical"
                                        sx={{ margin: '0 1.5rem', backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '52px' }}
                                    />
                                </>
                            )}
                            {(paginaAtual === 'Painel' && listaPedidos !== undefined && listaPedidos?.length > 0) && (
                                <>
                                    <Button
                                        variant="iconeComTextoFilled"
                                        onClick={() => {
                                            selecionarPedido('');
                                            abrirCriarPedido();
                                            dispatch(limparModelo({}));
                                        }}
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                    >
                                        Novo pedido
                                    </Button>
                                </>
                            )}
                            {isMobile && listaPedidos?.length === 0 && (
                                <Box

                                    onClick={() => {
                                        navigate('/Pedido/PainelPerformance')
                                        selecionarPagina('Painel')
                                    }}
                                    sx={{
                                        height: '52px',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '.5rem',
                                        userSelect: 'none',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <img
                                        style={{
                                            height: '100%',
                                            aspectRatio: '1/1',
                                            cursor: 'pointer',
                                            borderRadius: theme.sizes.borderRadius.sm,
                                        }}
                                        alt={companyName}
                                        src={companyLogo}
                                        onDragStart={(e) => { e.preventDefault() }}
                                    />
                                </Box>
                            )}
                            {!isMobile && (wallet !== undefined || walletRepresentante !== undefined) && (
                                <>
                                    {(paginaAtual === 'Painel' && listaPedidos !== undefined && listaPedidos?.length > 0) &&
                                        <Divider
                                            orientation="vertical"
                                            sx={{ margin: '0 1.5rem', backgroundColor: 'rgba(0, 0, 0, 0.05)', height: '52px' }}
                                        />
                                    }
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => (tipoDeUsuario === 'Representante' ? navigate('/Pick/AbastecerRepresentante') : navigate('/Pick/AbastecerWallet'))}
                                    >
                                        <Typography variant="body2" align="center" color={theme.palette.text.primary}>
                                            {(tipoDeUsuario === 'Representante' ? dinheiro(walletRepresentante) : dinheiro(wallet))} <br />
                                            <Typography variant="caption" color={theme.palette.grey[400]}>Na wallet</Typography>
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            <div style={{ marginLeft: isMobile ? 0 : 'auto' }}>
                                <IconButton
                                    sx={{
                                        bgcolor: 'transparent',
                                        color: theme.palette.text.primary,
                                    }}
                                    onClick={() => dispatch(toggleColorMode())}
                                >
                                    {theme.palette.mode === 'dark' ? <Icons.FaSun size={20} /> : <Icons.FaMoon size={20} />}
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        anchor="left"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        elevation={0}
                    >
                        <List
                            sx={{
                                height: '100%',
                                maxHeight: '100%',
                                overflowY: 'auto',
                                alignContent: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: theme.palette.background.default,
                                padding: '52px 10px 10px 10px',
                                boxSizing: 'border-box',
                            }}
                        >
                            {menuHierarchy.map((option) => (
                                <MenuItemComponent
                                    key={option.menuItemID}
                                    option={option}
                                    handleMenuItemClick={handleMenuItemClick}
                                    paginaAtual={paginaAtual}
                                    getIconComponent={getIconComponent}
                                    isOpen={openMenuItem === option.menuItemID}
                                    toggleOpenMenu={() => handleToggleMenuItem(option.menuItemID)}
                                />
                            ))}
                        </List>
                        <Divider sx={{ width: '100%', margin: '1rem 0' }} />
                        <Button
                            onClick={() => setModalSairOpen(true)}
                            variant="contained"
                            sx={{
                                mx: '1rem',
                                position: 'sticky',
                                bottom: 10,
                                justifyContent: 'space-between',
                                marginTop: '.5rem',
                                width: '300px',
                            }}
                            startIcon={<Logout />}
                        >
                            Sair <span />
                        </Button>
                    </Drawer>
                </div>
                {(modalQrCodeOpen && empresaID) && (
                    <ModalQrCode
                        open={modalQrCodeOpen}
                        onClose={() => setModalQrCodeOpen(false)}
                        empresaID={empresaID}
                    />
                )}
                <ModalContratarPlanos open={mostrarModalContratar} onClose={() => setMostrarModalContratar(!mostrarModalContratar)} />
                <ModalOpcoes
                    open={modalSairOpen}
                    onClose={() => setModalSairOpen(false)}
                    acao={'Sair'}
                    descricao={'Você deseja sair?'}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => { setModalSairOpen(false) }
                        },
                        {
                            label: 'Confirmar',
                            action: () => {
                                setModalSairOpen(false);
                                handleLogout();
                            }
                        },
                    ]}
                />
            </>
        );
    }
);

export default Header;
