import { cleanInput } from "../common";

const validateCPF = (cpf?: string | null): boolean => {
  if (!cpf || cpf.trim() === "") {
    return true; // CPF é opcional, então passa a validação se estiver vazio
  }

  const cleanedCPF = cleanInput(cpf);
  if (cleanedCPF.length !== 11) return false;

  let sum = 0,
    remainder;

  for (let i = 1; i <= 9; i++)
    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cleanedCPF.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cleanedCPF.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) remainder = 0;
  if (remainder !== parseInt(cleanedCPF.substring(10, 11))) return false;

  return true;
};

export default validateCPF;
