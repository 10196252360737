// @ts-nocheck
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { CardPedidoContent, InputDateTime } from "src/ds";

dayjs.locale("pt-br");

const AgendarPedido = ({ watch, setValue, register, methods }) => {
  const hookFormProps = (name) => ({
    name,
    methods,
    rules: {
      required: "Este campo é obrigatório", 
    },
  });

  return (
    <CardPedidoContent>
      <InputDateTime
        hookForm={{
          name: "dataAgendamento",
          methods
        }}
        label="Data de agendamento"
        sx={{ width: "100%", marginY: "1rem" }}
        disabled={false}
      />
      <InputDateTime
        hookForm={hookFormProps("dataInicioPreparacaoSugerida")}
        label="Data de início da preparação"
        sx={{ width: "100%" }}
        disabled={false}
      />
    </CardPedidoContent>
  );
};

export default AgendarPedido;
