import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Autocomplete,
    TextField,
    Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { listarVeiculosStart } from '../../../store/reducers/escalas';
import { useForm, Controller } from 'react-hook-form';
import { limparResposta, salvarUsuarioReq } from '../../../store/reducers/config';
import { telefone } from '../../../ds/common';
import { ButtonLoading, ModalBase, SelectToggleAtivo } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { Entregador } from 'src/dtos/EntregadoresDTO';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface Props {
    entregador?: Entregador
    showEditar: boolean;
    setShowEditar: Dispatch<SetStateAction<boolean>>;
    atualizar: () => void;
}

const CadastrarEntregador = ({ entregador, showEditar, setShowEditar, atualizar }: Props) => {
    const dispatch = useDispatch();
    const tiposDeVeiculo = useSelector((state: RootState) => state.escalas?.listarVeiculos?.data?.dados?.lista);
    const respostaEditar = useSelector((state: RootState) => state.config.salvarUsuario.data);
    const erroEditar = useSelector((state: RootState) => state.config.salvarUsuario.error);
    const loadingEditar = useSelector((state: RootState) => state.config.salvarUsuario.loading);
    const { showSuccess, showError } = useSnackbarContext();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [salvouEntregador, setSalvouEntregador] = useState(false);

    const hookForm = useForm({
        defaultValues: {
            nome: "",
            email: "",
            telefone: "",
            tipoVeiculoID: "",
            podePegarEscala: true,  
            senha: "",
            confirmeASenha: "",
            entregadorBasico: true,
            backend: true
        },
    });

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        watch,
    } = hookForm

    useEffect(() => {
        dispatch(listarVeiculosStart());
    }, []);

    useEffect(() => {
        if (entregador) {
            reset(entregador);
        }
    }, [entregador, reset]);

    const handleClose = () => {
        setShowEditar(false);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const onSubmit = (data: any) => {
        const { confirmeASenha, ...dataToSubmit } = data;
        dispatch(salvarUsuarioReq(dataToSubmit));
        setTimeout(() => {
            atualizar();
        }, 500)
    };

    useEffect(() => {
        if (showEditar) {
            if (respostaEditar) {
                showSuccess({
                    message: respostaEditar.mensagem,
                });
                setTimeout(() => {
                    setShowEditar(false);
                    setSalvouEntregador(true);
                    handleClose();
                }, 100);
            } else if (erroEditar) {
                showError({
                    message: erroEditar,
                });
            }
        }
        return () => {
            dispatch(limparResposta());
        }
    }, [respostaEditar, erroEditar]);

    return (
        <>
            <ModalBase open={showEditar} onClose={handleClose} headerModal={{ title: 'Cadastrar entregador' }}>
                <Box sx={{ m: '1rem 1rem 0 1rem', display: 'flex', flexDirection: 'column', gap: 2, }} component={'form'}>
                    <TextField
                        label="Nome do entregador"
                        {...register("nome", { required: "Nome é obrigatório" })}
                        error={!!errors.nome}
                        helperText={errors.nome?.message}
                        size='small'
                    />
                    <TextField
                        label="E-mail"
                        {...register("email", { required: "E-mail é obrigatório", pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                        error={!!errors.email}
                        helperText={errors.email ? (errors.email.type === 'pattern' ? 'Formato de e-mail inválido' : errors.email.message) : null}
                        size='small'
                    />
                    <TextField
                        label="Telefone"
                        {...register("telefone", { required: "Telefone é obrigatório" })}
                        value={telefone(watch('telefone') || '')}
                        slotProps={{ htmlInput: { maxLength: 15 } }}
                        error={!!errors.telefone}
                        helperText={errors.telefone?.message}
                        size='small'
                    />
                    <Controller
                        name={'tipoVeiculoID'}
                        control={control}
                        rules={{ required: "Tipo de veículo é obrigatório" }}
                        render={({ field }) => (
                            <Autocomplete
                                options={tiposDeVeiculo || []}
                                getOptionLabel={(option) => option.nome}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        label={"Tipo de veículo"}
                                        variant="outlined"
                                        error={!!errors['tipoVeiculoID']}
                                        helperText={errors['tipoVeiculoID']?.message}
                                        size='small'
                                    />
                                )}
                                onChange={(_event, value) => {
                                    field.onChange(value ? value.tipoVeiculoID : '');
                                }}
                            />
                        )}
                    />

                    <SelectToggleAtivo
                        hookForm={{
                            methods: hookForm,
                            name: 'podePegarEscala',
                            rules: { required: "Pode pegar escalas é obrigatório" },
                        }}
                        toggleValues={{ ativo: 'true', inativo: 'false' }}
                        toggleLabels={{ ativo: 'Sim', inativo: 'Não' }}
                        label='Pode pegar escalas?'
                    />

                    <TextField
                        label="Senha"
                        type={showPassword ? 'text' : 'password'}
                        {...register("senha", { required: "Senha é obrigatória" })}
                        error={!!errors.senha}
                        size='small'
                        helperText={errors.senha?.message}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />
                    <TextField
                        label="Confirme a senha"
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register("confirmeASenha", {
                            required: "Confirmação de senha é obrigatória",
                            validate: (value) => value === watch('senha') || "As senhas não correspondem"
                        })}
                        error={!!errors.confirmeASenha}
                        helperText={errors.confirmeASenha?.message}
                        size='small'
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={toggleShowConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }
                        }}
                    />
                </Box>
                <Stack
                    useFlexGap
                    sx={{ gap: 2, justifyContent: 'flex-end', p: 2, width: '100%', flexDirection: 'row', boxSizing: 'border-box' }}
                >
                    <Button variant="containedFilled" onClick={handleClose} sx={{ width: 'fit-content' }} color="grey">
                        Cancelar
                    </Button>
                    <ButtonLoading
                        loading={loadingEditar || salvouEntregador}
                        variant="containedFilled"
                        onClick={handleSubmit(onSubmit)}
                        sx={{ width: 'fit-content' }}
                    >
                        Salvar
                    </ButtonLoading>
                </Stack>
            </ModalBase>
        </>
    )
};

export default CadastrarEntregador;
