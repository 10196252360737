import { Button, Collapse, Grid2, Stack, StackProps, styled, TextField, TextFieldProps, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteMap, AutocompleteEmpresas, ButtonLoading, InputDateTime, MapWithMarker, ModalOpcoes, SelectCidade, SelectEstado, SelectFormaPagamento, SelectTipoDespacho, SelectTipoFatura, SelectTipoPedido, SelectToggleAtivo, SnackbarAlert, TextFieldChip, TextFieldDistance, TextFieldMoney, TextFieldTelefone, InputEntregador } from 'src/ds';
import { RootState } from 'src/store/reducers';
import { recalcularQuilometragemPedidoClear, recalcularQuilometragemPedidoStart, recalcularTabelasPedidoClear, recalcularTabelasPedidoStart, salvarPedidoClear, salvarPedidoStart } from 'src/store/reducers/pedidos';
import TextFieldTaxasAplicadas from './TextFieldTaxasAplicadas';
import StepperAndamento from './StepperAndamento';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ModalAndamentoPedido from './ModalAndamentoPedido';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface FormData {
    pedidoID: string;
    nome: any | null;
    clienteNome: string | null;
    empresaID: string | null;
    clienteTelefone: string | null;
    temRetorno: boolean | null;
    codigo: number | null;
    tipoDespacho: number | null;
    formaPagamentoID?: string | null;
    distanciaCalculadaKm: number | null;
    valor: number | null;
    trocoPara: number | null;
    distanciaCalculadaKmPorRota: number | null;
    dataEtaEntrega: string | null;
    dataEtaColeta: string | null;
    aplicacaoOrigemNome: string | null;
    tipoPedido: number | null;
    tipoFatura: number | null;
    detalhes: string | null;
    taxaTotalCobrada: number | null;
    taxaTotalEntregador: number | null;
    tagsSelecionadas: string[] | null;

    origemEndereco: {
        nome: string;
        CEP: string | null;
        bairro: string | null;
        cidadeID: number | null;
        complemento: string | null;
        estadoID: number | null;
        logradouro: string | null;
        numero: string | null;
        pontoReferencia: string | null;
        latitude: number | null;
        longitude: number | null;
    } | null;

    entregaEndereco: {
        nome: string;
        CEP: string | null;
        bairro: string | null;
        cidadeID: number | null;
        complemento: string | null;
        estadoID: number | null;
        logradouro: string | null;
        numero: string | null;
        pontoReferencia: string | null;
        latitude: number | null;
        longitude: number | null;
    } | null;
    dataAgendamento?: string | null;
    dataCadastro?: string | null;
    dataPronto?: string | null;
    dataDespachado?: string | null;
    dataAceito?: string | null;
    dataChegouEstabelecimento?: string | null;
    dataEmRota?: string | null;
    dataChegouDestino?: string | null;
    dataRetorno?: string | null;
    dataFinalizado?: string | null;
    dataCancelamento?: string | null;
    entregadorUsuarioID?: string | null;
}

interface DadosBasicosProps {
    callback: () => void;
}

const DadosBasicos: React.FC<DadosBasicosProps> = ({ callback }) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext();
    const pedidoModelo = useSelector((state: RootState) => state.pedidos.pedidoModelo);
    const hookForm = useForm<FormData>()
    const { register, reset, getValues, watch, setValue } = hookForm
    const salvarStore = useSelector((state: RootState) => state.pedidos.salvarPedido);
    const [modalTabelas, setModalTabelas] = useState(false);
    const [modalKm, setModalKm] = useState(false);
    const [modalAndamento, setModalAndamento] = useState(false)
    const [sectionsOpen, setSectionsOpen] = useState({
        andamento: false,
        origem: true,
        entrega: true
    })

    useEffect(() => {
        if (salvarStore.data) {
            showSuccess({
                message: salvarStore.data.mensagem,
            });
            callback()
        } else if (salvarStore.error) {
            showError({
                message: salvarStore.error,
            });
        }

        return () => {
            dispatch(salvarPedidoClear())
        }
    }, [salvarStore.data, salvarStore.error])

    const recalcularKmStore = useSelector((state: RootState) => state.pedidos.recalcularQuilometragemPedido);
    useEffect(() => {
        if (recalcularKmStore.data) {
            showSuccess({
                message: recalcularKmStore.data.mensagem,
            });
            callback()
        } else if (recalcularKmStore.error) {
            showError({
                message: recalcularKmStore.error,
            });
            callback()
        }

        return () => {
            dispatch(recalcularQuilometragemPedidoClear())
            handleCloseModalKm()
        }
    }, [recalcularKmStore.data, recalcularKmStore.error])

    const recalcularTabelaStore = useSelector((state: RootState) => state.pedidos.recalcularTabelasPedido);
    useEffect(() => {
        if (recalcularTabelaStore.data) {
            showSuccess({
                message: recalcularTabelaStore.data.mensagem,
            });
            callback()
        } else if (recalcularTabelaStore.error) {
            showError({
                message: recalcularTabelaStore.error,
            });
            callback()
        }

        return () => {
            dispatch(recalcularTabelasPedidoClear())
            handleCloseModalTabelas()
        }
    }, [recalcularTabelaStore.data, recalcularTabelaStore.error])

    useEffect(() => {
        if (!pedidoModelo.data?.modelo) return;
        const modelo = pedidoModelo.data.modelo
        setSectionsOpen((prev) => ({ ...prev, andamento: true }))

        reset({
            pedidoID: modelo.pedidoID,
            clienteNome: modelo.clienteNome,
            empresaID: modelo.empresaID,
            clienteTelefone: modelo.clienteTelefone,
            temRetorno: modelo.temRetorno,
            codigo: modelo.codigo,
            tipoDespacho: modelo.tipoDespacho,
            formaPagamentoID: modelo.formaPagamentoID,
            distanciaCalculadaKm: (modelo.distanciaCalculadaKm !== undefined && modelo.distanciaCalculadaKm !== null) ? + modelo.distanciaCalculadaKm.toFixed(2) : undefined,
            valor: (modelo.valor !== undefined && modelo.valor !== null) ? + modelo.valor.toFixed(2) : undefined,
            trocoPara: modelo.trocoPara,
            distanciaCalculadaKmPorRota: (modelo.distanciaCalculadaKmPorRota !== undefined && modelo.distanciaCalculadaKmPorRota !== null) ? + modelo.distanciaCalculadaKmPorRota?.toFixed(2) : undefined,
            dataEtaEntrega: modelo.dataEtaEntrega,
            dataEtaColeta: modelo.dataEtaColeta,
            aplicacaoOrigemNome: modelo.aplicacaoOrigemNome,
            tipoPedido: modelo.tipoPedido,
            tipoFatura: modelo.tipoFatura,
            detalhes: modelo.detalhes,
            taxaTotalCobrada: modelo.taxaTotalCobrada,
            taxaTotalEntregador: modelo.taxaTotalEntregador,
            origemEndereco: modelo.origemEndereco ? {
                nome: modelo.origemEndereco?.nome || 'Endereço de cliente',
                CEP: modelo.origemEndereco?.CEP || '',
                bairro: modelo.origemEndereco?.bairro || '',
                cidadeID: modelo.origemEndereco?.cidadeID,
                complemento: modelo.origemEndereco?.complemento || '',
                estadoID: modelo.origemEndereco?.estadoID,
                logradouro: modelo.origemEndereco?.logradouro || '',
                numero: modelo.origemEndereco?.numero || '',
                pontoReferencia: modelo.origemEndereco?.pontoReferencia || '',
                latitude: modelo.origemEndereco?.latitude,
                longitude: modelo.origemEndereco?.longitude
            } : null,
            entregaEndereco: {
                nome: modelo.entregaEndereco?.nome || 'Endereço de cliente',
                CEP: modelo.entregaEndereco?.CEP ?? '',
                bairro: modelo.entregaEndereco?.bairro || '',
                cidadeID: modelo.entregaEndereco?.cidadeID,
                complemento: modelo.entregaEndereco?.complemento || '',
                estadoID: modelo.entregaEndereco?.estadoID,
                logradouro: modelo.entregaEndereco?.logradouro || '',
                numero: modelo.entregaEndereco?.numero || '',
                pontoReferencia: modelo.entregaEndereco?.pontoReferencia || '',
                latitude: modelo.entregaEndereco?.latitude,
                longitude: modelo.entregaEndereco?.longitude
            },
            dataAgendamento: modelo?.dataAgendamento,
            dataCadastro: modelo?.dataCadastro,
            dataPronto: modelo?.dataPronto,
            dataDespachado: modelo?.dataDespachado,
            dataAceito: modelo?.dataAceito,
            dataChegouEstabelecimento: modelo?.dataChegouEstabelecimento,
            dataEmRota: modelo?.dataEmRota,
            dataChegouDestino: modelo?.dataChegouDestino,
            dataRetorno: modelo?.dataRetorno,
            dataFinalizado: modelo?.dataFinalizado,
            dataCancelamento: modelo?.dataCancelamento,
            entregadorUsuarioID: modelo.entregadorUsuarioID || '',
        })
    }, [pedidoModelo.data])

    const handleConfirm = () => {
        const dispatchData = getValues()
        const dataToDispatch = Object.fromEntries(
            Object.entries(dispatchData).map(([key, value]) => [key, value === null ? '' : value])
        )
        dispatch(salvarPedidoStart({
            ...dataToDispatch,
            tagsSelecionadas: dataToDispatch.tagsSelecionadas ? dataToDispatch.tagsSelecionadas.join('|') : ''
        }))
    }

    const setPositionOrigemEndereco = (position: { latitude: number; longitude: number }) => {
        setValue('origemEndereco.latitude', position.latitude)
        setValue('origemEndereco.longitude', position.longitude)
    }


    //* funções p/ lidar com troca de endereço
    const setOrigemEnderecoData = (data: any) => {
        const { bairro, cep, cidadeID, estadoID, complemento, numero } = data()
        setValue('origemEndereco.bairro', bairro)
        setValue('origemEndereco.CEP', cep)
        setValue('origemEndereco.numero', numero)
        setValue('origemEndereco.cidadeID', cidadeID)
        setValue('origemEndereco.estadoID', estadoID)
        setValue('origemEndereco.complemento', complemento)
        // Logradouro é tratado no valor do autocomplete
    }

    const setPositionEntregaEndereco = (position: { latitude: number; longitude: number }) => {
        setValue('entregaEndereco.latitude', position.latitude)
        setValue('entregaEndereco.longitude', position.longitude)
    }

    const setEntregaEnderecoData = (data: any) => {
        const { bairro, cep, cidadeID, estadoID, complemento, numero } = data()
        setValue('entregaEndereco.bairro', bairro)
        setValue('entregaEndereco.CEP', cep)
        setValue('entregaEndereco.numero', numero)
        setValue('entregaEndereco.cidadeID', cidadeID)
        setValue('entregaEndereco.estadoID', estadoID)
        setValue('entregaEndereco.complemento', complemento)
    }

    //* Lidando com Modais
    const handleCloseModalKm = () => {
        setModalKm(false)
    }

    const handleConfirmKm = () => {
        const pedidoID = pedidoModelo.data?.modelo?.pedidoID
        if (!pedidoID) return
        dispatch(recalcularQuilometragemPedidoStart({ pedidoID }));
    }

    const handleCloseModalTabelas = () => {
        setModalTabelas(false)
    }

    const handleConfirmTabelas = () => {
        const pedidoID = pedidoModelo.data?.modelo?.pedidoID
        if (!pedidoID) return
        dispatch(recalcularTabelasPedidoStart({
            dataInicio: '01/01/0001',
            dataFim: '01/01/0001',
            pedidoID
        }));
    }

    //* Estilização de componentes
    const stylesTextField = {
        flex: '1 1 300px',
    }

    const propsForTextField: Partial<TextFieldProps> = {
        size: 'small',
        sx: { ...stylesTextField }
    };

    return (
        <>
            <Grid2 container spacing={2}>
                {/* Informações do pedido */}
                <Grid2 size={{ xs: 12, md: 12 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '40px',
                            boxSizing: 'border-box'
                        }}
                        onClick={() => setSectionsOpen((prev) => ({ ...prev, andamento: !prev.andamento }))}
                    >
                        Andamento do pedido {sectionsOpen.andamento ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}
                    </Typography>
                    <Collapse in={sectionsOpen.andamento} timeout="auto">
                        <StepperAndamento
                            sx={{ mt: 2 }}
                            historicoAndamento={{
                                agendamento: watch('dataAgendamento'),
                                recebido: watch('dataCadastro'),
                                pronto: watch('dataPronto'),
                                despacho: watch('dataDespachado'),
                                aceito: watch('dataAceito'),
                                noLocal: watch('dataChegouEstabelecimento'),
                                noDestino: watch('dataChegouDestino'),
                                emRota: watch('dataEmRota'),
                                retorno: watch('dataRetorno'),
                                finalizado: watch('dataFinalizado'),
                                cancelado: watch('dataCancelamento'),
                            }}
                        />
                        <Stack alignItems={'flex-end'} sx={{ mt: 2, width: '100%' }}>
                            <Button
                                variant='containedFilled'
                                sx={{ width: 'fit-content' }}
                                onClick={() => setModalAndamento(true)}
                            >
                                Editar andamento
                            </Button>
                        </Stack>
                    </Collapse>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 12, lg: 6 }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Informações do pedido</Typography>
                    <StyledStack>
                        <TextField
                            {...propsForTextField}
                            {...register('clienteNome')}
                            label="Nome do cliente"
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('clienteNome'),
                                },
                            }}
                        />
                        <AutocompleteEmpresas
                            hookForm={{
                                methods: hookForm,
                                name: 'empresaID'
                            }}
                            label='Empresa'
                            sx={{ ...stylesTextField }}
                        />
                        <TextFieldTelefone
                            hookForm={{
                                methods: hookForm,
                                name: 'clienteTelefone',
                                defaultValue: pedidoModelo.data?.modelo?.clienteTelefone
                            }}
                            label='Telefone'
                            sx={stylesTextField}
                        />
                        <SelectToggleAtivo
                            hookForm={{
                                methods: hookForm,
                                name: 'temRetorno'
                            }}
                            toggleValues={{ ativo: 'true', inativo: 'false' }} //* Adicionar suporte a boolean
                            toggleLabels={{ ativo: 'Sim', inativo: 'Não' }}
                            label='Tem retorno?'
                            sx={{ ...stylesTextField }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Código do pedido'
                            {...register('codigo')}
                            disabled
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('codigo'),
                                },
                            }}
                        />
                        <SelectTipoDespacho
                            hookForm={{
                                methods: hookForm,
                                name: 'tipoDespacho'
                            }}
                            sx={stylesTextField}
                        />
                        <SelectTipoFatura
                            hookForm={{
                                methods: hookForm,
                                name: 'tipoFatura'
                            }}
                            sx={stylesTextField}
                        />
                        <SelectFormaPagamento
                            hookForm={{
                                methods: hookForm,
                                name: 'formaPagamentoID'
                            }}
                            label='Forma de pagamento'
                            sx={stylesTextField}
                        />
                        <TextFieldDistance
                            hookForm={{
                                methods: hookForm,
                                name: 'distanciaCalculadaKm',
                                defaultValue: (pedidoModelo.data?.modelo?.distanciaCalculadaKm !== undefined && pedidoModelo.data?.modelo?.distanciaCalculadaKm !== null) ? + pedidoModelo.data.modelo.distanciaCalculadaKm.toFixed(2) : undefined
                            }}
                            label='Distância calculada por raio (km)'
                            sx={stylesTextField}
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'valor',
                                defaultValue: (pedidoModelo.data?.modelo?.valor !== undefined && pedidoModelo.data?.modelo?.valor !== null) ? + pedidoModelo.data.modelo.valor.toFixed(2) : undefined
                            }}
                            label='Valor do pedido'
                            sx={stylesTextField}
                        />
                        <TextFieldDistance
                            hookForm={{
                                methods: hookForm,
                                name: 'distanciaCalculadaKmPorRota',
                                defaultValue: (pedidoModelo.data?.modelo?.distanciaCalculadaKmPorRota !== undefined && pedidoModelo.data?.modelo?.distanciaCalculadaKmPorRota !== null) ? + pedidoModelo.data.modelo.distanciaCalculadaKmPorRota.toFixed(2) : undefined
                            }}
                            label='Distância calculada por rota (km)'
                            sx={stylesTextField}
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'trocoPara',
                                defaultValue: (pedidoModelo.data?.modelo?.trocoPara !== undefined && pedidoModelo.data?.modelo?.trocoPara !== null) ? + pedidoModelo.data.modelo.trocoPara.toFixed(2) : undefined
                            }}
                            label='Troco'
                            sx={stylesTextField}
                        />
                        <InputDateTime
                            hookForm={{
                                name: 'dataEtaEntrega',
                                methods: hookForm
                            }}
                            disabled
                            label='Data estimada da entrega'
                            sx={{ ...stylesTextField }}
                        />
                        <InputDateTime
                            hookForm={{
                                name: 'dataEtaColeta',
                                methods: hookForm
                            }}
                            disabled
                            label='Data estimada da coleta'
                            sx={{ ...stylesTextField }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Origem'
                            {...register('aplicacaoOrigemNome')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('aplicacaoOrigemNome'),
                                },
                            }}
                        />
                        <SelectTipoPedido
                            hookForm={{
                                methods: hookForm,
                                name: 'tipoPedido'
                            }}
                            sx={stylesTextField}
                        />
                        <InputEntregador
                            hookForm={{
                                methods: hookForm,
                                name: 'entregadorUsuarioID',
                                defaultEntregadorData: {
                                    id: pedidoModelo.data?.modelo?.entregadorUsuarioID,
                                    nome: pedidoModelo.data?.modelo?.entregadorUsuario?.nome
                                }
                            }}
                        />
                        <TextField
                            label='Descrição do Pedido'
                            {...register('detalhes')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('detalhes'),
                                },
                            }}
                            multiline
                            rows={5}
                            fullWidth size='small'
                        />
                        <TextFieldChip
                            hookForm={{
                                methods: hookForm,
                                name: 'tagsSelecionadas',
                                defaultValue: pedidoModelo.data?.modelo?.tagsSelecionadas
                            }}
                            sx={stylesTextField}
                            label='Tags'
                        />
                    </StyledStack>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Taxas</Typography>
                    <StyledStack>
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaTotalCobrada',
                                defaultValue: (pedidoModelo.data?.modelo?.taxaTotalCobrada !== undefined && pedidoModelo.data?.modelo?.taxaTotalCobrada !== null) ? + pedidoModelo.data.modelo.taxaTotalCobrada.toFixed(2) : undefined
                            }}
                            label='Taxa total cobrada'
                            sx={stylesTextField}
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: 'taxaTotalEntregador',
                                defaultValue: (pedidoModelo.data?.modelo?.taxaTotalEntregador !== undefined && pedidoModelo.data?.modelo?.taxaTotalEntregador !== null) ? + pedidoModelo.data.modelo.taxaTotalEntregador.toFixed(2) : undefined
                            }}
                            label='Taxa total entregador'
                            sx={stylesTextField}
                        />
                        <TextFieldTaxasAplicadas
                            pedidoID={pedidoModelo.data?.modelo?.pedidoID}
                            sx={{ width: '100%' }}
                        />
                    </StyledStack>
                </Grid2>
                {/* Endereço de origem */}
                <Grid2 size={{ xs: 12, md: 6, lg: 3 }} spacing={2}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Endereço de origem</Typography>
                    <StyledStack>
                        <AutocompleteMap
                            size='small'
                            setPosition={setPositionOrigemEndereco}
                            setFormState={setOrigemEnderecoData}
                            sx={stylesTextField}
                            valueController={watch('origemEndereco.logradouro')}
                            onValueChangeController={(value: string) => { console.log(value); setValue('origemEndereco.logradouro', value) }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='CEP'
                            {...register('origemEndereco.CEP')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('origemEndereco.CEP'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Número'
                            {...register('origemEndereco.numero')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('origemEndereco.numero'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Complemento'
                            {...register('origemEndereco.complemento')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('origemEndereco.complemento'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Ponto de referência'
                            {...register('origemEndereco.pontoReferencia')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('origemEndereco.pontoReferencia'),
                                },
                            }}
                        />
                        <SelectEstado
                            hookForm={{
                                name: 'origemEndereco.estadoID',
                                methods: hookForm,
                            }}
                            sx={stylesTextField}
                        />
                        <SelectCidade
                            hookForm={{
                                methods: hookForm,
                                name: 'origemEndereco.cidadeID',
                                estadoName: 'origemEndereco.estadoID',
                            }}
                            sx={stylesTextField}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Bairro'
                            {...register('origemEndereco.bairro')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('origemEndereco.bairro'),
                                },
                            }}
                        />
                    </StyledStack>
                    <MapWithMarker
                        position={{
                            latitude: watch('origemEndereco.latitude'),
                            longitude: watch('origemEndereco.longitude'),
                        }}
                        setPosition={(position) => {
                            setValue('origemEndereco.latitude', position.latitude ?? null);
                            setValue('origemEndereco.longitude', position.longitude ?? null);
                        }}
                    />
                </Grid2>
                {/* Endereço de destino */}
                <Grid2 size={{ xs: 12, md: 6, lg: 3 }} spacing={2}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>Endereço de destino</Typography>
                    <StyledStack>
                        <AutocompleteMap
                            size='small'
                            setPosition={setPositionEntregaEndereco}
                            setFormState={setEntregaEnderecoData}
                            sx={stylesTextField}
                            valueController={watch('entregaEndereco.logradouro')}
                            onValueChangeController={(value: string) => setValue('entregaEndereco.logradouro', value)}
                        />
                        <TextField
                            {...propsForTextField}
                            label='CEP'
                            {...register('entregaEndereco.CEP')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('entregaEndereco.CEP'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Número'
                            {...register('entregaEndereco.numero')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('entregaEndereco.numero'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Complemento'
                            {...register('entregaEndereco.complemento')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('entregaEndereco.complemento'),
                                },
                            }}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Ponto de referência'
                            {...register('entregaEndereco.pontoReferencia')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('entregaEndereco.pontoReferencia'),
                                },
                            }}
                        />
                        <SelectEstado
                            hookForm={{
                                name: 'entregaEndereco.estadoID',
                                methods: hookForm,
                            }}
                            sx={stylesTextField}
                        />
                        <SelectCidade
                            hookForm={{
                                methods: hookForm,
                                name: 'entregaEndereco.cidadeID',
                                estadoName: 'entregaEndereco.estadoID',
                            }}
                            sx={stylesTextField}
                        />
                        <TextField
                            {...propsForTextField}
                            label='Bairro'
                            {...register('entregaEndereco.bairro')}
                            slotProps={{
                                inputLabel: {
                                    shrink: !!watch('entregaEndereco.bairro'),
                                },
                            }}
                        />
                    </StyledStack>
                    <MapWithMarker
                        position={{
                            latitude: watch('entregaEndereco.latitude'),
                            longitude: watch('entregaEndereco.longitude'),
                        }}
                        setPosition={(position) => {
                            setValue('entregaEndereco.latitude', position.latitude ?? null);
                            setValue('entregaEndereco.longitude', position.longitude ?? null);
                        }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                    <Stack
                        useFlexGap
                        sx={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '.5rem',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <ButtonLoading
                            onClick={() => { setModalKm(true) }}
                            color="secondary"
                            variant="containedFilled"
                            sx={{ width: 'min-content' }}
                            loading={recalcularKmStore.loading}
                        >
                            Recalcular quilometragem
                        </ButtonLoading>
                        <ButtonLoading
                            onClick={() => { setModalTabelas(true) }}
                            color="secondary"
                            variant="containedFilled"
                            sx={{ width: 'min-content' }}
                            loading={recalcularTabelaStore.loading}
                        >
                            Recalcular tabelas
                        </ButtonLoading>
                        <ButtonLoading
                            loading={salvarStore.loading}
                            variant="containedFilled"
                            type="submit"
                            color="primary"
                            sx={{ width: 'min-content' }}
                            onClick={handleConfirm}
                        >
                            Salvar
                        </ButtonLoading>
                    </Stack>
                </Grid2>
            </Grid2>


            {modalAndamento && (
                <ModalAndamentoPedido
                    hookForm={hookForm}
                    open={modalAndamento}
                    onClose={() => setModalAndamento(false)}
                />
            )}

            <ModalOpcoes
                open={modalKm}
                onClose={handleCloseModalKm}
                acao='Recalcular quilometragem'
                descricao='Você deseja recalcular a quilometragem do pedido?'
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: handleCloseModalKm
                    },
                    {
                        label: 'Confirmar',
                        action: handleConfirmKm,
                        loading: recalcularKmStore.loading
                    }
                ]}
            />

            <ModalOpcoes
                open={modalTabelas}
                onClose={handleCloseModalTabelas}
                acao='Recalcular tabelas'
                descricao='Você deseja recalcular as tabelas do pedido?'
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: handleCloseModalTabelas
                    },
                    {
                        label: 'Confirmar',
                        action: handleConfirmTabelas,
                        loading: recalcularTabelaStore.loading
                    }
                ]}
            />
        </>
    )
}

const StyledStack = styled((props: StackProps) =>
    <Stack useFlexGap {...props} />
)(({ theme }) => ({
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '.5rem',
    marginBottom: '1rem',
}));

export default DadosBasicos