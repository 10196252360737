import React, { createContext, useContext } from 'react';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';
import { GOOGLE_API_KEY } from 'src/ds';

const GoogleMapsLoaderContext = createContext<{ isLoaded: boolean; loadError: Error | undefined } | undefined>(
    undefined
);

export const GoogleMapsLoaderProvider = ({ children }: { children: React.ReactNode }) => {
    const GOOGLE_API_OPTIONS = {
        googleMapsApiKey: GOOGLE_API_KEY || '',
        libraries: ['visualization'] as Libraries,
        id: 'google-map-script',
    };
    const { isLoaded, loadError } = useJsApiLoader(GOOGLE_API_OPTIONS);

    return (
        <GoogleMapsLoaderContext.Provider value={{ isLoaded, loadError }}>
            {children}
        </GoogleMapsLoaderContext.Provider>
    );
};

export const useGoogleMapsLoader = () => {
    const context = useContext(GoogleMapsLoaderContext);
    if (!context) {
        throw new Error('useGoogleMapsLoader must be used within GoogleMapsLoaderProvider');
    }
    return context;
};
