import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Button, ButtonProps, styled, Theme } from "@mui/material";

interface ButtonTableOrderProps extends ButtonProps {
    children?: React.ReactNode;
    onClick?: () => void;
    nome?: string;
    sortDirection?: {
        [key: string]: 'asc' | 'desc';
    }
}

// Componente ButtonTableOrder que usa as props definidas na interface
const ButtonTableOrder = ({ children, onClick, sortDirection, nome, ...props }: ButtonTableOrderProps) => (
    <Button
        size='small'
        onClick={onClick}
        disableRipple={onClick ? false : true}
        sx={(theme: Theme) => ({
            fontSize: ".8rem",
            color: theme.palette.grey[700],
            alignContent: 'center',
            textAlign: 'center',
            borderRadius: '6px',
            cursor: onClick ? 'pointer' : 'default',
            ...theme.applyStyles('dark', {
                '&::hover': {
                    backgroundColor: theme.palette.background.paperDark,
                },
            }),
        })}
        startIcon={nome && sortDirection?.[nome] === 'asc' ? (<ArrowUpward fontSize="small" />) : ( nome && <ArrowDownward fontSize="small" />)}
        {...props}
    >
        {children}
    </Button>
);

export default ButtonTableOrder;
