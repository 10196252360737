import { ArrowBackIos } from '@mui/icons-material';
import { Box, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pedidoModeloClear, pedidoModeloStart } from 'src/store/reducers/pedidos';
import DadosBasicos from './DadosBasicos';
import { RootState } from 'src/store/reducers';
import { BoxLoading } from 'src/ds';
import LogPedido from './LogPedido';
import Despachos from './Despachos';
import MapaPedido from './MapaPedido';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface TabsPedidosProps {
    id: string
}

const TabsPedido: React.FC<TabsPedidosProps> = () => {
    const theme = useTheme();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const { showError } = useSnackbarContext();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const pedidoModelo = useSelector((state: RootState) => state.pedidos.pedidoModelo);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    function reqPedidoModelo() {
        if (!id) return;
        dispatch(pedidoModeloStart({ id }));
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [activeTab])

    // Redireciona p/ tela de relatório se houver erro
    useEffect(() => {
        if (pedidoModelo.error) {
            showError({
                message: pedidoModelo.error,
            });
            setTimeout(() => {
                navigate('/Pedido/Index');
            }, 1000)
        }
        return () => {
            dispatch(pedidoModeloClear());
        }
    }, [pedidoModelo.error])

    useEffect(() => {
        if (!id) return;
        reqPedidoModelo();
        return () => {
            dispatch(pedidoModeloClear());
        }
    }, [id])

    const tabsConfig = [
        { label: 'Dados básicos', content: <DadosBasicos callback={reqPedidoModelo} /> },
        pedidoModelo?.data?.modelo?.tipoPedido === 0 && { label: 'Despachos', content: <Despachos /> },
        pedidoModelo?.data?.modelo?.tipoPedido === 0 && { label: 'Mapa', content: <MapaPedido /> },
        { label: 'Log', content: <LogPedido /> },
    ].filter(Boolean);


    return (
        <>
            <Box p={2} sx={{ backgroundColor: 'background.paper' }}>

                <Stack sx={{ justifyContent: 'flex-start', alignItems: 'center', mb: 1, gap: 1, flexDirection: 'row' }}>
                    <ArrowBackIos
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(-1)}
                    />
                    <Typography variant="h6" >
                        {`Editar #${pedidoModelo.data?.modelo?.codigoQuatro ?? ''}`}
                        <span style={{ fontSize: '0.6em', color: theme.palette.grey[400], marginLeft: '0.5rem' }}>({pedidoModelo.data?.modelo?.pedidoID ?? ''})</span>
                    </Typography>
                </Stack>

                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Tabs de Edição"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabsConfig.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab && tab?.label}
                        />
                    ))}
                </Tabs>
                <BoxLoading loading={pedidoModelo.loading} sx={{ mt: 3 }}>
                    {tabsConfig[activeTab] && tabsConfig[activeTab]?.content}
                </BoxLoading>
            </Box >
        </>
    )
}

export default TabsPedido