import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase';
import { AutocompleteEmpresas, ContainerBodyModal, ModalOpcoes, TableRowLoading, TableRowNotFound } from 'src/ds';
import { Bots, EmpresaVinculada } from 'src/dtos/BotsDTO';
import { RootState } from 'src/store/reducers';
import { excluirEmpresaVinculadaClear, excluirEmpresaVinculadaStart, listarEmpresasVinculadasStart, salvarEmpresaVinculadaClear, salvarEmpresaVinculadaStart } from 'src/store/reducers/bots';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useForm } from 'react-hook-form';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface ModalEmpresasVinculadasProps {
    modalProps: ModalBaseProps;
    bot: Bots;
}

interface FormData {
    empresaID: string
}

const ModalEmpresasVinculadas = ({ modalProps, bot }: ModalEmpresasVinculadasProps) => {
    const dispatch = useDispatch()
    const { showSuccess, showError } = useSnackbarContext();

    const hookForm = useForm<FormData>({ mode: 'onChange' })
    const { formState: { errors }, handleSubmit, reset } = hookForm;

    const [empresaSelecionada, setEmpresaSelecionada] = useState<EmpresaVinculada | null>(null)
    const [modalVincularEmpresa, setModalVincularEmpresa] = useState<boolean>(false)

    const empresasVinculadasLoading = useSelector((state: RootState) => state.bots.listaEmpresasVinculadas.loading)
    const dadosEmpresasVinculadas = useSelector((state: RootState) => state.bots.listaEmpresasVinculadas.data)

    const desvincularEmpresaStore = useSelector((state: RootState) => state.bots.excluirEmpresaVinculada)

    useEffect(() => {
        if (desvincularEmpresaStore.data) {
            showSuccess({
                message: desvincularEmpresaStore.data.mensagem,
            })
            setEmpresaSelecionada(null)
        } else if (desvincularEmpresaStore.error) {
            showError({
                message: desvincularEmpresaStore.error,
            })
        }
        return () => {
            listarEmpresasVinculadas()
            dispatch(excluirEmpresaVinculadaClear())
        }
    }, [desvincularEmpresaStore.data, desvincularEmpresaStore.error])

    const vincularEmpresaStore = useSelector((state: RootState) => state.bots.salvarEmpresaVinculada)

    useEffect(() => {
        if (vincularEmpresaStore.data) {
            showSuccess({
                message: vincularEmpresaStore.data.mensagem,
            })
            reset();
            setModalVincularEmpresa(false);
        } else if (vincularEmpresaStore.error) {
            showError({
                message: vincularEmpresaStore.error,
            })
        }

        return () => {
            listarEmpresasVinculadas();
            dispatch(salvarEmpresaVinculadaClear());
        }
    }, [vincularEmpresaStore.data, vincularEmpresaStore.error])

    const listarEmpresasVinculadas = () => {
        dispatch(listarEmpresasVinculadasStart({
            pickWppBotID: bot.pickWppBotID,
            pagina: 1,
            paginacao: -1
        }))
    }

    useEffect(() => {
        listarEmpresasVinculadas()
    }, [bot])

    const desvincularEmpresa = (empresa: EmpresaVinculada) => {
        setEmpresaSelecionada(empresa)
    }

    const confirmarDesvincular = () => {
        if (empresaSelecionada) {
            dispatch(excluirEmpresaVinculadaStart({
                PickWppBotEmpresasID: empresaSelecionada.PickWppBotEmpresasID
            }))
        }
    }

    const confirmarVincular = (data: FormData) => {
        dispatch(salvarEmpresaVinculadaStart({
            pickWppBotID: bot.pickWppBotID,
            empresaID: data.empresaID
        }))
    }

    return (
        <>
            <ModalBase {...modalProps}>
                <ContainerBodyModal>
                    <Button
                        variant='containedFilled'
                        onClick={() => setModalVincularEmpresa(true)}
                    >
                        Adicionar Empresa
                    </Button>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    <TableCell align="center" width='80%'>
                                        Empresa
                                    </TableCell>
                                    <TableCell align="center" width='20%'>
                                        Ação
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {empresasVinculadasLoading
                                    ? <TableRowLoading />
                                    : dadosEmpresasVinculadas?.dados?.lista && dadosEmpresasVinculadas?.dados?.lista.length > 0
                                        ? dadosEmpresasVinculadas?.dados?.lista.map((empresa, index) => (
                                            <TableRow key={index} hover>
                                                <TableCell align="center">
                                                    <Typography>
                                                        {empresa.nomeEmpresa}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Desvincular empresa" arrow placement='top'>
                                                        <IconButton
                                                            color='error'
                                                            onClick={() => desvincularEmpresa(empresa)}
                                                        >
                                                            <DeleteRoundedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                        : <TableRowNotFound />
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ContainerBodyModal>
            </ModalBase>

            {empresaSelecionada &&
                <ModalOpcoes
                    open={!!empresaSelecionada}
                    onClose={() => setEmpresaSelecionada(null)}
                    acao='Desvincular empresa'
                    descricao={`Você deseja desvincular a empresa '${empresaSelecionada.nomeEmpresa}'?`}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => setEmpresaSelecionada(null)
                        },
                        {
                            label: 'Confirmar',
                            action: confirmarDesvincular,
                            loading: desvincularEmpresaStore.loading
                        },
                    ]}
                />
            }

            {modalVincularEmpresa &&
                <ModalOpcoes
                    open={modalVincularEmpresa}
                    onClose={() => setModalVincularEmpresa(false)}
                    acao='Vincular empresa'
                    descricao={(
                        <Box sx={{ mt: 2 }}>
                            <AutocompleteEmpresas
                                hookForm={{
                                    methods: hookForm,
                                    name: 'empresaID',
                                    rules: { required: 'Campo obrigatório' },
                                }}
                                sx={{ width: '100%' }}
                                label='Empresa'
                            />
                        </Box>
                    )}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => setModalVincularEmpresa(false)
                        },
                        {
                            label: 'Confirmar',
                            action: handleSubmit(confirmarVincular),
                            disabled: Object.keys(errors).length > 0,
                            loading: vincularEmpresaStore.loading
                        },
                    ]}
                />
            }
        </>
    )
}

export default ModalEmpresasVinculadas