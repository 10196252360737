import { CreditCardTwoTone } from "@mui/icons-material";
import { Avatar, Box, Card, FormGroup, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dinheiro } from "src/ds/common";
import { RootState } from "src/store/reducers";
import { gerarQrCodeStart } from "src/store/reducers/utils";

interface DetalhesProps {
    selectedItem: any;
}

const Detalhes: React.FC<DetalhesProps> = ({ selectedItem }) => {
    const template = useSelector((state: RootState) => state.config?.template?.template);

    const dispatch = useDispatch();
    const qrCode = useSelector((state: RootState) => state.utils.gerarQrCode?.data?.qrcode);
    const usuario = useSelector((state: RootState) => state.config?.masterPage?.empresa);

    useEffect(() => {
        if (selectedItem?.transacaoAPagar?.pixQrcode) {
            dispatch(gerarQrCodeStart(selectedItem?.transacaoAPagar?.pixQrcode));
        }
    }, [])

    if (!selectedItem) return null;
    return (
        <Card sx={{ p: 2, flexGrow: 1, display: "flex", flexDirection: "column", maxWidth: "90%" }} elevation={0}>
            <Box display="flex" justifyContent="space-between" gap={2} alignItems="center" mb={2}>
                <Box display="flex" gap={2} alignItems="center">
                    <Avatar src={template?.logoUrl} sx={{ width: 60, height: 60 }} />
                    <Typography variant="h6">{selectedItem?.usuarioResponsavel}</Typography>
                </Box>
                <span>
                    <Typography variant="body2" align="right">
                        <strong>Recibo #{selectedItem?.codigo}</strong>
                    </Typography>
                    <Typography variant="body2" align="right">
                        {selectedItem?.dataFechamento && `Data de vencimento: ${dayjs(selectedItem?.dataFechamento, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}`}
                    </Typography>
                </span>
            </Box>
            <Box display="flex" gap={6} flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                <Box>
                    <Typography>
                        <strong>Emitente:</strong> {usuario?.pickNome}, {usuario?.cidadeNome}
                    </Typography>                    
                </Box>
                <Box textAlign="left">
                    <Typography>
                        <strong>Tomador:</strong> {selectedItem?.usuarioResponsavel}
                    </Typography>
                    <Typography>{selectedItem?.endereco?.logradouro}</Typography>
                </Box>
                <Box>
                    {selectedItem?.formaPagamento?.nome === 'Gateway'
                        ? <CreditCardTwoTone sx={{ width: 100, height: 100 }} viewBox="0 4 20 24"/>
                        : <Box
                            component={'img'}
                            sx={{
                                maxHeight: '100%',
                                height: 'auto',
                                width: 'auto',
                                aspectRatio: '1 / 1',
                                objectFit: 'contain'
                            }}
                            src={qrCode ? `data:image/png;base64,${qrCode}` : ''}
                            style={{ height: '100%', width: '100%' }}
                            alt='Qr Code'
                        />
                    }
                </Box>
            </Box>
            <FormGroup>
                {selectedItem?.itens?.map((item: any) => (
                    <TextField
                        key={item.assinaturaFaturaItemID}
                        id="outlined-basic"
                        label="Detalhes do serviço"
                        variant="outlined"
                        color="secondary"
                        value={item.descricao}
                    />
                ))}
            </FormGroup>
            <Box display="flex" justifyContent="space-between" pt={2}>
                <Typography>
                    <strong>Total:</strong>
                </Typography>
                <Typography>{dinheiro(selectedItem?.valor)}</Typography>
            </Box>
        </Card>
    );
}

export default Detalhes;
