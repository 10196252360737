// TableRowOrdenacao.tsx
import React, { useEffect, useState } from 'react';
import TableCellOrdenacaoSimples from './TableCellOrdenacaoSimples';
import { SxProps } from '@mui/system';

export interface TableRowOrdenacaoCell {
  label: string;
  name?: string;
  sx?: SxProps;
}

interface TableRowOrdenacaoProps {
  cells?: TableRowOrdenacaoCell[];
  onSortChange?: (column: string, direction: 'asc' | 'desc') => void;
}

/**
 * Componente que renderiza uma linha de tabelas com a funcionalidade de ordenação.
 * Cada célula pode ser ordenada clicando na coluna correspondente.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {Array<TableRowOrdenacaoCell>} [props.cells] - Array de objetos que definem as células da linha da tabela.
 * Cada objeto deve ter:
 * - `label`: O rótulo visível da célula.
 * - `name` (opcional): O nome único da célula utilizado para identificação da ordenação.
 * - `sx` (opcional): Estilos personalizados para a célula.
 * 
 *  @note
 *  O array `cells` pode ser alterado a cada renderização. Para evitar renderizações desnecessárias e garantir que o estado de ordenação seja atualizado corretamente, é recomendável que `cells` seja memoizado usando `React.useMemo` para garantir que o valor não mude entre renderizações, a menos que seus valores reais mudem.
 * 
 *  Exemplo de uso:
 *  ```tsx
 *  const cells = React.useMemo(() => [
 *    { label: 'Origem', name: 'nome' },
 *    { label: 'Cadastro', name: 'pontuacaoCadastro' },
 *    { label: 'Login Diário', name: 'pontuacaoLogin' },
 *    { label: 'Ações', },
 *  ], []);
 *  ```
 * 
 *  Se `cells` for indefinido ou vazio, o componente não renderizará nada. Portanto, é importante garantir que esse valor seja definido corretamente durante o ciclo de vida do componente.
 * 
 * @param {Function} [props.onSortChange] - Função de callback chamada quando a ordenação de uma coluna é alterada.
 * Ela recebe o nome da coluna e a direção da ordenação (asc ou desc).
 * 
 * @example
 * <TableRowOrdenacao
 *   cells={cells}
 *   onSortChange={(column, direction) => {
 *     console.log(`Coluna: ${column}, Direção: ${direction}`);
 *   }}
 * />
 * 
 * @warning
 *  - O array `cells` deve ser fornecido de forma adequada e consistente. 
 *  - Como `cells` pode ser alterado durante as renderizações, é necessário garantir que o componente esteja sempre com um valor válido de `cells` para evitar renderizações incorretas.
 *  - O uso de `useMemo` para memoizar `cells` é altamente recomendado para evitar recalcular ou alterar as células de forma desnecessária em cada renderização.
 */
const TableRowOrdenacao: React.FC<TableRowOrdenacaoProps> = ({ cells, onSortChange }) => {
  const [sortState, setSortState] = useState<{ [key: string]: 'asc' | 'desc' }>({});

  useEffect(() => {
    if (!cells) return;
    const initialState = Object.fromEntries(
      cells.map((cell) => [cell.name, 'asc'])
    ) as { [key: string]: 'asc' | 'desc' };
    setSortState(initialState);
  }, [cells]);

  const handleSortChange = React.useCallback(
    (column: string) => {
      const newSortState: { [key: string]: 'asc' | 'desc' } = {};

      // Atualizar o estado: Apenas a coluna clicada será 'desc'
      cells?.forEach((cell) => {
        if (!cell.name) return;
        newSortState[cell.name] = cell.name === column
          ? sortState[column] === 'asc' ? 'desc' : 'asc' // Alternar entre asc e desc
          : 'asc';// As outras colunas voltam para asc
      });

      setSortState(newSortState);
      onSortChange?.(column, newSortState[column]);
    },
    [cells, sortState, onSortChange]
  );

  return (
    <>
      {cells?.map((cell, index) => (
        <TableCellOrdenacaoSimples
          key={cell.name || cell.label}
          column={cell.name || undefined}
          label={cell.label}
          sx={cell.sx}
          onSortChange={cell.name
            ? onSortChange
              ? () => handleSortChange(cell.name || 'asc')
              : undefined
            : undefined}
          sortDirection={cell.name ? sortState[cell.name] || 'asc' : undefined}
        />
      ))}
    </>
  );
};

export default TableRowOrdenacao;
