// {
//  paginacao: 1,
//  pagina: 1,
//  empresaID: '',
//  dataInicio: '',
//  dataFim: '',
// }

export type CamposTypes =
  | "TEXTO"
  | "NUMERO"
  | "DATA"
  | "DATA_HORA"
  | "CHECKBOX"
  | "SELECT_MULTIPLE"
  | "TAGS";

//   | "RELACIONAMENTO"

export interface FiltrosCampo {
  nome: string;
  valor: any;
  tipo?: CamposTypes;
  filtroCustomizado?: (arg0: string, arg1: any) => string;
  ou?: boolean;
  maiorQue?: boolean;
  menorQue?: boolean;
  igual?: boolean;
}

export const getFiltros = function (
  form: FiltrosCampo[],
  filtroBase?: string | null
) {
  let filtros = filtroBase ? filtroBase : "";

  for (let i = 0; i < form.length; i++) {
    const campo = form[i] ?? "";
    const valorCampo = campo.valor;

    if (valorCampo != undefined && valorCampo.toString().length > 0) {
      console.log(campo);
      if (campo.filtroCustomizado) {
        const valorCustomizado = campo.filtroCustomizado(
          campo.nome,
          valorCampo
        );
        if (valorCustomizado) {
          //ADICIONA && OU || CONFORME FILTRO DEFINIDO
          if (filtros.length > 0) {
            if (campo.ou) filtros += " || ";
            else filtros += " && ";
          }
          filtros += campo.filtroCustomizado(campo.nome, valorCampo);
        }
      } else {
        //ADICIONA && OU || CONFORME FILTRO DEFINIDO
        if (filtros.length > 0) {
          if (campo.ou) filtros += " || ";
          else filtros += " && ";
        }
        switch (campo.tipo) {
          case "TEXTO":
            if (campo.igual) {
              filtros += campo.nome + '.toLower().Equals("' + valorCampo.toLowerCase() + '")';
            } else {
              filtros += campo.nome + '.toLower().Contains("' + valorCampo.toLowerCase() + '")';
            }
            break;

          case "CHECKBOX":
            filtros += " && " + campo.nome;
            break;

          case "NUMERO":
            if (campo.maiorQue && campo.igual) {
              filtros += campo.nome + " >= " + valorCampo;
            } else if (campo.maiorQue) {
              filtros += campo.nome + " > " + valorCampo;
            } else if (campo.menorQue && campo.igual) {
              filtros += campo.nome + " <= " + valorCampo;
            } else if (campo.menorQue) {
              filtros += campo.nome + " < " + valorCampo;
            } else {
              filtros += campo.nome + " == " + valorCampo;
            }
            break;

          case "DATA":
            const dtAux = valorCampo.split("/");
            filtros += "(";
            filtros += campo.nome + ".Year == " + dtAux[2];
            filtros += " && ";
            filtros += campo.nome + ".Month == " + dtAux[1];
            filtros += " && ";
            filtros += campo.nome + ".Day == " + dtAux[0];
            filtros += ")";
            break;

          case "DATA_HORA":
            const [data, hora] = valorCampo.split(" ");
            const [dia, mes, ano] = data.split("/");
            const [horaInicial, minutoInicial] = hora.split(":");

            const dataHoraString = `DateTime(${ano},${mes},${dia},${horaInicial},${minutoInicial},0)`;

            if (campo.maiorQue && campo.igual) {
              filtros += `${campo.nome} >= ${dataHoraString}`;
            } else if (campo.maiorQue) {
              filtros += `${campo.nome} > ${dataHoraString}`;
            } else if (campo.menorQue && campo.igual) {
              filtros += `${campo.nome} <= ${dataHoraString}`;
            } else if (campo.menorQue) {
              filtros += `${campo.nome} < ${dataHoraString}`;
            } else if (campo.igual) {
              filtros += `${campo.nome} == ${dataHoraString}`;
            } else {
              filtros += `${campo.nome} == ${dataHoraString}`;
            }
            break;

          case "SELECT_MULTIPLE":
            const valores = campo.valor;
            console.log(valores)

            if (Array.isArray(valores) && valores.length > 0) {
              let valoresString = valores
                .map((valor) => `${campo.nome}.ToString().Equals("${valor}")`)
                .join(" || ");
              filtros += `(${valoresString})`;
            } else {
              filtros +=
                campo.nome + '.Contains("' + valorCampo.toString() + '")';
            }
            break;

          case "TAGS":
            const valoresTags = campo.valor;
            if (Array.isArray(valoresTags) && valoresTags.length > 0) {
              let valoresString;
              if (campo.ou) {
                valoresString = valoresTags
                  .map((valor) => `${campo.nome}.Contains("${valor}")`)
                  .join(" || ");
              } else {
                valoresString = valoresTags
                  .map((valor) => `${campo.nome}.Equals("${valor}")`)
                  .join(" && ");
              }
              filtros += `(${valoresString})`;
            } else {
              filtros +=
                campo.nome + '.Contains("' + valorCampo.toString() + '")';
            }
            break;

          default:
            filtros += campo.nome + '.ToString().Equals("' + valorCampo + '")';
            break;
        }

        // } else if (campo.tipo === "DROPDOWN" && campo.multiple === true) {
        //   var valores = campo.getValor();
        //   if (valores.length > 0) {
        //     var filtroCheckbox = "";
        //     for (var v = 0; v < valores.length; v++) {
        //       if (filtroCheckbox.length > 0) {
        //         filtroCheckbox += " || ";
        //       }
        //       filtroCheckbox +=
        //         campo.nome + '.ToString().Equals("' + valores[v] + '")';
        //     }
        //     filtros += "(" + filtroCheckbox + ")";
        //   }
        // } else if (campo.tipo === "CHECKBOX") {
        //   var valores = campo.getValor();
        //   if (valores.length > 0) {
        //     var filtroCheckbox = "";
        //     for (var v = 0; v < valores.length; v++) {
        //       if (filtroCheckbox.length > 0) {
        //         filtroCheckbox += " || ";
        //       }
        //       filtroCheckbox += campo.nome + " == " + valores[v].valor + "";
        //     }
        //     filtros += "(" + filtroCheckbox + ")";
        //   }
        // } else if (campo.tipo === "RELACIONAMENTO") {
        //   var valores = campo.getValor();
        //   if (valores.length > 0) {
        //     var filtroCheckbox = "";
        //     for (var v = 0; v < valores.length; v++) {
        //       if (filtroCheckbox.length > 0) {
        //         filtroCheckbox += " || ";
        //       }
        //       filtroCheckbox +=
        //         campo.nome + '.ToString().Equals("' + valores[v] + '")';
        //     }
        //     filtros += "(" + filtroCheckbox + ")";
        //   }
      }
    }
  }
  // console.log(filtros);
  return filtros;
  //   return ArrayFiltros.join("");
};
