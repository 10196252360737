import { Entregador } from "src/dtos/EntregadoresDTO";
import { EntregadoresOnline } from "src/dtos/RastreioDTO";

const createMarkerEntregadorElement = (
  entregador?: Entregador | EntregadoresOnline
) => {
  const ocupado =
    entregador && 'pedidosEmAndamento' in entregador &&
      entregador.pedidosEmAndamento !== undefined &&
      entregador.pedidosEmAndamento.length > 0
      ? true
      : false;

  const markerElement = document.createElement("div");
  markerElement.innerHTML = `
        <div style="position: relative; display: inline-block; text-align: center; z-index: 99999;" id=${entregador?.id}>
            <svg width="40" height="40" id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.82 223.63">
                <defs></defs>
                <g id="Camada_1-2" data-name="Camada_1">
                    <g>
                    <g>
                        <circle class="${ocupado ? "ocupado" : "cls-2"
    }" cx="97" cy="91" r="91"/>
                    <rect class="${ocupado ? "ocupado" : "cls-2"
    }" x="1" y="148.63" width="999.82" height="195" rx="1.3" ry="1.3"/>
                        <text x="197" y="191" class="cls-5">Delivery</text>
                    </g>
                    <g>
                        <path class="cls-6" d="M150.73,87.67c-.48,6.83-2.31,13.3-5.6,19.34-2,3.67-4.48,6.97-7.27,10.08-.89.99-1.94,1.27-3.11,1.44-8.28,1.22-16.56,2.46-24.85,3.67-6.94,1.02-13.89,2-20.83,3.03-6.98,1.03-13.96,2.1-20.94,3.15-5.64.84-11.28,1.69-16.94,2.48-4.25.6-7.24-2.03-7.24-6.28,0-5.47.03-10.94.04-16.41.73-3.39,1.44-6.79,2.2-10.18,1.63-7.22,3.07-14.49,4.95-21.65.69-2.65,1.6-5.24,2.89-7.67,3.22-7.22,7.92-13.34,13.91-18.48,7.43-6.39,16-10.45,25.64-12.24,27.61-5.14,51.52,12.61,56.39,37.65.18.96.44,1.93.37,2.93.31.65.23,1.34.22,2.02.48,2.37.35,4.77.18,7.13Z"/>
                        <path class="cls-7" d="M150.73,87.67c-.48,6.83-2.31,13.3-5.6,19.34-2,3.67-4.48,6.97-7.27,10.08-.89.99-1.94,1.27-3.11,1.44-8.28,1.22-16.56,2.46-24.85,3.67-6.94,1.02-13.89,2-20.83,3.03-6.98,1.03-13.96,2.1-20.94,3.15-5.64.84-11.28,1.69-16.94,2.48-4.25.6-7.24-2.03-7.24-6.28,0-5.47.03-10.94.04-16.41.4-.38.91-.35,1.4-.36,1.56,0,3.12-.04,4.68.02.04,0,.08,0,.11,0,.07,0,.14,0,.2,0,1.18-.02,2.36-.02,3.53,0h.26c8.76.03,17.53.17,26.29-.04,7.94-.19,14.9-3.08,20.69-8.65,5.61-5.39,8.79-11.91,9.57-19.62,0-.04,0-.08.01-.11.02-.1.03-.19.04-.29.43-2.97.14-5.76-2.17-8.04-.22-.22-.42-.48-.48-.8,0,0,0-.02,0-.04,0-.04,0-.08,0-.12.01-.48.29-.84.59-1.19,6.17-7.37,16.62-10.36,25.74-7.3,7.59,2.55,12.72,7.63,15.39,15.17.21.6.25,1.22.4,1.82,0,.03.02.06.02.09,0,.03.02.06.03.09.18.56.05,1.17.27,1.73.48,2.37.35,4.77.18,7.13Z"/>
                        <path class="cls-1" d="M150.28,78.81c-.05.02-.13.01-.22,0-.79-3.74-2.58-6.98-5.06-9.84-4.11-4.74-9.37-7.48-15.58-8.1-6.11-.61-11.74.98-16.67,4.72-1.73,1.31-3.38,2.74-4.51,4.65-.04.02-.09.04-.13.06-.19.07-.4.03-.56-.08-1.79-1.18-3.8-1.24-5.84-1.17-.05,0-.1,0-.16,0-.18,0-.37,0-.55,0-11.09.1-22.17.02-33.26.04-.7,0-1.4.05-2.09-.11-.24-.1-.39-.21-.47-.33-.01-.01-.02-.03-.03-.04h0c-.2-.36.16-.79.36-1.13,1.04-1.78,2.28-3.42,3.62-4.99,7.48-8.79,17.03-13.83,28.4-15.5,1.5-.22,3.02-.44,4.55-.35.33.02.66.01.99-.03,1.16-.15,1.89-.87,1.83-1.79-.06-.9-.75-1.47-1.93-1.48-4.69-.02-9.27.73-13.75,2.1-6.31,1.93-12.01,5.01-17,9.3-4.24,3.65-7.85,7.85-10.62,12.74-.2.36-.38.73-.67,1.03-.04.05-.08.09-.13.13-.07.07-.14.12-.22.17,0,0-.02.02-.03.02-.04.02-.07.04-.11.05-.1.04-.19.07-.3.1-1.67.16-3.34.07-5.01.06-.4,0-.82-.03-1.11-.39,3.22-7.22,7.92-13.34,13.91-18.48,7.43-6.39,16-10.45,25.64-12.24,27.61-5.14,51.52,12.61,56.39,37.65.18.96.44,1.93.37,2.93.07.18.04.26-.05.29Z"/>
                        <path class="cls-3" d="M60.43,69.76c-2.76,5.22-3.98,10.93-5.22,16.63-1.49,6.86-3.06,13.7-4.56,20.55-.07.31-.13.62-.28.88-.07.12-.16.22-.27.32-2.04,0-4.08.02-6.12.02.73-3.39,1.44-6.79,2.2-10.18,1.63-7.22,3.07-14.49,4.95-21.65.69-2.65,1.6-5.24,2.89-7.67,2.17.02,4.34.04,6.5.06.02.03.04.07.06.1.16.33,0,.64-.15.94Z"/>
                        <path class="cls-8" d="M150.55,80.54c-.39-.51-.23-1.18-.49-1.73.06-.06.11-.12.16-.18.04-.04.07-.08.11-.11.31.65.23,1.34.22,2.02Z"/>
                        <path class="cls-3" d="M65.23,68.64c8.44.01,16.89.02,25.33.04,3.75,0,7.5.03,11.24.04-.02.52-.48.49-.8.61-3.4,1.27-5.52,4.34-5.29,7.65.25,3.59,2.68,6.27,6.37,7.05,2.9.61,6.2-1.02,7.82-3.8.26-.44.28-1.17,1.07-1.13.02,0,.04.06.04.09,0,.04,0,.08-.01.12-.53,7.43-3.52,13.78-8.65,19.11-6.15,6.39-13.74,9.62-22.59,9.72-8.56.1-17.12,0-25.68,0-.36-.51-.12-1.04,0-1.54,1.87-8.35,3.7-16.7,5.58-25.05.84-3.75,2.17-7.33,3.88-10.77.37-.74.74-1.47,1.3-2.09.12-.09.25-.1.39-.05Z"/>
                        <path class="cls-5" d="M64.99,68.72c-1.57,2.98-3.03,6.01-4,9.25-1.54,5.15-2.45,10.44-3.68,15.67-1.13,4.82-2.16,9.67-3.23,14.51-1.33,0-2.66,0-3.99,0,.83-3.84,1.64-7.68,2.5-11.51,1.38-6.15,2.76-12.3,4.2-18.43.78-3.33,2.27-6.4,3.72-9.48,0,0,0-.08,0-.08,1.5-.45,3-.66,4.48.08Z"/>
                        <path class="cls-4" d="M111.01,79.32c-.89.44-.96,1.45-1.52,2.14-2.69,3.29-7.38,4.05-10.9,1.41-2.52-1.89-3.63-4.51-3.1-7.55.58-3.32,2.59-5.59,6-6.41.11-.03.21-.12.31-.19,2.33-.3,4.48.19,6.43,1.5,2.76,2.47,3.49,5.56,2.77,9.09Z"/>
                        <path class="cls-7" d="M64.99,68.72c-1.49-.03-2.99-.05-4.48-.08,1.22-1.9,2.29-3.88,3.65-5.69,4.09-5.45,8.96-10,14.9-13.42,4.71-2.71,9.71-4.6,15.04-5.64,2.94-.57,5.91-.93,8.91-.88,1.41.02,2.22.71,2.26,1.9.03,1.09-.9,2.05-2.18,2.03-3.1-.05-6.13.37-9.15,1.02-6.7,1.44-12.73,4.29-18.13,8.51-3.89,3.04-7.13,6.65-9.8,10.78-.29.45-.53.93-.79,1.39-.08.03-.16.06-.24.08Z"/>
                        <path class="cls-3" d="M99.35,76.62c-.01-2.24,1.73-3.98,3.97-3.98,2.19,0,3.91,1.71,3.94,3.89.03,2.22-1.81,4.02-4.09,4.01-2-.01-3.81-1.87-3.82-3.92Z"/>
                    </g>
                    </g>
                </g>
            </svg>
            <div style="position: absolute; bottom: 0; padding-bottom: 4px; padding-horizontal: 2px ;font-size: 10px; color: white; width: 100%; text-align: center; text-shadow: 0.2px 0.2px 0.1px black, -1px -1px 1px black, -1px 1px 1px black, 1px -1px 2px black;">
                ${entregador?.nome
      ? entregador.nome.split(" ")[0].substring(0, 6)
      : "Entregador".split(" ")[0].substring(0, 6)
    }
            </div>
        </div>            
    `;
  return markerElement;
};

export default createMarkerEntregadorElement;
