import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
    loading: boolean;
    data: any | null;
    error: string | null;
}

interface WalletState {
    pagina: State;
    minhasTransacoes: State;
    comprarCreditos: State
    consultarPagamento: State;
    novoCredito: State
    minhasTransacoesRepresentante: State
    comprarCreditosRepresentante: State
    disponiveisRepresentante: State;
    meusCartoes: State;
    cadastrarCartao: State;
}

const initialState: WalletState = {
    pagina: {
        loading: false,
        data: null,
        error: null
    },
    minhasTransacoes: {
        loading: false,
        data: null,
        error: null
    },
    comprarCreditos: {
        loading: false,
        data: null,
        error: null
    },
    consultarPagamento: {
        loading: false,
        data: null,
        error: null
    },
    novoCredito: {
        loading: false,
        data: null,
        error: null
    },
    minhasTransacoesRepresentante: {
        loading: false,
        data: null,
        error: null
    },
    comprarCreditosRepresentante: {
        loading: false,
        data: null,
        error: null
    },
    disponiveisRepresentante: {
        loading: false,
        data: null,
        error: null
    },
    meusCartoes: {
        loading: false,
        data: null,
        error: null
    },
    cadastrarCartao: {
        loading: false,
        data: null,
        error: null
    }
};

const walletSlice = createSlice({
    name: "Wallet",
    initialState: initialState,
    reducers: {
        paginaStart(state, action: PayloadAction<any>) {
            state.pagina.loading = true;
            state.pagina.error = null;
        },
        paginaFailure(state, action: PayloadAction<string>) {
            state.pagina.loading = false;
            state.pagina.error = action.payload;
        },
        paginaSuccess(state, action: PayloadAction<any>) {
            state.pagina.loading = false;
            state.pagina.data = action.payload;
        },
        minhasTransacoesStart(state, action: PayloadAction<any>) {
            state.minhasTransacoes.loading = true;
            state.minhasTransacoes.error = null;
        },
        minhasTransacoesFailure(state, action: PayloadAction<string>) {
            state.minhasTransacoes.loading = false;
            state.minhasTransacoes.error = action.payload;
        },
        minhasTransacoesSuccess(state, action: PayloadAction<any>) {
            state.minhasTransacoes.loading = false;
            state.minhasTransacoes.data = action.payload;
        },
        comprarCreditosStart(state, action: PayloadAction<any>) {
            state.comprarCreditos.loading = true;
            state.comprarCreditos.error = null;
        },
        comprarCreditosFailure(state, action: PayloadAction<string>) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.error = action.payload;
        },
        comprarCreditosSuccess(state, action: PayloadAction<any>) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.data = action.payload;
            state.novoCredito.data = action.payload;
        },
        limparComprarCreditos(state) {
            state.comprarCreditos.loading = false;
            state.comprarCreditos.error = null;
            state.comprarCreditos.data = null;
            state.novoCredito.data = null;
            state.comprarCreditosRepresentante.data = null;
            state.comprarCreditosRepresentante.loading = false;
            state.comprarCreditosRepresentante.error = null;
        },
        consultarPagamentoStart(state, action: PayloadAction<any>) {
            state.consultarPagamento.loading = true;
            state.consultarPagamento.error = null;
        },
        consultarPagamentoFailure(state, action: PayloadAction<string>) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.error = action.payload;
        },
        consultarPagamentoSuccess(state, action: PayloadAction<any>) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.data = action.payload;
        },
        limparConsultarPagamento(state) {
            state.consultarPagamento.loading = false;
            state.consultarPagamento.error = null;
            state.consultarPagamento.data = null;
        },
        minhasTransacoesRepresentanteStart(state, action: PayloadAction<any>) {
            state.minhasTransacoesRepresentante.loading = true;
            state.minhasTransacoesRepresentante.error = null;
        },
        minhasTransacoesRepresentanteFailure(state, action: PayloadAction<string>) {
            state.minhasTransacoesRepresentante.loading = false;
            state.minhasTransacoesRepresentante.error = action.payload;
        },
        minhasTransacoesRepresentanteSuccess(state, action: PayloadAction<any>) {
            state.minhasTransacoesRepresentante.loading = false;
            state.minhasTransacoesRepresentante.data = action.payload;
        },
        comprarCreditosRepresentanteStart(state, action: PayloadAction<any>) {
            state.comprarCreditosRepresentante.loading = true;
            state.comprarCreditosRepresentante.error = null;
        },
        comprarCreditosRepresentanteFailure(state, action: PayloadAction<string>) {
            state.comprarCreditosRepresentante.loading = false;
            state.comprarCreditosRepresentante.error = action.payload;
        },
        comprarCreditosRepresentanteSuccess(state, action: PayloadAction<any>) {
            state.comprarCreditosRepresentante.loading = false;
            state.comprarCreditosRepresentante.data = action.payload;
            state.novoCredito.data = action.payload;

        },
        disponiveisRepresentanteStart(state, action: PayloadAction<any>) {
            state.disponiveisRepresentante.loading = true;
            state.disponiveisRepresentante.error = null;
        },
        disponiveisRepresentanteFailure(state, action: PayloadAction<string>) {
            state.disponiveisRepresentante.loading = false;
            state.disponiveisRepresentante.error = action.payload;
        },
        disponiveisRepresentanteSuccess(state, action: PayloadAction<any>) {
            state.disponiveisRepresentante.loading = false;
            state.disponiveisRepresentante.data = action.payload;
        },
        meusCartoesStart(state, action: PayloadAction<any>) {
            state.meusCartoes.loading = true;
            state.meusCartoes.error = null;
        },
        meusCartoesFailure(state, action: PayloadAction<string>) {
            state.meusCartoes.loading = false;
            state.meusCartoes.error = action.payload;
        },
        meusCartoesSuccess(state, action: PayloadAction<any>) {
            state.meusCartoes.loading = false;
            state.meusCartoes.data = action.payload;
        },
        cadastrarCartaoStart(state, action: PayloadAction<any>) {
            state.cadastrarCartao.loading = true;
            state.cadastrarCartao.error = null;
        },
        cadastrarCartaoFailure(state, action: PayloadAction<string>) {
            state.cadastrarCartao.loading = false;
            state.cadastrarCartao.error = action.payload;
        },
        cadastrarCartaoSuccess(state, action: PayloadAction<any>) {
            state.cadastrarCartao.loading = false;
            state.cadastrarCartao.data = action.payload;
        },
    },
});

export const {
    paginaStart,
    paginaFailure,
    paginaSuccess,
    minhasTransacoesStart,
    minhasTransacoesFailure,
    minhasTransacoesSuccess,
    comprarCreditosFailure,
    comprarCreditosStart,
    comprarCreditosSuccess,
    consultarPagamentoStart,
    consultarPagamentoFailure,
    consultarPagamentoSuccess,
    limparConsultarPagamento,
    limparComprarCreditos,
    minhasTransacoesRepresentanteStart,
    minhasTransacoesRepresentanteFailure,
    minhasTransacoesRepresentanteSuccess,
    comprarCreditosRepresentanteStart,
    comprarCreditosRepresentanteFailure,
    comprarCreditosRepresentanteSuccess,
    disponiveisRepresentanteStart,
    disponiveisRepresentanteFailure,
    disponiveisRepresentanteSuccess,
    meusCartoesStart,
    meusCartoesFailure,
    meusCartoesSuccess,
    cadastrarCartaoStart,
    cadastrarCartaoFailure,
    cadastrarCartaoSuccess
} = walletSlice.actions;

export default walletSlice;
