import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { limparSalvarTabela } from "../../store/reducers/tabelaCobranca";
import TabelaCobranca from "../../components/TabelaCobranca/Tabela";
import CadastrarTabela from "src/components/TabelaCobranca/ModalCadastrarTabela";
import { ModalOpcoes, useResponseSnackbar, converterDataCadastro, DynamicDataContainer } from "src/ds";
import { definirTipoDeCobrancaStart, limparTipoDeCobranca } from "src/store/reducers/config";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store/reducers";
import { DynamicDataContainerRef } from "src/ds/components/DynamicDataContainer";

const TabelaCobrancaPage = () => {
    const tabelasCobrancaDados = useSelector((state: any) => state.tabelaCobranca.listaTabelasCobranca?.data?.dados);
    const tabelasCobranca = tabelasCobrancaDados?.lista;
    const loadingTabelasCobranca = useSelector((state: any) => state.tabelaCobranca.listaTabelasCobranca?.loading);
    const [openCadastro, setOpenCadastro] = useState(false);
    const [openModalOpcoes, setOpenModalOpcoes] = useState(false);
    const [tipoCobranca, setTipoCobranca] = useState<string | number>('');
    const dispatch = useDispatch();
    const [ultimaTabelaCadastrada, setUltimaTabelaCadastrada] = useState(null);
    const [triggerEditar, setTriggerEditar] = useState(false);
    const response = useSelector((state: any) => state.tabelaCobranca.salvarTabela);
    const response2 = useSelector((state: any) => state.config.tipoDeCobranca);
    const navigate = useNavigate();
    const metodoCobranca = useSelector((state: RootState) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);

    useResponseSnackbar([
        { response: response, clearAction: () => dispatch(limparSalvarTabela()) },
        { response: response2, clearAction: () => dispatch(limparTipoDeCobranca()) }
    ]);

    useEffect(() => {
        if (tabelasCobranca?.length > 0) {
            const storedId = localStorage.getItem('lastEditedTabelaId');

            const cincoMinutosAtras = new Date(new Date().getTime() - 0.5 * 60 * 1000);

            const tabelasRecentes = tabelasCobranca?.filter((tabela: any) => {
                const dataCadastro = converterDataCadastro(tabela.dataCadastro);
                return dataCadastro > cincoMinutosAtras;
            });

            const tabelaMaisRecente = tabelasRecentes?.reduce((maisRecente: any, tabelaAtual: any) => {
                const dataMaisRecente = converterDataCadastro(maisRecente?.dataCadastro || '');
                const dataAtual = converterDataCadastro(tabelaAtual?.dataCadastro);
                return dataAtual > dataMaisRecente ? tabelaAtual : maisRecente;
            }, null);

            if (tabelaMaisRecente && tabelaMaisRecente.tabelaCobrancaID !== storedId) {
                setUltimaTabelaCadastrada(tabelaMaisRecente);
                setTriggerEditar(true);
                localStorage.setItem('lastEditedTabelaId', tabelaMaisRecente.tabelaCobrancaID);
                navigate(`/TabelaCobranca/Index/${tabelaMaisRecente.tabelaCobrancaID}`);
            }
        }
    }, [tabelasCobranca]);

    const hookForm = useForm({
        defaultValues: {
            nome: '',
            dataInicial: '',
            dataFinal: '',
            paginacao: 20,
            pagina: 1,
        },
    });

    const cardRef = useRef<DynamicDataContainerRef>(null);
    const tipoUsuario = useSelector((state: any) => state.config?.tipoDeUsuario);

    const handleTipoCobrancaChange = (event: any) => {
        setTipoCobranca(event.target.value);
    };

    useEffect(() => {
        setTipoCobranca(metodoCobranca);
    }, [])

    const handleConfirmar = () => {
        dispatch(definirTipoDeCobrancaStart({ tipo: tipoCobranca }));
        setOpenModalOpcoes(false);
    };

    return (
        <>
            <DynamicDataContainer
                hookForm={hookForm}
                ref={cardRef}
                cardFormFiltrosProps={{
                    dispatchMethods: { actionName: 'listaTabelaCobrancaStart' },
                    fields: [
                        { name: "nome", ehFiltro: true, label: "Buscar por nome", tipoFiltro: 'TEXTO' },
                    ],
                    loading: loadingTabelasCobranca,
                }}
                sections={[{
                    title: 'Tabela de cobrança',
                    label: 'Tabela de cobrança',
                    tableNavigation: {
                        indiceInicial: tabelasCobrancaDados?.indiceInicial,
                        indiceFinal: tabelasCobrancaDados?.indiceFinal,
                        indiceTotal: tabelasCobrancaDados?.total,
                        ultimaPagina: tabelasCobrancaDados?.ultimaPagina,
                    },
                    extraButtons: [
                        ...(tipoUsuario === 'SAAS' ? [{
                            label: 'Método de cálculo',
                            action: () => setOpenModalOpcoes(true)
                        }] : []),
                        {
                            label: 'Inserir',
                            action: () => setOpenCadastro(true)
                        },
                    ],
                    component: <TabelaCobranca
                        fezRequisicao={cardRef.current?.fezRequisicao}
                        tabelasCobranca={tabelasCobranca}
                        loading={loadingTabelasCobranca}
                        atualizar={() => cardRef.current?.onSubmit()}
                        ultimaTabelaCadastrada={ultimaTabelaCadastrada}
                        triggerEditar={triggerEditar}
                        setTriggerEditar={setTriggerEditar}
                    />
                }]}
            />
            <CadastrarTabela open={openCadastro} onClose={() => {
                setOpenCadastro(false);
                setTimeout(() => {
                    cardRef.current?.onSubmit();
                }, 1000)
            }} />
            <ModalOpcoes
                open={openModalOpcoes}
                onClose={() => { setOpenModalOpcoes(false); }}
                acao="Definir método de cálculo"
                opcoes={[
                    { label: 'Cancelar', action() { setOpenModalOpcoes(false); } },
                    { label: 'Confirmar', action: handleConfirmar, disabled: tipoCobranca === '' },
                ]}
                descricao={
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel shrink htmlFor="estado-label" >Tipos</InputLabel>
                        <Select
                            displayEmpty
                            size="small"
                            id="tipos-label"
                            label="Tipos"
                            sx={{ textAlign: 'left' }}
                            value={tipoCobranca}
                            onChange={handleTipoCobrancaChange}

                        >
                            <MenuItem disabled value=''>
                                Selecione um tipo
                            </MenuItem>
                            <MenuItem key={0} value={0}>
                                Por raio
                            </MenuItem>
                            <MenuItem key={1} value={1}>
                                Por rota
                            </MenuItem>
                            <MenuItem key={2} value={2}>
                                Por bairro
                            </MenuItem>
                        </Select>
                    </FormControl>
                }
            />
        </>
    );
};

export default TabelaCobrancaPage;
