import React, { ReactNode } from "react";
import { Box, Divider, IconButton, ListItemButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button/Button";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { IconButtonFechar } from "./styledIconButtons";
import useResponsive from "../hooks/useResponsive";

interface ButtonHeader {
    label: string;
    action: () => void;
    disabled?: boolean;
    variant?: ButtonProps["variant"];
    icon?: ReactNode;
}
export interface HeaderModalProps {
    title?: string;
    controls?: null | ButtonHeader[];
    forceMenu?: boolean;
    onClose?: () => void;
}

/**
 * Header do Modal.
 * @param {Array} props.controlesHeader - Uma Array de Controles Laterais Header
 * @param {string} props.title - Titulo do Modal
 * @param {Boolean} props.forceMenu - Forçar Mobile
 * @param {function} props.onClose - Função para fechar o modal.
 * @returns
 */
const HeaderModal = ({
    controls,
    forceMenu = false,
    title = 'Modal',
    onClose
}: HeaderModalProps) => {
    const { isMobile } = useResponsive();
    const [anchorEl, setAnchorEl] = React.useState<null | EventTarget>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    return (
        <>
            <Box sx={{ p: '1rem' }} >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '100%'
                    }}
                >
                    {onClose && (
                        <div style={{ position: 'absolute', left: 0 }}>
                            <IconButtonFechar onClick={onClose} />
                        </div>
                    )}
                    <Typography variant="h2" sx={{ textAlign: 'center', px: '3rem' }}>
                        {title}
                    </Typography>
                    <div style={{ position: 'absolute', right: 0 }}>
                        {(controls && controls.length > 0) && (
                            (isMobile || forceMenu) ? (
                                <>
                                    <IconButton onClick={(e) => handleClick(e)} color={'primary'}>
                                        <MenuRoundedIcon />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl as Element | null}
                                        open={open}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        sx={{
                                            '& .MuiList-root': {
                                                padding: '0',
                                            }
                                        }}
                                    >
                                        {controls.map((item: ButtonHeader, index: number) => (
                                            <MenuItem
                                                key={index}
                                                disabled={item.disabled}
                                                aria-label={item.label}
                                                onClick={item.action}
                                            >
                                                {item.label}
                                                {item?.icon && (
                                                    <ListItemButton>
                                                        {item.icon}
                                                    </ListItemButton>
                                                )}
                                            </MenuItem >
                                        ))}
                                    </Menu >
                                </>
                            ) : (
                                <Stack
                                    sx={{
                                        gap: '.5rem',
                                    }}
                                    direction={'column'}
                                    useFlexGap
                                >
                                    {controls.map((item: ButtonHeader, index: number) => <div key={index}>
                                        <Button
                                            disabled={item.disabled}
                                            variant={item.variant ?? 'containedFilled'}
                                            aria-label={item.label}
                                            onClick={item.action}
                                        >
                                            {item.label}
                                        </Button>
                                    </div>)}
                                </Stack>
                            )
                        )}
                    </div >
                </Box >
            </Box >
            <Divider />
        </>
    )
}

export default HeaderModal