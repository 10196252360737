import { call, put, takeLatest } from "redux-saga/effects";
import { api } from "../../services/api";
import { ApiResponse, DefaultDTO } from "../../dtos/DefaultDTO";
import {
  excluirDespachoAutomaticoFailure,
  excluirDespachoAutomaticoStart,
  excluirDespachoAutomaticoSuccess,
  excluirEmpresaDAFailure,
  excluirEmpresaDAStart,
  excluirEmpresaDASuccess,
  excluirFormaDePagamentoDAFailure,
  excluirFormaDePagamentoDAStart,
  excluirFormaDePagamentoDASuccess,
  excluirTipoDeVeiculoDAFailure,
  excluirTipoDeVeiculoDAStart,
  excluirTipoDeVeiculoDASuccess,
  listarDaFailure,
  listarDaStart,
  listarDaSuccess,
  listarEmpresasDAFailure,
  listarEmpresasDAStart,
  listarEmpresasDASuccess,
  listarFormasDePagamentoDAFailure,
  listarFormasDePagamentoDAStart,
  listarFormasDePagamentoDASuccess,
  listarTiposDeVeiculoDAFailure,
  listarTiposDeVeiculoDAStart,
  listarTiposDeVeiculoDASuccess,
  paginaFailure,
  paginaStart,
  paginaSuccess,
  ParamsDA,
  salvarDespachoAutomaticoFailure,
  salvarDespachoAutomaticoStart,
  salvarDespachoAutomaticoSuccess,
  salvarEmpresaDAFailure,
  salvarEmpresaDAStart,
  salvarEmpresaDASuccess,
  salvarFormaDePagamentoDAFailure,
  salvarFormaDePagamentoDAStart,
  salvarFormaDePagamentoDASuccess,
  salvarTipoDeVeiculoDAFailure,
  salvarTipoDeVeiculoDAStart,
  salvarTipoDeVeiculoDASuccess,
} from "../reducers/despachoAutomatico";
import { DADTOS } from "src/dtos/DADTOS";

function* listarDespachoAutomatico({ payload }: { payload: ParamsDA }) {
  console.log(payload);
  const form = new FormData();

  const typedPayload: Record<string, any> = payload;

  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key]));
  });

  try {
    const response: ApiResponse<DADTOS> = yield call(
      api.post,
      "/TabelaDespachoAutomatico/Listar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(listarDaSuccess(response.data));
    } else {
      yield put(listarDaFailure(response.data.mensagem));
    }
  } catch (error) {
    yield put(listarDaFailure(`${error}}`));
    console.error(error);
  }
}

function* salvarDespachoAutomatico({ payload }: { payload: any }) {
  const form = new FormData();

  const typedPayload: Record<string, any> = payload;

  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key]));
  });

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomatico/Salvar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(salvarDespachoAutomaticoSuccess(response.data));
      yield put(
        listarDaStart({
          pagina: 1,
          paginacao: -1,
        })
      );
    } else {
      yield put(salvarDespachoAutomaticoFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* excluirDespachoAutomatico({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomatico/Excluir?id=" + payload
    );
    if (response.data.sucesso === true) {
      yield put(excluirDespachoAutomaticoSuccess(response.data));
      yield put(
        listarDaStart({
          pagina: 1,
          paginacao: -1,
        })
      );
    } else {
      yield put(excluirDespachoAutomaticoFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* listarEmpresasDA({ payload }: { payload: any }) {
  const form = new FormData();

  const typedPayload: Record<string, any> = payload;

  Object.keys(typedPayload).forEach((key) => {
    form.append(key, String(typedPayload[key]));
  });

  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoEmpresa/Listar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(listarEmpresasDASuccess(response.data));
    } else {
      yield put(listarEmpresasDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* salvarEmpresaDA({ payload }: { payload: any }) {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    form.append(key, String(payload[key]));
  });
  try {
    const response: ApiResponse<DADTOS> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoEmpresa/Salvar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(salvarEmpresaDASuccess(response.data));
      yield put(
        listarEmpresasDAStart({
          pagina: 1,
          paginacao: -1,
          filtros: `tabelaDespachoAutomaticoID =="${payload?.tabelaDespachoAutomaticoID}"`,
        })
      );
    } else {
      yield put(salvarEmpresaDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* excluirEmpresaDa({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoEmpresa/Excluir?id=" + payload.id
    );
    if (response.data.sucesso === true) {
      yield put(excluirEmpresaDASuccess(response.data));
      yield put(
        listarEmpresasDAStart({
          pagina: 1,
          paginacao: -1,
          filtros: `tabelaDespachoAutomaticoID == "${payload?.tabelaDespachoAutomaticoID}"`,
        })
      );
    } else {
      yield put(excluirEmpresaDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* listarTiposDeVeiculoDA({ payload }: { payload: any }) {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    form.append(key, String(payload[key]));
  });
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculo/Listar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(listarTiposDeVeiculoDASuccess(response.data));
    } else {
      yield put(listarTiposDeVeiculoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* salvarTipoDeVeiculoDA({ payload }: { payload: any }) {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    form.append(key, String(payload[key]));
  });
  try {
    const response: ApiResponse<any> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculo/Salvar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(salvarTipoDeVeiculoDASuccess(response.data));
    } else {
      yield put(salvarTipoDeVeiculoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* excluirTipoDeVeiculoDA({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculo/Excluir?id=" + payload
    );
    if (response.data.sucesso === true) {
      yield put(excluirTipoDeVeiculoDASuccess(response.data));
    } else {
      yield put(excluirTipoDeVeiculoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* listarFormasDePagamentoDA({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculoFormaPagamento/Listar",
      payload
    );
    if (response.data.sucesso === true) {
      yield put(listarFormasDePagamentoDASuccess(response.data));
    } else {
      yield put(listarFormasDePagamentoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* salvarFormasDePagamentoDA({ payload }: { payload: any }) {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    form.append(key, String(payload[key]));
  });
  try {
    const response: ApiResponse<any> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculoFormaPagamento/Salvar",
      form
    );
    if (response.data.sucesso === true) {
      yield put(salvarFormaDePagamentoDASuccess(response.data));
      yield put(
        listarFormasDePagamentoDAStart({
          filtros: `tabelaDespachoAutomaticoTipoVeiculoID == \"${payload.tabelaDespachoAutomaticoTipoVeiculoID}\"`,
          pagina: 1,
          paginacao: 20,
        })
      );
    } else {
      yield put(salvarFormaDePagamentoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* excluirFormaDePagamentoDA({ payload }: { payload: any }) {
  try {
    const response: ApiResponse<DefaultDTO<any>> = yield call(
      api.post,
      "/TabelaDespachoAutomaticoTipoVeiculoFormaPagamento/Excluir?id=" +
      payload.id
    );
    if (response.data.sucesso === true) {
      yield put(excluirFormaDePagamentoDASuccess(response.data));
      yield put(
        listarFormasDePagamentoDAStart({
          filtros: `tabelaDespachoAutomaticoTipoVeiculoID == \"${payload.tabelaDespachoAutomaticoTipoVeiculoID}\"`,
          pagina: 1,
          paginacao: 20,
        })
      );
    } else {
      yield put(excluirFormaDePagamentoDAFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* pagina({ payload }: { payload: any }) {
  const form = new FormData();
  Object.keys(payload).forEach((key) => {
    form.append(key, String(payload[key]));
  });
  try {
    const response: ApiResponse<any> = yield call(
      api.post,
      "/TabelaDespachoAutomatico/Pagina",
      form
    );
    if (response.data.sucesso === true) {
      yield put(paginaSuccess(response.data));
    } else {
      yield put(paginaFailure(response.data.mensagem));
    }
  } catch (error) {
    console.error(error);
  }
}

function* despachoAutomaticoSaga() {
  yield takeLatest(listarDaStart, listarDespachoAutomatico);
  yield takeLatest(salvarDespachoAutomaticoStart, salvarDespachoAutomatico);
  yield takeLatest(excluirDespachoAutomaticoStart, excluirDespachoAutomatico);
  yield takeLatest(salvarEmpresaDAStart, salvarEmpresaDA);
  yield takeLatest(listarEmpresasDAStart, listarEmpresasDA);
  yield takeLatest(excluirEmpresaDAStart, excluirEmpresaDa);
  yield takeLatest(listarTiposDeVeiculoDAStart, listarTiposDeVeiculoDA);
  yield takeLatest(salvarTipoDeVeiculoDAStart, salvarTipoDeVeiculoDA);
  yield takeLatest(excluirTipoDeVeiculoDAStart, excluirTipoDeVeiculoDA);
  yield takeLatest(listarFormasDePagamentoDAStart, listarFormasDePagamentoDA);
  yield takeLatest(salvarFormaDePagamentoDAStart, salvarFormasDePagamentoDA);
  yield takeLatest(excluirFormaDePagamentoDAStart, excluirFormaDePagamentoDA);
  yield takeLatest(paginaStart, pagina);
}

export default despachoAutomaticoSaga;
