import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { cleanInput, telefone } from 'src/ds/common';
import { AutocompleteEmpresas, BoxLoading, ContainerLoading, MapWithMarker, SelectCidade, SelectEstado, maskCpf, validateCPF } from 'src/ds';
import { salvarClienteStart } from 'src/store/reducers/cliente';
import { salvarUsuarioReq } from 'src/store/reducers/config';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { limparSalvarCliente, salvarClienteEmpresaStart } from 'src/store/reducers/empresa';

type Empresa = {
    nome: string;
    nomeExterno: string;
};

type Endereco = {
    enderecoID: string;
    nome: string;
    empresaID: string;
    logradouro: string;
    complemento: string | null;
    pontoReferencia: string | null;
    bairro: string;
    CEP: string | null;
    cidadeID: number;
    numero: string | null;
    numeroCasa: string | null;
    condominio: string | null;
    edificio: string | null;
    principal: boolean;
    latitude: number;
    longitude: number;
    dataCadastro: string;
    estadoID: number;
    cidadeNome: string;
    estadoNome: string;
    enderecoStr: string;
};

type FormData = {
    empresaClienteID: string;
    empresaID: string;
    empresa: Empresa;
    dataCadastro: string;
    nome: string;
    telefone: string;
    cpf: string;
    email: string | null;
    endereco: Endereco;
    perfilArquivo?: File | null;
    tagsSelecionadas?: string[];
    tagsPermissaoSelecionadas?: string[];
};


type DadosBasicosParams = {
    id: string | undefined;
};

const DadosBasicos = ({ id }: DadosBasicosParams) => {
    const dispatch = useDispatch();
    const cliente = useSelector((state: any) => state.clientes?.pagina?.data?.modelo);
    const clienteLoading = useSelector((state: any) => state.clientes?.pagina?.loading);
    const resposta = useSelector((state: any) => state.empresas?.salvarCliente?.data);
    const [loading, setLoading] = useState(true);
    const { showSuccess, showError } = useSnackbarContext();

    useEffect(() => {
        console.log(resposta);
        if (resposta?.sucesso === true) {
            showSuccess({ message: resposta?.mensagem});
        } else if (resposta?.sucesso === false) {
            showError({ message: resposta?.mensagem });
        }
        dispatch(limparSalvarCliente());
    }, [resposta]);

    const hookForm = useForm<FormData>({
        defaultValues: {
            empresaClienteID: id,
            empresaID: '',
            telefone: '',
            nome: '',
            email: '',
            cpf: '',
            endereco: {
                enderecoID: '',
                CEP: '',
                logradouro: '',
                numero: '',
                complemento: '',
                cidadeNome: '',
                estadoNome: '',
                latitude: 0,
                longitude: 0,
                bairro: '',
                pontoReferencia: '',
                cidadeID: 0,
                estadoID: 0,
            }
        }
    });

    const { register, control, handleSubmit, setValue, watch, formState: { errors } } = hookForm;

    const onSubmit = (data: any) => {
        if (!validateCPF(data.cpf)) {
            hookForm.setError('cpf', { message: 'CPF inválido' });
            return;
        }

        const endereco = data.endereco || {};
        const cleanedData = {
            ...data,
            telefone: cleanInput(data.telefone),
            cpf: cleanInput(data.cpf),
            endereco: {
                ...endereco,
                CEP: cleanInput(endereco.CEP || ''),
            },
        };

        const cleanNullValues = (obj: Record<string, any>) => {
            return Object.entries(obj).reduce((acc, [key, value]) => {
                if (value !== null && value !== undefined && value !== '') {
                    acc[key] = value;
                }
                return acc;
            }, {} as Record<string, any>);
        };

        const cleanedEndereco = cleanNullValues(cleanedData.endereco);
        const cleanedMainData = cleanNullValues(cleanedData);

        const formData = new FormData();

        Object.keys(cleanedEndereco).forEach((endKey) => {
            formData.append(`endereco.${endKey}`, cleanedEndereco[endKey]);
        });

        Object.keys(cleanedMainData).forEach((key) => {
            if (key !== 'endereco') {
                formData.append(key, cleanedMainData[key]);
            }
        });

        //@ts-ignore
        dispatch(salvarClienteEmpresaStart(formData));
    };


    useEffect(() => {
        if (cliente) {
            Object.keys(cliente).forEach((key: any) => {
                if (key === 'CEP' || key === 'logradouro' || key === 'numero' || key === 'complemento' || key === 'pontoReferencia' || key === 'estadoID' || key === 'cidadeID' || key === 'bairro') {
                    //@ts-ignore
                    setValue(`endereco.${key}`, cliente[key as keyof typeof cliente]);
                } else {
                    setValue(key as keyof FormData, cliente[key as keyof typeof cliente]);
                }
            });
            setLoading(false);
        }

    }, [cliente]);

    if (clienteLoading) return <ContainerLoading sx={{ height: '70vh' }}  />
    return (
        <Box
            component={'form'}
            onSubmit={handleSubmit(onSubmit)}
            sx={(theme) => ({ display: 'flex', gap: 2, flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper })}
        >
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Dados do usuário</Typography>
                    <AutocompleteEmpresas
                        label='Empresas'
                        hookForm={{
                            name: 'empresaID',
                            methods: hookForm,
                        }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Telefone"
                        value={telefone(watch("telefone") || "")}
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("telefone") || "").trim() !== '',
                            },
                            htmlInput: {
                                maxLength: 15
                            }

                        }}
                        {...register('telefone', { required: 'Telefone é obrigatório' })}
                        error={!!errors.telefone}
                        helperText={errors.telefone?.message}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Nome"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("nome") || "").trim() !== '',
                            }
                        }}
                        {...register('nome', { required: 'Nome é obrigatório' })}
                        error={!!errors.nome}
                        helperText={errors.nome?.message}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="CPF"
                        value={maskCpf(watch("cpf") || "")}
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("cpf") || "").trim() !== '',
                            }
                        }}
                        {...register('cpf', { required: 'CPF é obrigatório' })}
                        error={!!errors.cpf}
                        helperText={errors.cpf?.message}
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        size='small'
                        {...register('email', { required: 'Email obrigatório', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Email inválido' } })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{ marginBottom: '1rem' }}
                    />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography variant="subtitle1">Endereço</Typography>
                    <TextField
                        fullWidth
                        size="small"
                        label="CEP"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.CEP") || "").trim() !== '',
                            }
                        }}
                        {...register('endereco.CEP', { required: 'CEP é obrigatório' })}
                        error={!!errors.endereco?.CEP}
                        helperText={errors.endereco?.CEP?.message}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Logradouro"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.logradouro") || "").trim() !== '',
                            }
                        }}
                        {...register('endereco.logradouro', { required: 'Logradouro é obrigatório' })}
                        error={!!errors.endereco?.logradouro}
                        helperText={errors.endereco?.logradouro?.message}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Número"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.numero") || "").trim() !== '',
                            }
                        }}
                        {...register('endereco.numero', { required: 'Número é obrigatório' })}
                        error={!!errors.endereco?.numero}
                        helperText={errors.endereco?.numero?.message}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Complemento"
                        {...register('endereco.complemento')}
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.complemento") || "")?.trim() !== '',
                            }
                        }}
                    />
                    <TextField
                        fullWidth size="small"
                        label="Ponto de referência"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.pontoReferencia") || "").trim() !== '',
                            }
                        }}
                        {...register('endereco.pontoReferencia')}
                    />
                    <SelectEstado hookForm={{ methods: hookForm, name: 'endereco.estadoID' }} />
                    <SelectCidade hookForm={{ methods: hookForm, name: 'endereco.cidadeID', estadoName: 'endereco.estadoID' }} />
                    <TextField
                        fullWidth
                        size="small"
                        label="Bairro"
                        slotProps={{
                            inputLabel: {
                                shrink: (watch("endereco.bairro") || "").trim() !== '',
                            }
                        }}
                        {...register('endereco.bairro', { required: 'Bairro é obrigatório' })}
                        error={!!errors.endereco?.bairro}
                        helperText={errors.endereco?.bairro?.message}
                    />
                    <MapWithMarker
                        position={{ latitude: watch('endereco.latitude'), longitude: watch('endereco.longitude') }}
                        setPosition={(data) => {
                            setValue('endereco.latitude', data?.latitude ?? 0, { shouldDirty: true });
                            setValue('endereco.longitude', data?.longitude ?? 0, { shouldDirty: true });
                        }}
                    />
                </Box>
            </Box>
            <Button variant="containedFilled" color="primary" type="submit" sx={{ width: 'fit-content', alignSelf: 'flex-end' }}>
                Salvar
            </Button>
        </Box>
    );
};

export default DadosBasicos;
