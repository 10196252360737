import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/reducers'
import TabelaTaxaExtra from '../../components/TaxaExtra/TabelaTaxaExtra'
import { DynamicDataContainer } from 'src/ds'
import { useNavigate } from 'react-router-dom'
import { DynamicDataContainerRef } from 'src/ds/components/DynamicDataContainer'

const TaxaExtra = () => {
    const taxaExtra = useSelector((state: RootState) => state.taxaExtra.lista)

    const hookForm = useForm({
        defaultValues: {
            paginacao: 20,
            pagina: 1,
        }
    })

    const cardRef = useRef<DynamicDataContainerRef>(null)

    const navigate = useNavigate();

    return (
        <DynamicDataContainer
            hookForm={hookForm}
            ref={cardRef}
            cardFormFiltrosProps={{
                fields: [
                    { label: 'Busca Por Nome', name: 'nome', type: 'input', ehFiltro: true, tipoFiltro: 'TEXTO' },
                ],
                dispatchMethods: { actionName: 'taxaExtraStart' },
                loading: taxaExtra.loading,
            }}
            sections={[{
                title: 'Taxa Extra',
                label: 'Taxa Extra',
                tableNavigation: {
                    indiceInicial: taxaExtra?.data?.dados?.indiceInicial,
                    indiceFinal: taxaExtra?.data?.dados?.indiceFinal,
                    indiceTotal: taxaExtra?.data?.dados?.total,
                    ultimaPagina: taxaExtra?.data?.dados?.ultimaPagina,
                },
                extraButtons: [{
                    label: 'Inserir',
                    action() {
                        navigate('/TaxaExtra/Index/Cadastro')
                    }
                }],
                component: <TabelaTaxaExtra
                    data={taxaExtra.data?.dados?.lista}
                    loading={taxaExtra.loading}
                    fezRequisicao={cardRef.current?.fezRequisicao}
                    atualizar={cardRef.current?.onSubmit}
                />
            }]}
        />
    )
}

export default TaxaExtra