import { Alert, Button, Grid2, Stack, TextField, Tooltip, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { AutocompleteEmpresas, formatTime, ListCardTabelaTotal } from 'src/ds'
import timer from "../../../assets/icons/time.svg";
import userPlus from "../../../assets/icons/user-plus-solid.svg";
import routeSolid from "../../../assets/icons/route-solid.svg";
import circleCheck from "../../../assets/icons/circle-check-solid.svg";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { listarPerformancePedidosStart } from 'src/store/reducers/pedidos';
import dayjs from 'dayjs';
import { editarEmpresaClear, editarEmpresaStart, paginaEmpresaStart } from 'src/store/reducers/empresa';
import { useForm } from 'react-hook-form';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { Empresa } from 'src/dtos/EmpresasDTO';

const TabPedidos = () => {
    const theme = useTheme();
    const pedidosDados = useSelector((state: RootState) => state.pedidos.performancePedidos?.data?.dados);
    const dispatch = useDispatch();
    const empresaPagina = useSelector((state: RootState) => state.empresas?.paginaEmpresa?.data?.modelo);
    const empresasPick = useSelector((state: RootState) => state.config?.empresasPick?.dados?.lista);
    const resposta = useSelector((state: RootState) => state.empresas?.editarEmpresa?.data);
    const error = useSelector((state: RootState) => state.empresas?.editarEmpresa?.error);
    const snack = useSnackbarContext();
    const filteredBase = empresasPick?.filter((empresa: Empresa) => empresa.ehBase === false);

    useEffect(() => {
        if (resposta?.sucesso === true) {
            snack.showSuccess({
                message: `Tempo para pronto automático definido como ${hookForm.getValues('tempoMarcarProntoAutomatico')}m na empresa ${empresaPagina?.nome}`,
                duration: 5000
            })
        } else if (error) {
            snack.showError({
                message: error,
            })
        }

        return () => {
            dispatch(editarEmpresaClear());
        }
    }, [resposta, error]);

    const hookForm = useForm({
        defaultValues: {
            empresaID: filteredBase[0]?.empresaID,
            tempoMarcarProntoAutomatico: empresaPagina?.tempoMarcarProntoAutomatico,
        }
    });

    useEffect(() => {
        if (!pedidosDados) {
            dispatch(
                listarPerformancePedidosStart({
                    paginacao: 20,
                    pagina: 1,
                    dtInicial: dayjs().subtract(1, 'hour').format('DD/MM/YYYY HH:mm'),
                    dtFinal: dayjs().format('DD/MM/YYYY HH:mm'),
                })
            );
        }
    }, [pedidosDados]);

    useEffect(() => {
        const empresaID = hookForm.getValues('empresaID');
        if (empresaID && !empresaPagina) {
            dispatch(
                paginaEmpresaStart({
                    id: empresaID,
                })
            );
        }
    }, [empresaPagina, hookForm]);

    const handleSubmit = () => {
        const payload = {
            empresaID: empresaPagina?.empresaID,
            tempoMarcarProntoAutomatico: hookForm.getValues('tempoMarcarProntoAutomatico')
        }
        dispatch(editarEmpresaStart(payload));
    };


    return (
        <Grid2 container spacing={2}
            direction="column"
        >
            <Stack spacing={2} >
                <AutocompleteEmpresas
                    hookForm={{ methods: hookForm, name: 'empresaID' }}
                    sx={{ width: '100%' }}
                    label='Empresa'
                    shouldDisappear
                />
                <TextField
                    label="Tempo de pronto automático (minutos)"
                    variant="outlined"
                    size="small"
                    value={hookForm.watch('tempoMarcarProntoAutomatico')}
                    onChange={(e) => hookForm.setValue('tempoMarcarProntoAutomatico', Number(e.target.value))}
                    type="number"
                />
                <Tooltip title="Caso queira utilizar o pronto automático recomendamos que coloque sempre um valor acima da sua média.">
                    <Button onClick={handleSubmit} variant="containedFilled" color="primary" size="large" sx={{ width: 'fit-content', alignSelf: 'flex-end' }}>Definir tempo para empresa</Button>
                </Tooltip>
            </Stack>
            <Alert severity="info" sx={{ alignItems: 'center' }}>
                Os dados abaixo são médias dos últimos 60 minutos
            </Alert>
            <ListCardTabelaTotal
                /* @ts-ignore */
                sx={{ '& > .MuiGrid2-root': { display: 'flex', justifyContent: 'center', alignItems: 'center' } }}
                size={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                fixo
                cardData={[
                    {
                        srcIcon: circleCheck,
                        altIcon: 'Ícone Tempo Médio do Pedido Pronto',
                        bgcolor: theme.palette.grey[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO PEDIDO PRONTO',
                            subtitle1: pedidosDados?.mediaTempoPedidoPronto ? formatTime(pedidosDados?.mediaTempoPedidoPronto) : '0'
                        }
                    },
                    {
                        srcIcon: userPlus,
                        altIcon: 'Ícone Tempo Médio do Despacho',
                        bgcolor: theme.palette.blue[600],
                        texts: {
                            title1: 'TEMPO MÉDIO DO DESPACHO',
                            subtitle1: pedidosDados?.tempoMedioDespacho ? formatTime(pedidosDados?.tempoMedioDespacho) : '0'
                        }
                    },
                    {
                        srcIcon: routeSolid,
                        altIcon: 'Tempo Médio Rota do Entregador',
                        bgcolor: theme.palette.yellow[600],
                        texts: {
                            title1: 'TEMPO MÉDIO ROTA DO ENTREGADOR',
                            subtitle1: pedidosDados?.mediaTempoRotaEntregador ? formatTime(pedidosDados?.mediaTempoRotaEntregador) : '0'
                        }
                    },
                    {
                        srcIcon: timer,
                        altIcon: 'Ícone Porcentagem Entregas Dentro da Estimativa (ETA)',
                        bgcolor: theme.palette.green[600],
                        texts: {
                            title1: 'PORCENTAGEM ENTREGAS DENTRO DA ESTIMATIVA (ETA)',
                            subtitle1: pedidosDados?.porcentagemNegativos ? `${pedidosDados?.porcentagemNegativos.toFixed(2)} %` : '0%'
                        }
                    },
                ]}
            />

        </Grid2>
    )
}

export default TabPedidos