import { Box, TextField, Button, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { HeaderModal, ModalBase, SelectToggleAtivo, TextFieldMoney } from "src/ds";
import { salvarTabelaStart } from "src/store/reducers/tabelaCobranca";
import { cleanInput } from "src/ds/common";

interface FormValues {
    nome: string;
    ativo: boolean;
    valorPontoAdicional: string;
}

const CadastrarTabela = ({ open, onClose, editar }: any) => {
    const hookForm = useForm<FormValues>({
        defaultValues: {
            nome: "",
            ativo: true,
            valorPontoAdicional: "0",
        },
    });
    const { register, handleSubmit, formState: { errors } } = hookForm

    const dispatch = useDispatch();
    const onSubmit = (data: FormValues) => {
        dispatch(salvarTabelaStart({
            ...data,
            valorPontoAdicional: cleanInput(data.valorPontoAdicional)
        }));
        onClose();
    };

    return (
        <ModalBase open={open} onClose={onClose}>
            <HeaderModal title="Cadastrar tabela" onClose={onClose} />
            <Box p={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                        <TextField
                            label="Nome"
                            fullWidth
                            size="small"
                            {...register("nome", { required: "Nome é obrigatório" })}
                            error={!!errors.nome}
                            helperText={errors.nome ? errors.nome.message : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextFieldMoney
                            hookForm={{
                                methods: hookForm,
                                name: "valorPontoAdicional",
                                defaultValue: "0",
                                rules: { required: "O valor por ponto adicional é obrigatório" }
                            }}
                            label="Valor por ponto adicional"
                        />
                        <SelectToggleAtivo
                            hookForm={{ methods: hookForm, name: "ativo" }}
                            label="Situação"
                            toggleValues={{ ativo: "true", inativo: "false" }}
                        />
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="contained" color="secondary" sx={{ width: "fit-content" }} onClick={() => {
                                hookForm.reset();
                                onClose();
                            }}>
                                Cancelar
                            </Button>
                            <Button variant="containedFilled" color="primary" sx={{ width: "fit-content" }} type="submit">
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Box>
        </ModalBase>
    );
};

export default CadastrarTabela;
