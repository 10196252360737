import React, { useEffect, useState } from 'react';
import { TextField, Chip, Autocomplete, SxProps, InputAdornment, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { pedidoTaxaExtraClear, pedidoTaxaExtraStart } from 'src/store/reducers/pedidos';
import { RootState } from 'src/store/reducers';
import { PedidoTaxaExtra } from 'src/dtos/PedidosDTO';
import { useSnackbarContext } from 'src/context/SnackbarContext';

interface TextFieldTaxasAplicadasProps {
    sx?: SxProps;
    size?: 'small' | 'medium';
    label?: string;
    chipColor?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    pedidoID?: string
}

const TextFieldTaxasAplicadas: React.FC<TextFieldTaxasAplicadasProps> = ({
    sx,
    size = 'small',
    label = 'Taxas extras aplicadas',
    chipColor = 'default',
    pedidoID
}) => {
    const dispatch = useDispatch();
    const [tagsTaxaExtra, setTagsTaxaExtra] = useState<PedidoTaxaExtra[]>([]);
    const [inputValue, setInputValue] = useState<string[]>([]);
    const taxasExtras = useSelector((state: RootState) => state.pedidos.pedidoTaxaExtra || []);
    const { showError } = useSnackbarContext();

    useEffect(() => {
        if (!pedidoID) return
        dispatch(pedidoTaxaExtraStart({
            pagina: 1,
            paginacao: -1,
            filtros: `pedidoID =="${pedidoID}"`,
        }));
    }, [pedidoID]);

    useEffect(() => {
        if (taxasExtras.data) {
            setTagsTaxaExtra(taxasExtras.data.dados?.lista || []);
        } else if (taxasExtras.error) {
            showError({
                message: taxasExtras.error,
            });
        }

        return () => {
            dispatch(pedidoTaxaExtraClear());
        }
    }, [taxasExtras.data, taxasExtras.error]);

    useEffect(() => {
        if (!tagsTaxaExtra) return;
        setInputValue(tagsTaxaExtra.map((tag) => tag.taxaExtra.nome));
    }, [tagsTaxaExtra]);



    return (
        <>
            <Autocomplete
                multiple
                freeSolo
                readOnly
                options={[]}
                size={size}
                sx={sx}
                value={inputValue}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                        <Chip
                            {...getTagProps({ index })}
                            key={option}
                            label={option}
                            size={size}
                            color={chipColor}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={tagsTaxaExtra.length === 0 ? 'Nenhuma taxa extra aplicada' : ""}
                        variant="outlined"
                        fullWidth
                        slotProps={taxasExtras.loading ? {
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CircularProgress size={20} />
                                    </InputAdornment>
                                ),
                            }
                        } : {}}
                    />
                )}
            />
        </>
    )
};

export default TextFieldTaxasAplicadas;
