import { Typography, useTheme, Grid, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tempoMedioRequest } from "../../../store/reducers/config";
import React, { useEffect, useState } from "react";
import { FlagOutlined, Timer } from "@mui/icons-material";
import { GridPainelLateral, HeaderCard } from "../../../ds";
import ContainerLoading from "src/ds/components/ContainerLoading";
import { RootState } from "src/store/reducers";
import TabKm from "./TabKm";
import TabPedidos from "./TabPedidos";

const TempoMedio = ({ close }: any) => {
    const dispatch = useDispatch();
    const devMode = useSelector((state: RootState) => state.utils?.devMode);
    const tempoMedio = useSelector((state: RootState) => state.config.tempoMedio);
    const loading = useSelector((state: RootState) => state.config.loading);

    const [activeTab, setActiveTab] = useState("km");

    useEffect(() => {
        dispatch(tempoMedioRequest());
    }, [dispatch]);

    const handleCloseClick = () => {
        close();
    };

    const handleTabChange = (event: React.MouseEvent<HTMLElement>, newTab: string) => {
        if (newTab) {
            setActiveTab(newTab);
        }
    };

    return (
        <GridPainelLateral container sx={{ padding: "1rem" }}>
            <ToggleButtonGroup
                size="small"
                value={activeTab}
                onChange={handleTabChange}
                exclusive
                sx={{
                    alignSelf: "flex-end",
                }}
            >
                {/* @ts-ignore */}
                <ToggleButton value="km" variant="plain">
                    Km
                </ToggleButton>
                {/* @ts-ignore */}
                <ToggleButton value="pedido" variant="plain">
                    Pedido
                </ToggleButton>
            </ToggleButtonGroup>

            <HeaderCard
                title={
                    activeTab === "km"
                        ? "Tempo médio por quilometragem"
                        : "Tempo médio por pedido"
                }
                onClick={handleCloseClick}
            />


            <>
                {activeTab === "km" ? (
                    <TabKm tempoMedio={tempoMedio} loading={loading} />
                ) : (
                    <TabPedidos />
                )}
            </>

            <div style={{ marginTop: "auto" }}></div>
        </GridPainelLateral>
    );
};

export default TempoMedio;
