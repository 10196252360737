const unSanitizeSituacao = (situacao?: string | null) => {
  switch (situacao) {
    case "Criado":
      return "CRIADO";
    case "Pronto":
      return "PRONTO";
    case "Despachado":
      return "DESPACHADO";
    case "Recebido":
      return "RECEBIDO";
    case "Em rota":
      return "EM_ROTA";
    case "Aceito":
      return "ACEITO";
    case "Na origem":
      return "NO_ESTABELECIMENTO";
    case "Retornando":
      return "RETORNANDO";
    case "Finalizado":
      return "FINALIZADO";
    case "Cancelado":
      return "CANCELADO";
    default:
      return (situacao)?.toString() || '';
  }
};

export default unSanitizeSituacao;
