import React from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText, FormLabel,
} from "@mui/material";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions } from "src/store/Actions";

import dayjs from "dayjs";
import { ButtonLoading, InputDate, ModalBase } from "src/ds";
import { formatCardNumber } from "src/ds/common";




interface ModalCadastrarCartaoProps {
    open: boolean;
    onClose: () => void;
    dispatchMethods: { actionName: keyof typeof actions };
    empresaID?: string;
    loading?: boolean;
}

const ModalCadastrarCartao: React.FC<ModalCadastrarCartaoProps> = ({
    open,
    onClose,
    dispatchMethods,
    empresaID,
    loading
}) => {
    const hookForm = useForm({
        defaultValues: {
            empresaID: '',
            numero: '',
            nome: '',
            mesValidade: '',
            anoValidade: '',
            cvv: '',
        }
    });

    const { control, handleSubmit, setValue, formState: { errors } } = hookForm;
    const dispatch = useDispatch();

    const onSubmit = (data: any) => {
        if (dispatchMethods?.actionName) {
            const actionCreator = actions[dispatchMethods.actionName];
            if (actionCreator) {
                dispatch(actionCreator({
                    ...data,
                    empresaID: empresaID,
                }));
            }
            onClose();
        }
    };

    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];


    return (
        <ModalBase size="sm" open={open} onClose={onClose} headerModal={{ title: 'Cartão de crédito' }}>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 2,
                    padding: 2,
                }}
            >
                <Controller
                    name="nome"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O nome no cartão é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            sx={{ flex: "1 1 100%" }}
                            label="Nome no Cartão"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            size="small"
                        />
                    )}
                />

                <Controller
                    name="numero"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O número do cartão é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            label="Número do Cartão"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            sx={{ flex: "300px 1 1" }}
                            slotProps={{
                                htmlInput: { maxLength: 19 },
                            }}
                            size="small"
                            onChange={e => {
                                field.onChange(formatCardNumber(e.target.value));
                            }}
                        />
                    )}
                />

                <Controller
                    name="cvv"
                    control={control}
                    defaultValue=""
                    rules={{ required: "O CVV é obrigatório" }}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            onChange={(e) => {
                                const value = e.target.value;
                                const cleanedValue = value.replace(/[^0-9]/g, '');
                                setValue("cvv", cleanedValue);
                            }}
                            label="CVV"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            sx={{ flex: "100px 1 1" }}
                            size="small"
                            slotProps={{
                                htmlInput: { maxLength: 3 },
                            }}
                        />
                    )}
                />

                <Stack direction="row" spacing={2} sx={{ flex: "1 1 100%" }}>
                    <Controller
                        name="mesValidade"
                        control={control}
                        defaultValue=""
                        rules={{ required: "O mês de validade é obrigatório" }}
                        render={({ field }) => (
                            <FormControl error={!!errors.mesValidade} sx={{ flex: "300px 1 1" }}>
                                <InputLabel size="small">Mês</InputLabel>
                                <Select
                                    {...field}
                                    label="Mês"
                                    size="small"
                                >
                                    <MenuItem value="">Selecione um mês</MenuItem>
                                    {months.map((month, index) => (
                                        <MenuItem key={index} value={index + 1}>{month}</MenuItem>
                                    ))}
                                </Select>
                                {errors?.mesValidade && <FormHelperText error={true}>{errors.mesValidade?.message}</FormHelperText>}
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="anoValidade"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "O ano de validade é obrigatório",
                            pattern: {
                                value: /^[0-9]{4}$/,
                                message: "Digite um ano válido (ex: 2024)",
                            },
                            validate: (value) => {
                                const currentYear = dayjs().year();
                                const maxYear = currentYear + 10;
                                const year = Number(value);

                                if (year < currentYear) {
                                    return `O ano deve ser ${currentYear} ou posterior`;
                                }
                                if (year > maxYear) {
                                    return `O ano deve ser no máximo ${maxYear}`;
                                }
                                return true;
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                {...field}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const cleanedValue = value.replace(/[^0-9]/g, '');
                                    setValue("anoValidade", cleanedValue);
                                }}
                                label="Ano"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                sx={{ flex: "300px 1 1" }}
                                size="small"
                                slotProps={{
                                    htmlInput: { maxLength: 4 },
                                }}
                            />
                        )}
                    />
                </Stack>

                <Stack direction="row" spacing={2} sx={{ flex: '1 1 100%', justifyContent: "flex-end" }}>
                    <Button variant="containedFilled" color="secondary" sx={{ width: "min-content" }} onClick={() => {
                        hookForm.reset();
                        onClose()
                    }}>
                        Cancelar
                    </Button>
                    <ButtonLoading
                        type="submit"
                        variant="containedFilled"
                        color="primary"
                        sx={{ width: "min-content" }}
                        loading={loading}
                    >
                        Cadastrar
                    </ButtonLoading>
                </Stack>
            </Box>
        </ModalBase>
    );
};

export default ModalCadastrarCartao;
