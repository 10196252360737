import { useCallback, useEffect, useRef } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, { Marker, Offset, Projection } from 'mapbox-gl'
import { Fab, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { createMarkerEntregadorElement, createMarkerPedidoElement, MAPBOX_API_KEY } from 'src/ds';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

interface Etapa {
    situacao: string;
    nEtapa: number;
    texto?: string | null;
}

interface MapRastreioProps {
    positionEmpresa?: { lat: number, lng: number } | null;
    positionCliente?: { lat: number, lng: number } | null;
    positionEntregador?: { lat: number, lng: number } | null;
    etapaAtual?: Etapa | null;
}

const MapRastreio = ({ positionEmpresa, positionCliente, positionEntregador, etapaAtual }: MapRastreioProps) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const markerCliente = useRef<Marker | null>(null);
    const markerEmpresa = useRef<Marker | null>(null);
    const markerEntregador = useRef<Marker | null>(null);
    const mapPedido = useRef<mapboxgl.Map | null>(null)
    const defaultPosition = { lat: -15.7801, lng: -47.9292 }
    const pedido = useSelector((state: RootState) => state.rastreio.pedido.data)
    const entregadorData = useSelector((state: RootState) => state.rastreio.entregadores?.data?.dados?.lista[0])
    const empresa = useSelector((state: RootState) => state.rastreio.empresa.data)
    const template = useSelector((state: RootState) => state.config?.template);

    useEffect(() => {
        mapboxgl.accessToken = MAPBOX_API_KEY;
        const map = new mapboxgl.Map({
            container: containerRef.current!,
            style: template?.mapa,
            center: [defaultPosition.lng, defaultPosition.lat],
            zoom: 14,
            minZoom: 6,
            pitch: 0,
            projection: "globe" as unknown as Projection,
            maxZoom: 16,
            attributionControl: false,
        });

        mapPedido.current = map;
    }, [])

    useEffect(() => {
        mapPedido.current?.setStyle(template?.mapa || 'mapbox://styles/pickngo-demo/clabx63al002z14qpcdwwsq40');
    }, [template])

    const createPopupContent = useCallback((content: string) => `
        <div style="text-align: left; min-width: 100px">
            ${content}
        </div>
    `, []);

    const addMarkerWithPopup = (
        markerRef: React.MutableRefObject<Marker | null>,
        position: { lat: number; lng: number } | null,
        color: string,
        content: string,
        isCustomMarker = false,
        type?: 'pedido' | 'entregador' | 'empresa'
    ) => {
        if (!position || !mapPedido.current) return

        // @ts-expect-error
        if (markerRef.current && mapPedido.current._markers.includes(markerRef.current)) {
            if (isCustomMarker) {
                markerRef.current.setLngLat([position.lng, position.lat]);
            } else {
                const markerElement = markerRef.current.getElement();
                const currentColor = markerElement.style.backgroundColor;
                if (currentColor !== color) {
                    markerRef.current.remove();
                    const marker = new mapboxgl.Marker({ color, })
                        .setLngLat([position.lng, position.lat])
                        .addTo(mapPedido.current);

                    const popup = new mapboxgl.Popup({ offset: [0, -40], className: 'my-class', anchor: 'bottom' })
                        .setHTML(createPopupContent(content))
                        .setMaxWidth("300px")

                    marker.setPopup(popup);
                    marker.getElement().addEventListener('click', () => marker.togglePopup());
                    markerRef.current = marker;
                } else {
                    markerRef.current.setLngLat([position.lng, position.lat]);
                    const popup = markerRef.current.getPopup();
                    popup.setHTML(createPopupContent(content));
                    markerRef.current.setPopup(popup);
                }
            }
        } else {
            let marker;
            let markerElement;
            if (isCustomMarker) {
                if (type === 'entregador') {
                    markerElement = createMarkerEntregadorElement(entregadorData)
                } else if (type === 'pedido') {
                    if (pedido?.dados?.lista[0]) markerElement = createMarkerPedidoElement(pedido?.dados?.lista[0], theme);
                }
                marker = new mapboxgl.Marker({ element: markerElement })
                    .setLngLat([position.lng, position.lat])
                    .addTo(mapPedido.current);
            } else {
                marker = new mapboxgl.Marker({ color })
                    .setLngLat([position.lng, position.lat])
                    .addTo(mapPedido.current);
            }

            const popup = new mapboxgl.Popup({ offset: [0, -40], className: 'my-class', anchor: 'bottom' })
                .setHTML(createPopupContent(content))
                .setMaxWidth("300px")
            marker.setPopup(popup);
            marker.getElement().addEventListener('click', () => marker.togglePopup());
            markerRef.current = marker
        }
    };

    useEffect(() => {
        if (positionCliente) {
            const popUpColor = etapaAtual?.situacao ? theme.palette.status[etapaAtual?.situacao] : theme.palette.grey[500]

            if (!markerCliente.current) {
                mapPedido.current?.setCenter([positionCliente.lng, positionCliente.lat])
            }
            const content = `
            <h3 style="margin: 0;">#${pedido?.dados?.lista[0]?.codigoQuatro}</h3>
                <p>${pedido?.dados?.lista[0]?.entregaEnderecoLogradouro}</p>
                <p style="background-color: ${popUpColor}; color: white; text-align: center; margin: 0;">
                    ${etapaAtual?.situacao}
                </p>
            `;
            addMarkerWithPopup(markerCliente, positionCliente, popUpColor, content, true, 'pedido');
        }
    }, [positionCliente, etapaAtual])

    useEffect(() => {
        const content = `<h3 style="margin: 0;">${entregadorData?.nome}</h3>`;
        addMarkerWithPopup(markerEntregador, positionEntregador ?? null, 'pink', content, true, 'entregador');
        if (!positionCliente) {
            markerEntregador.current?.remove();
        }
    }, [positionEntregador]);

    useEffect(() => {
        const content = `
            <h3 style="margin: 0;">${empresa?.dados?.lista[0]?.nome}</h3>
            <p>${empresa?.dados?.lista[0]?.tipoEmpresa?.nome}</p>
            <p style="margin: 0;">${empresa?.dados?.lista[0]?.enderecoStr}</p>
        `;
        addMarkerWithPopup(markerEmpresa, positionEmpresa ?? null, theme.palette.primary.main, content);
        if (!positionCliente) {
            const latitude = positionEmpresa?.lat;
            const longitude = positionEmpresa?.lng;
            if (latitude && longitude) mapPedido.current?.setCenter([longitude, latitude])
        }
    }, [positionEmpresa]);

    return (
        <>
            <div ref={containerRef} style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 0 }} />
            {!!(positionCliente?.lat && positionCliente?.lng) &&
                <Fab
                    color="primary"
                    sx={{
                        position: 'absolute',
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                    }}
                    onClick={() => {
                        if (mapPedido.current) {
                            mapPedido.current.flyTo({
                                center: [positionCliente.lng, positionCliente.lat],
                                zoom: 16,
                                speed: 1,
                            })
                        }
                    }}
                >
                    <LocationOnOutlinedIcon />
                </Fab>
            }
        </>
    )
}

export default MapRastreio
