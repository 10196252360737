import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import { listaClientesFailure, listaClientesStart, listaClientesSuccess, paginaClientesFailure, paginaClientesStart, paginaClientesSuccess, salvarClienteFailure, salvarClienteStart, salvarClienteSuccess } from '../reducers/cliente';

export function* listaClientes(action) {
        console.log(action);
    const form = new FormData();
    let empresaID = null;

    Object.keys(action.payload).forEach(key => {
        if (key === 'empresaID') {
            empresaID = action.payload[key];
        } else {
            form.append(key, action.payload[key]);
        }
    });


    const url = empresaID ? `/EmpresaCliente/ListarSql?empresaID=${empresaID}` : '/EmpresaCliente/ListarSql?empresaID=null';

    try {
        const response = yield call(api.post, url, form);
        if (response.data.sucesso !== true) {
            yield put(listaClientesFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listaClientesSuccess(response.data.dados));
    } catch (error) {
        yield put(listaClientesFailure(error.toString()));
    }
}

export function* paginaClientes(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/EmpresaCliente/Pagina', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(paginaClientesSuccess(response.data));
    } catch (error) {
        yield put(paginaClientesFailure(error.toString()));
    }
}

export function* salvarCliente(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/EmpresaCliente/Salvar', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(salvarClienteSuccess(response.data));
    } catch (error) {
        yield put(salvarClienteFailure(error.toString()));
    }
}

function* ListarClientes() {
    yield takeLatest(listaClientesStart.type, listaClientes);
    yield takeLatest(paginaClientesStart.type, paginaClientes);
    yield takeLatest(salvarClienteStart.type, salvarCliente);
}

export default ListarClientes;
