import { call, put, takeLatest } from "redux-saga/effects";
import { cadastrarCartaoFailure, cadastrarCartaoStart, cadastrarCartaoSuccess, comprarCreditosFailure, comprarCreditosRepresentanteFailure, comprarCreditosRepresentanteStart, comprarCreditosRepresentanteSuccess, comprarCreditosStart, comprarCreditosSuccess, consultarPagamentoFailure, consultarPagamentoStart, consultarPagamentoSuccess, disponiveisRepresentanteFailure, disponiveisRepresentanteStart, disponiveisRepresentanteSuccess, meusCartoesFailure, meusCartoesStart, meusCartoesSuccess, minhasTransacoesFailure, minhasTransacoesRepresentanteFailure, minhasTransacoesRepresentanteStart, minhasTransacoesRepresentanteSuccess, minhasTransacoesStart, minhasTransacoesSuccess, paginaFailure, paginaStart, paginaSuccess } from "../reducers/wallet";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiResponse, DefaultDTO } from "src/dtos/DefaultDTO";
import { api } from "src/services/api";
import { gerarQrCodeStart } from "../reducers/utils";

function* pagina() {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/Pick/PaginaAbastecerWallet"
        );
        if (response.data.sucesso === true) {
            yield put(paginaSuccess(response.data));
        } else {
            yield put(paginaFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* minhasTransacoes(action: PayloadAction<any>) {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/PickWallet/MinhasTransacoes",
            action.payload
        );
        if (response.data.sucesso === true) {
            yield put(minhasTransacoesSuccess(response.data));
        } else {
            yield put(minhasTransacoesFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* comprarCreditos(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            "/PickWallet/ComprarCreditos",
            form
        );
        if (response.data.sucesso === true) {
            yield put(comprarCreditosSuccess(response.data));
            yield put(gerarQrCodeStart(response.data.pixQrcode));
        } else {
            yield put(comprarCreditosFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* consultarPagamento(action: PayloadAction<any>) {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/PickWallet/Consultar?id=" + action.payload.id,
        );
        if (response.data.sucesso === true) {
            yield put(consultarPagamentoSuccess(response.data));
        } else {
            yield put(consultarPagamentoFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* minhasTransacoesRepresentante(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/EmpresaCredito/MinhasTransacoes",
            form
        );
        if (response.data.sucesso === true) {
            yield put(minhasTransacoesRepresentanteSuccess(response.data));
        } else {
            yield put(minhasTransacoesRepresentanteFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* comprarCreditosRepresentante(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            "/EmpresaCredito/ComprarPlano",
            form
        );
        if (response.data.sucesso === true) {
            yield put(comprarCreditosRepresentanteSuccess(response.data));
            yield put(gerarQrCodeStart(response.data.pixQrcode));
        } else {
            yield put(comprarCreditosRepresentanteFailure((response.data.mensagem)));
        }
    }
    catch (error) {
        console.error(error);
    }
}

function* disponiveisRepresentante(action: PayloadAction<any>) {
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/PlanoCredito/Disponiveis",
            action.payload
        );
        if (response.data.sucesso === true) {
            yield put(disponiveisRepresentanteSuccess(response.data));
        } else {
            yield put(disponiveisRepresentanteFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* listarMeusCartoes(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<DefaultDTO<any>> = yield call(
            api.post,
            "/EmpresaCartao/MeusCartoes",
            form
        );
        if (response.data.sucesso === true) {
            yield put(meusCartoesSuccess(response.data));
        } else {
            yield put(meusCartoesFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* cadastrarCartao(action: PayloadAction<any>) {
    const form = new FormData();
    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    })
    try {
        const response: ApiResponse<any> = yield call(
            api.post,
            `/EmpresaCartao/Salvar`,
            form
        );
        if (response.data.sucesso === true) {
            yield put(cadastrarCartaoSuccess(response.data));
        } else {
            yield put(cadastrarCartaoFailure((response.data.mensagem)));
        }
    } catch (error) {
        console.error(error);
    }
}

function* walletSaga() {
    yield takeLatest(paginaStart, pagina);
    yield takeLatest(minhasTransacoesStart, minhasTransacoes);
    yield takeLatest(comprarCreditosStart, comprarCreditos);
    yield takeLatest(consultarPagamentoStart, consultarPagamento);
    yield takeLatest(minhasTransacoesRepresentanteStart, minhasTransacoesRepresentante);
    yield takeLatest(comprarCreditosRepresentanteStart, comprarCreditosRepresentante);
    yield takeLatest(disponiveisRepresentanteStart, disponiveisRepresentante);
    yield takeLatest(meusCartoesStart, listarMeusCartoes);
    yield takeLatest(cadastrarCartaoStart, cadastrarCartao);
}

export default walletSaga;
