function formatTimeFromMinutes(minutes?: number | null): string {
    if (minutes == null || isNaN(minutes)) return "-";

    minutes = Math.max(0, Math.round(minutes));

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
        return `${hours}h ${remainingMinutes}m`.trim();
    } else {
        return `${remainingMinutes}m`;
    }
}

export default formatTimeFromMinutes;
