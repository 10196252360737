import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../services/api';
import {
    exportarTabelaEntregadores,
    listaEntregadoresFailure,
    listaEntregadoresStart,
    listaEntregadoresSuccess,
    financeiroPorEntregadorStart,
    financeiroPorEntregadorSuccess,
    financeiroPorEntregadorFailure,
    invalidarSessoesSuccess,
    invalidarSessoesFailure,
    invalidarSessoesStart,
    desbloquearSenhaStart,
    desbloquearSenhaFailure,
    desbloquearSenhaSuccess,
    salvarContatoStart,
    salvarContatoSuccess,
    salvarContatoFailure,
    dispararPushStart,
    dispararPushSuccess,
    dispararPushFailure,
    usuarioPaginaStart,
    usuarioPaginaSuccess,
    usuarioPaginaFailure,
    listarPerformanceEntregadorSuccess,
    listarPerformanceEntregadorFailure,
    listarPerformanceEntregadorStart,
    exportarPerformanceEntregadorFailure,
    exportarPerformanceEntregadorSuccess,
    exportarPerformanceEntregadorStart,
    exportarFinanceiroEntregadorFailure,
    exportarFinanceiroEntregadorSuccess,
    exportarFinanceiroEntregadorStart,
    pontuacaoStart,
    pontuacaoSuccess,
    pontuacaoFailure,
    pontuacaoSalvarStart,
    pontuacaoSalvarSuccess,
    pontuacaoSalvarFailure,
    pontuacaoExcluirStart,
    pontuacaoExcluirSuccess,
    pontuacaoExcluirFailure,
    pontuacaoPaginaStart,
    pontuacaoPaginaFailure,
    pontuacaoPaginaSuccess,
} from '../reducers/entregador';
import { listarEntregadoresFailure } from '../reducers/config';

export function* listaEntregadores(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/ListarEntregadoresSql', form);
        if (response.data.sucesso !== true) {
            yield put(listarEntregadoresFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(listaEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listaEntregadoresFailure(error.toString()));
    }
}

export function* exportarEntregadores(action) {
    try {
        const queryParams = [
            { key: 'paginacao', value: action.payload.paginacao || -1 },
            { key: 'pagina', value: action.payload.pagina || 1 },
            { key: 'ordenacaoCampo', value: action.payload.ordenacaoCampo || '' },
            { key: 'ordenacaoOrdem', value: action.payload.ordenacaoOrdem || '' },
            {
                key: 'filtros',
                value: encodeURIComponent(
                    action.payload.filtros || '(entregador || dataEntregadorSolicitacao != null)'
                ),
            },
            {
                key: 'titulos',
                value: encodeURIComponent(
                    action.payload.titulos ||
                    'Nome,Email,Telefone,CPF,Habilitado como entregador,Data de resposta,Data de nascimento,Cidade,Bairro,CEP,CNH,Chave PIX,Veículo,Data de solicitação,Situação,Avaliação,Pontuação,Crédito'
                ),
            },
            {
                key: 'variaveis',
                value: encodeURIComponent(
                    action.payload.variaveis ||
                    'nome,email,telefone,cpf,entregadorStr,dataEntregadorResposta,dataNascimento,nomeCidade,nomeBairro,CEP,cnh,chavePix,nomeTipoVeiculo,dataEntregadorSolicitacao,situacaoStr,notaMedia,pontuacaoTotal,creditoPreAprovado'
                ),
            },
            { key: 'formato', value: action.payload.formato || 'csv' },
        ];

        // Adicionar outros parâmetros dinâmicos
        Object.keys(action.payload).forEach((key) => {
            if (
                ![
                    'paginacao',
                    'pagina',
                    'ordenacaoCampo',
                    'ordenacaoOrdem',
                    'filtros',
                    'titulos',
                    'variaveis',
                    'formato',
                ].includes(key) &&
                action.payload[key] !== '' &&
                action.payload[key] !== null &&
                action.payload[key] !== undefined
            ) {
                queryParams.push({ key, value: encodeURIComponent(action.payload[key]) });
            }
        });

        // Filtrar parâmetros vazios e construir a query string
        const filteredQueryParams = queryParams
            .filter(param => param.value !== '' && param.value !== null && param.value !== undefined)
            .map(param => `${param.key}=${param.value}`);

        const queryString = filteredQueryParams.join('&');

        const response = yield call(api.get, `/Usuario/ListarEntregadoresSql?${queryString}`, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Entregadores.csv');
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(listarEntregadoresFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(listaEntregadoresSuccess(response.data));
    } catch (error) {
        yield put(listaEntregadoresFailure(error.toString()));
    }
}


export function* financeiroPorEntregador(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        if (key === 'dataInicio' || key === 'dataFim' || key === 'empresaID' || key === 'buscar') return
        form.append(key, action.payload[key]);
    });

    // form.append('filtros',' dataFinalizado >= DateTime(2024,09,01,14,58,00) && dataFinalizado <= DateTime(2024,09,18,14,58,00)');

    try {
        const response = yield call(api.post, `/Pedido/ListarFinanceiroPorEntregadorPorPeriodoSql?inputDataInicial=${action.payload?.dataInicio ?? ''}&inputDataFinal=${action.payload?.dataFim ?? ''}&empresaID=${action.payload?.empresaID ?? ''}&inputBuscar=${action.payload?.buscar ?? ''}`, form);
        if (response.data.sucesso !== true) {
            yield put(financeiroPorEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }
        yield put(financeiroPorEntregadorSuccess(response.data));
    } catch (error) {
        yield put(financeiroPorEntregadorFailure(error.toString()));
    }
}

export function* invalidarSessoes(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'Usuario/InvalidarSessoes', form);
        if (response.data.sucesso !== true) {
            yield put(invalidarSessoesFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(invalidarSessoesSuccess(response.data));
    } catch (error) {
        yield put(invalidarSessoesFailure(error.toString()));
    }
}

function* desbloquearSenha(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'Usuario/DesbloquearSenha', form);
        if (response.data.sucesso !== true) {
            yield put(desbloquearSenhaFailure(response.data));
            throw new Error(response.data.erro);
        }
        yield put(desbloquearSenhaSuccess(response.data));
    } catch (error) {
        yield put(desbloquearSenhaFailure(error.toString()));
    }
}

export function* salvarContato(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, 'UsuarioContato/Salvar', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }

        yield put(salvarContatoSuccess(response.data));
    } catch (error) {
        yield put(salvarContatoFailure(error.toString()));
    }
}

export function* dispararPush(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/DispararPush', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }

        yield put(dispararPushSuccess(response.data));
    } catch (error) {
        yield put(dispararPushFailure(error.toString()));
    }
}

export function* usuarioPagina(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Usuario/Pagina', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(usuarioPaginaSuccess(response.data));
    } catch (error) {
        yield put(usuarioPaginaFailure(error.toString()));
    }
}

export function* listarPerformanceEntregador(action) {
    const form = new FormData();

    Object.keys(action.payload).forEach(key => {
        form.append(key, action.payload[key]);
    });

    try {
        const response = yield call(api.post, '/Pedido/TotalPedidosPorUsuario', form);
        if (response.data.sucesso !== true) {
            throw new Error(response.data.erro);
        }
        yield put(listarPerformanceEntregadorSuccess(response.data));
    } catch (error) {
        yield put(listarPerformanceEntregadorFailure(error.toString()));
    }
}

export function* exportarPerformanceEntregador(action) {
    try {
        const queryParams = [
            { key: 'paginacao', value: action.payload.paginacao || -1 },
            { key: 'pagina', value: action.payload.pagina || 1 },
            { key: 'ordenacaoCampo', value: action.payload.ordenacaoCampo || 'nomeUsuario' },
            { key: 'ordenacaoOrdem', value: action.payload.ordenacaoOrdem || 'asc' },
            { key: 'filtros', value: action.payload.filtros ? encodeURIComponent(action.payload.filtros) : '' },
            {
                key: 'titulos',
                value: encodeURIComponent(
                    action.payload.titulos ||
                    'Entregador,Qtd. Pedidos Aceitos,Qtd. Recusados Manualmente,Qtd. Recusados Automaticamente,Qtd. Pontuação,Tempo online'
                ),
            },
            {
                key: 'variaveis',
                value: encodeURIComponent(
                    action.payload.variaveis ||
                    'nomeUsuario,totalPedidosAceitos,totalPedidosRecusadosManual,totalPedidosRecusadosAutomatico,totalPontos,tempoOnline'
                ),
            },
            { key: 'formato', value: action.payload.formato || 'csv' },
            { key: 'dtInicial', value: action.payload.dtInicial ? encodeURIComponent(action.payload.dtInicial) : '' },
            { key: 'dtFinal', value: action.payload.dtFinal ? encodeURIComponent(action.payload.dtFinal) : '' },
            { key: 'usuarioID', value: action.payload.usuarioID ? encodeURIComponent(action.payload.usuarioID) : '' },
        ];

        // Filtrar parâmetros vazios
        const filteredQueryParams = queryParams
            .filter(param => param.value !== '' && param.value !== null && param.value !== undefined)
            .map(param => `${param.key}=${param.value}`);

        const queryString = filteredQueryParams.join('&');

        const response = yield call(api.get, `/Pedido/TotalPedidosPorUsuario?${queryString}`, {
            responseType: 'blob',
        });

        // Cria o arquivo CSV para download
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Performance de entregadores.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarPerformanceEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarPerformanceEntregadorSuccess(response.data));
    } catch (error) {
        yield put(exportarPerformanceEntregadorFailure(error.toString()));
    }
}


export function* exportarFinanceiroEntregador(action) {
    try {
        const queryParams = [
            { key: 'inputDataInicial', value: action.payload.dtInicial ? encodeURIComponent(action.payload.dtInicial) : '' },
            { key: 'inputDataFinal', value: action.payload.dtFinal ? encodeURIComponent(action.payload.dtFinal) : '' },
            { key: 'empresaID', value: action.payload.empresaID ? encodeURIComponent(action.payload.empresaID) : '' },
            { key: 'inputBuscar', value: action.payload.inputBuscar ? encodeURIComponent(action.payload.inputBuscar) : '' },
            { key: 'paginacao', value: action.payload.paginacao || -1 },
            { key: 'pagina', value: action.payload.pagina || 1 },
            { key: 'ordenacaoCampo', value: action.payload.ordenacaoCampo || '' },
            { key: 'ordenacaoOrdem', value: action.payload.ordenacaoOrdem || '' },
            { key: 'filtros', value: action.payload.filtros ? encodeURIComponent(action.payload.filtros) : '' },
            {
                key: 'titulos',
                value: encodeURIComponent(
                    action.payload.titulos ||
                    'Entregador,CPF,Telefone,E-mail,Chave PIX,Qtd. pedidos,Valor realizado,Valor total já antecipado,Valor total,Valor total programada'
                ),
            },
            {
                key: 'variaveis',
                value: encodeURIComponent(
                    action.payload.variaveis ||
                    'nome,cpf,telefone,email,chavePix,qtdPedidos,taxaTotalEntregador,valorAntecipado,valorTotal,taxaTotalEntregadorProgramada'
                ),
            },
            { key: 'formato', value: action.payload.formato || 'csv' },
        ];

        // Filtrar parâmetros vazios
        const filteredQueryParams = queryParams
            .filter(param => param.value !== '' && param.value !== null && param.value !== undefined)
            .map(param => `${param.key}=${param.value}`);

        const queryString = filteredQueryParams.join('&');

        const response = yield call(
            api.get,
            `/Pedido/ListarFinanceiroPorEntregadorPorPeriodoSql?${queryString}`,
            { responseType: 'blob' }
        );

        // Cria o arquivo CSV para download
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Financeiro por entregador por período.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (response.data.sucesso !== true) {
            yield put(exportarFinanceiroEntregadorFailure(response.data.erro));
            throw new Error(response.data.erro);
        }

        yield put(exportarFinanceiroEntregadorSuccess(response.data));
    } catch (error) {
        yield put(exportarFinanceiroEntregadorFailure(error.toString()));
    }
}


function* pontuacao(action) {
    try {
        const formData = new FormData();

        Object.keys(action.payload).forEach((key) => {
            formData.append(key, action.payload[key]);
        });

        const response = yield call(api.post, `/Pontuacao/Listar`, formData);

        if (response.data.sucesso !== true) {
            yield put(pontuacaoFailure(response.data.erro));
        }

        yield put(pontuacaoSuccess(response.data));
    } catch (error) {
        yield put(pontuacaoFailure(error.toString()));
    }
}

function* pontuacaoSalvar(action) {
    try {
        const formData = new FormData();

        Object.keys(action.payload).forEach((key) => {
            formData.append(key, action.payload[key]);
        });

        const response = yield call(api.post, '/Pontuacao/Salvar', formData);

        if (response.data.sucesso !== true) {
            yield put(pontuacaoSalvarFailure(response.data.erro));
        }

        yield put(pontuacaoSalvarSuccess(response.data));
    } catch (error) {
        yield put(pontuacaoSalvarFailure(error.toString()));
    }
}

function* pontuacaoExcluir(action) {
    try {
        const formData = new FormData();
        const { id } = action.payload;
        formData.append('id', id);

        const response = yield call(api.post, `/Pontuacao/Excluir?id=${id}`, formData);

        if (response.data.sucesso !== true) {
            yield put(pontuacaoExcluirFailure(response.data.erro));
        }

        yield put(pontuacaoExcluirSuccess(response.data));
    } catch (error) {
        yield put(pontuacaoExcluirFailure(error.toString()));
    }
}

function* pontuacaoPagina(action) {
    try {
        const formData = new FormData();
        const { id } = action.payload;
        formData.append('id', id);

        const response = yield call(api.post, '/Pontuacao/Pagina', formData);

        if (response.data.sucesso !== true) {
            yield put(pontuacaoPaginaFailure(response.data.erro));
        }

        yield put(pontuacaoPaginaSuccess(response.data));
    } catch (error) {
        yield put(pontuacaoPaginaFailure(error.toString()));
    }
}

function* ListarEntregadores() {
    yield takeLatest(listaEntregadoresStart.type, listaEntregadores);
    yield takeLatest(exportarTabelaEntregadores.type, exportarEntregadores);
    yield takeLatest(financeiroPorEntregadorStart.type, financeiroPorEntregador);
    yield takeLatest(invalidarSessoesStart.type, invalidarSessoes);
    yield takeLatest(desbloquearSenhaStart.type, desbloquearSenha);
    yield takeLatest(salvarContatoStart.type, salvarContato);
    yield takeLatest(dispararPushStart.type, dispararPush);
    yield takeLatest(usuarioPaginaStart.type, usuarioPagina);
    yield takeLatest(listarPerformanceEntregadorStart.type, listarPerformanceEntregador);
    yield takeLatest(exportarPerformanceEntregadorStart.type, exportarPerformanceEntregador);
    yield takeLatest(exportarFinanceiroEntregadorStart.type, exportarFinanceiroEntregador);
    yield takeLatest(pontuacaoStart.type, pontuacao);
    yield takeLatest(pontuacaoSalvarStart.type, pontuacaoSalvar);
    yield takeLatest(pontuacaoExcluirStart.type, pontuacaoExcluir);
    yield takeLatest(pontuacaoPaginaStart.type, pontuacaoPagina);
}

export default ListarEntregadores;
