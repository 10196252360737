import { Chip, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable, maskMoney, ModalOpcoes } from 'src/ds';
import { RootState } from 'src/store/reducers';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import BusinessIcon from '@mui/icons-material/Business';
import { vagaPadraoExcluirClear, vagaPadraoExcluirStart } from 'src/store/reducers/escalas';
import ModalHandleVagaPadrao from './ModalHandleVagaPadrao';
import { VagaPadrao } from 'src/dtos/EscalasDTO';
import ModalEmpresasVinculadas from './ModalEmpresasVinculadas';

interface TabelaVagaPadraoProps {
    onSubmit?: () => void;
    callback: () => void;
    hookForm: UseFormReturn<any>;
    fezRequisicao?: boolean
}

const TabelaVagaPadrao: React.FC<TabelaVagaPadraoProps> = ({ onSubmit, hookForm, fezRequisicao, callback }) => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useSnackbarContext()
    const vagaPadrao = useSelector((state: RootState) => state.escalas.vagaPadrao);
    const loading = useSelector((state: RootState) => state.escalas.vagaPadrao.loading);

    const vagaPadraoExcluir = useSelector((state: RootState) => state.escalas.vagaPadraoExcluir);

    const [vagaPadraoSelecionada, setVagaPadraoSelecionada] = useState<{
        tipo: 'editar' | 'excluir' | 'atribuir',
        id: string,
        nome: string;
        modelo?: VagaPadrao
    } | null>(null);

    function handleConfirmarExcluir(id: string) {
        dispatch(vagaPadraoExcluirStart({ id }));
    }

    useEffect(() => {
        if (vagaPadraoExcluir.data) {
            showSuccess({
                message: vagaPadraoExcluir.data.mensagem,
            });
            callback();
            setVagaPadraoSelecionada(null);
        } else if (vagaPadraoExcluir.error) {
            showError({
                message: vagaPadraoExcluir.error,
            });
        }
        return () => {
            dispatch(vagaPadraoExcluirClear());
        }
    }, [vagaPadraoExcluir.data, vagaPadraoExcluir.error])

    useEffect(() => {
        if (vagaPadrao.error) {
            showError({
                message: vagaPadrao.error,
            });
        }
    }, [vagaPadrao.error])

    const cells = React.useMemo(() => [
        { label: 'Situação' },
        { label: 'Nome', name: 'nome' },
        { label: 'Tipo de veículo', name: 'tipoVeiculo.nome' },
        { label: 'Hora de início', name: 'horaInicio' },
        { label: 'Hora de fim', name: 'horaFim' },
        { label: 'Taxa total cobrada' },
        { label: 'Taxa total entregador' },
        { label: 'Quantidade pedidos mínimo garantido' },
        { label: 'Empresas vinculadas' },
        { label: 'Ações' },
    ], []);

    return (
        <>
            <DynamicTable
                tableRowOrdenacaoProps={{
                    cells,
                    hookForm,
                    onSubmit,
                }}
                loading={loading}
                rows={vagaPadrao?.data?.dados?.lista}
                rowColumns={{
                    key: 'vagaPadraoID',
                    columns: [
                        {
                            field: 'ativo',
                            render: (value) => <Chip label={value ? 'Ativo' : 'Inativo'} color={value ? 'success' : 'error'} />,
                        },
                        { field: 'nome' },
                        { field: 'tipoveiculo.nome' },
                        { field: 'horaInicio' },
                        { field: 'horaFim' },
                        {
                            field: 'taxaTotalCobrada',
                            render: (value, row) => (
                                value // Aqui pode vir como 0, o que é falsy mas não é null
                                    ? maskMoney(value)
                                    : row.taxaMinimaGarantidaCobrada
                                        ? maskMoney(row.taxaMinimaGarantidaCobrada) + ' (Mínimo garantido)'
                                        : value != null
                                            ? maskMoney(value)
                                            : '-' //Apenas para garantir que não seja exibido 'null'
                            )
                        },
                        {
                            field: 'taxaTotalEntregador',
                            render: (value, row) => (
                                value
                                    ? maskMoney(value)
                                    : row.taxaMinimaGarantidaEntregador
                                        ? maskMoney(row.taxaMinimaGarantidaEntregador) + ' (Mínimo garantido)'
                                        : value != null
                                            ? maskMoney(value)
                                            : '-'
                            )
                        },
                        { field: 'qtdPedidosMinimoGarantidoCobrado' },
                        {
                            field: 'empresasVinculadas',
                            render: (value, row) => row.empresasVinculadas?.length > 0 ? row.empresasVinculadas?.map((empresa) => empresa.nome).join(', ') : '-'
                        },
                        {
                            field: 'acoes',
                            render: (_value, row) => (
                                <Stack direction='row' justifyContent={'center'}>
                                    <Tooltip title='Vincular empresas' arrow placement='top'>
                                        <IconButton
                                            onClick={() => setVagaPadraoSelecionada({ tipo: 'atribuir', id: row.vagaPadraoID, nome: row.nome })}
                                            size='small'
                                            color='success'
                                        >
                                            <BusinessIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Editar' arrow placement='top'>
                                        <IconButton
                                            onClick={() => setVagaPadraoSelecionada({ tipo: 'editar', id: row.vagaPadraoID, nome: row.nome, modelo: row })}
                                            size='small'
                                            color='primary'
                                        >
                                            <EditRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Excluir' arrow placement='top'>
                                        <IconButton
                                            size='small'
                                            color='error'
                                            onClick={() => setVagaPadraoSelecionada({ tipo: 'excluir', id: row.vagaPadraoID, nome: row.nome })}
                                        >
                                            <DeleteRoundedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )
                        }
                    ]
                }}
            />
            {vagaPadraoSelecionada?.tipo === 'excluir' && (
                <ModalOpcoes
                    acao='Excluir vaga padrão'
                    descricao={<Typography>Tem certeza que deseja excluir a vaga padrão <strong>{vagaPadraoSelecionada.nome}</strong>?</Typography>}
                    open={!!vagaPadraoSelecionada?.tipo}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    opcoes={[
                        {
                            label: 'Cancelar',
                            action: () => setVagaPadraoSelecionada(null),
                        },
                        {
                            label: 'Confirmar',
                            action: () => handleConfirmarExcluir(vagaPadraoSelecionada.id),
                            loading: vagaPadraoExcluir.loading,
                        },
                    ]}
                />
            )}
            {(vagaPadraoSelecionada?.tipo === 'editar' && vagaPadraoSelecionada?.modelo) && (
                <ModalHandleVagaPadrao
                    callback={callback}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    open={!!vagaPadraoSelecionada?.tipo}
                    modelo={vagaPadraoSelecionada.modelo}
                />
            )}

            {(vagaPadraoSelecionada?.tipo === 'atribuir' && vagaPadraoSelecionada.id) && (
                <ModalEmpresasVinculadas
                    callback={callback}
                    onClose={() => setVagaPadraoSelecionada(null)}
                    open={!!vagaPadraoSelecionada?.tipo}
                    vagaPadraoID={vagaPadraoSelecionada.id}
                />
            )}
        </>
    )
}

export default TabelaVagaPadrao


// renderRow={(row) => (
//     <TableRow key={row.vagaPadraoID}>
//         <TableCell align='center'>
//             <Chip
//                 label={row.ativo ? 'Ativo' : 'Inativo'}
//                 color={row.ativo ? 'success' : 'error'}
//             />
//         </TableCell>
//         <TableCell align='center'>
//             {row.nome || '-'}
//         </TableCell>
//         <TableCell align='center'>
//             {row.tipoVeiculo?.nome}
//         </TableCell>
//         <TableCell align='center'>
//             {row.horaInicio}
//         </TableCell>
//         <TableCell align='center'>
//             {row.horaFim}
//         </TableCell>
//         <TableCell align='center'>
//             {row.taxaTotalCobrada // Aqui pode vir como 0, o que é falsy mas não é null
//                 ? maskMoney(row.taxaTotalCobrada)
//                 : row.taxaMinimaGarantidaCobrada
//                     ? maskMoney(row.taxaMinimaGarantidaCobrada) + ' (Mínimo garantido)'
//                     : row.taxaTotalCobrada != null
//                         ? maskMoney(row.taxaTotalCobrada)
//                         : '-' //Apenas para garantir que não seja exibido 'null'
//             }
//         </TableCell>
//         <TableCell align='center'>
//             {row.taxaTotalEntregador
//                 ? maskMoney(row.taxaTotalEntregador)
//                 : row.taxaMinimaGarantidaEntregador
//                     ? maskMoney(row.taxaMinimaGarantidaEntregador) + ' (Mínimo garantido)'
//                     : row.taxaTotalEntregador != null
//                         ? maskMoney(row.taxaTotalEntregador)
//                         : '-'}
//         </TableCell>
//         <TableCell align='center'>
//             {row.qtdPedidosMinimoGarantidoCobrado}
//         </TableCell>
//         <TableCell align='center'>
//             {row.empresasVinculadas?.length > 0 ? row.empresasVinculadas?.map((empresa) => empresa.nome).join(', ') : '-'}
//         </TableCell>
//         <TableCell align='center'>
//             <Stack direction='row' justifyContent={'center'}>
//                 <Tooltip title='Vincular empresas' arrow placement='top'>
//                     <IconButton
//                         onClick={() => setVagaPadraoSelecionada({ tipo: 'atribuir', id: row.vagaPadraoID, nome: row.nome })}
//                         size='small'
//                         color='success'
//                     >
//                         <BusinessIcon />
//                     </IconButton>
//                 </Tooltip>
//                 <Tooltip title='Editar' arrow placement='top'>
//                     <IconButton
//                         onClick={() => setVagaPadraoSelecionada({ tipo: 'editar', id: row.vagaPadraoID, nome: row.nome, modelo: row })}
//                         size='small'
//                         color='primary'
//                     >
//                         <EditRoundedIcon />
//                     </IconButton>
//                 </Tooltip>
//                 <Tooltip title='Excluir' arrow placement='top'>
//                     <IconButton
//                         size='small'
//                         color='error'
//                         onClick={() => setVagaPadraoSelecionada({ tipo: 'excluir', id: row.vagaPadraoID, nome: row.nome })}
//                     >
//                         <DeleteRoundedIcon />
//                     </IconButton>
//                 </Tooltip>
//             </Stack>
//         </TableCell>
//     </TableRow>
// )}