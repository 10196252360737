import { MeusPedidos, Pedido } from '../../dtos/PedidosDTO';
import { DynamicTable } from 'src/ds';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { actions } from 'src/store/Actions';
import { UseFormReturn } from 'react-hook-form';
import PedidoRow from 'src/components/Pedidos/RowPedido';

export interface DispatchMethods {
  actionName: 'listarMeusPedidosStart' | 'listarPedidosGeral' | keyof typeof actions;
  payload?: any;
}

interface TabelaPedidosRepresentanteProps {
  pedidos: MeusPedidos[] | undefined;
  loading: boolean;
  fezRequisicao?: boolean;
  tipoUsuario: 'Representante';
  hookForm: UseFormReturn<any>;
  onSubmit?: () => void;
  isAllOpen: boolean;
}

interface TabelaPedidosGenericoProps {
  pedidos: Pedido[] | MeusPedidos[] | undefined;
  loading: boolean;
  fezRequisicao?: boolean;
  tipoUsuario: string;
  hookForm: UseFormReturn<any>;
  onSubmit?: () => void;
  isAllOpen: boolean;
}

type TabelaPedidosProps = TabelaPedidosRepresentanteProps | TabelaPedidosGenericoProps;

const TesteTabelaCustom = ({
  pedidos,
  loading,
  fezRequisicao,
  tipoUsuario,
  hookForm,
  onSubmit,
  isAllOpen,
}: TabelaPedidosProps) => {
  const [stepperVisible, setStepperVisible] = useState<{ [key: string]: boolean }>(
    () => pedidos?.reduce((acc, pedido) => ({ ...acc, [pedido.pedidoID]: false }), {}) || {}
  );

  const toggleStepperVisibility = useCallback((pedidoID: string) => {
    setStepperVisible(prev => ({
      ...prev,
      [pedidoID]: !prev[pedidoID],
    }));
  }, []);

  useEffect(() => {
    setStepperVisible(prev => {
      const updated = { ...prev };
      pedidos?.forEach(pedido => {
        updated[pedido.pedidoID] = isAllOpen;
      });
      return updated;
    });
  }, [isAllOpen]);

  const cells = useMemo(() => [
    { label: 'Origem', name: 'aplicacaoOrigemNome' },
    { label: 'Código', name: 'codigoExterno' },
    { label: 'Situação', name: 'situacao' },
    { label: 'Empresa', name: 'empresa.nome' },
    { label: 'Endereço', name: 'entregaEndereco.enderecoStr' },
    { label: 'Entregador', name: 'entregadorUsuario.nome' },
    { label: 'Cliente', name: 'clienteNome' },
    { label: 'Forma de pagamento', name: 'formaPagamento' },
    { label: 'Valor', name: 'valor' },
    { label: 'Taxa entregador', name: 'taxaEntregador' },
    { label: 'Taxa cobrada', name: 'taxaCobrada' },
    { label: 'Ações' }
  ], []);
  
  // const toggleSortDirection = (column: string, direction: string) => {
  //   hookForm.setValue('ordenacaoOrdem', direction);
  //   hookForm.setValue('ordenacaoCampo', column);
  //   onSubmit && onSubmit();
  // };
  
  return (
    <DynamicTable<Pedido | MeusPedidos>
      tableRowOrdenacaoProps={{
        cells,
        // onSortChange: toggleSortDirection,
        // Caso a função onSortChange não for passada, deve-se utilizar 
        // hookForm e a função de submit
        hookForm,
        onSubmit,
      }}
      loading={loading}
      fezRequisicao={fezRequisicao}
      rows={pedidos}
      renderRow={(pedido, _index) => (
        <PedidoRow
          key={pedido.pedidoID}
          //@ts-expect-error
          tipoUsuario={tipoUsuario}
          pedido={pedido}
          onToggleStepper={toggleStepperVisibility}
          stepperVisible={stepperVisible[pedido.pedidoID] || false}
        />
      )}
    />
  );
};

export default TesteTabelaCustom;


