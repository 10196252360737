import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { maskCpf, TableRowLoading, TableRowNotFound } from 'src/ds';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { FinanceiroPorEntregador } from 'src/dtos/FinanceiroPorEntregadorDTO';
import { dinheiro, telefone } from 'src/ds/common';
import TableCellOrdenado from 'src/ds/components/TableCellOrdenado';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { financeiroPorEntregadorStart } from 'src/store/reducers/entregador';
import { RootState } from 'src/store/reducers';

interface TabelaFinanceiroPorEntregadorProps {
    loading: boolean;
    fezRequisicao?: boolean;
    entregadores?: FinanceiroPorEntregador[] | null;
    hookForm: any;
}

const TabelaFinanceiroPorEntregador = (
    { loading, entregadores, fezRequisicao, hookForm }: TabelaFinanceiroPorEntregadorProps
) => {
    const [sortDirection, setSortDirection] = useState<{ [key: string]: 'asc' | 'desc' }>({
        entregador: 'asc',
        cpf: 'asc',
        telefone: 'asc',
        email: 'asc',
        chavePix: 'asc',
        qtdPedidos: 'asc',
        valorRealizado: 'asc',
        valorAntecipado: 'asc',
        valorTotalProgramado: 'asc',
    });

    const dispatch = useDispatch();
    const tipoDeUsuario = useSelector((state: RootState) => state.config?.tipoDeUsuario);

    const toggleSortDirection = (column: string) => {
        const newSortDirection: 'asc' | 'desc' = sortDirection[column] === 'asc' ? 'desc' : 'asc';

        const updatedSortDirection: { [key: string]: 'asc' | 'desc' } = {
            ...sortDirection,
            [column]: newSortDirection,
        };

        setSortDirection(updatedSortDirection);

        const formValues = hookForm.getValues();
        const filteredFormValues = Object.entries(formValues).reduce((acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {} as Record<string, any>);

        const payload = {
            ...filteredFormValues,
            ordenacaoCampo: column,
            ordenacaoOrdem: newSortDirection,
        };

        dispatch(financeiroPorEntregadorStart(payload));
    };

    return (
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCellOrdenado
                            label="Entregador"
                            column="nome"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '15%' }}
                        />
                        <TableCellOrdenado
                            label="CPF"
                            column="cpf"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Telefone"
                            column="telefone"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="E-Mail"
                            column="email"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Chave PIX"
                            column="chavePix"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        <TableCellOrdenado
                            label="Qtd. pedidos"
                            column="qtdPedidos"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />
                        {tipoDeUsuario !== 'SAAS' &&
                            <TableCellOrdenado
                                label="Valor realizado"
                                column="taxaTotalEntregador"
                                sortDirection={sortDirection}
                                onSortChange={toggleSortDirection}
                                sx={{ width: '10%' }}
                            />}
                        {tipoDeUsuario !== 'SAAS' && <TableCellOrdenado
                            label="Valor total já antecipado"
                            column="valorAntecipado"
                            sortDirection={sortDirection}
                            onSortChange={toggleSortDirection}
                            sx={{ width: '10%' }}
                        />}
                        <TableCellOrdenado
                            label="Valor total"
                            column="valorTotal"
                            sortDirection={sortDirection}
                            onSortChange={() => { }}
                            sx={{ width: '10%' }}
                        />
                        {tipoDeUsuario !== 'SAAS' &&
                            <TableCellOrdenado
                                label={
                                    <Stack
                                        sx={{
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '.5rem',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Typography variant="body2">Valor total programado</Typography>
                                        <Tooltip
                                            title="Valor Referente ao total programado + antecipações solicitadas"
                                            arrow
                                            placement="top"
                                            sx={{ textAlign: 'center' }}
                                        >
                                            <HelpOutlineOutlinedIcon color="info" />
                                        </Tooltip>
                                    </Stack>
                                }
                                column="taxaTotalEntregadorProgramada"
                                sortDirection={sortDirection}
                                onSortChange={toggleSortDirection}
                                sx={{ width: '10%' }}
                            />}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRowLoading />
                    ) : entregadores && entregadores.length > 0 ? (
                        entregadores.map((entregador) => (
                            <TableRow key={entregador.usuarioID} hover>
                                <TableCell align="center">{entregador.nome}</TableCell>
                                <TableCell align="center">{maskCpf(entregador.cpf) || '-'}</TableCell>
                                <TableCell align="center">{telefone(entregador.telefone)}</TableCell>
                                <TableCell align="center">{entregador.email}</TableCell>
                                <TableCell align="center">{entregador.chavePix || '-'}</TableCell>
                                <TableCell align="center">{entregador.qtdPedidos}</TableCell>
                                {tipoDeUsuario !== 'SAAS' && <TableCell align="center">{dinheiro(entregador.taxaTotalEntregador)}</TableCell>}
                                {tipoDeUsuario !== 'SAAS' && <TableCell align="center">{dinheiro(entregador.valorAntecipado)}</TableCell>}
                                <TableCell align="center">{dinheiro(entregador.valorTotal)}</TableCell>
                                {tipoDeUsuario !== 'SAAS' && <TableCell align="center">{dinheiro(entregador.taxaTotalEntregadorProgramada)}</TableCell>}
                            </TableRow>
                        ))
                    ) : (
                        <TableRowNotFound text="Nenhum resultado encontrado" fezRequisicao={fezRequisicao} />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabelaFinanceiroPorEntregador;
