import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntregadorDTO, PontuacaoDTO, PontuacaoExcluirDTO, PontuacaoPaginaDTO, PontuacaoSalvarDTO } from "../../dtos/EntregadoresDTO";
import { DefaultAsyncState } from ".";
import { FinanceiroPorEntregadorDTO } from "src/dtos/FinanceiroPorEntregadorDTO";

interface ListaEntregadoresParams {
  nome?: string;
  cpf?: string;
  dtInicial?: string;
  dtFinal?: string;
  tagsFiltro?: string;
  tipoVeiculoID?: string;
  paginacao: number | string;
  pagina: number | string;
  ordenacaoCampo?: string;
  ordenacaoOrdem?: string;
  filtros?: string;
  titulos?: string;
  variaveis?: string;
  formato?: string;
}

interface InitialState {
  listaEntregadores: DefaultAsyncState<EntregadorDTO>;
  financeiroPorEntregador: DefaultAsyncState<FinanceiroPorEntregadorDTO>;
  invalidarSessoes: DefaultAsyncState<any>;
  desbloquearSenha: DefaultAsyncState<any>;
  contato: DefaultAsyncState<any>;
  push: DefaultAsyncState<any>;
  usuarioPagina: DefaultAsyncState<any>;
  listarPerformanceEntregador: DefaultAsyncState<any>;
  exportarPerformanceEntregador: DefaultAsyncState<any>;
  exportarFinanceiroEntregador: DefaultAsyncState<any>;
  pontuacao: DefaultAsyncState<PontuacaoDTO>;
  pontuacaoSalvar: DefaultAsyncState<PontuacaoSalvarDTO>;
  pontuacaoExcluir: DefaultAsyncState<PontuacaoExcluirDTO>;
  pontuacaoPagina: DefaultAsyncState<PontuacaoPaginaDTO>;
}

const initialState: InitialState = {
  listaEntregadores: {
    loading: false,
    data: null,
    error: null,
  },
  financeiroPorEntregador: {
    loading: false,
    data: null,
    error: null,
  },
  invalidarSessoes: {
    loading: false,
    data: null,
    error: null,
  },
  desbloquearSenha: {
    loading: false,
    data: null,
    error: null,
  },
  contato: {
    loading: false,
    data: null,
    error: null,
  },
  push: {
    loading: false,
    data: null,
    error: null,
  },
  usuarioPagina: {
    loading: false,
    data: null,
    error: null,
  },
  listarPerformanceEntregador: {
    loading: false,
    data: null,
    error: null,
  },
  exportarPerformanceEntregador: {
    loading: false,
    data: null,
    error: null,
  },
  exportarFinanceiroEntregador: {
    loading: false,
    data: null,
    error: null,
  },
  pontuacao: {
    loading: false,
    data: null,
    error: null,
  },
  pontuacaoSalvar: {
    loading: false,
    data: null,
    error: null,
  },
  pontuacaoExcluir: {
    loading: false,
    data: null,
    error: null,
  },
  pontuacaoPagina: {
    loading: false,
    data: null,
    error: null,
  },
};

const entregadoresSlice = createSlice({
  name: "Entregadores",
  initialState: initialState,
  reducers: {
    listaEntregadoresStart(state, action: PayloadAction<any>) {
      state.listaEntregadores.loading = true;
      state.listaEntregadores.error = null;
    },
    listaEntregadoresFailure(state, action: PayloadAction<string>) {
      state.listaEntregadores.loading = false;
      state.listaEntregadores.error = action.payload;
    },
    listaEntregadoresSuccess(state, action: PayloadAction<any>) {
      state.listaEntregadores.loading = false;
      state.listaEntregadores.data = action.payload;
    },
    exportarTabelaEntregadores(
      state,
      action: PayloadAction<ListaEntregadoresParams>
    ) {
      state.listaEntregadores.loading = true;
      state.listaEntregadores.error = null;
    },
    exportarTabelaEntregadoresSuccess(state, action: PayloadAction<any>) {
      state.listaEntregadores.loading = false;
      state.listaEntregadores.error = null;
    },
    exportarTabelaEntregadoresFailure(state, action: PayloadAction<string>) {
      state.listaEntregadores.loading = false;
      state.listaEntregadores.error = action.payload;
    },
    financeiroPorEntregadorStart(state, action: PayloadAction<any>) {
      state.financeiroPorEntregador.loading = true;
      state.financeiroPorEntregador.data = null;
      state.financeiroPorEntregador.error = null;
    },
    financeiroPorEntregadorSuccess(state, action: PayloadAction<any>) {
      state.financeiroPorEntregador.loading = false;
      state.financeiroPorEntregador.data = action.payload;
      state.financeiroPorEntregador.error = null;
    },
    financeiroPorEntregadorFailure(state, action: PayloadAction<string>) {
      state.financeiroPorEntregador.loading = false;
      state.financeiroPorEntregador.data = null;
      state.financeiroPorEntregador.error = action.payload;
    },
    invalidarSessoesStart(state, action: PayloadAction<any>) {
      state.invalidarSessoes.loading = true;
      state.invalidarSessoes.data = null;
      state.invalidarSessoes.error = null;
    },
    invalidarSessoesSuccess(state, action: PayloadAction<any>) {
      state.invalidarSessoes.loading = false;
      state.invalidarSessoes.data = action.payload;
      state.invalidarSessoes.error = null;
    },
    invalidarSessoesFailure(state, action: PayloadAction<string>) {
      state.invalidarSessoes.loading = false;
      state.invalidarSessoes.data = null;
      state.invalidarSessoes.error = action.payload;
    },
    limparInvalidarSessoes(state) {
      state.invalidarSessoes.data = null;
      state.invalidarSessoes.error = null;
      state.invalidarSessoes.loading = false;
    },
    desbloquearSenhaStart(state, action: PayloadAction<any>) {
      state.desbloquearSenha.loading = true;
      state.desbloquearSenha.data = null;
      state.desbloquearSenha.error = null;
    },
    desbloquearSenhaSuccess(state, action: PayloadAction<any>) {
      state.desbloquearSenha.loading = false;
      state.desbloquearSenha.data = action.payload;
      state.desbloquearSenha.error = null;
    },
    desbloquearSenhaFailure(state, action: PayloadAction<string>) {
      state.desbloquearSenha.loading = false;
      state.desbloquearSenha.data = null;
      state.desbloquearSenha.error = action.payload;
    },
    limparDesbloquearSenha(state) {
      state.desbloquearSenha.data = null;
      state.desbloquearSenha.error = null;
      state.desbloquearSenha.loading = false;
    },
    salvarContatoStart: (state, action: PayloadAction<any>) => {
      state.contato.loading = true;
      state.contato.data = null;
      state.contato.error = null;
    },
    salvarContatoSuccess: (state, action: PayloadAction<any>) => {
      state.contato.loading = false;
      state.contato.data = action.payload;
      state.contato.error = null;
    },
    salvarContatoFailure: (state, action: PayloadAction<string>) => {
      state.contato.loading = false;
      state.contato.data = null;
      state.contato.error = action.payload;
    },
    dispararPushStart: (state, action: PayloadAction<any>) => {
      state.push.loading = true;
      state.push.data = null;
      state.push.error = null;
    },
    dispararPushSuccess: (state, action: PayloadAction<any>) => {
      state.push.loading = false;
      state.push.data = action.payload;
      state.push.error = null;
    },
    dispararPushFailure: (state, action: PayloadAction<string>) => {
      state.push.loading = false;
      state.push.data = null;
      state.push.error = action.payload;
    },
    limparPush: (state) => {
      state.push.data = null;
      state.push.error = null;
      state.push.loading = false;
    },
    usuarioPaginaStart: (state, action: PayloadAction<any>) => {
      state.usuarioPagina.loading = true;
      state.usuarioPagina.data = null;
      state.usuarioPagina.error = null;
    },
    usuarioPaginaSuccess: (state, action: PayloadAction<any>) => {
      state.usuarioPagina.loading = false;
      state.usuarioPagina.data = action.payload;
      state.usuarioPagina.error = null;
    },
    usuarioPaginaFailure: (state, action: PayloadAction<string>) => {
      state.usuarioPagina.loading = false;
      state.usuarioPagina.data = null;
      state.usuarioPagina.error = action.payload;
    },
    listarPerformanceEntregadorStart: (state, action: PayloadAction<any>) => {
      state.listarPerformanceEntregador.loading = true;
      state.listarPerformanceEntregador.data = null;
      state.listarPerformanceEntregador.error = null;
    },
    listarPerformanceEntregadorSuccess: (state, action: PayloadAction<any>) => {
      state.listarPerformanceEntregador.loading = false;
      state.listarPerformanceEntregador.data = action.payload;
      state.listarPerformanceEntregador.error = null;
    },
    listarPerformanceEntregadorFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.listarPerformanceEntregador.loading = false;
      state.listarPerformanceEntregador.data = null;
      state.listarPerformanceEntregador.error = action.payload;
    },
    exportarPerformanceEntregadorStart: (state, action: PayloadAction<any>) => {
      state.exportarPerformanceEntregador.loading = true;
      state.exportarPerformanceEntregador.data = null;
      state.exportarPerformanceEntregador.error = null;
    },
    exportarPerformanceEntregadorSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.exportarPerformanceEntregador.loading = false;
      state.exportarPerformanceEntregador.data = action.payload;
      state.exportarPerformanceEntregador.error = null;
    },
    exportarPerformanceEntregadorFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.exportarPerformanceEntregador.loading = false;
      state.exportarPerformanceEntregador.data = null;
      state.exportarPerformanceEntregador.error = action.payload;
    },
    exportarFinanceiroEntregadorStart: (state, action: PayloadAction<any>) => {
      state.exportarFinanceiroEntregador.loading = true;
      state.exportarFinanceiroEntregador.data = null;
      state.exportarFinanceiroEntregador.error = null;
    },
    exportarFinanceiroEntregadorSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.exportarFinanceiroEntregador.loading = false;
      state.exportarFinanceiroEntregador.data = action.payload;
      state.exportarFinanceiroEntregador.error = null;
    },
    exportarFinanceiroEntregadorFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.exportarFinanceiroEntregador.loading = false;
      state.exportarFinanceiroEntregador.data = null;
      state.exportarFinanceiroEntregador.error = action.payload;
    },
    pontuacaoStart: (state, action: PayloadAction<any>) => {
      state.pontuacao.loading = true;
      state.pontuacao.data = null;
      state.pontuacao.error = null;
    },
    pontuacaoSuccess: (state, action: PayloadAction<any>) => {
      state.pontuacao.loading = false;
      state.pontuacao.data = action.payload;
      state.pontuacao.error = null;
    },
    pontuacaoFailure: (state, action: PayloadAction<string>) => {
      state.pontuacao.loading = false;
      state.pontuacao.data = null;
      state.pontuacao.error = action.payload;
    },
    pontuacaoSalvarStart: (state, action: PayloadAction<any>) => {
      state.pontuacaoSalvar.loading = true;
      state.pontuacaoSalvar.data = null;
      state.pontuacaoSalvar.error = null;
    },
    pontuacaoSalvarSuccess: (state, action: PayloadAction<any>) => {
      state.pontuacaoSalvar.loading = false;
      state.pontuacaoSalvar.data = action.payload;
      state.pontuacaoSalvar.error = null;
    },
    pontuacaoSalvarFailure: (state, action: PayloadAction<string>) => {
      state.pontuacaoSalvar.loading = false;
      state.pontuacaoSalvar.data = null;
      state.pontuacaoSalvar.error = action.payload;
    },
    pontuacaoSalvarClear: (state) => {
      state.pontuacaoSalvar.data = null;
      state.pontuacaoSalvar.error = null;
      state.pontuacaoSalvar.loading = false;
    },
    pontuacaoExcluirStart: (state, action: PayloadAction<any>) => {
      state.pontuacaoExcluir.loading = true;
      state.pontuacaoExcluir.data = null;
      state.pontuacaoExcluir.error = null;
    },
    pontuacaoExcluirSuccess: (state, action: PayloadAction<any>) => {
      state.pontuacaoExcluir.loading = false;
      state.pontuacaoExcluir.data = action.payload;
      state.pontuacaoExcluir.error = null;
    },
    pontuacaoExcluirFailure: (state, action: PayloadAction<string>) => {
      state.pontuacaoExcluir.loading = false;
      state.pontuacaoExcluir.data = null;
      state.pontuacaoExcluir.error = action.payload;
    },
    pontuacaoExcluirClear: (state) => {
      state.pontuacaoExcluir.data = null;
      state.pontuacaoExcluir.error = null;
      state.pontuacaoExcluir.loading = false;
    }, 
    pontuacaoPaginaStart: (state, action: PayloadAction<any>) => {
      state.pontuacaoPagina.loading = true;
      state.pontuacaoPagina.data = null;
      state.pontuacaoPagina.error = null;
    },
    pontuacaoPaginaSuccess: (state, action: PayloadAction<any>) => {
      state.pontuacaoPagina.loading = false;
      state.pontuacaoPagina.data = action.payload;
      state.pontuacaoPagina.error = null;
    },
    pontuacaoPaginaFailure: (state, action: PayloadAction<string>) => {
      state.pontuacaoPagina.loading = false;
      state.pontuacaoPagina.data = null;
      state.pontuacaoPagina.error = action.payload;
    },
    pontuacaoPaginaClear: (state) => {
      state.pontuacaoPagina.data = null;
      state.pontuacaoPagina.error = null;
      state.pontuacaoPagina.loading = false;
    }
  },
});

export const {
  listaEntregadoresStart,
  listaEntregadoresFailure,
  listaEntregadoresSuccess,
  exportarTabelaEntregadores,
  exportarTabelaEntregadoresSuccess,
  exportarTabelaEntregadoresFailure,
  financeiroPorEntregadorStart,
  financeiroPorEntregadorSuccess,
  financeiroPorEntregadorFailure,
  invalidarSessoesStart,
  invalidarSessoesFailure,
  invalidarSessoesSuccess,
  limparInvalidarSessoes,
  desbloquearSenhaFailure,
  desbloquearSenhaStart,
  desbloquearSenhaSuccess,
  limparDesbloquearSenha,
  salvarContatoStart,
  salvarContatoFailure,
  salvarContatoSuccess,
  dispararPushStart,
  dispararPushFailure,
  dispararPushSuccess,
  limparPush,
  usuarioPaginaFailure,
  usuarioPaginaStart,
  usuarioPaginaSuccess,
  listarPerformanceEntregadorFailure,
  listarPerformanceEntregadorStart,
  listarPerformanceEntregadorSuccess,
  exportarPerformanceEntregadorFailure,
  exportarPerformanceEntregadorStart,
  exportarPerformanceEntregadorSuccess,
  exportarFinanceiroEntregadorFailure,
  exportarFinanceiroEntregadorStart,
  exportarFinanceiroEntregadorSuccess,
  pontuacaoStart,
  pontuacaoSuccess,
  pontuacaoFailure,
  pontuacaoSalvarStart,
  pontuacaoSalvarSuccess,
  pontuacaoSalvarFailure,
  pontuacaoSalvarClear,
  pontuacaoExcluirStart,
  pontuacaoExcluirSuccess,
  pontuacaoExcluirFailure,
  pontuacaoExcluirClear,
  pontuacaoPaginaStart,
  pontuacaoPaginaSuccess,
  pontuacaoPaginaFailure,
  pontuacaoPaginaClear,
} = entregadoresSlice.actions;
export default entregadoresSlice;
