import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import ModalBase, { ModalBaseProps } from 'src/ds/components/ModalBase';
import { BoxLoading, ContainerBodyModal } from 'src/ds';

import { limparComprarCreditos } from 'src/store/reducers/wallet';

interface QrCodeWalletProps {
    modalProps: ModalBaseProps;
    qrCode: string | undefined
    loading: boolean
    atualizar: () => void
    copiaECola: string
}

const QrCodeWallet = ({ modalProps, qrCode, loading, atualizar, copiaECola }: QrCodeWalletProps) => {
    const dispatch = useDispatch()
    const { showSuccess } = useSnackbarContext();

    useEffect(() => {
        dispatch(limparComprarCreditos())
    }, [])

    const copiarCodigo = (copiaECola: string) => {
        navigator.clipboard.writeText(copiaECola)
        showSuccess({
            message: 'Pix copiado para área de transferência',
        });
    }

    return (
        <ModalBase {...modalProps} headerModal={{ title: 'QRCode PIX' }}>
            <ContainerBodyModal >
                <BoxLoading loading={loading} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                    {loading
                        ? null
                        : qrCode === null
                            ? <Typography variant='notFound'>Nenhum QrCode encontrado</Typography>
                            : <Box
                                component={'img'}
                                sx={{
                                    maxHeight: '90%',
                                    height: 'auto',
                                    width: 'auto',
                                    aspectRatio: '1 / 1',
                                    objectFit: 'contain',
                                    pb: 2
                                }}
                                src={qrCode ? `data:image/png;base64,${qrCode}` : ''}
                                style={{ height: '100%', width: '100%' }}
                                alt='Qr Code'
                            />
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', gap: '1rem' }}>
                        <Button
                            onClick={() => copiarCodigo(copiaECola)}
                            variant="containedFilled"
                            sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                            color='secondary'
                        >
                            Copiar código
                        </Button>
                        <Button
                            onClick={() => atualizar()}
                            variant="containedFilled"
                            sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                        >
                            Atualizar pagamento
                        </Button>
                    </Box>
                </BoxLoading>
            </ContainerBodyModal>
        </ModalBase>
    )
}

export default QrCodeWallet