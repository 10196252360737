import { useTheme } from "@mui/material"
import { useForm } from "react-hook-form"
import { DynamicDataContainer } from "src/ds"
import { CardFormFiltrosRef } from "src/ds/components/CardFormFiltros"
import circleDollar from "../../assets/icons/circle-dollar.svg";
import TabelaFinanceiroPorEntregador from "src/components/FinanceiroPorEntregador/TabelaFinanceiroPorEntregador";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/reducers";
import { dinheiro } from "src/ds/common";
import { useRef } from "react";
import { exportarFinanceiroEntregadorStart } from "src/store/reducers/entregador";

const FinanceiroPorEntregador = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const hookForm = useForm({
    defaultValues: {
      pagina: 1,
      paginacao: 20,
      dataInicio: null,
      dataFim: null,
      buscar: null,
    },
    mode: "onChange",
  })

  const financeiroPorEntregador = useSelector((state: RootState) => state.entregadores.financeiroPorEntregador)
  const financeiroPorEntregadorDados = useSelector((state: RootState) => state.entregadores.financeiroPorEntregador.data?.dados)
  const exportarFinanceiroEntregador = useSelector((state: RootState) => state.entregadores.exportarFinanceiroEntregador)

  const cardRef = useRef<CardFormFiltrosRef>(null)

  const menuOptions = [
    {
      label: 'Exportar',
      action: () => {
        dispatch(exportarFinanceiroEntregadorStart(hookForm.getValues()))
      }
    }
  ]

  return (
    <DynamicDataContainer
      hookForm={hookForm}
      cardFormFiltrosProps={{
        dispatchMethods: { actionName: 'financeiroPorEntregadorStart' },
        fields: [
          {
            label: "Buscar",
            name: "buscar",
            type: "input",
          },
          {
            name: "dataInicio",
            label: "Data Início",
            type: "dateTime",
            rules: {
              required: 'Data Início obrigatório',
            }
          },
          {
            label: "Data Final",
            name: "dataFim",
            type: "dateTime",
            rules: {
              required: 'Data final obrigatório',
            }
          },
          {
            name: "empresaID",
            label: "Empresa",
            type: "autocompleteEmpresa",
          },
        ],
        loading: financeiroPorEntregador.loading
      }}
      cardTabelaTotalProps={{
        cardData: [
          {
            srcIcon: circleDollar,
            altIcon: 'VALOR TOTAL A PAGAR',
            bgcolor: theme.palette.blue[600],
            texts: {
              title1: 'VALOR TOTAL A PAGAR',
              subtitle1: financeiroPorEntregador.data?.totalTaxasEntregador ? dinheiro(financeiroPorEntregador.data?.totalTaxasEntregador) : 'R$0,00',
            }
          },
          {
            srcIcon: circleDollar,
            altIcon: 'VALOR TOTAL PAGO',
            bgcolor: theme.palette.green[600],
            texts: {
              title1: 'VALOR TOTAL PAGO',
              subtitle1: financeiroPorEntregador.data?.totalTaxasEntregadorProgramadas ? dinheiro(financeiroPorEntregador.data?.totalTaxasEntregadorProgramadas) : 'R$0,00',
            }
          },
          {
            srcIcon: circleDollar,
            altIcon: 'VALOR ANTECIPADO',
            bgcolor: theme.palette.red[600],
            texts: {
              title1: 'VALOR ANTECIPADO',
              subtitle1: financeiroPorEntregador.data?.totalAntecipado ? dinheiro(financeiroPorEntregador.data?.totalAntecipado) : 'R$0,00',
            }
          },
          {
            srcIcon: circleDollar,
            altIcon: 'VALOR ANTECIPADO A PAGAR',
            bgcolor: theme.palette.yellow[600],
            texts: {
              title1: 'VALOR ANTECIPADO A PAGAR',
              subtitle1: (financeiroPorEntregador.data?.totalAntecipado || financeiroPorEntregador.data?.totalAntecipadoRecebido) ? dinheiro((financeiroPorEntregador.data?.totalAntecipado ?? 0) - (financeiroPorEntregador.data?.totalAntecipadoRecebido ?? 0)) : 'R$0,00',
              title2: 'VALOR ANTECIPADO PAGO',
              subtitle2: financeiroPorEntregador.data?.totalAntecipadoRecebido ? dinheiro(financeiroPorEntregador.data?.totalAntecipadoRecebido) : 'R$0,00',
            }
          }
        ]
      }}
      sections={[{
        title: 'Financeiro por entregador',
        label: 'Financeiro por entregador',
        tableNavigation: {
          indiceInicial: financeiroPorEntregadorDados?.indiceInicial,
          indiceFinal: financeiroPorEntregadorDados?.indiceFinal,
          indiceTotal: financeiroPorEntregadorDados?.total,
          ultimaPagina: financeiroPorEntregadorDados?.ultimaPagina || 0
        },
        menu: {
          options: menuOptions,
          loading: exportarFinanceiroEntregador.loading
        },
        component: <TabelaFinanceiroPorEntregador
          fezRequisicao={cardRef.current?.fezRequisicao}
          loading={financeiroPorEntregador.loading}
          entregadores={financeiroPorEntregadorDados?.lista}
          hookForm={hookForm}
        />
      }]}
    />
  )
}

export default FinanceiroPorEntregador