import React, { useEffect, useState } from "react";
import { Autocomplete, CircularProgress, SxProps, TextField } from "@mui/material";
import { Controller, RegisterOptions, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { TipoVeiculo } from "src/dtos/TabelaCobrancaDTO";
import { RootState } from "src/store/reducers";
import { listarVeiculosStart } from "src/store/reducers/escalas";
import { Clear } from "@mui/icons-material";

interface HookForm {
  name: string;
  label?: string;
  methods: UseFormReturn<any>;
  rules?: RegisterOptions;
  defaultVeiculoID?: string | null;
}

interface AutocompleteTipoVeiculoProps {
  hookForm: HookForm;
  label: string;
  sx?: SxProps;
  size?: "small" | "medium";
}

const AutocompleteTipoVeiculo: React.FC<AutocompleteTipoVeiculoProps> = ({
  hookForm: { methods, name, rules, defaultVeiculoID },
  label,
  sx,
  size = "small",
}) => {
  const dispatch = useDispatch();
  const listaVeiculos = useSelector(
    (state: RootState) => state.escalas.listarVeiculos?.data?.dados?.lista
  );
  const loading = useSelector((state: RootState) => state.escalas.listarVeiculos.loading);

  const [veiculoSelecionado, setVeiculoSelecionado] = useState<Partial<TipoVeiculo> | null>(null);
  const { control, setValue, formState: { errors } } = methods;

  useEffect(() => {
    dispatch(listarVeiculosStart());
  }, [dispatch]);

  useEffect(() => {
    if (listaVeiculos && defaultVeiculoID) {
      const defaultOption = listaVeiculos.find(
        (item) => item.tipoVeiculoID === defaultVeiculoID
      );
      if (defaultOption) {
        setVeiculoSelecionado(defaultOption);
        setValue(name, defaultOption.tipoVeiculoID);
      }
    }
  }, [listaVeiculos, defaultVeiculoID, setValue, name]);

  const handleChange = (value: Partial<TipoVeiculo> | null) => {
    setVeiculoSelecionado(value);
    setValue(name, value?.tipoVeiculoID || null);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          value={veiculoSelecionado}
          options={listaVeiculos || []}
          getOptionLabel={(option) => option.nome || ""}
          isOptionEqualToValue={(option, value) =>
            option.tipoVeiculoID === value?.tipoVeiculoID
          }
          onChange={(_, value) => handleChange(value)}
          loading={loading}
          sx={sx}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              error={!!errors[name]}
              helperText={
                errors[name]?.message ? String(errors[name]?.message) : ""
              }
              size={size}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default AutocompleteTipoVeiculo;
