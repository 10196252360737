import { Box, Button, Grid, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { ModalBase } from "src/ds";

interface ModalSelectCartaoProps {
    open: boolean;
    handleClose: () => void;
    onComprar: (selectedCartao: any) => void;
    onRegistrarCartao: () => void; // Função que abre o outro modal
}

const ModalSelectCartao: React.FC<ModalSelectCartaoProps> = ({
    open,
    handleClose,
    onComprar,
    onRegistrarCartao,
}) => {
    const cartoes = useSelector((state: any) => state.wallet?.meusCartoes?.data?.dados?.lista);
    const [selectedCartao, setSelectedCartao] = useState<string | null>(null);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setSelectedCartao(event.target.value);
    };

    return (
        <ModalBase
            open={open}
            onClose={handleClose}
            size="sm"
            headerModal={{ title: "Selecione um cartão", onClose: handleClose }}
        >
            <Box m={2}>
                {/* Seletor de cartões */}
                <Box py={1} flex={1}>
                    <Select
                        fullWidth
                        value={selectedCartao || ""}
                        size="small"
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{
                            name: "cartao",
                            id: "empresaCartaoID",
                        }}
                    >
                        <MenuItem value="" disabled>
                            {cartoes?.length > 0 ? "Selecione um cartão" : "Nenhum cartão disponível"}
                        </MenuItem>
                        {cartoes?.map((cartao: any) => (
                            <MenuItem key={cartao.empresaCartaoID} value={cartao.empresaCartaoID}>
                                {cartao.numero}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {/* Botão para registrar novo cartão */}
                <Box py={1}>
                    <Button
                        variant="containedFilled"
                        color="primary"
                        sx={{ width: "100%" }}
                        onClick={onRegistrarCartao}
                    >
                        Registrar novo cartão
                    </Button>
                </Box>

                {/* Botões de ação */}
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (selectedCartao) {
                                    console.log("Comprar com cartão", selectedCartao);
                                    onComprar(selectedCartao);
                                    handleClose();
                                }
                            }}
                            disabled={!selectedCartao}
                        >
                            Comprar
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </ModalBase>
    );
};

export default ModalSelectCartao;
