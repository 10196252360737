import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getHubspotTokenStart, getIfoodMerchantIdsStart } from 'src/store/reducers/config';
import { usePagina } from './PaginaContext';
import { useResponsive } from 'src/ds';

interface HubspotConversationsContextType {
    toggleWidget: () => void;
    isOpen: boolean;
    unreadMessagesCount: number;
}

const HubspotConversationsContext = createContext<HubspotConversationsContextType | null>(null);

interface Prop {
    children: React.ReactNode;
}

export const HubspotConversationsProvider: FC<Prop> = ({ children }) => {
    const location = useLocation
    const dispatch = useDispatch();
    const xtoken = localStorage.getItem('@token');

    const [isReady, setIsReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [loadImmediately, setLoadImmediately] = useState(false);

    const user = useSelector((state: any) => state.config?.masterPage?.usuario?.email);
    const hubspotToken = useSelector((state: any) => state.config?.hubspotToken?.data?.token);
    const merchantUuidArray = useSelector((state: any) => state.config?.ifoodMerchantIds?.data?.result);
    const merchantUuid = Array.isArray(merchantUuidArray) ? merchantUuidArray[0] : merchantUuidArray;
    const tipoDeUsuario = useSelector((state: any) => state.config?.tipoDeUsuario);
    const { paginaAtual } = usePagina()
    const { isMobile } = useResponsive()

    useEffect(() => {
        if (xtoken) {
            dispatch(getHubspotTokenStart());
            dispatch(getIfoodMerchantIdsStart());
        }
    }, [xtoken]);

    const loadHubSpotScript = useCallback(() => {
        if (tipoDeUsuario === 'Representante') return new Promise<void>((resolve) => resolve());

        return new Promise<void>((resolve, reject) => {
            if (window.HubSpotConversations) {
                resolve();
            } else {
                const script = document.createElement('script');
                script.src = `//js.hs-scripts.com/46791646.js`;
                script.async = true;
                script.onload = () => {
                    resolve();
                    setTimeout(() => {
                        const widgetElement = document.querySelector('iframe[title="Conversations Widget"]');
                        if (widgetElement) {
                            (widgetElement as HTMLElement).style.zIndex = '500';
                            (widgetElement as HTMLElement).style.position = 'relative';
                            (widgetElement as HTMLElement).style.right = '110';
                        }
                    }, 1000);
                };
                script.onerror = (e) => reject(e);
                document.body.appendChild(script);
            }
        });
    }, []);
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        const handleRouteChange = () => {
            setCurrentPath(window.location.pathname);
        };

        window.addEventListener('popstate', handleRouteChange);
        window.addEventListener('pushState', handleRouteChange);
        window.addEventListener('replaceState', handleRouteChange);

        const cleanup = loadIfoodWidget();

        return () => {
            window.removeEventListener('popstate', handleRouteChange);
            window.removeEventListener('pushState', handleRouteChange);
            window.removeEventListener('replaceState', handleRouteChange);
            if (cleanup) cleanup();
        };
    }, []);

    const loadIfoodWidget = useCallback(() => {
        if (isMobile) return
        if (merchantUuid) {
            if (paginaAtual === 'Painel') {
                const widgetId = '7ee7e552-d0d5-4c34-9cc8-edf1bca37bd1';
                const ifoodScript = document.createElement('script');
                ifoodScript.src = 'https://widgets.ifood.com.br/widget.js';
                ifoodScript.async = true;

                ifoodScript.onload = () => {
                    if (merchantUuid.length > 0) {
                        window.iFoodWidget.init({
                            widgetId,
                            merchantIds: merchantUuid,
                        });
                    }
                };
                document.head.appendChild(ifoodScript);

                return () => {
                    document.head.removeChild(ifoodScript);
                };
            }
        } else {
            console.log("merchantUuid não encontrado");
        }
    }, [merchantUuid, currentPath]);


    useEffect(() => {
        if (tipoDeUsuario === 'Representante') return
        loadHubSpotScript()
            .then(() => setIsReady(true))
            .catch((e) => console.error("Falha ao carregar o script do HubSpot:", e));
        loadIfoodWidget();
    }, [loadHubSpotScript, loadIfoodWidget, paginaAtual]);

    const configureHubSpot = useCallback(() => {
        if (tipoDeUsuario === 'Representante') return

        if (!hubspotToken || !user) return;

        window.hsConversationsSettings = {
            identificationEmail: user,
            identificationToken: hubspotToken,
            loadImmediately: loadImmediately,
        };

        window.hsConversationsOnReady = [() => {
            const chatSettings = {
                email: user,
                firstName: user.split(" ")[0],
                lastName: user.split(" ").slice(1).join(" "),
                phone: "61981111998",
                company: "Empresa não identificada",
            };
            window.HubSpotConversations?.widget.identify(chatSettings);
            setLoadImmediately((prev) => !prev);
        }];

        window.HubSpotConversations?.resetAndReloadWidget();
        window.HubSpotConversations?.widget.load();
    }, [hubspotToken, user, loadImmediately, paginaAtual]);

    useEffect(() => {
        if (hubspotToken && user) {
            configureHubSpot();
        }
    }, [hubspotToken, user, configureHubSpot, paginaAtual]);

    const hideWidget = useCallback(() => {
        setIsOpen(false);
    }, []);

    const showWidget = useCallback(() => {
        if (!isReady) return;
        window.HubSpotConversations?.widget.load();
        window.HubSpotConversations?.widget.open();
        setIsOpen(true);
    }, [isReady]);

    const toggleWidget = useCallback(() => {
        if (isOpen) {
            hideWidget();
        } else {
            showWidget();
        }
    }, [isOpen, showWidget, hideWidget]);



    useEffect(() => {
        if (!isReady || !window.HubSpotConversations) return;

        function listener(payload: { unreadCount: number }) {
            setUnreadMessagesCount(payload.unreadCount);
        }

        window.HubSpotConversations.on('unreadConversationCountChanged', listener);

        return () => {
            if (window.HubSpotConversations) {
                window.HubSpotConversations.off('unreadConversationCountChanged', listener);
            }
        };
    }, [isReady]);

    useEffect(() => {
        if (isReady && window.HubSpotConversations) {
            window.HubSpotConversations?.widget.refresh();
        }
    }, [isReady]);

    return (
        <HubspotConversationsContext.Provider
            value={{ isOpen, toggleWidget, unreadMessagesCount }}
        >
            {children}
        </HubspotConversationsContext.Provider>
    );
};

export function useHubspotConversations() {
    const context = useContext(HubspotConversationsContext);

    if (context === null) {
        throw new Error('useHubspotConversations must be used within HubspotConversationsProvider');
    }

    return context;
}

declare global {
    interface Window {
        hsConversationsSettings: Record<string, any>;
        hsConversationsOnReady: Array<() => void>;
        HubSpotConversations: {
            on: any;
            off: any;
            widget: {
                status: () => { loaded: boolean; pending: boolean };
                load: (params?: { widgetOpen: boolean }) => void;
                remove: () => void;
                open: () => void;
                close: () => void;
                refresh: (openToNewThread?: boolean) => void;
                identify: (params: Record<string, any>) => void;
            };
            resetAndReloadWidget: () => void;
        };
        iFoodWidget: {
            init: (params: {
                widgetId: string;
                merchantIds: string | string[];
            }) => void;
        };
    }
}
