import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Paper, Typography, useTheme } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { alterarSituacaoRequest, limparResposta } from '../../../store/reducers/config';
import { BoxLine, GridColumn, ModalOpcoes, unSanitizeSituacao } from '../../../ds';
import { RootState } from 'src/store/reducers';
import { useSnackbarContext } from 'src/context/SnackbarContext';
import { setAtualizarEmAndamento } from 'src/store/reducers/utils';

interface Props {
    onClose: () => void;
    pedido: any;
    closePopper: () => void;
    statusAtual: any;
}

let currentModal: React.RefObject<any> | null = null;


const ModalTrocarStatus = ({ onClose, pedido, closePopper, statusAtual }: Props) => {
    const theme = useTheme();
    const [selectedStatus, setSelectedStatus] = useState<null | string>(null);
    const respostaSituacao = useSelector((state: RootState) => state.config.respostaSituacao);
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.config.loadingSituacao);
    const { showSuccess } = useSnackbarContext();
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (currentModal && currentModal !== modalRef) {
            currentModal.current?.dispatchEvent(new CustomEvent('close-modal'));
        }
        currentModal = modalRef;

        return () => {
            if (currentModal === modalRef) {
                currentModal = null;
            }
        };
    }, []);

    useEffect(() => {
        const handleClose = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setSelectedStatus(null);
                closePopper();
            }
        };

        document.addEventListener('keydown', handleClose);

        return () => {
            document.removeEventListener('keydown', handleClose);
        };
    }, [closePopper]);

    const handleStatusClick = (status: string) => {
        setSelectedStatus(status);
    };

    const handleTrocarStatus = useCallback(() => {
        dispatch(alterarSituacaoRequest({ pedidoID: pedido?.pedidoID, situacao: unSanitizeSituacao(selectedStatus) }));
    }, [dispatch, pedido?.pedidoID, selectedStatus]);

    useEffect(() => {
        if (respostaSituacao) {
            showSuccess({
                message: respostaSituacao,
            });
            dispatch(setAtualizarEmAndamento())
            dispatch(limparResposta());
            setSelectedStatus(null);
            closePopper()
        }
    }, [respostaSituacao]);

    useEffect(() => {
        const handleClose = () => {
            setSelectedStatus(null);
            closePopper();
        };

        modalRef.current?.addEventListener('close-modal', handleClose);

        return () => {
            modalRef.current?.removeEventListener('close-modal', handleClose);
        };
    }, [closePopper]);

    return (
        <Paper
            sx={{
                p: theme.sizes.padding.sm,
                width: '200px',
                maxHeight: '100vh',
                maxWidth: '100vw',
                overflow: 'auto',
            }}
            ref={modalRef}

        >
            <BoxLine justifyContent="space-between" alignItems="flex-start">
                <CloseOutlined style={{ cursor: 'pointer' }} fontSize="small" sx={{ mr: 1 }} onClick={onClose} />
                <Typography variant='body1'>Alterar situação #{pedido?.codigoQuatro}</Typography>
                <span></span>
            </BoxLine>
            <GridColumn mt={2}>
                {['Recebido', 'Pronto', 'Aceito', 'Na origem', 'Em rota', 'Retornando', 'Finalizado', 'Cancelado'].map((status) => (
                    <Button
                        variant='outlined'
                        key={status}
                        onClick={() => handleStatusClick(status)}
                        sx={{
                            marginBottom: '0.3rem',
                            backgroundColor: selectedStatus === status ? theme.palette.primary.main : theme.palette.background.default,
                            color: selectedStatus === status ? theme.palette.primary.contrastText : theme.palette.grey[500],
                            border: selectedStatus === status ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.grey[500]}`,
                            borderRadius: theme.sizes.borderRadius.sm,
                        }}
                    >
                        {status}
                    </Button>
                ))}
                {/*                 <ButtonLoading
                    loading={loading}
                    variant="containedFilled"
                    sx={{ mt: 2 }}
                    onClick={handleTrocarStatus}
                >
                    Salvar situação
                </ButtonLoading> */}
                {selectedStatus &&
                    <ModalOpcoes
                        open={!!selectedStatus}
                        onClose={() => setSelectedStatus(null)}
                        acao='Alterar situação'
                        descricao={
                            <Typography>
                                Você deseja alterar a situação do pedido <strong>#{pedido?.codigoQuatro}</strong> para <strong>{selectedStatus}</strong>?
                            </Typography>
                        }
                        opcoes={[
                            {
                                label: 'Cancelar',
                                action: () => setSelectedStatus(null)
                            },
                            {
                                label: 'Confirmar',
                                action: handleTrocarStatus,
                                loading: loading
                            },
                        ]}
                        sx={{
                            zIndex: 10000
                        }}
                    />
                }
            </GridColumn>
        </Paper>
    );
};

export default ModalTrocarStatus;

