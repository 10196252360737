import React from 'react';
import { TextField, InputAdornment, IconButton, Badge, Button, Tooltip } from '@mui/material';
import { Search, FilterAlt, Clear, Route, Check } from '@mui/icons-material';
import { BoxLine } from 'src/ds';
import { usePedido } from 'src/context/PedidoContext';
import { useSelector } from 'react-redux';

interface BarraDePesquisaProps {
    searchTerm: string;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    filtrosValidos: number;
    onFilterClick: () => void;
}

const BarraDePesquisa: React.FC<BarraDePesquisaProps> = ({ searchTerm, onSearchChange, filtrosValidos, onFilterClick }) => {
    const { iniciarAdicaoPedidos, pararAdicaoPedidos, adicionandoPedidos, limparNovaLista } = usePedido();
    const metodoCobranca = useSelector((state: any) => state.config?.masterPage?.pickSelecionada?.tipoCobranca);
    const metodoPagamento = useSelector((state: any) => state.config?.masterPage?.pickSelecionada?.tipoPagamento);

    const handleAdicionar = () => {
        if (adicionandoPedidos) {
            limparNovaLista();
            pararAdicaoPedidos();
            return
        }
        if (adicionandoPedidos) {
            pararAdicaoPedidos();
        } else {
            iniciarAdicaoPedidos();
        }
    }
    return (
        <BoxLine style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem', padding: '0.5rem' }}>
            <TextField
                size="small"
                fullWidth
                value={searchTerm}
                onChange={onSearchChange}
                placeholder="Encontre o pedido pelo código, cliente, entregador, empresa..."
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            onSearchChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        edge="end"
                                    >
                                        <Clear />
                                    </IconButton>
                                </InputAdornment>
                            )
                        ),
                    }
                }}
            />
            {metodoCobranca !== 2 && metodoPagamento !== 2 &&
                <Tooltip title={adicionandoPedidos ? 'Desative para selecionar apenas um pedido' : 'Ative para selecionar múltiplos pedidos'} arrow placement='top'>
                    {/* @ts-ignore */}
                    <IconButton onClick={() => handleAdicionar()} variant={adicionandoPedidos ? "containedFilled" : "contained"} color='primary' size='small' sx={{
                        borderRadius: '6px',
                    }}>
                        <Route />
                    </IconButton>
                </Tooltip>}
            <Badge
                badgeContent={filtrosValidos}
                color={'primary'}
                sx={{
                    '& .MuiBadge-badge': {
                        top: '0.4rem',
                        right: '0.4rem',
                    }
                }}
            >
                {/* @ts-ignore */}
                <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={onFilterClick}
                    sx={{
                        borderRadius: '6px',
                    }}
                >
                    <FilterAlt />
                </IconButton>
            </Badge>
        </BoxLine >
    )
};

export default BarraDePesquisa;
