/* @ts-nocheck */

import { useState, useEffect } from "react";
import CardHeader from "./CardHeader";
import CardFooter from "./CardFooter";
import { usePedido } from "../../../context/PedidoContext";
import { useTheme } from "@mui/material/styles";
import CardContent from "./CardBody";
import { CardPrincipal } from "src/ds";
import { Pedido } from "src/dtos/PedidosDTO";


const CardPedido = ({ pedido, ...props }: any) => {
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(true);
    const {
        novaListaPedidos,
        adicionarPedidoNaLista,
        removerPedidoDaLista,
        adicionandoPedidos,
        selecionarCardPedido
    } = usePedido();
    
    const toggleCollapse = () => {
        setCollapsed(!collapsed)
    };

    const [mostarDashed, setMostarDashed] = useState(false);
    useEffect(() => {
        setMostarDashed(novaListaPedidos.some((p: Pedido) => p.pedidoID === pedido.pedidoID));
    }, [novaListaPedidos, pedido.pedidoID]);

    const handlePedidoClick = () => {
        if (!adicionandoPedidos) {
            console.log('handlePedidoClick');
            selecionarCardPedido(pedido)

            return;
        }
        if (mostarDashed) {
            removerPedidoDaLista(pedido.pedidoID);
        } else {
            adicionarPedidoNaLista(pedido);
        }
    };

    if (pedido === null) return null;

    return (
        <CardPrincipal
            sx={{
                position: "relative",
                mt: ".5rem",
                height: "fit-content",
                outline: mostarDashed ? `4px dashed ${theme.palette.primary.main}` : "none",
                overflow: "visible",
            }}
            onClick={handlePedidoClick}
            {...props}
        >
            <CardHeader
                pedido={pedido}
                toggleCollapse={toggleCollapse}
                abrirAtribuirPedido={props.abrirAtribuirPedido}
                abrirEditarPedido={props.abrirEditarPedido}
                abrirEntregaParceira={props.abrirEntregaParceira}
                collapsed={collapsed}
            />
            <CardContent
                pedido={pedido}
                collapsed={collapsed}
                abrirAtribuirPedido={props.abrirAtribuirPedido}
                abrirCriarOcorrencia={props.abrirCriarOcorrencia}
                key={pedido.pedidoID}
            />
            <CardFooter
                pedido={pedido}
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
            />
        </CardPrincipal>
    );
};

export default CardPedido;

