// DynamicTable.tsx
import { TableBody, TableCellProps, TableContainer } from '@mui/material';
import React from 'react';
import TableRowOrdenacao, { TableRowOrdenacaoCell } from './TableRowOrdenacao';
import TableRowLoading from './TableRowLoading';
import TableRowNotFound from './TableRowNotFound';
import { UseFormReturn } from 'react-hook-form';
import DynamicRow, { DynamicRowProps } from './DynamicRow';

interface TableRowOrdenacaoBase {
  cells?: TableRowOrdenacaoCell[];
  ordenacaoCampoNome?: string;
  ordenacaoOrdemNome?: string;
}

interface TableRowOrdenacaoComHookForm extends TableRowOrdenacaoBase {
  hookForm: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  onSortChange?: undefined;
}

interface TableRowOrdenacaoComOnSortChange extends TableRowOrdenacaoBase {
  hookForm?: undefined;
  onSubmit?: undefined;
  onSortChange: (column: string, direction: 'asc' | 'desc') => void;
}

type TableRowOrdenacaoProps = TableRowOrdenacaoComHookForm | TableRowOrdenacaoComOnSortChange;

interface DynamicTableProps<T> {
  tableRowOrdenacaoProps: TableRowOrdenacaoProps;
  loading?: boolean;
  fezRequisicao?: boolean;
  rows?: T[] | null;
  renderRow?: (row: T, index: number) => React.ReactNode;
  rowColumns?: Omit<DynamicRowProps<T>, 'row'>
}

/**
 * Componente de tabela dinâmica que renderiza linhas com base nos dados passados, 
 * e pode incluir recursos como ordenação, carregamento e células personalizadas.
 * 
 * Caso não seja fornecido tableRowOrdenacaoProps.onSortChange, o hookForm deve ser fornecido junto com a função onSubmit.
 * 
 * Caso não seja fornecido um `renderRow`, o componente DynamicRow será utilizado para renderizar as linhas.
 * 
 * @template T Tipo genérico para as linhas de dados (ex.: um array de objetos).
 * @param {Object} props As propriedades do componente.
 * @param {TableRowOrdenacaoProps} props.tableRowOrdenacaoProps Propriedades específicas para a ordenação das linhas.
 * @param {boolean} [props.loading=false] Indica se a tabela está no estado de carregamento.
 * @param {boolean} [props.fezRequisicao=false] Indica se a requisição de dados foi feita.
 * @param {T[] | null} [props.rows] Dados das linhas a serem renderizadas.
 * @param {Function} [props.renderRow] Função para customizar a renderização das linhas.
 * @param {DynamicRowProps<T>} [props.rowColumns] Definições das colunas a serem renderizadas utilizando o componente DynamicRow.
 * @returns {React.ReactNode} Um JSX que representa a tabela dinâmica.
 */
const DynamicTable = <T,>({
  tableRowOrdenacaoProps,
  fezRequisicao,
  rows,
  loading,
  renderRow,
  rowColumns
}: DynamicTableProps<T>) => {
  const {
    cells,
    onSortChange,
    hookForm,
    onSubmit,
    ordenacaoCampoNome,
    ordenacaoOrdemNome
  } = tableRowOrdenacaoProps;

  const handleSortChange = (column: string, direction: string) => {
    if (!hookForm) return
    const { setValue } = hookForm;
    setValue(ordenacaoCampoNome ?? 'ordenacaoOrdem', direction);
    setValue(ordenacaoOrdemNome ?? 'ordenacaoCampo', column);
    onSubmit && onSubmit();
  };

  return (
    <TableContainer>
      <TableRowOrdenacao cells={cells} onSortChange={onSortChange ?? handleSortChange} />
      <TableBody>
        {loading
          ? <TableRowLoading />
          : rows != null && rows.length > 0
            ? rows.map((row, index) => renderRow
              ? renderRow(row, index)
              : <DynamicRow key={index} row={row} columns={rowColumns?.columns} />)
            : <TableRowNotFound fezRequisicao={fezRequisicao} />
        }
      </TableBody>
    </TableContainer>
  );
};

export default DynamicTable;
