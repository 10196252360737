function formatTime(seconds?: number | null): string {
    if (seconds == null || isNaN(seconds)) return "-";

    seconds = Math.abs(Math.round(seconds));

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        return `${hours}h ${minutes}m ${remainingSeconds}s`.trim();
    } else if (minutes > 0) {
        return `${minutes}m ${remainingSeconds > 0 ? `${remainingSeconds}s` : ""}`.trim();
    } else {
        return `${remainingSeconds}s`;
    }
}

export default formatTime;
