export const maskMoney = (valor: string | number): string => {
    const valorString = String(valor);

    const numero = parseFloat(valorString.replace(/[^0-9.-]+/g, ""));

    if (isNaN(numero)) return String(valor);

    return numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export default maskMoney
