import { AddCircleOutline, Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalBase, ModalOpcoes, TableNavigation, TableRowLoading, TableRowNotFound, TipoDeVeiculo, useResponseSnackbar } from 'src/ds';
import { excluirTipoDeVeiculoStart, limparExcluirTipoDeVeiculo, limparSalvarTipoDeVeiculoTaxaExtra, listarTiposDeVeiculoTaxaExtraStart, salvarTipoDeVeiculoTaxaExtraStart } from 'src/store/reducers/taxaExtra';

interface TiposVeiculoProps {
    data: any;
}

const TiposVeiculo = ({ data }: TiposVeiculoProps) => {
    const [showSelector, setShowSelector] = React.useState(false);
    const [openDeletar, setOpenDeletar] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const response = useSelector((state: any) => state.taxaExtra.listarTiposVeiculo);
    const response2 = useSelector((state: any) => state.taxaExtra.excluirTipoDeVeiculo);
    const response3 = useSelector((state: any) => state.taxaExtra.salvarTiposVeiculo);
    const loadingSalvar = response3?.data?.loading;
    const hasRunRef = useRef(false);

    useResponseSnackbar([
        { response: response2, clearAction: () => dispatch(limparExcluirTipoDeVeiculo()) },
        { response: response3, clearAction: () => dispatch(limparSalvarTipoDeVeiculoTaxaExtra()) }
    ]);

    const tiposVeiculo = response.data?.dados;
    const loading = response?.loading;

    const hookForm = useForm({
        defaultValues: {
            tipoVeiculoID: '',
            taxaExtraID: data?.taxaExtraID,
            pagina: 1,
            paginacao: 20
        }
    });
    const dispatch = useDispatch();
    const handleSave = () => {
        dispatch(salvarTipoDeVeiculoTaxaExtraStart({
            taxaExtraID: hookForm.watch("taxaExtraID"),
            tipoVeiculoID: hookForm.watch("tipoVeiculoID")
        }))
        setShowSelector(false);
        dispatch(listarTiposDeVeiculoTaxaExtraStart({
            pagina: hookForm.watch("pagina"),
            paginacao: hookForm.watch("paginacao"),
            filtros: `taxaExtraID =="${data?.taxaExtraID}"`
        }));
    }


    useEffect(() => {
        dispatch(listarTiposDeVeiculoTaxaExtraStart({
            pagina: hookForm.watch("pagina"),
            paginacao: hookForm.watch("paginacao"),
            filtros: `taxaExtraID =="${data?.taxaExtraID}"`
        }));
    }, [hookForm.watch("pagina"), hookForm.watch("paginacao"), showSelector]);

    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Button
                variant="containedFilled"
                sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                onClick={() => setShowSelector(true)}
                startIcon={<AddCircleOutline />}
            >
                Adicionar novo tipo de veículo
            </Button>

            <ModalBase open={showSelector} onClose={() => setShowSelector(false)} headerModal={{ title: 'Adicionar novo tipo de veículo' }} size='sm'>
                <Stack spacing={2} p={2}>
                    <TipoDeVeiculo hookForm={hookForm} />
                    <Button
                        variant="containedFilled"
                        sx={{ alignSelf: 'flex-end', width: 'fit-content' }}
                        onClick={handleSave}
                        disabled={loadingSalvar}
                    >
                        Salvar
                    </Button>
                </Stack>
            </ModalBase>
            <Stack sx={{ flex: 1, gap: 2 }}>
                {tiposVeiculo?.ultimaPagina ? (
                    <TableNavigation
                        indiceInicial={tiposVeiculo.indiceInicial}
                        indiceFinal={tiposVeiculo.indiceFinal}
                        indiceTotal={tiposVeiculo.total}
                        ultimaPagina={tiposVeiculo?.ultimaPagina}
                        hookForm={hookForm}
                    />
                ) : null}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowLoading />
                        ) : tiposVeiculo?.lista?.length > 0 ? (
                            tiposVeiculo?.lista?.map((item: any) => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">{item.tipoVeiculo?.nome}</TableCell>
                                    <TableCell align="right">
                                        <IconButton aria-label="edit" size="small">
                                            <Edit color="warning" />
                                        </IconButton>
                                        <IconButton aria-label="delete" size="small">
                                            <Delete color="error" onClick={() => {
                                                setSelectedItem(item);
                                                setOpenDeletar(true)
                                            }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRowNotFound text="Nenhum resultado encontrado." fezRequisicao={true} />
                        )}
                    </TableBody>
                </Table>
            </Stack>

            <ModalOpcoes
                descricao={(
                    <Typography variant="body1" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        Tem certeza que deseja excluir <Typography component="span" fontWeight="bold" sx={{ display: 'inline' }}>{selectedItem?.tipoVeiculo?.nome}</Typography> da tabela?
                    </Typography>
                )}
                acao="Excluir"
                open={openDeletar}
                onClose={() => { setOpenDeletar(false) }}
                opcoes={[
                    {
                        label: 'Cancelar',
                        action: () => setOpenDeletar(false)
                    },
                    {
                        label: 'Excluir',
                        action: () => {
                            dispatch(excluirTipoDeVeiculoStart({
                                id: selectedItem.taxaExtraTipoVeiculoID,
                                taxaExtraID: selectedItem?.taxaExtraID
                            }))
                            setOpenDeletar(false)
                        }
                    }
                ]}
            />
        </Stack>
    );
}

export default TiposVeiculo