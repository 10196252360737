// TableCellOrdenacaoSimples.tsx
import React from 'react';
import { TableCell, SxProps, Theme, Button } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

interface TableCellOrdenacaoSimplesProps {
  label: string;
  column?: string;
  onSortChange?: (column: string, direction: 'asc' | 'desc') => void;
  sx?: SxProps<Theme>;
  sortDirection?: 'asc' | 'desc';
}

/**
 * Componente que renderiza uma célula de tabela com a funcionalidade de ordenação.
 * O componente exibe um botão que permite alternar entre as direções de ordenação (ascendente ou descendente) ao ser clicado.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {string} props.label - O rótulo visível da célula.
 * @param {string} props.column - O nome único da coluna que será utilizada para ordenação.
 * @param {Function} [props.onSortChange] - Função de callback chamada quando a direção de ordenação é alterada.
 * Ela recebe o nome da coluna e a nova direção da ordenação (asc ou desc).
 * 
 * @param {SxProps<Theme>} [props.sx] - Estilos personalizados para a célula. 
 * Pode ser utilizado para personalizar o comportamento visual da célula.
 * 
 * @param {('asc' | 'desc')} [props.sortDirection] - Direção de ordenação atual da coluna. 
 * Pode ser 'asc' para ascendente ou 'desc' para descendente. O valor padrão é 'asc' se não fornecido.
 * 
 * @note
 *  - O componente altera a direção de ordenação ao ser clicado e chama a função `onSortChange` se ela for fornecida.
 *  - O componente pode ser utilizado para qualquer célula de tabela que precise de ordenação interativa.
 *  - Ao clicar no botão, o ícone de seta também mudará de acordo com a direção da ordenação, oferecendo feedback visual ao usuário.
 * 
 * @example
 * <TableCellOrdenacaoSimples
 *   label="Origem"
 *   column="nome"
 *   sortDirection="asc"
 *   onSortChange={(column, direction) => {
 *     console.log(`Coluna: ${column}, Direção: ${direction}`);
 *   }}
 * />
 * 
 * @warning
 *  - A função `onSortChange` é opcional, mas deve ser fornecida se a funcionalidade de ordenação for necessária.
 *  - O botão de ordenação altera a direção a cada clique, alternando entre 'asc' e 'desc'.
 */
const TableCellOrdenacaoSimples: React.FC<TableCellOrdenacaoSimplesProps> = ({
  label,
  column,
  onSortChange,
  sx,
  sortDirection,
}) => {
  return (
    <TableCell align="center" sx={sx}>
      <Button
        aria-label={`ordenar por ${label}`}
        size="small"
        onClick={() => column && onSortChange?.(column, sortDirection === 'asc' ? 'desc' : 'asc')}
        disableRipple={!onSortChange}
        sx={(theme) => ({
          fontSize: '.8rem',
          color: (theme) => theme.palette.grey[700],
          borderRadius: '6px',
          cursor: column && onSortChange ? 'pointer' : 'default',
          ...theme.applyStyles('dark', {
            '&:hover': {
              backgroundColor: theme.palette.background.paperDark,
            }
          })
        })}
        startIcon={
          column ?
            sortDirection === 'asc' ? (
              <ArrowUpward fontSize="small" />
            ) : (
              <ArrowDownward fontSize="small" />
            )
            : undefined
        }
      >
        {label}
      </Button>
    </TableCell>
  );
};

export default TableCellOrdenacaoSimples;
